import { useEffect, useState } from "react";
import Text from "../../../components/gamification/modals/nudge/text";
import add from "../../../assets/svg/addW.svg";
import Variable, {
  typeReverseMap,
} from "../../../components/users/variables/variable";
import AddVariable from "./addVariable";
import { useDispatch, useSelector } from "react-redux";
import { getUserVariable } from "../../../redux/user/userActions";
import { useUserAuth } from "../../../hooks/useAuthContextProvider";
import Loader from "../../../components/common/loader";
import { toggleConfirmationPopup } from "../../../redux/api/apiActions";
import searchIcon from "../../../assets/svg/search.svg";

const UserVariables = () => {
  const [search, setSearch] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<string>("Date");
  const [open, setOpen] = useState<boolean>(false);
  const [userVariable, setUserVariable] = useState<string>("");
  // 1s2n3b4d
  const dispatch = useDispatch();
  const { user } = useUserAuth();

  const {
    isGettingUserVariable,
    getUserVariableResp,
    getUserVariableRespStatus,
    isDeletingUserVariable,
    deleteUserVariableRespStatus,
  } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (getUserVariableRespStatus !== 200) {
      dispatch(getUserVariable({ token: user.accessToken }));
    }
  }, []);

  useEffect(() => {
    if (deleteUserVariableRespStatus === 200) {
      dispatch(getUserVariable({ token: user.accessToken }));
      dispatch(toggleConfirmationPopup({}));
    }
  }, [isDeletingUserVariable]);

  return (
    <div className="bg-[#F9F9F9] text-[#4C4C66] h-[calc(100vh-135px)]">
      <div className="pt-10 px-10">
        <div className="flex items-center justify-between">
          <div className="text-xl font-bold">All created variables</div>
          <div className="flex items-center gap-3">
            <Text
              text={search}
              setText={setSearch}
              full={false}
              place={"Search variable"}
              required={false}
              type={"text"}
              icon={searchIcon}
            />
            <div
              className="bg-[#FF7B5F] flex items-center gap-3 rounded-[100px] cursor-pointer  text-white font-bold px-5 py-2"
              onClick={() => setOpen(true)}
            >
              Add a new variable
              <img src={add} alt="add" className="h-[20px] w-[20px]" />
            </div>
          </div>
        </div>
        <div className="mt-5 bg-white">
          <div className="border-4 border-[#EFF0F6] p-3 max-h-[calc(100vh-300px)] overflow-y-auto">
            {isGettingUserVariable && (
              <div className="flex items-center justify-center">
                <Loader heavy={true} />
              </div>
            )}
            {getUserVariableRespStatus === 200 &&
              (getUserVariableResp.data?.value?.filter(
                (each: any) =>
                  each.name?.toLowerCase().includes(search?.toLowerCase()) ||
                  typeReverseMap[each.type]
                    ?.toLowerCase()
                    .includes(search?.toLowerCase()) ||
                  each.variable_name_id
                    ?.toLowerCase()
                    ?.includes(search?.toLowerCase())
              )?.length > 0 ? (
                getUserVariableResp.data?.value
                  ?.filter(
                    (each: any) =>
                      each.name
                        ?.toLowerCase()
                        .includes(search?.toLowerCase()) ||
                      typeReverseMap[each.type]
                        ?.toLowerCase()
                        .includes(search?.toLowerCase()) ||
                      each.variable_name_id
                        ?.toLowerCase()
                        ?.includes(search?.toLowerCase())
                  )
                  ?.map((each: any) => {
                    return (
                      <Variable
                        variable={each}
                        setName={setName}
                        setType={setType}
                        setOpen={setOpen}
                        setUserVariable={setUserVariable}
                      />
                    );
                  })
              ) : (
                <div className="text-center">
                  {search.length > 0 ? "No result found :(" : "Add a variable"}
                </div>
              ))}
          </div>
        </div>
      </div>
      <AddVariable
        name={name}
        setName={setName}
        type={type}
        setType={setType}
        open={open}
        setOpen={setOpen}
        userVariable={userVariable}
        setUserVariable={setUserVariable}
      />
    </div>
  );
};

export default UserVariables;

import { useUserAuth } from "./useAuthContextProvider";

const useHomePathFinder = (path: string) => {
    let hasAccess: boolean = false;
    const { userDetails } = useUserAuth();
    // const pathsMap: any = {
    //   "/tokens": "tms",
    //   "/gamification": "streaks",
    // };

    const reversePathsMap: any = {
        tms: "/tokens",
        streaks: "/gamification",
        contests: "/contests",
        social: "/social",
    };
    hasAccess = userDetails?.client_id?.features_enabled?.find(
        (each: string) => {
            return each?.includes("streaks")
                ? reversePathsMap["streaks"] === path
                : reversePathsMap[each] === path;
        }
    );
    const availablePaths = userDetails?.client_id?.features_enabled?.map(
        (each: string) => {
            return each?.includes("streaks")
                ? reversePathsMap["streaks"]
                : reversePathsMap[each];
        }
    );
    return [hasAccess, availablePaths];
};

export default useHomePathFinder;

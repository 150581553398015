import {
  ADD_API_INIT,
  ADD_API_SUCCESS,
  ADD_API_FAILURE,
  UPDATE_API_INIT,
  UPDATE_API_SUCCESS,
  UPDATE_API_FAILURE,
  GET_API_INIT,
  GET_API_SUCCESS,
  GET_API_FAILURE,
  DELETE_API_INIT,
  DELETE_API_SUCCESS,
  DELETE_API_FAILURE,
  TOGGLE_CONFIRMATION_POPUP,
} from "../actionTypes";
import { IApiState } from "../../models/api/api";

const initialState = {
  isAddingApi: false,
  addApiError: false,
  addApiErrorMsg: "",
  addApiResp: [],
  addApiRespStatus: 0,
  isUpdatingApi: false,
  updateApiError: false,
  updateApiErrorMsg: "",
  updateApiResp: [],
  updateApiRespStatus: 0,
  isGettingApi: false,
  getApiError: false,
  getApiErrorMsg: "",
  getApiResp: [],
  getApiRespStatus: 0,
  isDeletingApi: false,
  deleteApiError: false,
  deleteApiErrorMsg: "",
  deleteApiResp: {},
  deleteApiRespStatus: 0,
  isConfirmation: false,
  // eslint-disable-next-line no-empty-pattern
  confirmationFunction: ({}) => console.log("return"),
  confirmationText: "",
};

const api = (state: IApiState = initialState, action: any) => {
  switch (action.type) {
    case ADD_API_INIT:
      return {
        ...state,
        isAddingApi: true,
        addApiError: false,
        addApiRespStatus: 0,
      };

    case ADD_API_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingApi: false,
        addApiResp: data,
        addApiRespStatus: 200,
      };
    }

    case ADD_API_FAILURE:
      const { error } = action;
      return {
        ...state,
        isAddingApi: false,
        addApiError: true,
        addApiErrorMsg: error,
        addApiRespStatus: 400,
      };

    case UPDATE_API_INIT:
      return {
        ...state,
        isUpdatingApi: true,
        updateApiError: false,
        updateApiRespStatus: 0,
      };

    case UPDATE_API_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingApi: false,
        updateApiResp: data,
        updateApiRespStatus: 200,
      };
    }

    case UPDATE_API_FAILURE:
      return {
        ...state,
        isUpdatingApi: false,
        updateApiError: true,
        updateApiErrorMsg: error.message,
        updateApiRespStatus: 400,
      };

    case GET_API_INIT:
      return {
        ...state,
        isGettingApi: true,
        getApiError: false,
        getApiRespStatus: 0,
      };

    case GET_API_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingApi: false,
        getApiResp: data,
        getApiRespStatus: 200,
      };
    }

    case GET_API_FAILURE:
      return {
        ...state,
        isGettingApi: false,
        getApiError: true,
        getApiErrorMsg: action.error.message,
        getApiRespStatus: 400,
      };

    case DELETE_API_INIT:
      return {
        ...state,
        isDeletingApi: true,
        deleteApiError: false,
        deleteApiRespStatus: 0,
      };

    case DELETE_API_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isDeletingApi: false,
        deleteApiResp: data,
        deleteApiRespStatus: 200,
      };
    }

    case DELETE_API_FAILURE:
      return {
        ...state,
        isDeletingApi: false,
        deleteApiError: true,
        deleteApiErrorMsg: action.error.message,
        deleteApiRespStatus: 400,
      };

    case TOGGLE_CONFIRMATION_POPUP:
      return {
        ...state,
        isConfirmation: action.data.isConfirmation ? true : false,
        confirmationFunction: action.data.isConfirmation
          ? action.data.confirmationFunction
          : // eslint-disable-next-line no-empty-pattern
            ({}) => console.log("terutn"),
        confirmationText: action.data.isConfirmation
          ? action.data.confirmationText
          : "",
      };
    default:
      return state;
  }
};

export default api;

import NotFound from "../../containers/notFound/notFound";
import Sidebar from "../../containers/sidebar/sidebar";
import { useUserAuth } from "../../hooks/useAuthContextProvider";

const NotFoundIndex = () => {
  const { user } = useUserAuth();
  return (
    <div className="text-white h-screen">
      <div className="flex">
        {user && <Sidebar />}
        <NotFound />
      </div>
    </div>
  );
};

export default NotFoundIndex;

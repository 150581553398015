export interface IValueProps {
  name: string;
  value: number | string | boolean;
  operator?: string;
  multi?: boolean;
  secondVal?: any;
}
const operatorMap: { [key: string]: string } = {
  e: " = ",
  lt: " < ",
  gt: " > ",
  bet: " in ",
  con: " contains ",
  in: " in ",
  nin: " not in ",
  ":": ":",
};
const Value = ({ name, value, operator, multi, secondVal }: IValueProps) => {
  return (
    <div className="px-3 py-[1.6px] text-sm flex items-center gap-2 bg-white rounded-[100px] mt-1 border border-[#FFAFA8]">
      <svg
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="3" cy="3" r="3" fill="#6200EE" />
      </svg>
      <div>{name}</div>
      {operator ? operatorMap[operator] : value? "|": ""}
      <div>{value === false ? "False" : value === true ? "True" : value}</div>
      {multi && secondVal ? `& ${secondVal}` : null}
    </div>
  );
};

export default Value;

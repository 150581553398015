import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomeIndex from "./screens/home";
import AnalyticsIndex from "./screens/analytics";
import BusinessIndex from "./screens/business";
import ClubConsoleIndex from "./screens/club-console";
import GamificationIndex from "./screens/gamification";
import DeveloperToolsIndex from "./screens/developerTools";
import SettingsIndex from "./screens/settings";
import SupportIndex from "./screens/support";
import TokensIndex from "./screens/tokens";
import SnipeSocialIndex from "./screens/snipeSocial";
import LoggedInRoute from "./components/common/loggedInRoute";
import AuthIndex from "./screens/auth";
import ProtectedRoute from "./components/common/privateRoute";
import { UserAuthContextProvider } from "./hooks/useAuthContextProvider";
import Onboarding from "./screens/auth/onboarding";
import Modal from "react-modal";
import { customStyles } from "./containers/gamification/campaign";
import { useDispatch, useSelector } from "react-redux";
import { toggleConfirmationPopup } from "./redux/api/apiActions";
import Loader from "./components/common/loader";
import NotFoundIndex from "./screens/notFound";
import InternalServerErrorIndex from "./screens/internalServerError";
import BugIndex from "./screens/bug";
import RestrictedIndex from "./screens/restricted";
import UserIndex from "./screens/users";
import ContestIndex from "./screens/contests";
import EventIndex from "./screens/events";

function App() {
    const {
        isConfirmation,
        confirmationFunction,
        confirmationText,
        isUpdatingApi,
        isAddingApi,
        isDeletingApi,
    } = useSelector((state: any) => state.api);
    const { isDeletingEvent } = useSelector((state: any) => state.events);
    const { isDeletingUserVariable } = useSelector((state: any) => state.user);
    const { isDeletingRole } = useSelector((state: any) => state.settings);
    const dispatch = useDispatch();
    return (
        <div className="App">
            <Router>
                <UserAuthContextProvider>
                    <Routes>
                        <Route
                            path="/login"
                            element={
                                <LoggedInRoute>
                                    <AuthIndex />
                                </LoggedInRoute>
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute>
                                    <UserIndex />
                                    {/* <HomeIndex /> */}
                                </ProtectedRoute>
                            }
                        />
                        <Route path="/analytics" element={<AnalyticsIndex />} />
                        <Route path="/business" element={<BusinessIndex />} />
                        <Route
                            path="club-console"
                            element={<ClubConsoleIndex />}
                        />
                        <Route
                            path="/tokens"
                            element={
                                <ProtectedRoute>
                                    <TokensIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/gamification"
                            element={
                                <ProtectedRoute>
                                    <GamificationIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/social"
                            element={
                                <ProtectedRoute>
                                    <SnipeSocialIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/contests"
                            element={
                                <ProtectedRoute>
                                    <ContestIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/events"
                            element={
                                <ProtectedRoute>
                                    <EventIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/developer-tools"
                            element={
                                <ProtectedRoute>
                                    <DeveloperToolsIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/settings"
                            element={
                                <ProtectedRoute>
                                    <SettingsIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route path="/support" element={<SupportIndex />} />
                        <Route
                            path="/users"
                            element={
                                <ProtectedRoute>
                                    <UserIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/restricted"
                            element={<RestrictedIndex />}
                        />
                        <Route path="/bug" element={<BugIndex />} />
                        <Route
                            path="/internalServerError"
                            element={<InternalServerErrorIndex />}
                        />
                        <Route path="*" element={<NotFoundIndex />} />
                        <Route
                            path="/onboarding"
                            element={
                                <ProtectedRoute>
                                    <Onboarding />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </UserAuthContextProvider>
            </Router>
            <Modal
                isOpen={isConfirmation}
                style={customStyles}
                onRequestClose={() =>
                    toggleConfirmationPopup({ isConfirmation: false })
                }
                shouldCloseOnOverlayClick={
                    !isUpdatingApi ||
                    !isAddingApi ||
                    !isDeletingApi ||
                    !isDeletingEvent ||
                    !isDeletingUserVariable ||
                    !isDeletingRole
                }
            >
                <div className="p-5">
                    <div className="text-lg text-[#4C4C66]">
                        {confirmationText}
                    </div>
                    <div className="flex items-center justify-end gap-5 mt-5">
                        <button
                            disabled={
                                isUpdatingApi ||
                                isAddingApi ||
                                isDeletingApi ||
                                isDeletingUserVariable ||
                                isDeletingRole
                            }
                            className="border-2 border-[#FF7B5F] rounded bg-white cursor-pointer px-5 py-2"
                            onClick={() =>
                                dispatch(
                                    toggleConfirmationPopup({
                                        isConfirmation: false,
                                    })
                                )
                            }
                        >
                            Cancel
                        </button>
                        <button
                            className="outline-none border-2 border-[#FF7B5F] rounded text-[#FFFFFF] bg-[#FF7B5F] cursor-pointer px-5 py-2"
                            onClick={() => confirmationFunction()}
                            disabled={
                                isUpdatingApi ||
                                isAddingApi ||
                                isDeletingApi ||
                                isDeletingEvent ||
                                isDeletingUserVariable ||
                                isDeletingRole
                            }
                        >
                            {isUpdatingApi ||
                            isAddingApi ||
                            isDeletingApi ||
                            isDeletingEvent ||
                            isDeletingUserVariable ||
                            isDeletingRole ? (
                                <Loader />
                            ) : (
                                "Yes"
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default App;

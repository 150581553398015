// import Gamification from "../../containers/gamification/gamification";
import Settings from "../../containers/settings/settings";
import Sidebar from "../../containers/sidebar/sidebar";

const SettingsIndex = () => {
  return (
    <div className="text-white  min-h-screen">
      <div className="flex gap-10">
        <Sidebar />
        <Settings />
      </div>
    </div>
  );
};

export default SettingsIndex;

import Modal from "react-modal";
import { customStyles } from "../../containers/gamification/campaign";
import Text from "../../components/gamification/modals/nudge/text";
import Radio from "../gamification/modals/nudge/radio";
import { useState } from "react";

interface ComponentProps {
  showModalr: boolean;
  handleCloseModalRef: () => void;
  handleContr: () => void;
  selectedReward: any;
  selectedReferrerRewards: any;
  selectedRefereeRewards: any;
  setSelectedReferrerRewards: (selectedItems: any) => void;
  setSelectedRefereeRewards: (selectedItems: any) => void;
  allows: any;
  setAllows: (setAllows: any) => void;
  day: any;
  setDay: (setDay: any) => void;
  allowed: boolean;
  setAllowed: (setAllowed: boolean) => void;
  active: string;
  setActive: (setActive: string) => void;
  type: any;
  setType: (
    heme: "referee" | "referrer",
    event_id: string,
    token_id: string,
    valueType: "min_spend" | "cap_value"
  ) => void;
  events: [];
  tokens: [];
}

const AddRewards = ({
  showModalr,
  handleCloseModalRef,
  handleContr,
  selectedReward,
  selectedReferrerRewards,
  selectedRefereeRewards,
  setSelectedReferrerRewards,
  setSelectedRefereeRewards,
  allows,
  setAllows,
  day,
  setDay,
  allowed,
  setAllowed,
  active,
  setActive,
  type,
  setType,
  tokens,
  events,
}: ComponentProps) => {
  const [tokenTypes, setTokenTypes] = useState<{
    [token_id: string]: { [event_id: string]: string };
  }>({});

  const [rewardType, setRewardType] = useState<{ [event_id: string]: string }>(
    {}
  );

  const [showDetails, setShowDetails] = useState<{
    [token_id: string]: boolean;
  }>({});

  const handleItemClicksReferrer = (index: string, event_id: string) => {
    if (
      selectedReferrerRewards.some(
        (i: any) => i.token_id === index && i.event_id === event_id
      )
    ) {
      setSelectedReferrerRewards((prev: any) => {
        const updatedItems = prev.filter(
          (item: any) => item.token_id !== index || item.event_id !== event_id
        );
        return updatedItems;
      });
    } else {
      setSelectedReferrerRewards([
        ...selectedReferrerRewards,
        {
          token_id: index,
          event_id: event_id,
        },
      ]);
    }
  };

  const handleItemClicksReferee = (index: string, event_id: string) => {
    if (
      selectedRefereeRewards.some(
        (i: any) => i.token_id === index && i.event_id === event_id
      )
    ) {
      setSelectedRefereeRewards((prev: any) => {
        const updatedItems = prev.filter(
          (item: any) => item.token_id !== index || item.event_id !== event_id
        );
        return updatedItems;
      });
    } else {
      setSelectedRefereeRewards([
        ...selectedRefereeRewards,
        {
          token_id: index,
          event_id: event_id,
        },
      ]);
    }
  };

  const handleInputChangeReferrer = (
    e: any,
    token_id: any,
    event_id: any,
    fieldName: any
  ) => {
    const newValue = parseInt(e);

    setSelectedReferrerRewards((prevItems: any) => {
      const updatedItems = prevItems.map((item: any) => {
        if (item.event_id === event_id && item.token_id === token_id) {
          return {
            ...item,
            value: {
              ...item.value,
              [fieldName]: newValue,
            },
            event_id,
          };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const handleInputChangeReferee = (
    e: any,
    token_id: any,
    event_id: any,
    fieldName: any
  ) => {
    const newValue = parseInt(e);

    setSelectedRefereeRewards((prevItems: any) => {
      const updatedItems = prevItems.map((item: any) => {
        if (item.event_id === event_id && item.token_id === token_id) {
          return {
            ...item,
            value: {
              ...item.value,
              [fieldName]: newValue,
            },
            event_id,
          };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const handleChangeTokenType = (
    token_id: string,
    event_id: string,
    type: string,
    rewardFor: string
  ) => {
    setTokenTypes((prevTypes: any) => ({
      ...prevTypes,
      [token_id]: {
        ...prevTypes?.[token_id],
        [rewardFor]: {
          ...prevTypes?.[token_id]?.[rewardFor],
          [event_id]: type,
        },
      },
    }));
  };

  const handleChangeRewardType = (event_id: string, type: string) => {
    setRewardType((prevTypes) => ({
      ...prevTypes,
      [event_id]: type,
    }));

    const referrerReward = selectedReferrerRewards?.filter(
      (rewards: any) => rewards.event_id === event_id
    );

    if (type === "Same as Referrer") {
      setSelectedRefereeRewards((prev: any) => {
        const updatedItems = prev.filter(
          (item: any) => item.event_id !== event_id
        );
        const newItems = [...updatedItems, ...referrerReward];
        console.log(newItems);
        return newItems;
      });
    }
  };

  const toggleTokenDetails = (event_id: string) => {
    setShowDetails((prevDetails) => ({
      ...prevDetails,
      [event_id]: !prevDetails[event_id],
    }));
  };

  return (
    <Modal
      style={customStyles}
      isOpen={showModalr}
      onRequestClose={handleCloseModalRef}
      shouldCloseOnOverlayClick={true}
    >
      <form>
        <div className="p-5">
          <div className="flex justify-between w-[750px]">
            <div>
              <div className="text-2xl text-[#4C4C66] font-bold">
                Select Rewards
              </div>
            </div>

            <div className="cursor-pointer" onClick={handleCloseModalRef}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                  fill="#4C4C66"
                />
              </svg>
            </div>
          </div>
          <div className="flex items-center gap-3 mt-10">
            <div
              className={
                "text-lg cursor-pointer text-[#5F5E76]" +
                (active === "Referrer-Rewards"
                  ? " border-b-2 border-[#5F5E76] font-bold"
                  : "")
              }
              onClick={() => setActive("Referrer-Rewards")}
            >
              Referrer Rewards
            </div>
            <div
              className={
                "text-lg cursor-pointer text-[#5F5E76]" +
                (active === "Referee-Rewards"
                  ? " border-b-2 border-[#5F5E76] font-bold"
                  : "")
              }
              onClick={() => setActive("Referee-Rewards")}
            >
              Referee Rewards
            </div>
          </div>
          <div className="text-[#5F5E76] text-md mt-3">
            Select Rewards for each event
          </div>
          {active && active === "Referrer-Rewards" && (
            <div className="max-h-[500px] overflow-y-auto">
              {selectedReward &&
                selectedReward.length > 0 &&
                events
                  .filter((event: any) =>
                    selectedReward.some((e: any) => e?._id === event._id)
                  )
                  .map((event: any) => (
                    <div
                      key={event._id}
                      className="rounded border-2 border-[#FFAFA8] bg-[#FFE8E6] cursor-pointer flex flex-col gap-3 p-3 max-w-[750px] m-2"
                    >
                      <div className="flex items-center gap-3">
                        <div className="text-lg text-[#4C4C66]">
                          {event.name}
                        </div>
                        <div className="text-lg text-[#CEB0FA]">|</div>
                        <div className="text-sm text-[#4C4C66]">
                          type:&nbsp;
                          {event.transaction_type
                            ? !event.is_transaction_based
                              ? "Non-Transaction Based"
                              : "Transaction Based"
                            : "Non-Transaction Based"}
                        </div>
                        <div
                          className="text-sm text-[#4C4C66] cursor-pointer capitalize ml-auto"
                          onClick={() => toggleTokenDetails(event._id)}
                        >
                          {!showDetails[event._id] ? (
                            <span className="flex flex-row gap-2 items-center">
                              <span>Show Details</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                className="w-3 h-3"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span className="flex flex-row gap-2 items-center">
                              <span>Hide Details</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                className="w-3 h-3"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 15.75l7.5-7.5 7.5 7.5"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      </div>
                      {showDetails[event._id] && (
                        <div className="text-sm text-[#4C4C66]">
                          Select a token and enter details*
                        </div>
                      )}
                      {showDetails[event._id] &&
                      selectedReward.some((e: any) => e?._id === event._id) ? (
                        <div className="bg-white p-3 rounded flex flex-col gap-2">
                          {tokens &&
                            tokens.length > 0 &&
                            tokens.map((token: any) => {
                              return (
                                <div
                                  className={`overflow-y-auto rounded border-2 border-[#FFAFA8] cursor-pointer p-3
                      			  ${
                                selectedReferrerRewards.some(
                                  (reward: any) =>
                                    reward.token_id === token._id &&
                                    reward.event_id === event._id
                                )
                                  ? " bg-white"
                                  : " bg-[#FFF2F2]"
                              }`}
                                >
                                  <div
                                    key={token._id}
                                    className={"flex items-center gap-3"}
                                  >
                                    {selectedReferrerRewards.some(
                                      (reward: any) =>
                                        reward.token_id === token._id &&
                                        reward.event_id === event._id
                                    ) ? (
                                      <div className="flex flex-col">
                                        <div className="flex flex-row items-center gap-2">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={() =>
                                              handleItemClicksReferrer(
                                                token._id,
                                                event._id
                                              )
                                            }
                                          >
                                            <path
                                              d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                              fill="#5F5E76"
                                            />
                                          </svg>
                                          <div className="flex items-center gap-3">
                                            <div className="text-lg font-bold">
                                              {token.name}
                                            </div>
                                          </div>
                                        </div>

                                        {event.transaction_type === 0 ? (
                                          <div className="">
                                            <div
                                              className="flex flex-row"
                                              onClick={(e: any) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              <div>
                                                <div className="mt-4 text-[14px] text-[#4C4C66] w-[650px]">
                                                  Number of tokens to give{" "}
                                                </div>
                                                <Text
                                                  text={
                                                    selectedReferrerRewards?.find(
                                                      (i: any) =>
                                                        i.token_id ===
                                                          token._id &&
                                                        i.event_id === event._id
                                                    )?.value?.percentage
                                                  }
                                                  setText={(e: any) =>
                                                    handleInputChangeReferrer(
                                                      e,
                                                      token._id,
                                                      event._id,
                                                      "percentage"
                                                    )
                                                  }
                                                  place={"e.g 5"}
                                                  full={true}
                                                  required={true}
                                                  type={"number"}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : event.is_transaction_based ? (
                                          <div className="flex flex-col">
                                            <div className="flex items-center gap-3 mt-4">
                                              {[
                                                "Constant",
                                                "Percentage based",
                                              ].map((each: any) => {
                                                return (
                                                  <Radio
                                                    selected={
                                                      tokenTypes[token._id]
                                                        ?.referrer?.[event._id]
                                                    }
                                                    setSelected={(
                                                      type: string
                                                    ) =>
                                                      handleChangeTokenType(
                                                        token._id,
                                                        event._id,
                                                        type,
                                                        "referrer"
                                                      )
                                                    }
                                                    each={each}
                                                    multi={false}
                                                  />
                                                );
                                              })}
                                            </div>
                                            {tokenTypes[token._id] && (
                                              <div className="flex flex-row gap-2">
                                                {tokenTypes[token._id] &&
                                                  tokenTypes[token._id]
                                                    ?.referrer?.[event._id] ===
                                                    "Constant" && (
                                                    <>
                                                      <div>
                                                        <div className="mt-4 text-[14px] text-[#4C4C66] w-[200px]">
                                                          Number of tokens to
                                                          give
                                                        </div>
                                                        <Text
                                                          text={
                                                            selectedReferrerRewards?.find(
                                                              (i: any) =>
                                                                i.token_id ===
                                                                  token._id &&
                                                                i.event_id ===
                                                                  event._id
                                                            )?.value?.noTokens
                                                          }
                                                          setText={(e: any) =>
                                                            handleInputChangeReferrer(
                                                              e,
                                                              token._id,
                                                              event._id,
                                                              "noTokens"
                                                            )
                                                          }
                                                          place={"e.g 5"}
                                                          full={false}
                                                          required={true}
                                                          type={"number"}
                                                        />
                                                      </div>
                                                      <div>
                                                        <div className="mt-4 text-[14px] text-[#4C4C66] w-[200px]">
                                                          Min spend
                                                        </div>
                                                        <Text
                                                          text={
                                                            selectedReferrerRewards?.find(
                                                              (i: any) =>
                                                                i.token_id ===
                                                                  token._id &&
                                                                i.event_id ===
                                                                  event._id
                                                            )?.value?.minSpend
                                                          }
                                                          setText={(e: any) =>
                                                            handleInputChangeReferrer(
                                                              e,
                                                              token._id,
                                                              event._id,
                                                              "minSpend"
                                                            )
                                                          }
                                                          place={"e.g 5"}
                                                          full={true}
                                                          required={true}
                                                          type={"number"}
                                                        />
                                                      </div>
                                                    </>
                                                  )}
                                                {tokenTypes[token._id] &&
                                                  tokenTypes[token._id]
                                                    ?.referrer?.[event._id] ===
                                                    "Percentage based" && (
                                                    <>
                                                      <div>
                                                        <div className="mt-4 text-[14px] text-[#4C4C66] w-[200px]">
                                                          Percentage
                                                        </div>
                                                        <Text
                                                          text={
                                                            selectedReferrerRewards?.find(
                                                              (i: any) =>
                                                                i.token_id ===
                                                                  token._id &&
                                                                i.event_id ===
                                                                  event._id
                                                            )?.value?.percentage
                                                          }
                                                          setText={(e: any) =>
                                                            handleInputChangeReferrer(
                                                              e,
                                                              token._id,
                                                              event._id,
                                                              "percentage"
                                                            )
                                                          }
                                                          place={"e.g 5"}
                                                          full={true}
                                                          required={true}
                                                          type={"number"}
                                                        />
                                                      </div>
                                                      <div className="cursor-pointer gap-3 p-3 max-w-[750px]">
                                                        <div className="flex flex-row items-center gap-3">
                                                          <div className="">
                                                            <div
                                                              className="items-center flex flex-row"
                                                              onClick={() =>
                                                                setType(
                                                                  "referrer",
                                                                  event._id,
                                                                  token._id,
                                                                  "cap_value"
                                                                )
                                                              }
                                                            >
                                                              {type?.referrer?.[
                                                                event._id
                                                              ]?.[token._id]
                                                                ?.cap_value ? (
                                                                <svg
                                                                  width="24"
                                                                  height="24"
                                                                  viewBox="0 0 24 24"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                                    fill="#5F5E76"
                                                                  />
                                                                </svg>
                                                              ) : (
                                                                <svg
                                                                  width="24"
                                                                  height="24"
                                                                  viewBox="0 0 24 24"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                                    fill="#FFAFA8"
                                                                  />
                                                                </svg>
                                                              )}
                                                              <div className="text-[14px] text-[#4C4C66]">
                                                                Enter Cap Value
                                                              </div>
                                                            </div>
                                                            {type?.referrer?.[
                                                              event._id
                                                            ]?.[token._id]
                                                              ?.cap_value ? (
                                                              <Text
                                                                text={
                                                                  selectedReferrerRewards?.find(
                                                                    (i: any) =>
                                                                      i.token_id ===
                                                                        token._id &&
                                                                      i.event_id ===
                                                                        event._id
                                                                  )?.value
                                                                    ?.capValue
                                                                }
                                                                setText={(
                                                                  e: any
                                                                ) =>
                                                                  handleInputChangeReferrer(
                                                                    e,
                                                                    token._id,
                                                                    event._id,
                                                                    "capValue"
                                                                  )
                                                                }
                                                                place={"e.g 5"}
                                                                full={true}
                                                                required={true}
                                                                type={"number"}
                                                              />
                                                            ) : (
                                                              <>
                                                                <input
                                                                  type="text"
                                                                  className="text-gray-500 bg-gray-100 rounded-md p-2 mt-1 w-full"
                                                                  placeholder="e.g 5"
                                                                  disabled
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="cursor-pointer gap-3 p-3 max-w-[750px]">
                                                        <div className="flex flex-row items-center gap-3">
                                                          <div className="">
                                                            <div
                                                              className="items-center flex flex-row"
                                                              onClick={() =>
                                                                setType(
                                                                  "referrer",
                                                                  event._id,
                                                                  token._id,
                                                                  "min_spend"
                                                                )
                                                              }
                                                            >
                                                              {type?.referrer?.[
                                                                event._id
                                                              ]?.[token._id]
                                                                ?.min_spend ? (
                                                                <svg
                                                                  width="24"
                                                                  height="24"
                                                                  viewBox="0 0 24 24"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                                    fill="#5F5E76"
                                                                  />
                                                                </svg>
                                                              ) : (
                                                                <svg
                                                                  width="24"
                                                                  height="24"
                                                                  viewBox="0 0 24 24"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                                    fill="#FFAFA8"
                                                                  />
                                                                </svg>
                                                              )}
                                                              <div className="text-[14px] text-[#4C4C66]">
                                                                Min spend
                                                              </div>
                                                            </div>
                                                            {type?.referrer?.[
                                                              event._id
                                                            ]?.[token._id]
                                                              ?.min_spend ? (
                                                              <Text
                                                                text={
                                                                  selectedReferrerRewards?.find(
                                                                    (i: any) =>
                                                                      i.token_id ===
                                                                        token._id &&
                                                                      i.event_id ===
                                                                        event._id
                                                                  )?.value
                                                                    ?.minSpend
                                                                }
                                                                setText={(
                                                                  e: any
                                                                ) =>
                                                                  handleInputChangeReferrer(
                                                                    e,
                                                                    token._id,
                                                                    event._id,
                                                                    "minSpend"
                                                                  )
                                                                }
                                                                place={"e.g 5"}
                                                                full={true}
                                                                required={true}
                                                                type={"number"}
                                                              />
                                                            ) : (
                                                              <>
                                                                <input
                                                                  type="text"
                                                                  className="text-gray-500 bg-gray-100 rounded-md p-2 mt-1 w-full"
                                                                  placeholder="e.g 5"
                                                                  disabled
                                                                />
                                                              </>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="">
                                            <div
                                              className="flex flex-row"
                                              onClick={(e: any) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              <div>
                                                <div className="mt-4 text-[14px] text-[#4C4C66] w-[650px]">
                                                  Number of tokens to give{" "}
                                                </div>
                                                <Text
                                                  text={
                                                    selectedReferrerRewards?.find(
                                                      (i: any) =>
                                                        i.token_id ===
                                                          token._id &&
                                                        i.event_id === event._id
                                                    )?.value?.noTokens
                                                  }
                                                  setText={(e: any) =>
                                                    handleInputChangeReferrer(
                                                      e,
                                                      token._id,
                                                      event._id,
                                                      "noTokens"
                                                    )
                                                  }
                                                  place={"e.g 5"}
                                                  full={true}
                                                  required={true}
                                                  type={"number"}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="flex flex-row items-center gap-2">
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          onClick={() =>
                                            handleItemClicksReferrer(
                                              token._id,
                                              event._id
                                            )
                                          }
                                        >
                                          <path
                                            d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3.43934 4.10218 3.43934 4.5V19.5C3.43934 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3.43934 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                            fill="#FFAFA8"
                                          />
                                        </svg>
                                        <div className="flex items-center gap-3">
                                          <div className="text-lg font-bold">
                                            {token.name}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
            </div>
          )}
          {active && active === "Referee-Rewards" && (
            <div className="max-h-[500px] overflow-y-auto">
              {selectedReward &&
                selectedReward.length > 0 &&
                events
                  .filter((event: any) =>
                    selectedReward.some((e: any) => e?._id === event._id)
                  )
                  .map((event: any) => (
                    <div
                      key={event._id}
                      className="rounded border-2 border-[#FFAFA8] bg-[#FFE8E6] cursor-pointer flex flex-col gap-3 p-3 max-w-[750px] m-2"
                    >
                      <div className="flex items-center gap-3">
                        <div className="text-lg text-[#4C4C66]">
                          {event.name}
                        </div>
                        <div className="text-lg text-[#CEB0FA]">|</div>
                        <div className="text-sm text-[#4C4C66]">
                          type:&nbsp;
                          {event.transaction_type
                            ? !event.is_transaction_based
                              ? "Non-Transaction Based"
                              : "Transaction Based"
                            : "Non-Transaction Based"}
                        </div>
                        <div
                          className="text-sm text-[#4C4C66] cursor-pointer capitalize ml-auto"
                          onClick={() => toggleTokenDetails(event._id)}
                        >
                          {!showDetails[event._id] ? (
                            <span className="flex flex-row gap-2 items-center">
                              <span>Show Details</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                className="w-3 h-3"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span className="flex flex-row gap-2 items-center">
                              <span>Hide Details</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                className="w-3 h-3"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4.5 15.75l7.5-7.5 7.5 7.5"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      </div>

                      {showDetails[event._id] && (
                        <div>
                          <div>Reward type</div>
                          <div className="flex items-center gap-3 mt-1 mb-2">
                            {["Same as Referrer", "Custom rewards"].map(
                              (each: any) => {
                                return (
                                  <Radio
                                    selected={rewardType[event._id]}
                                    setSelected={(type: string) =>
                                      handleChangeRewardType(event._id, type)
                                    }
                                    each={each}
                                    multi={false}
                                    white
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                      {showDetails[event._id] &&
                      selectedReward.some((e: any) => e?._id === event._id) ? (
                        <div
                          className={`bg-white rounded flex flex-col ${
                            rewardType[event._id] === "Custom rewards"
                              ? "gap-2 p-3"
                              : ""
                          }`}
                        >
                          {tokens &&
                            tokens.length > 0 &&
                            tokens.map((token: any) => {
                              return (
                                <div>
                                  {rewardType[event._id] ===
                                    "Custom rewards" && (
                                    <div
                                      className={`overflow-y-auto rounded border-2 border-[#FFAFA8] cursor-pointer p-3
                          			  ${
                                    selectedReferrerRewards.some(
                                      (reward: any) =>
                                        reward.token_id === token._id &&
                                        reward.event_id === event._id
                                    )
                                      ? " bg-white"
                                      : " bg-[#FFF2F2]"
                                  }`}
                                    >
                                      <div
                                        key={token._id}
                                        className={"flex items-center gap-3"}
                                      >
                                        {selectedRefereeRewards.some(
                                          (reward: any) =>
                                            reward.token_id === token._id &&
                                            reward.event_id === event._id
                                        ) ? (
                                          <div className="flex flex-col">
                                            <div className="flex flex-row items-center gap-2">
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                onClick={() =>
                                                  handleItemClicksReferee(
                                                    token._id,
                                                    event._id
                                                  )
                                                }
                                              >
                                                <path
                                                  d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                  fill="#5F5E76"
                                                />
                                              </svg>
                                              <div className="flex items-center gap-3">
                                                <div className="text-lg font-bold">
                                                  {token.name}
                                                </div>
                                              </div>
                                            </div>
                                            {event.is_transaction_based ===
                                            false ? (
                                              <div className="">
                                                <div
                                                  className="flex flex-row"
                                                  onClick={(e: any) =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  <div>
                                                    <div className="mt-4 text-[14px] text-[#4C4C66] w-[650px]">
                                                      Number of tokens to give{" "}
                                                    </div>
                                                    <Text
                                                      text={
                                                        selectedRefereeRewards?.find(
                                                          (i: any) =>
                                                            i.token_id ===
                                                              token._id &&
                                                            i.event_id ===
                                                              event._id
                                                        )?.value?.percentage
                                                      }
                                                      setText={(e: any) =>
                                                        handleInputChangeReferee(
                                                          e,
                                                          token._id,
                                                          event._id,
                                                          "percentage"
                                                        )
                                                      }
                                                      place={"e.g 5"}
                                                      full={true}
                                                      required={true}
                                                      type={"number"}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : event.transaction_type ? (
                                              <div className="flex flex-col">
                                                <div className="flex items-center gap-3 mt-2">
                                                  {[
                                                    "Constant",
                                                    "Percentage based",
                                                  ].map((each: any) => {
                                                    return (
                                                      <Radio
                                                        selected={
                                                          tokenTypes[token._id]
                                                            ?.referee?.[
                                                            event._id
                                                          ]
                                                        }
                                                        setSelected={(
                                                          type: string
                                                        ) =>
                                                          handleChangeTokenType(
                                                            token._id,
                                                            event._id,
                                                            type,
                                                            "referee"
                                                          )
                                                        }
                                                        each={each}
                                                        multi={false}
                                                      />
                                                    );
                                                  })}
                                                </div>
                                                {tokenTypes[token._id] && (
                                                  <div className="flex flex-row gap-2">
                                                    {tokenTypes[token._id] &&
                                                      tokenTypes[token._id]
                                                        ?.referee?.[
                                                        event._id
                                                      ] === "Constant" && (
                                                        <>
                                                          <div>
                                                            <div className="mt-4 text-[14px] text-[#4C4C66] w-[200px]">
                                                              Number of tokens
                                                              to give
                                                            </div>
                                                            <Text
                                                              text={
                                                                selectedRefereeRewards?.find(
                                                                  (i: any) =>
                                                                    i.token_id ===
                                                                      token._id &&
                                                                    i.event_id ===
                                                                      event._id
                                                                )?.value
                                                                  ?.noTokens
                                                              }
                                                              setText={(
                                                                e: any
                                                              ) =>
                                                                handleInputChangeReferee(
                                                                  e,
                                                                  token._id,
                                                                  event._id,
                                                                  "noTokens"
                                                                )
                                                              }
                                                              place={"e.g 5"}
                                                              full={true}
                                                              required={true}
                                                              type={"number"}
                                                            />
                                                          </div>
                                                          <div>
                                                            <div className="mt-4 text-[14px] text-[#4C4C66] w-[200px]">
                                                              Min spend
                                                            </div>
                                                            <Text
                                                              text={
                                                                selectedRefereeRewards?.find(
                                                                  (i: any) =>
                                                                    i.token_id ===
                                                                      token._id &&
                                                                    i.event_id ===
                                                                      event._id
                                                                )?.value
                                                                  ?.minSpend
                                                              }
                                                              setText={(
                                                                e: any
                                                              ) =>
                                                                handleInputChangeReferee(
                                                                  e,
                                                                  token._id,
                                                                  event._id,
                                                                  "minSpend"
                                                                )
                                                              }
                                                              place={"e.g 5"}
                                                              full={true}
                                                              required={true}
                                                              type={"number"}
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                    {tokenTypes[token._id] &&
                                                      tokenTypes[token._id]
                                                        ?.referee?.[
                                                        event._id
                                                      ] ===
                                                        "Percentage based" && (
                                                        <>
                                                          <div>
                                                            <div className="mt-4 text-[14px] text-[#4C4C66] w-[200px]">
                                                              Percentage
                                                            </div>
                                                            <Text
                                                              text={
                                                                selectedRefereeRewards?.find(
                                                                  (i: any) =>
                                                                    i.token_id ===
                                                                      token._id &&
                                                                    i.event_id ===
                                                                      event._id
                                                                )?.value
                                                                  ?.percentage
                                                              }
                                                              setText={(
                                                                e: any
                                                              ) =>
                                                                handleInputChangeReferee(
                                                                  e,
                                                                  token._id,
                                                                  event._id,
                                                                  "percentage"
                                                                )
                                                              }
                                                              place={"e.g 5"}
                                                              full={true}
                                                              required={true}
                                                              type={"number"}
                                                            />
                                                          </div>
                                                          <div className="cursor-pointer gap-3 p-3 max-w-[750px]">
                                                            <div className="flex flex-row items-center gap-3">
                                                              <div className="">
                                                                <div
                                                                  className="items-center flex flex-row"
                                                                  onClick={() =>
                                                                    setAllows(
                                                                      !allows
                                                                    )
                                                                  }
                                                                >
                                                                  {allows ? (
                                                                    <svg
                                                                      width="24"
                                                                      height="24"
                                                                      viewBox="0 0 24 24"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <path
                                                                        d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                                        fill="#5F5E76"
                                                                      />
                                                                    </svg>
                                                                  ) : (
                                                                    <svg
                                                                      width="24"
                                                                      height="24"
                                                                      viewBox="0 0 24 24"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <path
                                                                        d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                                        fill="#FFAFA8"
                                                                      />
                                                                    </svg>
                                                                  )}
                                                                  <div className="text-[14px] text-[#4C4C66]">
                                                                    Enter Cap
                                                                    Value
                                                                  </div>
                                                                </div>
                                                                {allows ? (
                                                                  <Text
                                                                    text={
                                                                      selectedRefereeRewards?.find(
                                                                        (
                                                                          i: any
                                                                        ) =>
                                                                          i.token_id ===
                                                                            token._id &&
                                                                          i.event_id ===
                                                                            event._id
                                                                      )?.value
                                                                        ?.capValue
                                                                    }
                                                                    setText={(
                                                                      e: any
                                                                    ) =>
                                                                      handleInputChangeReferee(
                                                                        e,
                                                                        token._id,
                                                                        event._id,
                                                                        "capValue"
                                                                      )
                                                                    }
                                                                    place={
                                                                      "e.g 5"
                                                                    }
                                                                    full={true}
                                                                    required={
                                                                      true
                                                                    }
                                                                    type={
                                                                      "number"
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <>
                                                                    <input
                                                                      type="text"
                                                                      className="text-gray-500 bg-gray-100 rounded-md p-2 mt-1 w-full"
                                                                      placeholder="e.g 5"
                                                                      disabled
                                                                    />
                                                                  </>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="cursor-pointer gap-3 p-3 max-w-[750px]">
                                                            <div className="flex flex-row items-center gap-3">
                                                              <div className="">
                                                                <div
                                                                  className="items-center flex flex-row"
                                                                  onClick={() =>
                                                                    setAllowed(
                                                                      !allowed
                                                                    )
                                                                  }
                                                                >
                                                                  {allowed ? (
                                                                    <svg
                                                                      width="24"
                                                                      height="24"
                                                                      viewBox="0 0 24 24"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <path
                                                                        d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                                        fill="#5F5E76"
                                                                      />
                                                                    </svg>
                                                                  ) : (
                                                                    <svg
                                                                      width="24"
                                                                      height="24"
                                                                      viewBox="0 0 24 24"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <path
                                                                        d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                                        fill="#FFAFA8"
                                                                      />
                                                                    </svg>
                                                                  )}
                                                                  <div className="text-[14px] text-[#4C4C66]">
                                                                    Min spend
                                                                  </div>
                                                                </div>
                                                                {allowed ? (
                                                                  <Text
                                                                    text={
                                                                      selectedRefereeRewards?.find(
                                                                        (
                                                                          i: any
                                                                        ) =>
                                                                          i.token_id ===
                                                                            token._id &&
                                                                          i.event_id ===
                                                                            event._id
                                                                      )?.value
                                                                        ?.minSpend
                                                                    }
                                                                    setText={(
                                                                      e: any
                                                                    ) =>
                                                                      handleInputChangeReferee(
                                                                        e,
                                                                        token._id,
                                                                        event._id,
                                                                        "minSpend"
                                                                      )
                                                                    }
                                                                    place={
                                                                      "e.g 5"
                                                                    }
                                                                    full={true}
                                                                    required={
                                                                      true
                                                                    }
                                                                    type={
                                                                      "number"
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <>
                                                                    <input
                                                                      type="text"
                                                                      className="text-gray-500 bg-gray-100 rounded-md p-2 mt-1 w-full"
                                                                      placeholder="e.g 5"
                                                                      disabled
                                                                    />
                                                                  </>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      )}
                                                  </div>
                                                )}
                                              </div>
                                            ) : (
                                              <div className="">
                                                <div
                                                  className="flex flex-row"
                                                  onClick={(e: any) =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  <div>
                                                    <div className="mt-4 text-[14px] text-[#4C4C66] w-[650px]">
                                                      Number of tokens to give{" "}
                                                    </div>
                                                    <Text
                                                      text={
                                                        selectedRefereeRewards?.find(
                                                          (i: any) =>
                                                            i.token_id ===
                                                              token._id &&
                                                            i.event_id ===
                                                              event._id
                                                        )?.value?.percentage
                                                      }
                                                      setText={(e: any) =>
                                                        handleInputChangeReferee(
                                                          e,
                                                          token._id,
                                                          event._id,
                                                          "percentage"
                                                        )
                                                      }
                                                      place={"e.g 5"}
                                                      full={true}
                                                      required={true}
                                                      type={"number"}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="flex flex-row items-center gap-2">
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              onClick={() =>
                                                handleItemClicksReferee(
                                                  token._id,
                                                  event._id
                                                )
                                              }
                                            >
                                              <path
                                                d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3.43934 4.10218 3.43934 4.5V19.5C3.43934 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3.43934 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                fill="#FFAFA8"
                                              />
                                            </svg>
                                            <div className="flex items-center gap-3">
                                              <div className="text-lg font-bold">
                                                {token.name}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
            </div>
          )}
        </div>
      </form>
      <div className="mt-10 mb-5 px-5 flex items-center justify-end">
        <div
          className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
          onClick={handleContr}
        >
          Continue
        </div>
      </div>
    </Modal>
  );
};

export default AddRewards;

import { IMilestoneState } from "../../models/tokens/milestone";
import {
    ADD_MILESTONE_FAILURE,
    ADD_MILESTONE_INIT,
    ADD_MILESTONE_SUCCESS,
    DELETE_MILESTONE_FAILURE,
    DELETE_MILESTONE_INIT,
    DELETE_MILESTONE_SUCCESS,
    GET_MILESTONE_FAILURE,
    GET_MILESTONE_INIT,
    GET_MILESTONE_SUCCESS,
    UPDATE_MILESTONE_FAILURE,
    UPDATE_MILESTONE_INIT,
    UPDATE_MILESTONE_SUCCESS,
} from "../../redux/actionTypes";

const initialState = {
    isGettingMilestone: false,
    getMilestoneResp: [],
    getMilestoneRespStatus: 0,
    getMilestoneError: false,
    getMilestoneErrorMsg: "",
    isUpdatingMilestone: false,
    updateMilestoneError: false,
    updateMilestoneErrorMsg: "",
    updateMilestoneResp: [],
    updateMilestoneRespStatus: 0,
    isAddingMilestone: false,
    addMilestoneError: false,
    addMilestoneErrorMsg: "",
    addMilestoneResp: [],
    addMilestoneRespStatus: 0,
    isDeletingMilestone: false,
    deleteMilestoneError: false,
    deleteMilestoneErrorMsg: "",
    deleteMilestoneResp: [],
    deleteMilestoneRespStatus: 0,
};

const milestones = (state: IMilestoneState = initialState, action: any) => {
    switch (action.type) {
        case GET_MILESTONE_INIT:
            return {
                ...state,
                isGettingMilestone: true,
                getMilestoneError: false,
                getMilestoneRespStatus: 0,
            };

        case GET_MILESTONE_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isGettingMilestone: false,
                getMilestoneResp: data,
                getMilestoneRespStatus: 200,
            };
        }

        case GET_MILESTONE_FAILURE:
            const { error } = action;
            return {
                ...state,
                isGettingMilestone: false,
                getMilestoneError: true,
                getMilestoneErrorMsg: error,
                getMilestoneRespStatus: 400,
            };

        case UPDATE_MILESTONE_INIT:
            return {
                ...state,
                isUpdatingMilestone: true,
                updateMilestoneError: false,
                updateMilestoneRespStatus: 0,
            };

        case UPDATE_MILESTONE_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isUpdatingMilestone: false,
                updateMilestoneResp: data,
                updateMilestoneRespStatus: 200,
            };
        }

        case UPDATE_MILESTONE_FAILURE:
            return {
                ...state,
                isUpdatingMilestone: false,
                updateMilestoneError: true,
                updateMilestoneErrorMsg: action.error,
                updateMilestoneRespStatus: 400,
            };

        case ADD_MILESTONE_INIT:
            return {
                ...state,
                isAddingMilestone: true,
                addMilestoneError: false,
                addMilestoneRespStatus: 0,
            };

        case ADD_MILESTONE_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isAddingMilestone: false,
                addMilestoneResp: data,
                addMilestoneRespStatus: 200,
            };
        }

        case ADD_MILESTONE_FAILURE:
            return {
                ...state,
                isAddingMilestone: false,
                addMilestoneError: true,
                addMilestoneErrorMsg: action.error,
                addMilestoneRespStatus: 400,
            };

        case DELETE_MILESTONE_INIT:
            console.log("DELETE_MILESTONE_INIT", {
                ...state,
                isDeletingMilestone: true,
                deleteMilestoneError: false,
                deleteMilestoneRespStatus: 0,
            });
            return {
                ...state,
                isDeletingMilestone: true,
                deleteMilestoneError: false,
                deleteMilestoneRespStatus: 0,
            };

        case DELETE_MILESTONE_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isDeletingMilestone: false,
                deleteMilestoneResp: data,
                deleteMilestoneRespStatus: 200,
            };
        }

        case DELETE_MILESTONE_FAILURE:
            return {
                ...state,
                isDeletingMilestone: false,
                deleteMilestoneError: true,
                deleteMilestoneErrorMsg: action.error,
                deleteMilestoneRespStatus: 400,
            };

        default:
            return state;
    }
};

export default milestones;

import { TOGGLE_MODE_ENVIRONMENT } from "../actionTypes";
import { IDeveloperTools } from "../../models/developerTools/developerTools";
import Cookies from "js-cookie";

const initialState: any = {
  isProduction: Cookies.get("isProduction") === "true" ? true : false,
  url:
    Cookies.get("isProduction") === "true"
      ? process.env.REACT_APP_PROD
      : process.env.REACT_APP_DEV,
};

const developerTools = (state: IDeveloperTools = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_MODE_ENVIRONMENT:
      Cookies.set("isProduction", action.data.isProduction, { expires: 7 });
      return {
        ...state,
        isProduction: action.data.isProduction,
        url: action.data.isProduction
          ? process.env.REACT_APP_PROD
          : process.env.REACT_APP_DEV,
      };
    default:
      return state;
  }
};

export default developerTools;

import { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signOut,
  signInWithCustomToken,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../redux/auth/authActions";
import { useNavigate } from "react-router-dom";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {
    isGettingUser,
    getUserResp,
    getUserRespStatus,
    getUserError,
    getUserErrorMsg,
  } = useSelector((state) => {
    return {
      isGettingUser: state.auth.isGettingUser,
      getUserResp: state.auth.getUserResp,
      getUserRespStatus: state.auth.getUserRespStatus,
      getUserError: state.auth.getUserError,
      getUserErrorMsg: state.auth.getUserErrorMsg,
    };
  });

  useEffect(() => {
    if (user?.uid) {
      dispatch(getUser({ token: user.accessToken }));
    }
  }, [user]);

  useEffect(() => {
    if (getUserRespStatus === 200) {
      setUserDetails(getUserResp);
    } else {
      if (getUserError) {
        logOut();
        alert("Something went wrong, please login again!")
        navigate("/login");
      }
    }
  }, [isGettingUser]);

  function logIn(token) {
    return signInWithCustomToken(auth, token);
  }

  function logOut() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider value={{ user, logIn, logOut, userDetails }}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}

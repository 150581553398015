import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ data, plain, loss, title }: any) => {
  const chartOptions = plain
    ? {
        chart: { id: "basic", toolbar: { show: false }, background: "#FFFFFF" },
        xaxis: { labels: { show: false } },
        yaxis: { show: false },
        legend: { show: false },
        grid: { show: false },
        stroke: {
          curve: "smooth" as "smooth",
          colors: [loss ? "#FF00B8" : "#1B59F8"],
          width: 3,
        },
      }
    : {
        title: {
          text: title || "Growth overview (for all tokens)",
          align: "left" as "left",
        },
        chart: {
          background: "#FFFFFF",
          toolbar: {
            show: false,
            tools: {
              zoom: true,
              zoomin: true,
              zoomout: true,
              reset: true,
            },
          },
        },
        xaxis: {
          type: "datetime" as "datetime", // Set the type to "datetime"
        },
        stroke: { width: 3 },
        yaxis: {
          title: {
            // text: "Y-Axis Title",
          },
        },
      };

  return (
    <ReactApexChart
      options={chartOptions}
      series={data}
      type="line"
      height={plain ? "100px" : "100%"}
    />
  );
};

export default LineChart;

import p from "../../../assets/png/image 62.png";
import edit from "../../../assets/svg/editD.svg";
import delet from "../../../assets/svg/deleteD.svg";
import copy from "../../../assets/svg/copy.svg";
import { useDispatch } from "react-redux";
import { toggleConfirmationPopup } from "../../../redux/api/apiActions";
import { deleteUserVariable } from "../../../redux/user/userActions";
import { useUserAuth } from "../../../hooks/useAuthContextProvider";

export interface IVariableProps {
  variable: any;
  setName: (val: string) => void;
  setType: (val: string) => void;
  setOpen: (val: boolean) => void;
  setUserVariable: (val: string) => void;
}

export const typeReverseMap: { [key: number]: string } = {
  1: "String",
  2: "Number",
  3: "Boolean",
  4: "Date",
};
const Variable = ({
  variable,
  setName,
  setType,
  setOpen,
  setUserVariable,
}: IVariableProps) => {
  const { user } = useUserAuth();
  const dispatch = useDispatch();
  return (
    <div className="bg-[#FFE8E6] px-5 py-5 flex items-center justify-between mb-3 rounded">
      <div className="flex items-center gap-3">
        <div className="bg-white w-[100px] rounded-[100px] flex justify-center px-3 py-1">
          {typeReverseMap[variable.type]}
        </div>
        <div className="text-lg font-bold">{variable.name}</div>
        <div className="flex items-center text-sm gap-1">
          Created by
          <img
            src={p}
            alt="p"
            className="h-[20px] w-[20px] rounded-full border border-[#FF409A]"
          />
          {variable.created_by?.name}
        </div>
        <div className="text-sm">
          | &nbsp;Created on:{" "}
          {new Date(variable.created_at).toISOString().split("T")[0]}
          {/* &nbsp;|&nbsp; Modified on: DD/MM/YY */}
        </div>
        <div className="text-sm bg-white px-3 rounded-[100px] flex items-center gap-3">
          ID: {variable.variable_name_id}
          <img
            src={copy}
            alt="c"
            className="w-[30px] h-[30px] cursor-pointer pt-1"
            onClick={() =>
              navigator.clipboard.writeText(variable.variable_name_id)
            }
          />
        </div>
      </div>
      <div className="flex items-center gap-3">
        <img
          src={edit}
          alt="edit"
          className="w-[30px] h-[30px] cursor-pointer"
          onClick={() => {
            setName(variable.name);
            setType(typeReverseMap[variable.type]);
            setOpen(true);
            setUserVariable(variable._id);
          }}
        />
        <img
          src={delet}
          alt="delet"
          className="w-[30px] h-[30px] cursor-pointer"
          onClick={() =>
            dispatch(
              toggleConfirmationPopup({
                isConfirmation: true,
                confirmationFunction: () =>
                  dispatch(
                    deleteUserVariable({
                      token: user.accessToken,
                      variableId: variable._id,
                    })
                  ),
                confirmationText: "Do you really want to delete the variable?",
              })
            )
          }
        />
      </div>
    </div>
  );
};

export default Variable;

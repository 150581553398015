import Modal from "react-modal";
import { customStyles } from "../../containers/gamification/campaign";
import Text from "../gamification/modals/nudge/text";
import searchIcon from "../../assets/svg/search.svg";
import back from "../../assets/svg/back.svg";
import { useState } from "react";

interface ComponentProps {
  showModale: boolean;
  handleConti: () => void;
  handleCloseModalEvent: () => void;
  selectedItem: any;
  setSelectedItem: (selectedItem: any) => void;
  events: [];
}
const AddEvent = ({
  showModale,
  handleCloseModalEvent,
  handleConti,
  selectedItem,
  setSelectedItem,
  events,
}: ComponentProps) => {
  const handleItemClick = (index: any) => {
    console.log(index);
    if (selectedItem.some((e: any) => e?._id === index)) {
      setSelectedItem((prev: any) =>
        prev.filter((item: any) => item?._id !== index)
      );
    } else {
      setSelectedItem((prev: any) => [
        ...prev,
        events.find((e: any) => e?._id === index),
      ]);
    }
  };

  const [search, setSearch] = useState<string>("");

  const handleSetSearch = (e: string) => {
    setSearch(e);
  };
  return (
    <div>
      {" "}
      <Modal
        style={customStyles}
        isOpen={showModale}
        onRequestClose={handleCloseModalEvent}
        shouldCloseOnOverlayClick={true}
      >
        <form>
          <div className="p-5">
            <div className="flex justify-between w-[750px]">
              <div>
                <div className="text-2xl font-bold text-[#4C4C66] font-bold">
                  Select Events
                </div>
                <div className="mt-2 w-[745px]">
                  <div className="text-[#5F5E76] text-md mt-3">
                    Search Events
                  </div>
                  <Text
                    text={search}
                    setText={handleSetSearch}
                    full={true}
                    place={"Search event"}
                    required={false}
                    type={"text"}
                    icon={searchIcon}
                  />
                </div>
              </div>

              <div className="cursor-pointer" onClick={handleCloseModalEvent}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                    fill="#4C4C66"
                  />
                </svg>
              </div>
            </div>

            <div className="text-[#5F5E76] text-md mt-3">
              Or, Select the events
            </div>
            {events &&
            events.length > 0 &&
            events?.filter((each: any) =>
              each?.name?.toLowerCase()?.includes(search?.toLowerCase())
            ).length > 1 ? (
              events
                ?.filter((each: any) =>
                  each?.name?.toLowerCase()?.includes(search?.toLowerCase())
                )
                .map((event: any) => (
                  <div
                    key={event._id}
                    className="rounded border-2 border-[#FFAFA8] bg-[#FFE8E6] cursor-pointer flex flex-col gap-3 p-3 max-w-[750px] mt-2 mb-2"
                    onClick={() => handleItemClick(event._id)}
                  >
                    <div className="flex items-center gap-3">
                      {selectedItem.some((e: any) => e?._id === event._id) ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                            fill="#5F5E76"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                            fill="#FFAFA8"
                          />
                        </svg>
                      )}

                      <div className="text-lg text-[#4C4C66]">{event.name}</div>

                      <div className="text-lg text-[#CEB0FA]">|</div>
                      <div className="text-sm text-[#4C4C66]">
                        type:{" "}
                        {event.transaction_type
                          ? !event.is_transaction_based
                            ? "Non-Transactional"
                            : "Transactional"
                          : "Non-Transactional"}
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="flex items-center justify-center mt-5 border-4 border-[#EFF0F6] p-3 bg-white rounded">
                No result found :(
              </div>
            )}
          </div>
        </form>
        <div className="mt-10 mb-5 px-5 flex items-center justify-between">
          <div className="flex flex-row">
            <div className="text-[#5F5E76] text-lg rounded cursor-pointer py-3 px-1">
              {" "}
              <img src={back} alt="back" className="w-[20px] h-[20px]" />
            </div>

            <div
              className="text-[#5F5E76] text-lg rounded cursor-pointer py-2"
              onClick={handleCloseModalEvent}
            >
              Cancel
            </div>
          </div>

          <div
            className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
            onClick={handleConti}
          >
            Continue
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AddEvent;

import {
  UPDATE_EVENT_TOKEN_INPUT,
  GET_EVENTS_INIT,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  UPDATE_EVENT_INIT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  ADD_EVENT_INIT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  DELETE_EVENT_INIT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
} from "../../redux/actionTypes";
import { IEventsState } from "../../models/tokens/events";

const initialState = {
  inputs: {},
  isGettignEvents: false,
  getEventsResp: [],
  getEventsRespStatus: 0,
  getEventsError: false,
  getEventsErrorMsg: "",
  isUpdatingEvent: false,
  updateEventError: false,
  updateEventErrorMsg: "",
  updateEventResp: [],
  updateEventRespStatus: 0,
  isAddingEvent: false,
  addEventError: false,
  addEventErrorMsg: "",
  addEventResp: [],
  addEventRespStatus: 0,
  isDeletingEvent: false,
  deleteEventError: false,
  deleteEventErrorMsg: "",
  deleteEventResp: [],
  deleteEventRespStatus: 0,
};

const events = (state: IEventsState = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_EVENT_TOKEN_INPUT:
      const id = action.data.id;
      const value = action.data.value;
      const capValue = action.data.capValue;
      if (action.data.e) {
        let temp: any = {};
        for (let i = 0; i < action.data.e?.length; i++) {
          temp[action.data.e[i].token_id] = action.data.e[i];
        }
        if (action.data.e.length < 1) {
          return {
            ...state,
            inputs: {},
          };
        }
        return {
          ...state,
          inputs: { ...state.inputs, ...temp },
        };
      }
      return {
        ...state,
        inputs: id
          ? {
              ...state.inputs,
              [id]: {
                token_id: id,
                value: parseFloat(value),
                capValue: parseFloat(capValue),
                transaction: action.data.transaction,
              },
            }
          : {},
      };

    case GET_EVENTS_INIT:
      return {
        ...state,
        isGettignEvents: true,
        getEventsError: false,
        getEventsRespStatus: 0,
      };

    case GET_EVENTS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettignEvents: false,
        getEventsResp: data,
        getEventsRespStatus: 200,
      };
    }

    case GET_EVENTS_FAILURE:
      const { error } = action;
      return {
        ...state,
        isGettignEvents: false,
        getEventsError: true,
        getEventsErrorMsg: error,
        getEventsRespStatus: 400,
      };

    case UPDATE_EVENT_INIT:
      return {
        ...state,
        isUpdatingEvent: true,
        updateEventError: false,
        updateEventRespStatus: 0,
      };

    case UPDATE_EVENT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingEvent: false,
        updateEventResp: data,
        updateEventRespStatus: 200,
      };
    }

    case UPDATE_EVENT_FAILURE:
      return {
        ...state,
        isUpdatingEvent: false,
        updateEventError: true,
        updateEventErrorMsg: action.error,
        updateEventRespStatus: 400,
      };

    case ADD_EVENT_INIT:
      return {
        ...state,
        isAddingEvent: true,
        addEventError: false,
        addEventRespStatus: 0,
      };

    case ADD_EVENT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingEvent: false,
        addEventResp: data,
        addEventRespStatus: 200,
      };
    }

    case ADD_EVENT_FAILURE:
      return {
        ...state,
        isAddingEvent: false,
        addEventError: true,
        addEventErrorMsg: action.error,
        addEventRespStatus: 400,
      };

    case DELETE_EVENT_INIT:
      return {
        ...state,
        isDeletingEvent: true,
        deleteEventError: false,
        deleteEventRespStatus: 0,
      };

    case DELETE_EVENT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isDeletingEvent: false,
        deleteEventResp: data,
        deleteEventRespStatus: 200,
      };
    }

    case DELETE_EVENT_FAILURE:
      return {
        ...state,
        isDeletingEvent: false,
        deleteEventError: true,
        deleteEventErrorMsg: action.error,
        deleteEventRespStatus: 400,
      };

    default:
      return state;
  }
};

export default events;

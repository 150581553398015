import { useState } from "react";
import Tile from "../../components/common/tile";
import photo from "../../assets/png/image 62.png";
import Navbar from "../../components/common/navbar";

const Business = () => {
  const [usageAndBilling, setUsageAndBilling] = useState<number>(1);
  const [promotions, setPromotions] = useState<number>(1);

  const filters = [
    {
      name: "Last 24 hours",
      type: 1,
    },
    {
      name: "Last 07 Days",
      type: 7,
    },
    {
      name: "Last 30 Days",
      type: 30,
    },
  ];

  return (
    <div className="flex flex-col gap-5 w-full h-[100vh] overflow-y-auto pe-10">
      <Navbar />
      <div className="bg-[#0E0E0E] rounded-[24px] p-5 text-[18px]">
        Your Profile
        <div className="flex items-center justify-between mt-5">
          <img src={photo} alt="profile" />
          <div>
            <div className="grid grid-cols-4 gap-2">
              <div className="col-span-1 fw-bold">Company</div>
              <div className="col-span-3 text-[#B3AEAA]">Third Wave Coffee</div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-2">
              <div className="col-span-1 fw-bold">Partner ID</div>
              <div className="col-span-2 text-[#B3AEAA]">SEQWYRNBJASGFSMF</div>
              <div className="col-span-1 text-[#005DF2] underline">Copy</div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-2">
              <div className="col-span-1 fw-bold">Role</div>
              <div className="col-span-2 text-[#B3AEAA]">Admin</div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-4 gap-2">
              <div className="col-span-1 fw-bold">Name</div>
              <div className="col-span-2 text-[#B3AEAA]">Navin Bansal</div>
              <div className="col-span-1 text-[#005DF2] underline">Edit</div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-2">
              <div className="col-span-1 fw-bold">Phone Number</div>
              <div className="col-span-2 text-[#B3AEAA]">9831239908</div>
              <div className="col-span-1 text-[#005DF2] underline">Edit</div>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-2">
              <div className="col-span-1 fw-bold">Email</div>
              <div className="col-span-2 text-[#B3AEAA]">
                navin@thirdwavecoffee...
              </div>
              <div className="col-span-1 text-[#005DF2] underline">Edit</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#0E0E0E] rounded-[24px] p-5 text-[18px]">
        Business Details
        <div className="flex items-center justify-between mt-5">
          <div>
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-1 font-bold">Bank Details</div>
              <div className="col-span-1 text-[#B3AEAA] underline">View</div>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-2">
              <div className="col-span-1 font-bold">GST Details</div>
              <div className="col-span-1 text-[#B3AEAA] underline">View</div>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-2">
              <div className="col-span-1 font-bold">Business Details</div>
              <div className="col-span-1 text-[#B3AEAA] underline">View</div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-1 font-bold">Manage Payments</div>
              <div className="col-span-1 text-[#B3AEAA] underline">View</div>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-2">
              <div className="col-span-1 font-bold">Manage Team</div>
              <div className="col-span-1 text-[#B3AEAA] underline">View</div>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-2">
              <div className="col-span-1 font-bold">Raise a Ticket</div>
              <div className="col-span-1 text-[#B3AEAA] underline">View</div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-1 font-bold">
                <span className="text-[#005DF2]">Snipe</span> Bank Details
              </div>
              <div className="col-span-1 text-[#B3AEAA] underline">View</div>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-2">
              <div className="col-span-1 font-bold">
                <span className="text-[#005DF2]">Snipe</span> GST Details
              </div>
              <div className="col-span-1 text-[#B3AEAA] underline">View</div>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-2">
              <div className="col-span-1 font-bold">
                <span className="text-[#005DF2]">Snipe</span> Agreement
              </div>
              <div className="col-span-1 text-[#B3AEAA] underline">View</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#0E0E0E] rounded-[24px] p-5 text-[18px]">
        <div className="flex items-center justify-between">
          <div>Usage & Billing</div>
          <div className="flex items-center gap-2">
            {filters.map((each: any) => {
              return (
                <div
                  className={
                    "cursor-pointer" +
                    (each.type === usageAndBilling
                      ? " custom-linear-bg px-3 py-2 rounded-[8px]"
                      : "")
                  }
                  onClick={() => setUsageAndBilling(each.type)}
                >
                  {each.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 mt-10">
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={32986}
              increase={4}
              name={"Unique Active Users"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={100000}
              increase={4}
              name={"API Calls"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"money"}
              number={12000}
              decrease={4}
              name={"API Usage"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"money"}
              number={13390}
              increase={4}
              name={"Total Usage"}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#0E0E0E] rounded-[24px] p-5 text-[18px]">
        <div className="flex items-center justify-between">
          <div>Promotions</div>
          <div className="flex items-center gap-2">
            {filters.map((each: any) => {
              return (
                <div
                  className={
                    "cursor-pointer" +
                    (each.type === promotions
                      ? " custom-linear-bg px-3 py-2 rounded-[8px]"
                      : "")
                  }
                  onClick={() => setPromotions(each.type)}
                >
                  {each.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 mt-10">
          <div className="col-span-1">
            <Tile
              type={"money"}
              number={50000}
              increase={4}
              name={"Promotional Budget Used"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={45200}
              name={"No. Of Consumers Distributed"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"money"}
              number={0.034}
              increase={4}
              name={"Avg. Amount Per Consumer"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"percentage"}
              number={84.56}
              increase={4}
              name={"Consumer Transacting"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"money"}
              number={40000}
              increase={4}
              name={"Transactional Cashback"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"money"}
              number={10000}
              increase={4}
              name={"Promotional Cashback"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;

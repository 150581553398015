import { useDispatch, useSelector } from "react-redux";
import { Dispatch, useEffect, useState } from "react";
import { toggleCustomTokenModal } from "../../../../redux/user/customTokenActions";
import Radio from "../../../gamification/modals/nudge/radio";
import Select from "../../../gamification/modals/nudge/select";
import Text from "../../../gamification/modals/nudge/text";
import DateContainer from "../../../gamification/modals/nudge/date";
import Loader from "../../../common/loader";
import {
    addCustomTokenFinalUser,
    addCustomTokenUser,
} from "../../../../redux/user/userActions";
import { typeReverseMap } from "../../variables/variable";
import searchIcon from "../../../../assets/svg/search.svg";

type Props = {
    state?: Dispatch<any> | null;
};

const UsersWith = (props: Props) => {
    const dispatch = useDispatch();
    const [active, setActive] = useState<Array<string>>([""]);
    // const [stringType, setStringType] = useState<string>("");
    // const [stringVal, setStringVal] = useState<string>("");
    // const [numberType, setNumberType] = useState<string>("");
    // const [numberVal, setNumberVal] = useState<Array<number>>([]);
    // const [numberLowerVal, setNumberLowerVal] = useState<number>(0);
    // const [numberUpperVal, setNumberUpperVal] = useState<number>(0);
    // const [dateType, setDateType] = useState<string>("");
    // const [dateVal, setDateVal] = useState<Array<any>>([null]);
    // const [dateLowerVal, setDateLowerVal] = useState<any>(null);
    // const [dateUpperVal, setDateUpperVal] = useState<any>(null);
    const [search, setSearch] = useState<string>("");
    const [frequency, setFrequency] = useState<Array<string>>([]);
    // const [booleanVal, setBooleanVal] = useState<string>("False");
    const {
        isGettingUserVariable,
        getUserVariableResp,
        getUserVariableRespStatus,
        userFilter,
    } = useSelector((state: any) => state.user);

    const typeMap: { [key: number]: string } = {
        1: "strings",
        2: "numbers",
        3: "booleans",
        4: "dates",
    };

    const stringConditionMap: { [key: string]: string } = {
        "Equals to": "e",
        "Contains substring": "con",
        "Present in": "in",
        "Not Present in": "nin",
    };

    const reverseStringConditionMap: { [key: string]: string } = {
        e: "Equals to",
        con: "Contains substring",
        in: "Present in",
        nin: "Not Present in",
    };

    const numberConditionMap: { [key: string]: string } = {
        "Equals to": "e",
        "Greater than": "gt",
        "Less than": "lt",
        "In Between": "bet",
    };

    const reverseNumberConditionMap: { [key: string]: string } = {
        e: "Equals to",
        gt: "Greater than",
        lt: "Less than",
        bet: "In Between",
    };

    const dateConditionMap: { [key: string]: string } = {
        "Equals to": "e",
        "Greater than": "gt",
        "Less than": "lt",
        "In Between": "bet",
    };

    const reverseDateConditionMap: { [key: string]: string } = {
        e: "Equals to",
        gt: "Greater than",
        lt: "Less than",
        bet: "In Between",
    };

    useEffect(() => {
        if (getUserVariableRespStatus === 200) {
            let finalObject: any = {
                strings: [],
                numbers: [],
                booleans: [],
                dates: [],
            };
            const arrVar = getUserVariableResp.data?.value;
            for (let i = 0; i < arrVar?.length; i++) {
                finalObject[typeMap[arrVar[i].type]] = [
                    ...finalObject[typeMap[arrVar[i].type]],
                    {
                        variable_id: arrVar[i]._id,
                        values: arrVar[i].type === 3 ? false : [],
                        operation: arrVar[i].type !== 3 ? "e" : null,
                    },
                ];
            }
            dispatch(addCustomTokenUser({ newData: finalObject }));
        }
    }, [isGettingUserVariable]);

    const handleChange = (
        variableId: string,
        values: any,
        type: number,
        operation?: string,
        second?: any //for second value of array
    ) => {
        dispatch(
            second
                ? addCustomTokenUser({
                      variableId: variableId,
                      values: values,
                      type: type,
                      operation: operation,
                      second: second,
                  })
                : addCustomTokenUser({
                      variableId: variableId,
                      values: values,
                      type: type,
                      operation: operation,
                  })
        );
    };
    // console.log(active);
    return (
        <div>
            <div className="flex justify-between w-[700px] px-5 pt-5 pb-5">
                <div>
                    <div className="text-2xl text-[#4C4C66] font-bold">
                        User filter
                    </div>
                    <div className="text-[#3189B4] mt-3">
                        Add custom filter groups to select a group of users. You
                        can select multiple filter groups as well.
                    </div>
                </div>
                <div
                    className="text-xl text-[#4C4C66] cursor-pointer"
                    onClick={() => dispatch(toggleCustomTokenModal(""))}
                >
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                            fill="#4C4C66"
                        />
                    </svg>
                </div>
            </div>
            {isGettingUserVariable && (
                <div className="flex items-center justify-center">
                    <Loader heavy={true} />
                </div>
            )}
            <div className="px-5 pb-5">
                <div className="text-[#5F5E76] text-xl mb-5">
                    Select variable(s):
                </div>
                <Text
                    text={search}
                    setText={setSearch}
                    place={"Search Variable"}
                    full={true}
                    type={"text"}
                    required={false}
                    icon={searchIcon}
                />
            </div>
            {getUserVariableRespStatus === 200 && (
                <div className="px-5 max-h-[300px] overflow-y-auto">
                    {getUserVariableResp.data?.value?.filter(
                        (each: any) =>
                            each.name
                                ?.toLowerCase()
                                .includes(search?.toLowerCase()) ||
                            typeReverseMap[each.type]
                                ?.toLowerCase()
                                .includes(search?.toLowerCase()) ||
                            each.variable_name_id
                                ?.toLowerCase()
                                ?.includes(search?.toLowerCase())
                    )?.length > 0 ? (
                        getUserVariableResp.data?.value
                            ?.filter(
                                (each: any) =>
                                    each.name
                                        ?.toLowerCase()
                                        .includes(search?.toLowerCase()) ||
                                    typeReverseMap[each.type]
                                        ?.toLowerCase()
                                        .includes(search?.toLowerCase()) ||
                                    each.variable_name_id
                                        ?.toLowerCase()
                                        ?.includes(search?.toLowerCase())
                            )
                            ?.map((each: any) => {
                                return (
                                    <div
                                        className={
                                            "px-3 py-2 w-full border-2 border-[#FFAFF8] cursor-pointer mb-5 rounded text-[#5F5E76]" +
                                            (active.includes(each._id)
                                                ? " bg-[#FFE8E6]"
                                                : "")
                                        }
                                        onClick={() =>
                                            active.includes(each._id)
                                                ? setActive(
                                                      active.filter(
                                                          (ele: any) =>
                                                              ele !== each._id
                                                      )
                                                  )
                                                : setActive(
                                                      Array.from(
                                                          new Set([
                                                              ...active,
                                                              each._id,
                                                          ])
                                                      )
                                                  )
                                        }
                                    >
                                        <div className="flex items-center gap-2">
                                            {active.includes(each._id) ? (
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                        fill="#5F5E76"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                        fill="#FFAFA8"
                                                    />
                                                </svg>
                                            )}
                                            <div className="text-xl">
                                                {each.name}
                                            </div>
                                        </div>
                                        {active.includes(each._id) &&
                                            // active.includes("Variable (type - string)") &&
                                            each.type === 1 && (
                                                <div
                                                    className="px-10 mt-5 flex items-center gap-5"
                                                    onClick={(e: any) =>
                                                        e.stopPropagation()
                                                    }
                                                >
                                                    <div>
                                                        <div className="text-[#5F5E756] text-[12px]">
                                                            Condition*
                                                        </div>
                                                        <Select
                                                            selected={
                                                                userFilter.strings.find(
                                                                    (
                                                                        stringFilter: any
                                                                    ) =>
                                                                        stringFilter.variable_id ===
                                                                        each._id
                                                                )?.value?.[0]
                                                            }
                                                            setSelected={(
                                                                e: any
                                                            ) =>
                                                                dispatch(
                                                                    addCustomTokenUser(
                                                                        {
                                                                            variableId:
                                                                                each._id,
                                                                            values: [
                                                                                "",
                                                                            ],
                                                                            type: 1,
                                                                            operation:
                                                                                stringConditionMap[
                                                                                    e
                                                                                ],
                                                                        }
                                                                    )
                                                                )
                                                            }
                                                            values={[
                                                                "Equals to",
                                                                "Contains substring",
                                                                "Present in",
                                                                "Not Present in",
                                                            ]}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="text-[#5F5E76] text-[12px]">
                                                            {[
                                                                "Present in",
                                                                "Not Present in",
                                                            ].includes(
                                                                reverseStringConditionMap[
                                                                    userFilter.strings.find(
                                                                        (
                                                                            stringFilter: any
                                                                        ) =>
                                                                            stringFilter.variable_id ===
                                                                            each._id
                                                                    )?.operation
                                                                ]
                                                            )
                                                                ? "Comma Separated "
                                                                : ""}
                                                            Value*
                                                        </div>
                                                        <Text
                                                            text={
                                                                userFilter.strings.find(
                                                                    (
                                                                        stringFilter: any
                                                                    ) =>
                                                                        stringFilter.variable_id ===
                                                                        each._id
                                                                )?.value?.[0]
                                                            }
                                                            setText={(e: any) =>
                                                                handleChange(
                                                                    each._id,
                                                                    [e],
                                                                    1,
                                                                    userFilter.strings.find(
                                                                        (
                                                                            stringFilter: any
                                                                        ) =>
                                                                            stringFilter.variable_id ===
                                                                            each._id
                                                                    )?.operation
                                                                )
                                                            }
                                                            place={
                                                                [
                                                                    "Present in",
                                                                    "Not Present in",
                                                                ].includes(
                                                                    reverseStringConditionMap[
                                                                        userFilter.strings.find(
                                                                            (
                                                                                stringFilter: any
                                                                            ) =>
                                                                                stringFilter.variable_id ===
                                                                                each._id
                                                                        )
                                                                            ?.operation
                                                                    ]
                                                                )
                                                                    ? "one,two,three"
                                                                    : "one"
                                                            }
                                                            full={false}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        {active.includes(each._id) &&
                                            // active.includes("Variable (type - number)") &&
                                            each.type === 2 && (
                                                <div
                                                    className="px-10 mt-5 flex items-center gap-5"
                                                    onClick={(e: any) =>
                                                        e.stopPropagation()
                                                    }
                                                >
                                                    <div>
                                                        <div className="text-[#5F5E756] text-[12px]">
                                                            Condition*
                                                        </div>
                                                        <Select
                                                            selected={
                                                                userFilter.numbers.find(
                                                                    (
                                                                        numberFilter: any
                                                                    ) =>
                                                                        numberFilter.variable_id ===
                                                                        each._id
                                                                )?.value?.[0]
                                                            }
                                                            setSelected={(
                                                                e: any
                                                            ) =>
                                                                dispatch(
                                                                    addCustomTokenUser(
                                                                        {
                                                                            variableId:
                                                                                each._id,
                                                                            values: [],
                                                                            type: 2,
                                                                            operation:
                                                                                numberConditionMap[
                                                                                    e
                                                                                ],
                                                                        }
                                                                    )
                                                                )
                                                            }
                                                            values={[
                                                                "Equals to",
                                                                "Greater than",
                                                                "Less than",
                                                                "In Between",
                                                            ]}
                                                        />
                                                    </div>
                                                    <div>
                                                        {[
                                                            "In Between",
                                                        ].includes(
                                                            reverseNumberConditionMap[
                                                                userFilter.numbers.find(
                                                                    (
                                                                        numberFilter: any
                                                                    ) =>
                                                                        numberFilter.variable_id ===
                                                                        each._id
                                                                )?.operation
                                                            ]
                                                        ) ? (
                                                            <div className="flex items-center gap-3">
                                                                <div>
                                                                    <div className="text-[#5F5E76] text-[12px]">
                                                                        Lower
                                                                        Value*
                                                                    </div>
                                                                    <Text
                                                                        text={
                                                                            userFilter.numbers.find(
                                                                                (
                                                                                    numberFilter: any
                                                                                ) =>
                                                                                    numberFilter.variable_id ===
                                                                                    each._id
                                                                            )
                                                                                ?.values?.[0]
                                                                        }
                                                                        setText={(
                                                                            e: any
                                                                        ) =>
                                                                            handleChange(
                                                                                each._id,
                                                                                e,
                                                                                2,
                                                                                userFilter.numbers.find(
                                                                                    (
                                                                                        numberFilter: any
                                                                                    ) =>
                                                                                        numberFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.operation,
                                                                                userFilter.numbers.find(
                                                                                    (
                                                                                        numberFilter: any
                                                                                    ) =>
                                                                                        numberFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.values?.[1]
                                                                            )
                                                                        }
                                                                        type={
                                                                            "number"
                                                                        }
                                                                        place={
                                                                            "Lower value"
                                                                        }
                                                                        full={
                                                                            false
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className="text-[#5F5E76] text-[12px]">
                                                                        Upper
                                                                        Value*
                                                                    </div>
                                                                    <Text
                                                                        text={
                                                                            userFilter.numbers.find(
                                                                                (
                                                                                    numberFilter: any
                                                                                ) =>
                                                                                    numberFilter.variable_id ===
                                                                                    each._id
                                                                            )
                                                                                ?.values?.[1]
                                                                        }
                                                                        setText={(
                                                                            e: any
                                                                        ) =>
                                                                            handleChange(
                                                                                each._id,
                                                                                userFilter.numbers.find(
                                                                                    (
                                                                                        numberFilter: any
                                                                                    ) =>
                                                                                        numberFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.values[0],
                                                                                2,
                                                                                userFilter.numbers.find(
                                                                                    (
                                                                                        numberFilter: any
                                                                                    ) =>
                                                                                        numberFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.operation,
                                                                                e
                                                                            )
                                                                        }
                                                                        type={
                                                                            "number"
                                                                        }
                                                                        place={
                                                                            "Upper value"
                                                                        }
                                                                        full={
                                                                            false
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="text-[#5F5E76] text-[12px]">
                                                                    Value*
                                                                </div>
                                                                <Text
                                                                    text={
                                                                        userFilter.numbers.find(
                                                                            (
                                                                                numberFilter: any
                                                                            ) =>
                                                                                numberFilter.variable_id ===
                                                                                each._id
                                                                        )
                                                                            ?.values?.[0]
                                                                    }
                                                                    setText={(
                                                                        e: any
                                                                    ) =>
                                                                        handleChange(
                                                                            each._id,
                                                                            [e],
                                                                            2
                                                                        )
                                                                    }
                                                                    type={
                                                                        "number"
                                                                    }
                                                                    place={
                                                                        "value"
                                                                    }
                                                                    full={false}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        {active.includes(each._id) &&
                                            // active.includes("Variable (type - boolean)") &&
                                            each.type === 3 && (
                                                <div
                                                    className="px-10 flex items-center gap-5 mt-5"
                                                    onClick={(e: any) =>
                                                        e.stopPropagation()
                                                    }
                                                >
                                                    {["True", "False"]?.map(
                                                        (ele: any) => {
                                                            return (
                                                                <Radio
                                                                    selected={
                                                                        userFilter.booleans.find(
                                                                            (
                                                                                booleanFilter: any
                                                                            ) =>
                                                                                booleanFilter.variable_id ===
                                                                                each._id
                                                                        )
                                                                            ?.values
                                                                            ? "True"
                                                                            : "False"
                                                                    }
                                                                    setSelected={(
                                                                        e: any
                                                                    ) => {
                                                                        dispatch(
                                                                            addCustomTokenUser(
                                                                                {
                                                                                    variableId:
                                                                                        each._id,
                                                                                    values: e,
                                                                                    type: 3,
                                                                                }
                                                                            )
                                                                        );
                                                                    }}
                                                                    each={ele}
                                                                    multi={
                                                                        false
                                                                    }
                                                                    white={true}
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            )}
                                        {active.includes(each._id) &&
                                            // active.includes("Variable (type - date)") &&
                                            each.type === 4 && (
                                                <div
                                                    className="px-10 mt-5 flex items-center gap-5"
                                                    onClick={(e: any) =>
                                                        e.stopPropagation()
                                                    }
                                                >
                                                    <div>
                                                        <div className="text-[#5F5E756] text-[12px]">
                                                            Condition*
                                                        </div>
                                                        <Select
                                                            selected={
                                                                reverseDateConditionMap[
                                                                    userFilter.dates.find(
                                                                        (
                                                                            dateFilter: any
                                                                        ) =>
                                                                            dateFilter.variable_id ===
                                                                            each._id
                                                                    )
                                                                        ?.values?.[0]
                                                                ]
                                                            }
                                                            setSelected={(
                                                                e: any
                                                            ) =>
                                                                dispatch(
                                                                    addCustomTokenUser(
                                                                        {
                                                                            variableId:
                                                                                each._id,
                                                                            values: [
                                                                                new Date().getTime(),
                                                                                new Date(
                                                                                    new Date().setDate(
                                                                                        new Date().getDate() +
                                                                                            1
                                                                                    )
                                                                                ).getTime(),
                                                                            ],
                                                                            type: 4,
                                                                            operation:
                                                                                dateConditionMap[
                                                                                    e
                                                                                ],
                                                                        }
                                                                    )
                                                                )
                                                            }
                                                            values={[
                                                                "Equals to",
                                                                "Greater than",
                                                                "Less than",
                                                                "In Between",
                                                            ]}
                                                        />
                                                    </div>
                                                    <div>
                                                        {[
                                                            "In Between",
                                                        ].includes(
                                                            reverseDateConditionMap[
                                                                userFilter.dates.find(
                                                                    (
                                                                        dateFilter: any
                                                                    ) =>
                                                                        dateFilter.variable_id ===
                                                                        each._id
                                                                )?.operation
                                                            ]
                                                        ) ? (
                                                            <div className="flex items-center gap-3">
                                                                <div>
                                                                    <div className="text-[#5F5E76] text-[12px]">
                                                                        Lower
                                                                        Value*
                                                                    </div>
                                                                    <DateContainer
                                                                        selected={
                                                                            new Date(
                                                                                userFilter.dates.find(
                                                                                    (
                                                                                        dateFilter: any
                                                                                    ) =>
                                                                                        dateFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.values?.[0] ||
                                                                                    Date.now()
                                                                            )
                                                                                .toISOString()
                                                                                ?.split(
                                                                                    "T"
                                                                                )?.[0]
                                                                        }
                                                                        setSelected={(
                                                                            e: any
                                                                        ) =>
                                                                            handleChange(
                                                                                each._id,
                                                                                new Date(
                                                                                    e
                                                                                ).getTime(),
                                                                                4,
                                                                                userFilter.dates.find(
                                                                                    (
                                                                                        dateFilter: any
                                                                                    ) =>
                                                                                        dateFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.operation,
                                                                                userFilter.dates.find(
                                                                                    (
                                                                                        dateFilter: any
                                                                                    ) =>
                                                                                        dateFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.values?.[1] ||
                                                                                    new Date().getTime()
                                                                            )
                                                                        }
                                                                        required={
                                                                            true
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className="text-[#5F5E76] text-[12px]">
                                                                        Upper
                                                                        Value*
                                                                    </div>
                                                                    <DateContainer
                                                                        selected={
                                                                            new Date(
                                                                                userFilter.dates.find(
                                                                                    (
                                                                                        dateFilter: any
                                                                                    ) =>
                                                                                        dateFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.values?.[1] ||
                                                                                    Date.now()
                                                                            )
                                                                                .toISOString()
                                                                                ?.split(
                                                                                    "T"
                                                                                )?.[0]
                                                                        }
                                                                        setSelected={(
                                                                            e: any
                                                                        ) =>
                                                                            handleChange(
                                                                                each._id,
                                                                                userFilter.dates.find(
                                                                                    (
                                                                                        dateFilter: any
                                                                                    ) =>
                                                                                        dateFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.values?.[0] ||
                                                                                    new Date().getTime(),
                                                                                4,
                                                                                userFilter.dates.find(
                                                                                    (
                                                                                        dateFilter: any
                                                                                    ) =>
                                                                                        dateFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.operation,
                                                                                new Date(
                                                                                    e
                                                                                ).getTime()
                                                                            )
                                                                        }
                                                                        required={
                                                                            true
                                                                        }
                                                                        min={
                                                                            new Date(
                                                                                userFilter.dates.find(
                                                                                    (
                                                                                        dateFilter: any
                                                                                    ) =>
                                                                                        dateFilter.variable_id ===
                                                                                        each._id
                                                                                )
                                                                                    ?.values?.[0] ||
                                                                                    Date.now()
                                                                            )
                                                                                .toISOString()
                                                                                ?.split(
                                                                                    "T"
                                                                                )?.[0]
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="text-[#5F5E76] text-[12px]">
                                                                    Filter
                                                                    Value*
                                                                </div>
                                                                <DateContainer
                                                                    selected={
                                                                        new Date(
                                                                            userFilter.dates?.find(
                                                                                (
                                                                                    dateFilter: any
                                                                                ) =>
                                                                                    dateFilter.variable_id ===
                                                                                    each._id
                                                                            )
                                                                                ?.values?.[0] ||
                                                                                Date.now()
                                                                        )
                                                                            .toISOString()
                                                                            .split(
                                                                                "T"
                                                                            )[0]
                                                                    }
                                                                    setSelected={(
                                                                        e: any
                                                                    ) =>
                                                                        handleChange(
                                                                            each._id,
                                                                            [
                                                                                new Date(
                                                                                    e
                                                                                ).getTime(),
                                                                            ],
                                                                            4
                                                                        )
                                                                    }
                                                                    required={
                                                                        true
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        {active.includes(each._id) &&
                                            active.includes(
                                                "Usage Frequency"
                                            ) && (
                                                <div className="px-5 mt-5 flex items-center flex-wrap gap-3">
                                                    {[
                                                        "Low",
                                                        "Medium",
                                                        "High",
                                                    ].map((each: string) => {
                                                        return (
                                                            <Radio
                                                                selected={
                                                                    frequency
                                                                }
                                                                setSelected={
                                                                    setFrequency
                                                                }
                                                                multi={true}
                                                                each={each}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            )}
                                    </div>
                                );
                            })
                    ) : (
                        <div className="text-center my-3">No result found</div>
                    )}
                </div>
            )}
            <div className="flex items-center justify-end px-5 my-5">
                <div
                    className="px-3 py-2 text-white text-xl font-bold bg-[#FF7B5F] cursor-pointer rounded"
                    onClick={() => {
                        dispatch(addCustomTokenFinalUser({ active: active }));
                        dispatch(toggleCustomTokenModal(""));
                    }}
                >
                    Continue
                </div>
            </div>
        </div>
    );
};
export default UsersWith;

// import Button from 'react-bootstrap/Button';
import { FC, useEffect, useState } from "react";
// import OtpInput from "../../components/auth/otpInput";
import { useDispatch, useSelector } from "react-redux";
import { getOTP, resetOtp, verifyOTP } from "../../redux/auth/authActions";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import Loader from "../../components/common/loader";
import Countdown from "react-countdown";
import ObjectID from "bson-objectid";
// import Navbar from '../../components/navbar/navbar';

const AuthIndex: FC = () => {
    const countdownRenderer = ({ minutes, seconds, completed }: any) => {
        if (completed) {
            // Countdown is complete, you can add your desired action here
            return <span>Countdown Complete</span>;
        } else {
            return (
                <span>
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
            );
        }
    };
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [otpSent, setOTPSent] = useState<boolean>(false);
    const [resend, setResend] = useState<boolean>(false);
    const [otp, setOtp] = useState<string>("");
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [cName, setCName] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [old, setOld] = useState<boolean>(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        isGettingOTP,
        getOTPError,
        // getOTPErrorMsg,
        getOTPResp,
        getOTPRespStatus,
        isVerifyingOTP,
        verifyOTPError,
        // verifyOTPErrorMsg,
        verifyOTPResp,
        verifyOTPRespStatus,
    } = useSelector((state: any) => state.auth);

    const handleGetOTP = () => {
        if (old) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (email && emailRegex.test(email)) {
                setResend(false);
                dispatch(getOTP({ email: email }));
            } else {
                alert("Please enter a valid Email");
            }
        } else {
            if (name.length === 0) {
                alert("Please Enter Name");
                return;
            }
            if (phoneNumber.length < 10) {
                alert("Please Enter a valid Phone Number");
                return;
            }
            if (cName.length === 0) {
                alert("Please Enter Company Name");
                return;
            }
            const getOTPData = {
                phone: phoneNumber,
                client_name: cName,
                client_user_name: name,
                client_prod_id: new ObjectID().toString(),
                client_user_prod_id: new ObjectID().toString(),
            };
            setResend(false);
            dispatch(getOTP(getOTPData));
        }
    };

    useEffect(() => {
        if (getOTPRespStatus === 200) {
            setOTPSent(true);
            setOtp("");
        } else {
            if (getOTPError) {
                alert("Something went wrong");
            }
            setOTPSent(false);
        }
    }, [isGettingOTP]);

    // VERIFY OTP
    const handleVerifyOTP = () => {
        if (otp.length === 5) {
            const verifyOTPData = {
                otp: otp,
                otphash: getOTPResp.data.value.otphash,
                uid: getOTPResp.data.value.uid,
            };
            dispatch(verifyOTP(verifyOTPData));
        } else {
            alert("Please enter a valid OTP");
            setOTPSent(false);
        }
    };

    const { logIn } = useUserAuth();

    useEffect(() => {
        if (verifyOTPRespStatus === 200) {
            (async function () {
                setIsLoggingIn(true);
                await logIn(verifyOTPResp.data.value.token);
                setIsLoggingIn(false);
                setOTPSent(false);
                setOtp("");
                verifyOTPResp.data.value.client?.is_onboarded
                    ? navigate("/tokens")
                    : navigate("/onboarding");
                dispatch(resetOtp({}));
            })();
        } else {
            if (verifyOTPError) {
                alert("Something went wrong");
                setOTPSent(false);
            }
        }
    }, [verifyOTPRespStatus]);

    return (
        <div className="">
            <div className="h-[100vh] grid grid-cols-2">
                <div className="col-span-1 flex items-center justify-center">
                    <div className="auth-content mt-[-100px] w-[85%] max-w-[600px]">
                        <div className="text-[#4C4C66] text-3xl font-bold mb-[50px] text-center">
                            {old ? "LOGIN TO DASHBOARD" : "WELCOME TO SNIPE"}
                        </div>
                        {otpSent ? (
                            <div className="w-full">
                                <div id="otp">
                                    <div className="text-[#4C4C66]">
                                        A 6-digit OTP has been sent to your
                                        phone number. The OTP will expire in 5
                                        minutes.
                                    </div>
                                    <div className="text-sm text-[#4C4C66] mt-5">
                                        Enter OTP
                                    </div>
                                    <input
                                        type="number"
                                        maxLength={10}
                                        placeholder="Enter the 6-digit OTP"
                                        className="px-4 py-2 rounded border-2 border-[#FFAFA8] outline-none w-full"
                                        value={otp}
                                        disabled={isVerifyingOTP}
                                        onChange={(e) => {
                                            if (e.target.value.length > 6) {
                                                e.target.value =
                                                    e.target.value.slice(0, 6);
                                                setOtp(e.target.value);
                                            } else {
                                                setOtp(e.target.value);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="text-lg font-bold">
                                {old === false && (
                                    <div className="mt-3">
                                        <div className="text-sm font-normal text-[#4C4C66]">
                                            Name
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Enter Your Full Name"
                                            className="px-4 py-2 rounded border-2 border-[#FFAFA8] outline-none w-full"
                                            value={name}
                                            disabled={isGettingOTP}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                )}
                                {old === false ? (
                                    <div className="mt-3">
                                        <div className="text-sm font-normal text-[#4C4C66]">
                                            Phone
                                        </div>
                                        <input
                                            type="number"
                                            maxLength={10}
                                            placeholder="Enter Your Phone Number"
                                            className="px-4 py-2 rounded border-2 border-[#FFAFA8] outline-none w-full"
                                            value={phoneNumber}
                                            disabled={isGettingOTP}
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length > 10
                                                ) {
                                                    e.target.value =
                                                        e.target.value.slice(
                                                            0,
                                                            10
                                                        );
                                                    setPhoneNumber(
                                                        e.target.value
                                                    );
                                                } else {
                                                    setPhoneNumber(
                                                        e.target.value
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div className="mt-3">
                                        <div className="text-sm font-normal text-[#4C4C66]">
                                            Email
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Enter Your Email"
                                            className="px-4 py-2 rounded border-2 border-[#FFAFA8] outline-none w-full"
                                            value={email}
                                            disabled={isGettingOTP}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                        />
                                    </div>
                                )}
                                {old === false && (
                                    <div className="mt-3">
                                        <div className="text-sm font-normal text-[#4C4C66]">
                                            Company Name
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Enter Your Company Name"
                                            className="px-4 py-2 rounded border-2 border-[#FFAFA8] outline-none w-full"
                                            value={cName}
                                            disabled={isGettingOTP}
                                            onChange={(e) =>
                                                setCName(e.target.value)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        {otpSent ? (
                            <div className="w-full mt-10">
                                <button
                                    disabled={isVerifyingOTP || isLoggingIn}
                                    className="flex items-center justify-center bg-[#FF7B5F] text-white font-bold shadow-lg px-5 py-2 rounded w-full"
                                    onClick={() => {
                                        handleVerifyOTP();
                                    }}
                                    // style={{ backgroundColor: "#D32F2F" }}
                                >
                                    {isVerifyingOTP || isLoggingIn ? (
                                        <Loader />
                                    ) : (
                                        "Verify OTP"
                                    )}
                                </button>{" "}
                            </div>
                        ) : (
                            <div className="w-full mt-10">
                                <button
                                    disabled={isGettingOTP}
                                    className="flex items-center justify-center bg-[#FF7B5F] text-white font-bold shadow-lg px-5 py-2 rounded w-full"
                                    onClick={() => {
                                        handleGetOTP();
                                    }}
                                    // style={{ backgroundColor: "#D32F2F" }}
                                >
                                    {isGettingOTP ? <Loader /> : "Get OTP"}
                                </button>{" "}
                            </div>
                        )}
                        {otpSent ? null : (
                            <div className="mt-5">
                                <div className="text-center font-normal">
                                    {old ? (
                                        <div>
                                            New User?
                                            <span
                                                className="font-bold cursor-pointer underline"
                                                onClick={() => setOld(false)}
                                            >
                                                {" "}
                                                Sign up
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                            Existing User?
                                            <span
                                                className="font-bold cursor-pointer underline"
                                                onClick={() => setOld(true)}
                                            >
                                                {" "}
                                                Sign in
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {otpSent && !(isVerifyingOTP || isLoggingIn) ? (
                            <div className="text-[#4C4C66] mt-3 flex items-center gap-3">
                                {resend === false && (
                                    <>
                                        Resend OTP in
                                        <Countdown
                                            date={Date.now() + 120000}
                                            renderer={countdownRenderer}
                                            onComplete={() => setResend(true)}
                                        />
                                    </>
                                )}
                                {resend && (
                                    <div className="">
                                        <u
                                            className="text-blue underline cursor-pointer"
                                            onClick={handleGetOTP}
                                        >
                                            Resend OTP
                                        </u>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="col-span-1 custom-bg">
                    <div className="p-10 h-full">
                        <div className="flex items-center justify-end">
                            <div className="flex items-center gap-3">
                                <div className="text-3xl text-[#65EECB]">
                                    snipeit.
                                    <span className="text-[#82C8F1]">ai</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center h-full mt-[-50px]">
                            <div className="text-[34px] md:text-[40px] lg:text-[54px] font-bold text-white col-span-10 md:col-span-6 xl:col-span-6 flex i w-full mt-5 md:mt-[0] flex-col justify-center">
                                <div className="w-full">
                                    Drive <br className="block md:hidden" />
                                    <span className="text-[#EAD260] ">
                                        {/* <Typewriter words={["Engagement", "Retention", "Loyalty"]} /> */}
                                        Engagement{" "}
                                    </span>
                                    with <br />
                                    <div className="flex items-center gap-3 md:gap-5">
                                        <span className="game text-[38px] md:text-[42px] lg:text-[58px] grad">
                                            Gamification
                                        </span>{" "}
                                    </div>
                                    {/* <br /> */}
                                </div>
                                <div className="text-[20px] md:text-[34px] mt-5">
                                    with SNIPE's one-of-a-kind{" "}
                                    <br className="hidden lg:block" />
                                    <span className="text-[#EAD260]">
                                        Customer Delight Engine.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthIndex;

import {
  GET_MULTIPLIERS_INIT,
  GET_MULTIPLIERS_SUCCESS,
  GET_MULTIPLIERS_FAILURE,
  UPDATE_MULTIPLIER_INIT,
  UPDATE_MULTIPLIER_SUCCESS,
  UPDATE_MULTIPLIER_FAILURE,
  ADD_MULTIPLIER_INIT,
  ADD_MULTIPLIER_SUCCESS,
  ADD_MULTIPLIER_FAILURE,
  DELETE_MULTIPLIER_INIT,
  DELETE_MULTIPLIER_SUCCESS,
  DELETE_MULTIPLIER_FAILURE,
} from "../../redux/actionTypes";
import { IMultipliersState } from "../../models/tokens/multipliers";

const initialState = {
  isGettingMultipliers: false,
  getMultipliersResp: [],
  getMultipliersRespStatus: 0,
  getMultipliersError: false,
  getMultipliersErrorMsg: "",
  isUpdatingMultiplier: false,
  updateMultiplierError: false,
  updateMultiplierErrorMsg: "",
  updateMultiplierResp: [],
  updateMultiplierRespStatus: 0,
  isAddingMultiplier: false,
  addMultiplierError: false,
  addMultiplierErrorMsg: "",
  addMultiplierResp: [],
  addMultiplierRespStatus: 0,
  isDeletingMultiplier: false,
  deleteMultiplierError: false,
  deleteMultiplierErrorMsg: "",
  deleteMultiplierResp: [],
  deleteMultiplierRespStatus: 0,
};

const multipliers = (state: IMultipliersState = initialState, action: any) => {
  switch (action.type) {
    case GET_MULTIPLIERS_INIT:
      return {
        ...state,
        isGettingMultipliers: true,
        getMultipliersError: false,
        getMultipliersRespStatus: 0,
      };

    case GET_MULTIPLIERS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingMultipliers: false,
        getMultipliersResp: data,
        getMultipliersRespStatus: 200,
      };
    }

    case GET_MULTIPLIERS_FAILURE:
      const { error } = action;
      return {
        ...state,
        isGettingMultipliers: false,
        getMultipliersError: true,
        getMultipliersErrorMsg: error,
        getMultipliersRespStatus: 400,
      };

    case UPDATE_MULTIPLIER_INIT:
      return {
        ...state,
        isUpdatingMultiplier: true,
        updateMultiplierError: false,
        updateMultiplierRespStatus: 0,
      };

    case UPDATE_MULTIPLIER_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingMultiplier: false,
        updateMultiplierResp: data,
        updateMultiplierRespStatus: 200,
      };
    }

    case UPDATE_MULTIPLIER_FAILURE:
      return {
        ...state,
        isUpdatingMultiplier: false,
        updateMultiplierError: true,
        updateMultiplierErrorMsg: action.error,
        updateMultiplierRespStatus: 400,
      };

    case ADD_MULTIPLIER_INIT:
      return {
        ...state,
        isAddingMultiplier: true,
        addMultiplierError: false,
        addMultiplierRespStatus: 0,
      };

    case ADD_MULTIPLIER_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingMultiplier: false,
        addMultiplierResp: data,
        addMultiplierRespStatus: 200,
      };
    }

    case ADD_MULTIPLIER_FAILURE:
      return {
        ...state,
        isAddingMultiplier: false,
        addMultiplierError: true,
        addMultiplierErrorMsg: action.error,
        addMultiplierRespStatus: 400,
      };

    case DELETE_MULTIPLIER_INIT:
      return {
        ...state,
        isDeletingMultiplier: true,
        deleteMultiplierError: false,
        deleteMultiplierRespStatus: 0,
      };

    case DELETE_MULTIPLIER_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isDeletingMultiplier: false,
        deleteMultiplierResp: data,
        deleteMultiplierRespStatus: 200,
      };
    }

    case DELETE_MULTIPLIER_FAILURE:
      return {
        ...state,
        isDeletingMultiplier: false,
        deleteMultiplierError: true,
        deleteMultiplierErrorMsg: action.error,
        deleteMultiplierRespStatus: 400,
      };

    default:
      return state;
  }
};

export default multipliers;

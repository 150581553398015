import { all, spawn } from "redux-saga/effects";
import * as gamificationSagas from "./gamification/gamificationSagas";
import * as tokenSagas from "./token/tokenSagas";
import * as eventSagas from "./token/eventSagas";
import * as authSagas from "./auth/authSaga";
import * as apiSagas from "./api/apiSagas";
import * as settingsSagas from "./settings/settingsSagas";
import * as userSagas from "./user/userSagas";
import * as multipliersSagas from "./token/multipliersSagas";
import * as milestoneSagas from "./token/milestoneSagas";
import * as streaksSagas from "./streak/streakSagas";
import * as referralSagas from "./referral/referralSagas";
import * as contestSagas from "./contest/contestSaga";
import * as attributeSagas from "./attribute/attributeSaga";

export default function* rootSaga() {
    yield all(
        [
            ...Object.values(gamificationSagas),
            ...Object.values(tokenSagas),
            ...Object.values(eventSagas),
            ...Object.values(authSagas),
            ...Object.values(apiSagas),
            ...Object.values(settingsSagas),
            ...Object.values(userSagas),
            ...Object.values(multipliersSagas),
            ...Object.values(milestoneSagas),
            ...Object.values(streaksSagas),
            ...Object.values(referralSagas),
            ...Object.values(contestSagas),
            ...Object.values(attributeSagas),
        ].map(spawn)
    );
}

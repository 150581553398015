import { useSelector } from "react-redux";
import Value from "../users/management/value";
import dayjs from "dayjs";

interface IReferralCardProps {
  referral: any;
  setSelectedReferral: (val: string) => void;
  setAddOrUpdateReferral: (val: boolean) => void;
  handleDelete: (ref_id: any) => void;
  handleEdit: ()=> void;
}

const ReferralCard = ({
  referral,
  setSelectedReferral,
  setAddOrUpdateReferral,
  handleDelete,
  handleEdit,
}: IReferralCardProps) => {
  const { getEventsResp } = useSelector((state: any) => state.events);
  const { getTokensResp } = useSelector((state: any) => state.tokens);
  const { getUserVariableResp } = useSelector((state: any) => state.user);

  return (
    <div>
      <div className="bg-[#FFF2F1] rounded-lg w-full p-5 mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="text-xl font-bold">{referral.name}</div>
            <div>|</div>

            <div className="flex items-center gap-1">
              <div className="text-[12px]">Priority: </div>
              <div className="text-[12px]">{referral.priority}</div>
            </div>
          </div>

          <div className="px-3 py-1 bg-[#BDBDBD] text-white font-bold rounded">
            Saved
          </div>
        </div>
        <div className="flex flex-row justify-between mt-2 mb-4">
          <div className="mt-2">
            Starting Date:{" "}
            {referral.start_date
              ? dayjs(referral.start_date).format("DD/MM/YYYY")
              : "N/A"}
          </div>
          <div className="mt-2">
            Ending Date:{" "}
            {referral.end_date
              ? dayjs(referral.end_date).format("DD/MM/YYYY")
              : "N/A"}
          </div>
        </div>
        <div className="grid grid-cols-4 gap-3 bg-[#FF7B5F] rounded-t-lg text-white font-bold px-3 py-2 mt-2">
          <div className="col-span-1">Referral Type</div>
          <div className="col-span-1">For users with</div>
          <div className="col-span-1">For these events</div>
          <div className="col-span-1">Rewards</div>
        </div>
        <div className="bg-[#FFE8E6] rounded-b-lg px-3 py-2 grid grid-cols-4 gap-3">
          <div className="col-span-1 flex items-start">
            {referral.referral_type != null || undefined ? (
              <Value
                name={referral.referral_type ? "Code Based" : "Dynamic Link"}
                value={""}
              />
            ) : (
              ""
            )}
          </div>
          <div className="col-span-1">
            <div
              className="cursor-pointer"
              //   onClick={() => handleOpen("to-users")}
            >
              <div className="flex items-center flex-wrap">
                {referral.user_filter?.strings
                  ?.filter((each: any) => each.values?.length > 0)
                  ?.map((each: any) => {
                    return (
                      <Value
                        name={
                          getUserVariableResp.data?.value?.find(
                            (ele: any) => ele._id === each.variable_id
                          )?.name
                        }
                        value={
                          ["in", "nin"].includes(each.operation)
                            ? `[${each.values?.join(", ")}]`
                            : each.values
                        }
                        operator={each.operation}
                      />
                    );
                  })}
              </div>
              <div className="flex items-center flex-wrap">
                {referral.user_filter?.numbers
                  ?.filter((each: any) => each.values?.length > 0)
                  ?.map((each: any) => {
                    return (
                      <Value
                        name={
                          getUserVariableResp.data?.value?.find(
                            (ele: any) => ele._id === each.variable_id
                          )?.name
                        }
                        value={
                          ["bet"].includes(each.operation)
                            ? `[${each.values?.join(",")}]`
                            : each.values
                        }
                        operator={each.operation}
                      />
                    );
                  })}
              </div>
              <div className="flex items-center flex-wrap">
                {referral.user_filter?.booleans?.map((each: any) => {
                  return (
                    <Value
                      name={
                        getUserVariableResp.data?.value?.find(
                          (ele: any) => ele._id === each.variable_id
                        )?.name
                      }
                      value={each.values === true ? "True" : "False"}
                    />
                  );
                })}
              </div>
              <div className="flex items-center flex-wrap">
                {referral.user_filter?.dates?.map((each: any) => {
                  return (
                    <Value
                      name={
                        getUserVariableResp.data?.value?.find(
                          (ele: any) => ele._id === each.variable_id
                        )?.name
                      }
                      value={
                        new Date(each.value[0]).toISOString().split("T")[0]
                      }
                      operator={each.operation}
                      multi={each.operation === "bet"}
                      secondVal={
                        new Date(each.value[1] || new Date().getTime())
                          .toISOString()
                          .split("T")[0]
                      }
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-span-1">
            {referral.referrer_rewards &&
            referral.referrer_rewards.length > 0 &&
            getEventsResp &&
            getEventsResp?.data?.value.length > 0
              ? getEventsResp?.data?.value.map((event: any) => {
                  return (
                    referral.referrer_rewards.some(
                      (r: any) => r.event_id === event._id
                    ) && (
                      <div className="flex flex-row">
                        <Value key={event._id} name={event.name} value={""} />
                      </div>
                    )
                  );
                })
              : ""}
          </div>

          <div className="col-span-1">
            <div className="grid grid-cols-5 gap-3">
              <div className="col-span-2 mt-1">Referrer Rewards</div>
              <div className="col-span-3">
                <div className="flex items-center flex-wrap gap-1">
                  <div className="cursor-pointer">
                    {referral.referrer_rewards &&
                      referral.referrer_rewards.length > 0 && (
                        <div className="cursor-pointer">
                          {getTokensResp &&
                            getTokensResp?.data?.value.length > 0 &&
                            referral &&
                            referral.referrer_rewards.map(
                              (reward: any, index: number) => (
                                <div key={index}>
                                  {reward.tokens.map(
                                    (token: any, index: number) => {
                                      return (
                                        <div
                                          key={index}
                                          className="flex flex-row"
                                        >
                                          <Value
                                            key={token._id}
                                            name={
                                              getTokensResp.data?.value?.find(
                                                (ele: any) =>
                                                  ele._id === token.token_id
                                              )?.name
                                            }
                                            value={token.value}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )
                            )}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-span-2 mt-1">Referee Rewards</div>
              <div className="col-span-3">
                <div className="flex items-center flex-wrap gap-1">
                  <div className="cursor-pointer">
                    {referral.referee_rewards &&
                      referral.referee_rewards.length > 0 && (
                        <div className="cursor-pointer">
                          {getTokensResp &&
                            getTokensResp?.data?.value.length > 0 &&
                            referral &&
                            referral.referee_rewards.map(
                              (reward: any, index: number) => (
                                <div key={index}>
                                  {reward.tokens.map(
                                    (token: any, index: number) => {
                                      return (
                                        <div
                                          key={index}
                                          className="flex flex-row"
                                        >
                                          <Value
                                            key={token._id}
                                            name={
                                              getTokensResp.data?.value?.find(
                                                (ele: any) =>
                                                  ele._id === token.token_id
                                              )?.name
                                            }
                                            value={token.value}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )
                            )}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3 mt-5">
          <button
            className="outline-none bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-1"
            onClick={() => {
              setSelectedReferral(referral._id);
              setAddOrUpdateReferral(true);
              handleEdit();
            }}
          >
            Edit
          </button>
          <button
            className="outline-none bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-1"
            onClick={() => {
              handleDelete(referral._id);
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferralCard;

import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Add from "../../gamification/add";
import {
  addCampaign,
  toggleCustomTokenModal,
} from "../../../redux/user/customTokenActions";
import Value from "./value";
import { useUserAuth } from "../../../hooks/useAuthContextProvider";
import { filterCreditIndividual } from "../../../redux/user/userActions";
import Loader from "../../common/loader";
// import json from "./json";

type CustomTokenProps = {
  //   add: boolean;
  title: string;
  isGroup: boolean;
};

const CustomIndividualToken = ({ title, isGroup }: CustomTokenProps) => {
  const { user } = useUserAuth();
  const dispatch = useDispatch();

  const { id } = useSelector((state: any) => state.customToken);
  const { isGettingTokens, getTokensResp, getTokensRespStatus } = useSelector(
    (state: any) => state.tokens
  );
  const { getMultipliersResp } = useSelector((state: any) => state.multipliers);
  const {
    individualUserFilter,
    individualInputs,
    isFilterCreditIndividual,
    userIndividualMultiplier,
  } = useSelector((state: any) => state.user);

  const handleOpen = (type: string) => {
    const i = uuidv4();
    if (id) {
    } else {
      dispatch(addCampaign({ id: i }));
      // const exists = localStorage.getItem("campaigns");
    }
    dispatch(toggleCustomTokenModal({ id: id ? id : i, type: type }));
  };
  return (
    <div className="bg-[#FFF2F1] rounded-lg w-full p-5 mt-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="text-xl font-bold">{title}</div>
        </div>
      </div>
      <div className="grid grid-cols-2 bg-[#FF7B5F] rounded-t-lg text-white font-bold px-3 py-2 mt-5">
        {/* <div className="col-span-1">Notification method</div> */}
        <div className="col-span-1">To users with</div>
        <div className="col-span-1">What to send</div>
      </div>
      <div className="bg-[#FFE8E6] rounded-b-lg px-3 py-2 grid grid-cols-2">
        <div className="col-span-1">
          <div className="flex items-center flex-wrap gap-3 mt-1">
            {individualUserFilter.map((each: string) => {
              return <Value name={"ID"} value={each} operator={":"} />;
            })}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => handleOpen("to-individual-users")}
          >
            <Add text={"ADD RULES HERE"} />
          </div>
        </div>
        <div className="col-span-1">
          <div className="grid grid-cols-4 gap-3">
            <div className="col-span-1 mt-1">Tokens:</div>
            <div className="col-span-3">
              <div className="flex items-center flex-wrap gap-1">
                <div
                  className="cursor-pointer"
                  onClick={() => handleOpen("individual-what")}
                >
                  {getTokensRespStatus === 200 && (
                    <div className="flex items-center flex-wrap gap-3">
                      {Object.values(individualInputs)
                        ?.filter((each: any) => each.selected === true)
                        ?.map((each: any) => {
                          const token = getTokensResp.data?.value?.find(
                            (ele: any) => ele._id === each.token_id
                          );
                          return <Value name={token.name} value={each.value} />;
                        })}
                      {isGettingTokens ? (
                        <Loader />
                      ) : (
                        <Add text={"ADD RULES HERE"} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-1 mt-1">Multipliers:</div>
            <div className="col-span-3">
              <div className="flex items-center flex-wrap gap-1">
                {userIndividualMultiplier && (
                  <Value
                    name={
                      getMultipliersResp.data?.value?.find(
                        (ele: any) => ele._id === userIndividualMultiplier
                      )?.name
                    }
                    value={""}
                    operator={"|"}
                  />
                )}
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    handleOpen("individual-what-multipliers");
                  }}
                >
                  <Add text={"Add token"} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3 mt-5">
          <button
            className="bg-[#FF7B5F] rounded-[100px] cursor-pointer text-white shadow-lg px-5 py-2"
            onClick={() => {
              if (userIndividualMultiplier) {
                dispatch(
                  filterCreditIndividual({
                    users: Object.values(individualUserFilter),
                    tokens: Object.values(individualInputs),
                    token: user.accessToken,
                    multiplier: userIndividualMultiplier,
                  })
                );
              } else {
                dispatch(
                  filterCreditIndividual({
                    users: Object.values(individualUserFilter),
                    tokens: Object.values(individualInputs),
                    token: user.accessToken,
                  })
                );
              }
            }}
            disabled={isFilterCreditIndividual}
          >
            {isFilterCreditIndividual ? <Loader /> : "Send Tokens"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default CustomIndividualToken;

import Modal from "react-modal";
import { customStyles } from "../../gamification/campaign";
import Text from "../../../components/gamification/modals/nudge/text";
import { useState, useEffect } from "react";
import Value from "../../../components/users/management/value";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/common/loader";
import {
  searchUserWithId,
  updateUserIndividualMultiplierInput,
  filterCreditIndividual,
} from "../../../redux/user/userActions";
import { useUserAuth } from "../../../hooks/useAuthContextProvider";
import UserTransactionHistory from "../management/userTransactionHistroy";

import { addCustomTokenValueIndividualUser } from "../../../redux/user/userActions";
interface IUserManagementHistory {
  history: boolean;
  setHistory: (val: boolean) => void;
}

const UserManagementHistory = ({
  history,
  setHistory,
}: IUserManagementHistory) => {
  const [active, setActive] = useState<Array<string>>([""]);
  const [individualToken, setIndividualToken] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const { user } = useUserAuth();
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const handleSearch = (e: any) => {
    e.preventDefault();
    if (search.length !== 24) {
      alert("Incorrect Snipe User ID");
      return;
    }
    dispatch(searchUserWithId({ token: user.accessToken, userId: search }));
  };

  const { isGettingTokens, getTokensResp, getTokensRespStatus } = useSelector(
    (state: any) => state.tokens
  );

  const { isGettingMultipliers, getMultipliersResp, getMultipliersRespStatus } =
    useSelector((state: any) => state.multipliers);

  const {
    isSearchingUserWithId,
    searchUserWithIdResp,
    searchUserWithIdRespStatus,
    searchUserWithIdError,
    // searchUserWithErrorMsg,
    individualInputs,
    userIndividualMultiplier,
    getUserTokenTransactionHistoryResp,
  } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (getTokensRespStatus === 200) {
      dispatch(
        addCustomTokenValueIndividualUser({
          e: individualInputs,
        })
      );
      setActive(
        Object.keys(individualInputs).filter(
          (each: any, i: number) =>
            (Object.values(individualInputs) as any)[i].selected === true
        )
      );
    }
  }, [isGettingTokens]);

  const handleAdd = (multiplierId: string) => {
    dispatch(
      updateUserIndividualMultiplierInput({ multiplierId: multiplierId })
    );
  };
  const handleCloseModalEvent = () => {
    setShowModal(false);
  };
  const handleOpenModal = (userId: string) => {
    setShowModal(true);
  };
  console.log(searchUserWithIdResp?.data?.value);
  return (
    <div>
      {" "}
      <Modal
        style={customStyles}
        isOpen={history}
        onRequestClose={() => {
          setHistory(false);
          setIndividualToken("");
        }}
      >
        <div className="p-5 text-[#4C4C66] w-[700px]">
          {individualToken === "" && (
            <>
              <div className="flex justify-between ">
                <div>
                  <div className="text-2xl text-[#4C4C66] font-bold">
                    View User History
                  </div>
                  {/* <div className="text-[#3189B4] mt-3">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Praesentium, aperiam quis in explicabo a aliquid libero dicta
            repudiandae consequatur deleniti enim et, harum sapiente veritatis
            dolores? Similique minima mollitia enim?
          </div> */}
                </div>
                <div
                  className="text-xl text-[#4C4C66] cursor-pointer"
                  onClick={() => setHistory(false)}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                      fill="#4C4C66"
                    />
                  </svg>
                </div>
              </div>
              <div className="mt-5 w-full">
                <div className="text-sm text-[#4C4C66]">
                  Search user with Snipe User ID
                </div>
                <form
                  className="flex items-center gap-3 w-full"
                  onSubmit={handleSearch}
                >
                  <Text
                    text={search}
                    setText={setSearch}
                    place={"- eg : sdfb28374rb2389 -"}
                    full={true}
                    required={true}
                  />
                  <button
                    className="bg-[#FF7b5F] rounded text-white font-bold shadow-lg px-5 py-2 border-none"
                    type={"submit"}
                    disabled={isSearchingUserWithId}
                  >
                    {isSearchingUserWithId ? <Loader /> : "Search"}
                  </button>
                </form>
              </div>
              {searchUserWithIdError && (
                <div className="flex items-center justify-center mt-5">
                  Something went wrong
                </div>
              )}
              {searchUserWithIdRespStatus === 200 && search.length === 24 && (
                <>
                  <div className="text-lg font-bold mt-5">Search result</div>
                  <div className="bg-[#FFE8E6] rounded p-5">
                    <div className="grid grid-cols-3">
                      <div className="col-span-1">ID</div>
                      <div className="col-span-2">{search}</div>
                    </div>
                    <div className="grid grid-cols-3 mt-3">
                      <div className="col-span-1">Current holdings</div>
                      <div className="col-span-2 flex teims-center flex-wrap gap-3">
                        {searchUserWithIdResp.data?.value?.current_tokens?.map(
                          (each: any) => {
                            return (
                              <Value name={each.token_id} value={each.value} />
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-3 mt-3">
                      <div className="col-span-1">User Variables</div>
                      <div className="col-span-2 flex teims-center flex-wrap gap-3">
                        {searchUserWithIdResp?.data?.value?.user_variables?.map(
                          (each: any) => {
                            return (
                              <Value
                                name={each.variable_id}
                                value={each.value}
                              />
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end gap-2">
                    <button
                      className="bg-[#FF7b5F]  mt-4 mb-4  rounded text-white shadow-lg px-2 py-2 border-none"
                      onClick={() => {
                        setIndividualToken("openCustomToken");
                      }}
                    >
                      {"Credit tokens"}
                    </button>
                    <button
                      className="bg-[#FF7b5F] mt-4 mb-4 rounded text-white shadow-lg px-2 py-2 border-none"
                      onClick={() => {
                        setIndividualToken("openCutomMultiplier");
                      }}
                    >
                      {"Add multiplier"}
                    </button>
                    <button
                      className="bg-[#FF7b5F] mt-4 mb-4  rounded text-white  shadow-lg px-2 py-2 border-none"
                      onClick={() => handleOpenModal(search)}
                    >
                      {"View transaction history of the user"}
                    </button>
                  </div>
                </>
              )}
            </>
          )}
          {individualToken === "openCustomToken" && (
            <>
              <form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  dispatch(
                    filterCreditIndividual({
                      users: [search],
                      tokens: Object.values(individualInputs),
                      token: user.accessToken,
                    })
                  );
                  dispatch(
                    addCustomTokenValueIndividualUser({
                      id: "",
                      value: "",
                      selected: false,
                    })
                  );
                  setIndividualToken("");
                  setActive([]);
                }}
              >
                <div className="flex justify-between w-[100%] px-5 pt-5 pb-5">
                  <div>
                    <div className="text-2xl text-[#4C4C66] font-bold">
                      Select Tokens
                    </div>
                    <div className="text-[#3189B4] mt-3"></div>
                  </div>
                  <div
                    className="text-xl text-[#4C4C66] cursor-pointer"
                    onClick={() => {
                      dispatch(
                        addCustomTokenValueIndividualUser({
                          id: "",
                          value: "",
                          selected: false,
                        })
                      );
                      setIndividualToken("");
                      setActive([]);
                    }}
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                        fill="#4C4C66"
                      />
                    </svg>
                  </div>
                </div>
                {isGettingTokens && (
                  <div className="flex items-center justify-center">
                    <Loader heavy={true} />
                  </div>
                )}
                {getTokensRespStatus === 200 && (
                  <div className="px-5 max-h-[500px] overflow-y-auto">
                    <div className="text-[#5F5E76] text-xl mb-5">
                      Select rule(s):
                    </div>
                    <div className="mt-3 bg-[#FFE8E6] rounded w-full max-h-[150px] overflow-y-auto p-3 flex flex-col gap-3">
                      {getTokensResp?.data?.value?.map((each: any) => {
                        return (
                          <div
                            className={
                              "rounded border-2 border-[#FFAFA8]  cursor-pointer p-3" +
                              (active.includes(each._id)
                                ? " bg-white"
                                : " bg-[#FFF2F2]")
                            }
                          >
                            <div
                              className={"flex items-center gap-3"}
                              onClick={() => {
                                if (active.includes(each._id)) {
                                  setActive(
                                    active.filter(
                                      (element: any) => element !== each._id
                                    )
                                  );
                                } else {
                                  setActive(
                                    Array.from(new Set([...active, each._id]))
                                  );
                                }
                              }}
                            >
                              {active.includes(each._id) ? (
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                    fill="#5F5E76"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                    fill="#FFAFA8"
                                  />
                                </svg>
                              )}
                              <div className="flex items-center gap-3">
                                <img
                                  src={each.image}
                                  alt={each.image}
                                  className="w-[30px]"
                                />
                                <div className="text-lg font-bold">
                                  {each.name}
                                </div>
                              </div>
                            </div>
                            {active.includes(each._id) ? (
                              <div
                                className="mt-3"
                                onClick={(e: any) => e.stopPropagation()}
                              >
                                <div className="mt-1 text-[14px] text-[#4C4C66]">
                                  Enter value
                                </div>
                                <Text
                                  text={individualInputs[each._id]?.["value"]}
                                  setText={(e: any) => {
                                    dispatch(
                                      addCustomTokenValueIndividualUser({
                                        id: each._id,
                                        value: e,
                                        selected: true,
                                      })
                                    );
                                  }}
                                  place={"e.g 5"}
                                  full={false}
                                  required={true}
                                  type={"number"}
                                />
                                <div className="mt-3 text-[14px] text-[#4C4C66]">
                                  Note : Enter value of the transaction amount
                                  to be given as token
                                </div>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="flex items-center justify-end px-5 my-5">
                  <button
                    className="px-3 py-2 text-white text-xl font-bold bg-[#FF7B5F] cursor-pointer rounded"
                    type={"submit"}
                  >
                    Send
                  </button>
                </div>
              </form>
            </>
          )}
          {individualToken === "openCutomMultiplier" && (
            <>
              <div className="p-5 w-[100%]">
                <div className="mt-5 flex flex-col w-full gap-3">
                  <div className="flex justify-between">
                    <div>
                      <div className="text-2xl text-[#4C4C66] font-bold">
                        Add Multiplier
                      </div>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setIndividualToken("");
                        handleAdd("");
                      }}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                          fill="#4C4C66"
                        />
                      </svg>
                    </div>
                  </div>
                  {isGettingMultipliers && (
                    <div className="flex items-center justify-center">
                      <Loader heavy={true} />
                    </div>
                  )}
                  {getMultipliersRespStatus === 200 &&
                    getMultipliersResp.data?.value?.map((each: any) => {
                      return (
                        <div
                          className={
                            "rounded border-2 border-[#FFAFA8]  cursor-pointer p-3" +
                            (userIndividualMultiplier === each._id
                              ? " bg-white"
                              : " bg-[#FFF2F2]")
                          }
                        >
                          <div
                            className={"flex items-center gap-3"}
                            onClick={() => handleAdd(each._id)}
                          >
                            {userIndividualMultiplier === each._id ? (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                  fill="#5F5E76"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                  fill="#FFAFA8"
                                />
                              </svg>
                            )}
                            <div className="flex items-center gap-3">
                              <div className="text-lg font-bold">
                                {each.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="mt-10 mb-5 px-5 flex items-center justify-end">
                  <div
                    className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
                    onClick={() => {
                      dispatch(
                        filterCreditIndividual({
                          users: [search],
                          tokens: Object.values(individualInputs),
                          token: user.accessToken,
                          multiplier: userIndividualMultiplier,
                        })
                      );
                      setIndividualToken("");
                      handleAdd("");
                    }}
                  >
                    Send
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
      {showModal && (
        <UserTransactionHistory
          showModal={showModal}
          handleCloseModalEvent={handleCloseModalEvent}
          events={getUserTokenTransactionHistoryResp?.data?.value}
          search={search}
        />
      )}
    </div>
  );
};
export default UserManagementHistory;

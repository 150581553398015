import { put, call, takeLatest } from "redux-saga/effects";
import {
    ADD_CONTEST_INIT,
    DELETE_CONTEST_INIT,
    UPDATE_CONTEST_INIT,
    GET_CONTEST_INIT,
} from "../actionTypes";
import {
    addContestSuccess,
    addContestFailure,
    deleteContestSuccess,
    deleteContestFailure,
    getContestSuccess,
    getContestFailure,
    updateContestSuccess,
    updateContestFailure,
} from "./contestAction";
import {
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
} from "../../utils/apiRequests";
import { endPoints } from "../../utils/apiEndPoints";
import store from "../store";

function* getContest(action: any): any {
    try {
        const data = yield call(
            getRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.getAllContests
            }`,
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(getContestSuccess(data));
        } else {
            yield put(getContestFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(getContestFailure(err.message));
    }
}

export function* getContestSaga() {
    yield takeLatest(GET_CONTEST_INIT, getContest);
}

function* updateContest(action: any): any {
    try {
        const { token, _id, ...reqData } = action.payload.params;
        const data = yield call(
            patchRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.updateContest
            }/${_id}`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(updateContestSuccess(data));
        } else {
            yield put(updateContestFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(updateContestFailure(err.message));
    }
}

export function* updateContestSaga() {
    yield takeLatest(UPDATE_CONTEST_INIT, updateContest);
}

function* addContest(action: any): any {
    try {
        const { token, ...reqData } = action.payload.params;
        const data = yield call(
            postRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.addContest
            }`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(addContestSuccess(data));
        } else {
            yield put(addContestFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(addContestFailure(err.message));
    }
}

export function* addContestSaga() {
    yield takeLatest(ADD_CONTEST_INIT, addContest);
}

function* deleteContest(action: any): any {
    try {
        const { _id } = action.payload.params;
        const data = yield call(
            deleteRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.deleteContest
            }/${_id}`,
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(deleteContestSuccess(data));
        } else {
            yield put(deleteContestFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(deleteContestFailure(err.message));
    }
}

export function* deleteContestSaga() {
    yield takeLatest(DELETE_CONTEST_INIT, deleteContest);
}

import { IAttributeState } from "../../models/attribute";
import {
    ADD_ATTRIBUTE_FAILURE,
    ADD_ATTRIBUTE_INIT,
    ADD_ATTRIBUTE_SUCCESS,
    DELETE_ATTRIBUTE_FAILURE,
    DELETE_ATTRIBUTE_INIT,
    DELETE_ATTRIBUTE_SUCCESS,
    GET_ATTRIBUTE_FAILURE,
    GET_ATTRIBUTE_INIT,
    GET_ATTRIBUTE_SUCCESS,
    UPDATE_ATTRIBUTE_FAILURE,
    UPDATE_ATTRIBUTE_INIT,
    UPDATE_ATTRIBUTE_SUCCESS,
} from "../../redux/actionTypes";

const initialState = {
    isGettingAttributes: false,
    getAttributesResp: [],
    getAttributesRespStatus: 0,
    getAttributesError: false,
    getAttributesErrorMsg: "",
    isUpdatingAttribute: false,
    updateAttributeError: false,
    updateAttributeErrorMsg: "",
    updateAttributeResp: [],
    updateAttributeRespStatus: 0,
    isAddingAttribute: false,
    addAttributeError: false,
    addAttributeErrorMsg: "",
    addAttributeResp: [],
    addAttributeRespStatus: 0,
    isDeletingAttribute: false,
    deleteAttributeError: false,
    deleteAttributeErrorMsg: "",
    deleteAttributeResp: [],
    deleteAttributeRespStatus: 0,
};

const contests = (state: IAttributeState = initialState, action: any) => {
    switch (action.type) {
        case GET_ATTRIBUTE_INIT:
            return {
                ...state,
                isGettingAttributes: true,
                getAttributesError: false,
                getAttributesRespStatus: 0,
            };

        case GET_ATTRIBUTE_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isGettingAttributes: false,
                getAttributesResp: data,
                getAttributesRespStatus: 200,
            };
        }

        case GET_ATTRIBUTE_FAILURE:
            const { error } = action;
            return {
                ...state,
                isGettingAttributes: false,
                getAttributesError: true,
                getAttributesErrorMsg: error,
                getAttributesRespStatus: 400,
            };

        case UPDATE_ATTRIBUTE_INIT:
            return {
                ...state,
                isUpdatingAttribute: true,
                updateAttributeError: false,
                updateAttributeRespStatus: 0,
            };

        case UPDATE_ATTRIBUTE_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isUpdatingAttribute: false,
                updateAttributeResp: data,
                updateAttributeRespStatus: 200,
            };
        }

        case UPDATE_ATTRIBUTE_FAILURE:
            return {
                ...state,
                isUpdatingAttribute: false,
                updateAttributeError: true,
                updateAttributeErrorMsg: action.error,
                updateAttributeRespStatus: 400,
            };

        case ADD_ATTRIBUTE_INIT:
            return {
                ...state,
                isAddingAttribute: true,
                addAttributeError: false,
                addAttributeRespStatus: 0,
            };

        case ADD_ATTRIBUTE_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isAddingAttribute: false,
                addAttributeResp: data,
                addAttributeRespStatus: 200,
            };
        }

        case ADD_ATTRIBUTE_FAILURE:
            return {
                ...state,
                isAddingAttribute: false,
                addAttributeError: true,
                addAttributeErrorMsg: action.error,
                addAttributeRespStatus: 400,
            };

        case DELETE_ATTRIBUTE_INIT:
            return {
                ...state,
                isDeletingAttribute: true,
                deleteAttributeError: false,
                deleteAttributeRespStatus: 0,
            };

        case DELETE_ATTRIBUTE_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isDeletingAttribute: false,
                deleteAttributeResp: data,
                deleteAttributeRespStatus: 200,
            };
        }

        case DELETE_ATTRIBUTE_FAILURE:
            return {
                ...state,
                isDeletingAttribute: false,
                deleteAttributeError: true,
                deleteAttributeErrorMsg: action.error,
                deleteAttributeRespStatus: 400,
            };

        default:
            return state;
    }
};

export default contests;

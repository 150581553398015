import ObjectId from "bson-objectid";
import { ref as REF, getDownloadURL, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import deleteD from "../../assets/svg/deleteD.svg";
import eye from "../../assets/svg/eye.svg";
import uploadD from "../../assets/svg/uploadD.svg";
import Accordion from "../../components/common/accordion";
import Loader from "../../components/common/loader";
import DateContainer from "../../components/gamification/modals/nudge/date";
import Select from "../../components/gamification/modals/nudge/select";
import Text from "../../components/gamification/modals/nudge/text";
import { storage } from "../../firebaseConfig";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import {
    addContest,
    getContest,
    updateContest,
} from "../../redux/contest/contestAction";
import store from "../../redux/store";
import { getRequest } from "../../utils/apiRequests";

const generateMongoObjectId = () => {
    let id = new ObjectId().toString();
    return id;
};

type Props = {
    closeAddMilestone: () => void;
    data?: any | null;
    tokens: [];
    events: [];
};
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: "0%",
        border: "none",
    },
    overlay: {
        zIndex: "1000",
        background: "rgba(1, 1, 1, 0.1)",
        backdropFilter: "blur(7px)",
    },
};

const generateRandomName = (extension: string) => {
    // Generate a random string for the image name based on the current timestamp
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(7);
    return `${timestamp}${randomString}.${extension}`;
};

const UploadImage = async (data: any, uploadType: string, clientId: string) => {
    if (data == null) {
        return null;
    } else if (typeof data === "string") {
        return data;
    }

    let imgURL = "";
    if (uploadType === "milestone") {
        imgURL = imgURL + `Contests/${clientId}/milestone`;
    } else if (uploadType === "challenge") {
        imgURL = imgURL + `Contests/${clientId}/challenge`;
    } else {
        imgURL = imgURL + `Contests/${clientId}/updatedData`;
    }

    const params = data;

    // Get the file extension from the original file name
    const fileExtension = params.name.split(".").pop();

    // Generate a random image name with the proper extension
    const randomImageName = generateRandomName(fileExtension);
    const imageRef = REF(storage, `${imgURL}/${randomImageName}`);
    await uploadBytes(imageRef, params);
    imgURL = await getDownloadURL(imageRef);
    return imgURL;
};

const AddMilestone = (props: Props) => {
    const { user } = useUserAuth();
    const dispatch = useDispatch();
    const [events, setEvents] = useState<any[]>(props.events || []);
    const [tokens, setTokens] = useState<any[]>(props.tokens || []);
    const [eventAttributes, setEventAttributes] = useState<any>([]);
    const [active, setActive] = useState<boolean>(true);
    const [activeAccordion, setActiveAccordion] = useState<number>(1);
    const [activeChallengeAccordion, setActiveChallengeAccordion] =
        useState<number>(props.data ? -1 : 1);
    const [editing, setEditing] = useState<boolean>(false);
    const [contestImage, setContestImage] = useState<any>(null);
    const [audienceCsv, setAudienceCsv] = useState<any>(null);
    const [contestName, setContestName] = useState<string>("");
    const [contestDescription, setContestDescription] = useState<string>("");
    const [contestStartDate, setContestStartDate] = useState<Date | null>(null);
    const [contestEndDate, setContestEndDate] = useState<Date | null>(null);
    const [isAddingContest, setIsAddingContest] = useState<boolean>(false);
    const [targetAudienceOption, setTargetAudienceOption] =
        useState<string>("upload-csv");
    const [rewards, setRewards] = useState<
        {
            milestones: string[];
            tokens: {
                value: string | number;
                token_id: string;
            }[];
        }[]
    >([
        {
            milestones: ["658a9e4f99f3f56ce0255d4a"],
            tokens: [
                {
                    value: "",
                    token_id: "",
                },
            ],
        },
    ]);

    const { addContestRespStatus, updateContestRespStatus } = useSelector(
        (state: any) => state.contest
    );

    const [challenges, setChallenges] = useState<
        {
            image: any;
            name: string;
            description: string;
            event: string;
            event_attribute: string;
            variable_value: string;
            milestones: {
                image: any;
                name: string;
                description: string;
                milestone_quantity: number | string;
                _id: string;
            }[];
        }[]
    >([
        {
            image: null,
            name: "",
            description: "",
            event: "",
            event_attribute: "",
            variable_value: "",
            milestones: [
                {
                    image: null,
                    name: "",
                    description: "",
                    milestone_quantity: "",
                    _id: generateMongoObjectId(),
                },
            ],
        },
    ]);

    const fetchEventAttributes = async (id: string) => {
        try {
            const data = await getRequest(
                `${
                    (store.getState().developerTools as any).url
                }/snipe-milestone-contests/event-attributes/${id}`,
                { Authorization: `Bearer ${user.accessToken}` }
            );
            setEventAttributes(data.data.value);
        } catch (e: any) {}
    };

    const [isChallengesValid, setIsChallengesValid] = useState<boolean>(true);
    const [isRewardValid, setIsRewardValid] = useState<boolean>(true);

    const [openSaveContestModal, setOpenSaveContestModal] =
        useState<boolean>(false);

    const initialiseData = async () => {
        setActive(props.data.is_active);
        setContestImage(props.data.image);
        setContestName(props.data.name);
        setContestDescription(props.data.description);
        let sd = new Date(props.data.start_date);
        sd.setHours(sd.getHours() + 5);
        sd.setMinutes(sd.getMinutes() + 30);
        let ed = new Date(props.data.end_date);
        ed.setHours(ed.getHours() + 5);
        ed.setMinutes(ed.getMinutes() + 30);
        setContestStartDate(sd);
        setContestEndDate(ed);
        setChallenges(
            props.data.challenges.map((challenge: any) => {
                let { ...rest } = challenge;
                rest.milestones = rest.milestones.map((milestone: any) => {
                    let { ...restM } = milestone;
                    return restM;
                });
                rest.event = events.find((e) => e._id === rest.event)?.name;
                return rest;
            })
        );
        setRewards(
            props.data.rewards.map((reward: any) => {
                let { _id, ...rest } = reward;
                rest.tokens = rest.tokens.map((token: any) => {
                    let { _id, ...restT } = token;
                    restT.token_id = tokens.find(
                        (t: any) => t._id === restT.token_id
                    )?.name;
                    return restT;
                });
                return rest;
            })
        );
        setEditing(true);
    };

    useEffect(() => {
        if (props.data) {
            initialiseData();
        }
    }, [props.data]);

    const [firstAccordionValid, setFirstAccordionValid] =
        useState<boolean>(true);

    const checkFirstAccordionValidity = () => {
        const isValid =
            contestName.trim() !== "" &&
            contestStartDate !== null &&
            contestEndDate !== null;

        setFirstAccordionValid(isValid);
        return isValid;
    };
    const onSaveFirstAccordion = () => {
        if (checkFirstAccordionValidity()) {
            setActiveAccordion(activeAccordion === 1 ? 2 : -1);
        } else {
            setFirstAccordionValid(false);
            alert("Please fill in all required fields.");
        }
    };

    const checkCompleteValidity = () => {
        const isTokenValueValid = rewards.every((reward) =>
            typeof reward.tokens[0].value === "string"
                ? parseInt(reward.tokens[0].value) > 0
                : reward.tokens[0].value > 0
        );

        const isTokenSelectedValid = rewards.every(
            (reward) => reward.tokens[0].token_id !== ""
        );
        return (
            contestName.trim() !== "" &&
            contestStartDate !== null &&
            contestEndDate !== null &&
            isTokenSelectedValid &&
            isTokenValueValid &&
            challenges.every(
                (challenge) =>
                    challenge.name.trim() !== "" &&
                    challenge.event !== "" &&
                    challenge.event_attribute !== "" &&
                    challenge.variable_value !== "" &&
                    challenge.milestones.every((milestone) =>
                        milestone.name.trim() !== "" &&
                        typeof milestone.milestone_quantity === "string"
                            ? parseInt(milestone.milestone_quantity) > 0
                            : (milestone.milestone_quantity as number) > 0
                    )
            )
        );
    };

    const prepareTargetAudienceData = (callback: (val: string) => {}) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (e.target?.result) {
                let content = e.target.result as string;
                callback(content);
            }
        };
        reader.readAsText(audienceCsv);
    };

    const [userData, setUserData] = useState<any[]>([]);

    const audienceDataGetter = (content: string) => {
        const rows: string[] = content.split("\n") as string[];
        const result: {
            specific_users: {
                user_hash: string;
                snipe_user_id: string;
            }[];
        } = {
            specific_users: [],
        };
        for (let i = 1; i < rows.length; i++) {
            const columns: string[] = rows[i].split(",");
            if (columns.length === 2) {
                const userObject = {
                    user_hash: columns[0].trim(),
                    snipe_user_id: columns[1].trim(),
                };
                result.specific_users.push(userObject);
            }
        }
        setUserData(result.specific_users);
        return result;
    };

    useEffect(() => {
        if (audienceCsv) {
            prepareTargetAudienceData(audienceDataGetter);
        }
    }, [audienceCsv]);

    const prepareChallengeData = async () => {
        let tempData = [...challenges];
        tempData = await Promise.all(
            tempData.map(async (challenge) => {
                let tc = { ...challenge };
                tc.event = events.find((e) => e.name === tc.event)?._id;
                tc.image = await UploadImage(tc.image, "challenge", user.uid);
                tc.milestones = await Promise.all(
                    tc.milestones.map(async (milestone) => {
                        let tm = { ...milestone };
                        tm.milestone_quantity =
                            typeof tm.milestone_quantity == "string"
                                ? parseInt(tm.milestone_quantity)
                                : tm.milestone_quantity;
                        tm.image = await UploadImage(
                            tm.image,
                            "milestone",
                            user.uid
                        );
                        return tm;
                    })
                );
                return tc;
            })
        );
        return tempData;
    };

    /*Challenge*/
    // function to check the form validity of challenge details
    const checkChallengeFormValidity = (): boolean => {
        for (let i = 0; i < challenges.length; i++) {
            const challenge = challenges[i];

            if (!challenge.name || !challenge.event) {
                alert(
                    `Challenge ${
                        i + 1
                    } is missing required fields (Challenge Name and Event).`
                );
                // setActiveAccordion(i + 1);
                return false;
            }

            // let previousQuantity = -1;
            for (let j = 0; j < challenge.milestones.length; j++) {
                const milestone = challenge.milestones[j];

                if (
                    !milestone.name ||
                    (milestone.milestone_quantity as number) <= 0
                ) {
                    alert(
                        `Milestone ${j + 1} of Challenge ${
                            i + 1
                        } is missing required fields (Milestone Name or Quantity).`
                    );
                    //  setActiveAccordion(i + 1);
                    setActiveChallengeAccordion(i + 1);
                    return false;
                }

                // const currentQuantity = milestone.milestone_quantity;
                // console.log(
                //     (currentQuantity as number) <= (previousQuantity as number)
                // );
                // console.log("currentQuantity", currentQuantity as number);
                // console.log("previousQuantity", previousQuantity as number);

                // // if (
                // //     (currentQuantity as number) <= (previousQuantity as number)
                // // ) {
                // //     alert(
                // //         `Milestone quantities for Challenge ${
                // //             i + 1
                // //         } should be in increasing order.`
                // //     );
                // //     // setActiveAccordion(i + 1);
                // //     setActiveChallengeAccordion(i + 1);
                // //     return false;
                // // }
                // console.log(
                //     `valid for milestone ${
                //         j + 1
                //     } with current quantity ${currentQuantity} and previous quantity ${previousQuantity}`
                // );
                // (previousQuantity as number) = currentQuantity as number;
            }
        }

        return true;
    };

    // function to handle challenge save
    const onChallengeSave = () => {
        const isFormValid = checkChallengeFormValidity();
        setIsChallengesValid(isFormValid);
        if (isFormValid) setActiveAccordion(3);
    };

    /*Rewards*/

    // function to check the form validity of reward details
    const checkRewardFormValidity = (): boolean => {
        const isTokenValueValid = rewards.every((reward) =>
            typeof reward.tokens[0].value === "string"
                ? parseInt(reward.tokens[0].value) > 0
                : reward.tokens[0].value > 0
        );

        const isTokenSelectedValid = rewards.every(
            (reward) => reward.tokens[0].token_id !== ""
        );

        if (!isTokenValueValid || !isTokenSelectedValid) {
            alert("Please fill in all reward details");
            return false;
        }

        return true;
    };

    // function to handle reward save
    const onRewardSave = () => {
        const isFormValid = checkRewardFormValidity();
        setIsRewardValid(isFormValid);
        setActiveAccordion(4);
    };

    const onSaveContest = async () => {
        let sd = new Date(contestStartDate as Date);
        let nsd = sd.toISOString().slice(0, 10) + "T00:00:00.000+05:30";
        let ed = new Date(contestEndDate as Date);
        let ned = ed.toISOString().slice(0, 10) + "T23:59:59.000+05:30";

        let data = {
            image: await UploadImage(contestImage, "", user.uid),
            name: contestName,
            is_active: active,
            description: contestDescription,
            start_date: nsd,
            end_date: ned,
            // milestone_quantity: challenges[0].milestones.length,
            challenges: await prepareChallengeData(),
            rewards: rewards.map((reward, ind) => {
                let tr = { ...reward };
                tr.milestones = challenges.map(
                    (challenge) => challenge.milestones[ind]._id
                );
                tr.tokens = tr.tokens.map((token) => {
                    let tt = { ...token };
                    tt.token_id = tokens.find(
                        (t: any) => t.name === tt.token_id
                    )?._id;
                    tt.value =
                        typeof tt.value === "string"
                            ? parseInt(tt.value)
                            : tt.value;
                    return tt;
                });
                return tr;
            }),
            specific_users: userData,
        };
        if (editing) {
            dispatch(
                updateContest({
                    token: user.accessToken,
                    _id: props.data._id,
                    ...data,
                })
            );
        } else {
            dispatch(addContest({ token: user.accessToken, ...data }));
        }
        dispatch(getContest({ token: user.accessToken }));
    };

    const handleSave = () => {
        setOpenSaveContestModal(true);
    };

    const handleCancelSave = () => {
        setOpenSaveContestModal(false);
    };

    const handleConfirmSave = async () => {
        setIsAddingContest(true);
        await onSaveContest().then(() => {
            setOpenSaveContestModal(false);
            setIsAddingContest(false);
            if (
                addContestRespStatus < 300 ||
                (props.data && updateContestRespStatus < 300)
            ) {
                props.closeAddMilestone();
            } else {
                alert("Something wedfghjnt wrong");
            }
        });
    };
    return (
        <div className="overflow-y-auto w-full gap-5 mt-10">
            <div className="flex items-center justify-between">
                <div className="text-xl">
                    <span className="text-[#4C4C66]">Add / Modify Contest</span>
                </div>
            </div>
            <div className="flex flex-col w-full gap-y-2 mt-4 mb-4">
                <Accordion
                    isOpen={activeAccordion === 1}
                    onToggle={() => {
                        setActiveAccordion((prevAccordion) => {
                            if (prevAccordion === 1) {
                                onSaveFirstAccordion();
                                return -1;
                            } else if (prevAccordion === 2) {
                                return -1;
                            } else {
                                return 1;
                            }
                        });
                    }}
                    title="Basic Details"
                >
                    <div className="w-full text-[#4C4C66]">
                        <div className="mt-2 flex gap-5">
                            <div>
                                <div className="text-[14px] text-[#4C4C66]">
                                    Add image for Contest
                                </div>
                                <label htmlFor={"in"}>
                                    <div className="w-[150px] h-[150px] object-cover border-2 border-dashed border-[#FFAFa8] rounded flex items-center justify-center text-center mt-1 text-[14px] cursor-pointer p-5">
                                        {contestImage ? (
                                            <img
                                                src={
                                                    typeof contestImage ===
                                                    "string"
                                                        ? contestImage
                                                        : URL.createObjectURL(
                                                              contestImage
                                                          )
                                                }
                                                alt="t"
                                                className="w-full h-[100%] object-contain"
                                            />
                                        ) : (
                                            "Drag & Drop or upload"
                                        )}
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    id="in"
                                    accept="image/png, image/jpeg"
                                    className="hidden"
                                    onChange={(e: any) => {
                                        setContestImage(e.target.files[0]);
                                    }}
                                />
                            </div>
                            <div className="w-[3px] bg-[#CEB0Fa]"></div>
                            <div className="w-full">
                                {props.data && (
                                    <div className="flex flex-row items-center justify-end">
                                        <div className="flex items-center space-x-1">
                                            <label className="flex items-center cursor-pointer gap-3 bg-[#eff0F6] border-2 border=[#E0E4ff] rounded-[100px] px-3 py-2">
                                                <div
                                                    className={
                                                        "mr-3 " +
                                                        (active === false
                                                            ? " font-bold text-[#4C4C66]"
                                                            : " font-medium text-gray-400")
                                                    }
                                                >
                                                    Inactive
                                                </div>
                                                <div
                                                    className={`relative ${
                                                        active
                                                            ? "bg-[#4C4C66]"
                                                            : "bg-gray-300"
                                                    } w-10 h-6 rounded-full transition`}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="hidden"
                                                        checked={active}
                                                        onChange={() => {
                                                            setActive(!active);
                                                        }}
                                                    />
                                                    <div
                                                        className={`dot absolute top-1 ${
                                                            active
                                                                ? "bg-white right-1"
                                                                : "left-1 bg-gray-400"
                                                        } w-4 h-4 rounded-full transition`}
                                                    ></div>
                                                </div>
                                                <div
                                                    className={
                                                        "ml-3 " +
                                                        (active
                                                            ? " font-bold text-[#4C4C66]"
                                                            : " font-medium text-gray-400")
                                                    }
                                                >
                                                    Active
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={`${
                                        !firstAccordionValid &&
                                        contestName.trim() === "" &&
                                        "bg-[#DB6757] p-2"
                                    }`}
                                >
                                    <div className="text-[14px] text-[#4C4C66]">
                                        <div
                                            className={`${
                                                !firstAccordionValid &&
                                                contestName.trim() === "" &&
                                                "text-white"
                                            }`}
                                        >
                                            Contest Name*
                                        </div>
                                    </div>
                                    <Text
                                        text={contestName}
                                        setText={setContestName}
                                        place={"- eg: coin of fortune -"}
                                        full={true}
                                        required={true}
                                    />
                                </div>
                                <div className="mt-2">
                                    <div className="text-[14px] text-[#4C4C66]">
                                        <div
                                            className={`${
                                                !firstAccordionValid &&
                                                contestName.trim() === "" &&
                                                "text-white"
                                            }`}
                                        >
                                            Contest Description
                                        </div>
                                    </div>
                                    <Text
                                        text={contestDescription}
                                        setText={setContestDescription}
                                        place={"- eg: coin of fortune -"}
                                        full={true}
                                    />
                                </div>

                                <div className="text-[14px] text-[#4C4C66] mt-2">
                                    Contest Validity*
                                </div>
                                <div className="flex flex-row w-full items-center justify-between mt-1 bg-[#f9f9f9] p-2 rounded-md">
                                    <div
                                        className={`flex flex-col w-[45%] gap-y-1 ${
                                            !firstAccordionValid &&
                                            !contestStartDate &&
                                            "bg-[#DB6757] p-2"
                                        }`}
                                    >
                                        <span className="font-[500] font-DMSans text-[12px]">
                                            <div
                                                className={`flex flex-col w-[45%] gap-y-1 ${
                                                    !firstAccordionValid &&
                                                    !contestStartDate &&
                                                    "text-white"
                                                }`}
                                            >
                                                {" "}
                                                Start Date*
                                            </div>
                                        </span>
                                        <DateContainer
                                            selected={
                                                contestStartDate
                                                    ? new Date(contestStartDate)
                                                          ?.toISOString()
                                                          ?.split("T")?.[0]
                                                    : ""
                                            }
                                            setSelected={(val) => {
                                                if (val) {
                                                    const startDate = new Date(
                                                        val
                                                    );
                                                    if (
                                                        contestEndDate &&
                                                        contestEndDate <
                                                            startDate
                                                    ) {
                                                        alert(
                                                            "Start date cannot be greater than end date"
                                                        );
                                                    } else {
                                                        setContestStartDate(
                                                            startDate
                                                        );
                                                    }
                                                } else {
                                                    setContestStartDate(null);
                                                }
                                            }}
                                            required={false}
                                            classname="w-full"
                                        />
                                    </div>
                                    <div
                                        className={`flex flex-col w-[45%] gap-y-1 ${
                                            !firstAccordionValid &&
                                            !contestEndDate &&
                                            "bg-[#DB6757] p-2"
                                        }`}
                                    >
                                        <span className="font-[500] font-DMSans text-[12px]">
                                            <div
                                                className={`flex flex-col w-[45%] gap-y-1 ${
                                                    !firstAccordionValid &&
                                                    !contestStartDate &&
                                                    "text-white"
                                                }`}
                                            >
                                                End Date*
                                            </div>
                                        </span>
                                        <DateContainer
                                            selected={
                                                contestEndDate
                                                    ? contestEndDate
                                                          .toISOString()
                                                          ?.split("T")?.[0]
                                                    : ""
                                            }
                                            setSelected={(val) => {
                                                if (val) {
                                                    const endDate = new Date(
                                                        val
                                                    );
                                                    const startDate =
                                                        contestStartDate;

                                                    // Check if end date is smaller than start date
                                                    if (
                                                        startDate &&
                                                        endDate < startDate
                                                    ) {
                                                        // Show an alert or handle it in your preferred way
                                                        alert(
                                                            "End date cannot be smaller than start date"
                                                        );
                                                    } else {
                                                        setContestEndDate(
                                                            endDate
                                                        );
                                                    }
                                                } else {
                                                    setContestEndDate(null);
                                                }
                                            }}
                                            required={false}
                                            classname="w-full"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end gap-3 mt-2">
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={props.closeAddMilestone}
                        >
                            Cancel Changes
                        </button>
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={onSaveFirstAccordion}
                        >
                            Save & Next
                        </button>
                    </div>
                </Accordion>
                <Accordion
                    isOpen={activeAccordion === 2}
                    onToggle={() => {
                        activeAccordion === 2
                            ? setActiveAccordion(-1)
                            : setActiveAccordion(2);
                    }}
                    title="Challenge Details"
                    classname="bg-[#f9f9f9]"
                >
                    {challenges.map((challenge, index) => {
                        return (
                            <Accordion
                                isOpen={activeChallengeAccordion === index + 1}
                                onToggle={() => {
                                    activeChallengeAccordion === index + 1
                                        ? setActiveChallengeAccordion(-1)
                                        : setActiveChallengeAccordion(
                                              index + 1
                                          );

                                    fetchEventAttributes(
                                        events.find(
                                            (e) => e.name === challenge.event
                                        )?._id
                                    );
                                }}
                                title={"Challenge " + (index + 1)}
                                classname="mt-2"
                                key={"challenge-" + index}
                            >
                                <div className="flex flex-row w-full items-center justify-between mt-2 bg-[#ffffff] p-2 rounded-md text-[#4C4C66]">
                                    <div
                                        className={`flex flex-col w-[32%] gap-y-1 ${
                                            !isChallengesValid &&
                                            !challenge.name &&
                                            "bg-[#DB6757] p-2"
                                        }`}
                                    >
                                        <span className="font-[500] font-DMSans text-[12px]">
                                            <div
                                                className={`${
                                                    !isChallengesValid &&
                                                    !challenge.name &&
                                                    "text-white"
                                                }`}
                                            >
                                                {" "}
                                                Challenge Name*
                                            </div>
                                        </span>
                                        <Text
                                            text={challenge.name}
                                            setText={(val: string) => {
                                                let temp = [...challenges];
                                                temp[index].name = val;
                                                setChallenges(temp);
                                            }}
                                            place={"- eg : coin of fortune -"}
                                            full={true}
                                            required={true}
                                        />
                                    </div>
                                    <div className="flex flex-col w-[32%] gap-y-1">
                                        <span className="font-[500] font-DMSans text-[12px] ">
                                            Challenge Icon
                                        </span>
                                        <div
                                            className={`flex flex-row border-2 border-[#FFAFA8] px-3 py-2 rounded items-center cursor-pointer ${
                                                challenge.image
                                                    ? "justify-between"
                                                    : "justify-center"
                                            } `}
                                        >
                                            <label htmlFor={"in"}>
                                                {challenge.image
                                                    ? "Image Selected"
                                                    : "- CLICK TO UPLOAD IMAGE -"}
                                            </label>
                                            <input
                                                type="file"
                                                id="in"
                                                className="hidden"
                                                accept="image/png, image/jpeg"
                                                onChange={(e: any) => {
                                                    let temp = [...challenges];
                                                    temp[index].image =
                                                        e.target.files[0];
                                                    setChallenges(temp);
                                                }}
                                            />
                                            {challenge.image ? (
                                                <div className="flex flex-row items-center gap-x-2">
                                                    <img
                                                        src={eye}
                                                        alt="upload"
                                                        className="w-[15px] h-[15px]"
                                                        onClick={() => {
                                                            window.open(
                                                                challenge.image !==
                                                                    null &&
                                                                    typeof challenge.image ===
                                                                        "string"
                                                                    ? challenge.image
                                                                    : URL.createObjectURL(
                                                                          challenge.image
                                                                      )
                                                            );
                                                        }}
                                                    />
                                                    <img
                                                        src={deleteD}
                                                        alt="upload"
                                                        className="w-[15px] h-[15px]"
                                                        onClick={() => {
                                                            let temp = [
                                                                ...challenges,
                                                            ];
                                                            temp[index].image =
                                                                null;
                                                            setChallenges(temp);
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className={`flex flex-col w-[32%] gap-y-1 ${
                                            !isChallengesValid &&
                                            !challenge.event &&
                                            "bg-[#DB6757] p-2"
                                        }`}
                                    >
                                        <span className="font-[500] font-DMSans text-[12px]">
                                            <div
                                                className={`${
                                                    !isChallengesValid &&
                                                    !challenge.event &&
                                                    "text-white"
                                                }`}
                                            >
                                                Select Event*
                                            </div>
                                        </span>
                                        <Select
                                            selected={challenge.event}
                                            setSelected={(val: string) => {
                                                let temp = [...challenges];
                                                temp[index].event = val;
                                                fetchEventAttributes(
                                                    events.find(
                                                        (e) => e.name === val
                                                    )?._id
                                                );
                                                setChallenges(temp);
                                            }}
                                            required={true}
                                            values={events.map(
                                                (event) => event.name
                                            )}
                                            classname="w-full"
                                        />
                                    </div>
                                </div>
                                <div className="text-[14px] text-[#4C4C66] mt-2">
                                    Configure Attributes*
                                </div>
                                <div className="flex flex-row w-full items-center justify-between mt-1 bg-[#f9f9f9] p-2 rounded-md text-[#4C4C66]">
                                    <div className="flex flex-col w-[49%] gap-y-1">
                                        <span className="font-[500] font-DMSans text-[12px]">
                                            Select Attributes
                                        </span>

                                        <Select
                                            selected={
                                                eventAttributes.filter(
                                                    (e: any) =>
                                                        e.code ===
                                                        challenge.event_attribute
                                                )[0]?.alias
                                            }
                                            setSelected={(val: string) => {
                                                let temp = [...challenges];
                                                temp[index].event_attribute =
                                                    eventAttributes.filter(
                                                        (e: any) =>
                                                            e.alias === val
                                                    )[0].code;

                                                setChallenges(temp);
                                            }}
                                            required={true}
                                            values={eventAttributes.map(
                                                (e: any) => e.alias
                                            )}
                                            classname="w-full"
                                        />
                                    </div>
                                    <div className="flex flex-col w-[49%] gap-y-1">
                                        <span className="font-[500] font-DMSans text-[12px]">
                                            Attributes Value
                                        </span>
                                        <Text
                                            text={challenge.variable_value.toString()}
                                            setText={(val: string) => {
                                                let temp = [...challenges];
                                                temp[index].variable_value =
                                                    val;

                                                setChallenges(temp);
                                            }}
                                            place={"- eg : coin of fortune -"}
                                            full={true}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="text-[14px] text-[#4C4C66] mt-2">
                                    Configure Milestone *
                                </div>
                                <div className="flex flex-col w-full mt-1 bg-[#f9f9f9] p-2 rounded-md text-[#4C4C66]">
                                    {challenge.milestones.map(
                                        (milestone, ind) => (
                                            <>
                                                <div className="text-[14px] text-[#4C4C66] mt-2">
                                                    Milestone {ind + 1}
                                                </div>
                                                <div className="flex flex-row gap-x-3 items-center w-full">
                                                    <div className="flex flex-row w-full items-center justify-between mt-1 bg-[#ffffff] p-2 rounded-md text-[#4C4C66]">
                                                        <div
                                                            className={`!flex flex-col w-[32%] gap-y-1 ${
                                                                !isChallengesValid &&
                                                                !milestone.name &&
                                                                "bg-[#DB6757] p-2"
                                                            }`}
                                                        >
                                                            <div className="flex flex-row w-full items-center justify-between">
                                                                <span className="font-[500] font-DMSans text-[12px]">
                                                                    <div
                                                                        className={` ${
                                                                            !isChallengesValid &&
                                                                            !milestone.name &&
                                                                            "text-white"
                                                                        }`}
                                                                    >
                                                                        Milestone
                                                                        Name /
                                                                        Descrption*
                                                                    </div>
                                                                </span>
                                                                <span className="font-[400] font-DMSans text-[10px]">
                                                                    {"( "}
                                                                    {
                                                                        challenges[
                                                                            index
                                                                        ]
                                                                            .milestones[
                                                                            ind
                                                                        ].name
                                                                            .length
                                                                    }
                                                                    /8{" )"}
                                                                </span>
                                                            </div>
                                                            <Text
                                                                text={
                                                                    milestone.name
                                                                }
                                                                setText={(
                                                                    val: string
                                                                ) => {
                                                                    let temp = [
                                                                        ...challenges,
                                                                    ];
                                                                    temp[
                                                                        index
                                                                    ].milestones[
                                                                        ind
                                                                    ].name =
                                                                        val;
                                                                    setChallenges(
                                                                        temp
                                                                    );
                                                                }}
                                                                place={
                                                                    "- eg : Collect Coins -"
                                                                }
                                                                full={true}
                                                                required={true}
                                                                maxLength={8}
                                                            />
                                                        </div>
                                                        <div
                                                            className={`flex flex-col w-[32%] gap-y-1 ${
                                                                !isChallengesValid &&
                                                                !milestone.milestone_quantity &&
                                                                "bg-[#DB6757] p-2"
                                                            }`}
                                                        >
                                                            <span className="font-[500] font-DMSans text-[12px]">
                                                                <div
                                                                    className={` ${
                                                                        !isChallengesValid &&
                                                                        !milestone.name &&
                                                                        "text-white"
                                                                    }`}
                                                                >
                                                                    Quantity
                                                                </div>
                                                            </span>
                                                            <Text
                                                                text={milestone.milestone_quantity.toString()}
                                                                setText={(
                                                                    val: any
                                                                ) => {
                                                                    let temp = [
                                                                        ...challenges,
                                                                    ];
                                                                    temp[
                                                                        index
                                                                    ].milestones[
                                                                        ind
                                                                    ].milestone_quantity =
                                                                        val;
                                                                    setChallenges(
                                                                        temp
                                                                    );
                                                                }}
                                                                place={
                                                                    "- eg : 10 -"
                                                                }
                                                                full={true}
                                                                required={true}
                                                                type="number"
                                                            />
                                                        </div>
                                                        <div className="flex flex-col w-[32%] gap-y-1 ">
                                                            <span className="font-[500] font-DMSans text-[12px]">
                                                                Milestone Image
                                                            </span>
                                                            <div
                                                                className={`flex flex-row border-2 border-[#FFAFA8] px-3 py-2 rounded items-center cursor-pointer ${
                                                                    milestone.image
                                                                        ? "justify-between"
                                                                        : "justify-center"
                                                                }`}
                                                            >
                                                                <label
                                                                    htmlFor={
                                                                        "in" +
                                                                        ind
                                                                    }
                                                                >
                                                                    {milestone.image
                                                                        ? "Image Selected"
                                                                        : "- CLICK TO UPLOAD IMAGE -"}
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    id={
                                                                        "in" +
                                                                        ind
                                                                    }
                                                                    className="hidden"
                                                                    accept="image/png, image/jpeg"
                                                                    onChange={(
                                                                        e: any
                                                                    ) => {
                                                                        let temp =
                                                                            [
                                                                                ...challenges,
                                                                            ];
                                                                        temp[
                                                                            index
                                                                        ].milestones[
                                                                            ind
                                                                        ].image =
                                                                            e.target.files[0];
                                                                        setChallenges(
                                                                            temp
                                                                        );
                                                                    }}
                                                                />
                                                                {milestone.image ? (
                                                                    <div className="flex flex-row items-center gap-x-2">
                                                                        <img
                                                                            src={
                                                                                eye
                                                                            }
                                                                            alt="upload"
                                                                            className="w-[15px] h-[15px]"
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    milestone.image !==
                                                                                        null &&
                                                                                        typeof milestone.image ===
                                                                                            "string"
                                                                                        ? milestone.image
                                                                                        : URL.createObjectURL(
                                                                                              milestone.image
                                                                                          )
                                                                                );
                                                                            }}
                                                                        />
                                                                        <img
                                                                            src={
                                                                                deleteD
                                                                            }
                                                                            alt="upload"
                                                                            className="w-[15px] h-[15px]"
                                                                            onClick={() => {
                                                                                let temp =
                                                                                    [
                                                                                        ...challenges,
                                                                                    ];
                                                                                temp[
                                                                                    index
                                                                                ].milestones[
                                                                                    ind
                                                                                ].image =
                                                                                    null;
                                                                                setChallenges(
                                                                                    temp
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {challenge.milestones
                                                        .length > 1 &&
                                                        ind !== 0 && (
                                                            <img
                                                                src={deleteD}
                                                                alt="deleteMilestone"
                                                                className="w-[25px] h-[25px]"
                                                                onClick={() => {
                                                                    if (
                                                                        challenges[
                                                                            index
                                                                        ]
                                                                            .milestones
                                                                            .length >
                                                                        1
                                                                    ) {
                                                                        let temp =
                                                                            [
                                                                                ...challenges,
                                                                            ];
                                                                        let tempRewards =
                                                                            [
                                                                                ...rewards,
                                                                            ];

                                                                        tempRewards =
                                                                            tempRewards.splice(
                                                                                ind
                                                                            );
                                                                        temp =
                                                                            temp.map(
                                                                                (
                                                                                    t
                                                                                ) => {
                                                                                    let tc =
                                                                                        {
                                                                                            ...t,
                                                                                        };
                                                                                    tc.milestones =
                                                                                        tc.milestones.splice(
                                                                                            ind
                                                                                        );
                                                                                    return tc;
                                                                                }
                                                                            );
                                                                        setRewards(
                                                                            tempRewards
                                                                        );
                                                                        setChallenges(
                                                                            temp
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                </div>
                                            </>
                                        )
                                    )}
                                </div>
                            </Accordion>
                        );
                    })}

                    <div className="flex items-center justify-end gap-3 mt-2">
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={props.closeAddMilestone}
                        >
                            Cancel Changes
                        </button>
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={() => {
                                if (challenges[0].milestones.length === 3) {
                                    alert("You can add only 3 milestones");
                                    return;
                                }
                                let temp = [...challenges];
                                let tempRewards = [
                                    ...rewards,
                                    {
                                        milestones: [],
                                        tokens: [
                                            {
                                                value: "",
                                                token_id: "",
                                            },
                                        ],
                                    },
                                ];
                                temp = temp.map((challenge) => {
                                    let tc = { ...challenge };
                                    tc.milestones.push({
                                        image: "",
                                        name: "",
                                        description: "",
                                        milestone_quantity: "",
                                        _id: generateMongoObjectId(),
                                    });

                                    return tc;
                                });
                                setRewards(tempRewards);
                                setChallenges(temp);
                            }}
                        >
                            Add a new Milestone Quantity
                        </button>
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={() => {
                                let newMilestoneData = [];
                                for (
                                    let i = 0;
                                    i < challenges[0].milestones.length;
                                    i++
                                ) {
                                    newMilestoneData.push({
                                        image: "",
                                        name: "",
                                        description: "",
                                        milestone_quantity: "",
                                        _id: generateMongoObjectId(),
                                    });
                                }
                                setChallenges([
                                    ...challenges,
                                    {
                                        image: "",
                                        name: "",
                                        description: "",
                                        event: "",
                                        event_attribute: "",
                                        variable_value: "",
                                        milestones: [...newMilestoneData],
                                    },
                                ]);
                                setEventAttributes([]);
                            }}
                        >
                            Add Challenge
                        </button>
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={onChallengeSave}
                        >
                            Save & Next
                        </button>
                    </div>
                </Accordion>
                <Accordion
                    isOpen={activeAccordion === 3}
                    onToggle={() => {
                        activeAccordion === 3
                            ? setActiveAccordion(-1)
                            : setActiveAccordion(3);
                    }}
                    title="Reward Details"
                >
                    <div className="w-full text-[#4C4C66]">
                        <span className="font-[500] font-DMSans text-[12px]">
                            Reward Details*
                        </span>
                        <div className="flex flex-col w-full items-center gap-y-2 mt-1 bg-[#f9f9f9] p-2 rounded-md">
                            {rewards.map((_, ind) => {
                                return (
                                    <div className="flex flex-row items-center justify-between bg-[#ffffff] p-2 rounded-md w-full">
                                        <span className="font-[500] font-DMSans text-[14px]">
                                            On Achievement of all Milestone{" "}
                                            {ind + 1}
                                        </span>
                                        <div className="flex flex-row items-center gap-x-2">
                                            <div
                                                className={`flex flex-col gap-y-1 ${
                                                    !isRewardValid &&
                                                    !rewards[ind].tokens[0]
                                                        .value &&
                                                    "bg-[#DB6757] p-2"
                                                }`}
                                            >
                                                <div className="text-[12px] text-[#4C4C66]">
                                                    <div
                                                        className={`${
                                                            !isRewardValid &&
                                                            !rewards[ind]
                                                                .tokens[0]
                                                                .value &&
                                                            "text-white"
                                                        }`}
                                                    >
                                                        Value of Token*
                                                    </div>
                                                </div>
                                                <Text
                                                    text={rewards[
                                                        ind
                                                    ].tokens[0].value.toString()}
                                                    setText={(val: any) => {
                                                        let temp = [...rewards];
                                                        temp[
                                                            ind
                                                        ].tokens[0].value = val;

                                                        setRewards(temp);
                                                    }}
                                                    place={"- eg : 100 -"}
                                                    full={false}
                                                    required={true}
                                                    type="number"
                                                />
                                            </div>
                                            <div
                                                className={`flex flex-col gap-y-1 ${
                                                    !isRewardValid &&
                                                    !rewards[0].tokens[ind]
                                                        .token_id &&
                                                    "bg-[#DB6757] p-2"
                                                }`}
                                            >
                                                <div className="text-[12px] text-[#4C4C66]">
                                                    <div
                                                        className={`flex flex-col gap-y-1 ${
                                                            !isRewardValid &&
                                                            !rewards[0].tokens[
                                                                ind
                                                            ].token_id &&
                                                            "text-white"
                                                        }`}
                                                    >
                                                        Token*
                                                    </div>
                                                </div>
                                                <Select
                                                    selected={
                                                        rewards[ind].tokens[0]
                                                            .token_id
                                                    }
                                                    setSelected={(
                                                        val: string
                                                    ) => {
                                                        let temp = [...rewards];
                                                        temp[
                                                            ind
                                                        ].tokens[0].token_id =
                                                            val;
                                                        setRewards(temp);
                                                    }}
                                                    required={true}
                                                    values={tokens.map(
                                                        (token: any) =>
                                                            token.name
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex items-center justify-end gap-3 mt-2">
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={props.closeAddMilestone}
                        >
                            Cancel Changes
                        </button>
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={onRewardSave}
                        >
                            Save & Next
                        </button>
                    </div>
                </Accordion>
                <Accordion
                    isOpen={activeAccordion === 4}
                    onToggle={() => {
                        activeAccordion === 4
                            ? setActiveAccordion(-1)
                            : setActiveAccordion(4);
                    }}
                    title="Target Audience"
                >
                    <div className="w-full text-[#4C4C66]">
                        <span className="font-[500] font-DMSans text-[12px]">
                            Select Target Audience and Rules
                        </span>
                        <div className="flex flex-row items-center justify-between w-full">
                            <div className="mt-2 flex flex-row gap-x-2">
                                <div className="flex flex-row items-center gap-x-1 rounded-[20px] border-[2px] px-[6px] h-[35px]">
                                    <input
                                        type="radio"
                                        className="w-3 h-3"
                                        checked={
                                            targetAudienceOption ===
                                            "upload-csv"
                                        }
                                        onChange={() => {
                                            setTargetAudienceOption(
                                                "upload-csv"
                                            );
                                        }}
                                    />
                                    <span className="font-DMSans font-[500] text-[10px]">
                                        Upload CSV
                                    </span>
                                </div>
                                <div className="flex flex-row items-center gap-x-1 rounded-[20px] border-[2px] px-[6px] h-[35px] bg-[#c3c2c2]">
                                    <input
                                        type="radio"
                                        className="w-3 h-3"
                                        disabled
                                        checked={
                                            targetAudienceOption ===
                                            "advanced-grouping"
                                        }
                                        onChange={() => {
                                            setTargetAudienceOption(
                                                "advanced-grouping"
                                            );
                                        }}
                                    />
                                    <span className="font-DMSans font-[500] text-[10px]">
                                        Advanced Grouping
                                    </span>
                                </div>
                            </div>
                            <a
                                href="https://firebasestorage.googleapis.com/v0/b/snipeit-api.appspot.com/o/contest%2Fdata%2Ftarget_audience.csv?alt=media&token=93fcda21-7b20-4ed9-94e0-08b2afbb3293"
                                target="_blank"
                            >
                                <span className="text-blue-500 underline text-[12px] cursor-pointer">
                                    Download Sample CSV
                                </span>
                            </a>
                        </div>
                    </div>
                    <span className="font-[500] font-DMSans text-[12px] text-[#4C4C66]">
                        Upload Excel of Eligible Stores
                    </span>
                    <div className="flex flex-col gap-y-2 items-center justify-center rounded-[3px] border-[2px] p-[12px] mt-1 relative h-[20vh] text-[#4C4C66]">
                        <span className="font-DMSans font-[500] text-[15px]">
                            {audienceCsv
                                ? audienceCsv.name
                                : "Drag and Drop or Click to Upload"}
                        </span>
                        <img
                            src={uploadD}
                            alt="upload"
                            className="w-[15px] h-[15px]"
                        />
                        <input
                            type="file"
                            accept=".csv"
                            className="absolute top-0 left-0 w-[100%] h-[100%]"
                            style={{
                                opacity: 0,
                            }}
                            onChange={(e: any) => {
                                setAudienceCsv(e.target.files[0]);
                            }}
                        />
                    </div>
                    <div className="bg-red-500 p-1 px-2 rounded text-white text-[12px] mt-1">
                        if No Target Audience is Defined, it is Applicable to
                        all the Users
                    </div>
                    <div className="flex items-center justify-end gap-3 mt-2">
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={props.closeAddMilestone}
                        >
                            Cancel Changes
                        </button>
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={() => {
                                setActiveAccordion(-1);
                            }}
                        >
                            Save
                        </button>
                    </div>
                </Accordion>
                <div className="flex items-center justify-end gap-3">
                    <button
                        className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                        onClick={props.closeAddMilestone}
                    >
                        Cancel
                    </button>
                    <button
                        className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                        onClick={handleSave}
                    >
                        Save Contest
                    </button>
                </div>
            </div>
            <Modal
                style={customStyles}
                isOpen={openSaveContestModal}
                ariaHideApp={false}
            >
                <div className="p-4 bg-[#F9F9F9]">
                    <h2 className="text-xl font-semibold mb-4">Confirm Save</h2>
                    <p>Are you sure you want to save?</p>
                    <div className="mt-6 space-x-4 w-full flex justify-end">
                        <button
                            onClick={handleCancelSave}
                            className="px-4 py-2 rounded text-black border border-black"
                        >
                            No
                        </button>
                        <button
                            onClick={handleConfirmSave}
                            disabled={!checkCompleteValidity()}
                            className={`text-white px-4 py-2 rounded bg-[#FF7B5F] ${
                                !checkCompleteValidity() &&
                                "opacity-50 cursor-not-allowed"
                            }}`}
                        >
                            {isAddingContest ? <Loader heavy={true} /> : "Yes"}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AddMilestone;

import { useEffect, useState } from "react";
import offer from "../../assets/png/image 63.png";
import Select, { StylesConfig } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import edit from "../../assets/png/image 70 (1).png";
import green from "../../assets/png/image 64.png";
import red from "../../assets/png/image 64 (1).png";
import bin from "../../assets/png/image 69 (1).png";
import { v4 } from "uuid";
import SelectedName from "../../components/club-console/selectedName";
import Navbar from "../../components/common/navbar";

const ClubConsole = () => {
  const style: StylesConfig = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#1D1D1D",
      width: "250px",
      border: "none",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#1D1D1D",
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected
        ? "#005DF2"
        : isFocused
        ? "#005DF2"
        : "#1D1D1D",
    }),
    input: (provided) => ({
      ...provided,
      color: "#FFFFFF",
    }),
  };
  const categories = [
    { value: "Food & Bevarages", label: "Food & Bevarages" },
    { value: "Lifestyle", label: "Lifestyle" },
    { value: "Sports", label: "Sports" },
    { value: "any", label: "any" },
  ];
  const brands = [
    { value: "One", label: "One" },
    { value: "Twp", label: "Two" },
    { value: "Three", label: "Three" },
    { value: "any", label: "any" },
  ];
  const fields = [
    { name: "All Rules", type: 100 },
    { name: "Active Rules", type: 1 },
    { name: "Inactive Rulls", type: 2 },
    { name: "Drafts", type: 0 },
  ];
  const [selectedCategories, setSelectedCategories] = useState<any>(null);
  const [selectedBrands, setSelectedBrands] = useState<any>(null);

  const [categoriesPermission, setCategoriesPermission] = useState<any>(null);

  const [latestRule, setLatestRule] = useState<boolean>(false);

  const [brandsPermission, setBrandsPermission] = useState<any>(null);

  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);

  const [selectedRule, setSelectedRule] = useState<string>("");
  const [isUpdatingRule, setIsUpdatingRule] = useState<boolean>(false);
  const [field, setField] = useState<number>(100);
  const [rules, setRules] = useState<Array<any>>([]);

  const typeRules = (type: number) => {
    type === 100
      ? setRules(JSON.parse(localStorage.getItem("rules") || "[]"))
      : setRules(
          JSON.parse(
            JSON.stringify(
              JSON.parse(localStorage.getItem("rules") || "[]").filter(
                (each: any) => each.type === type
              )
            )
          )
        );
  };
  useEffect(() => {
    setRules(JSON.parse(localStorage.getItem("rules") || "[]"));
  }, []);

  const handleSave = (status: any, id?: string) => {
    if (id) {
      for (let i = 0; i < rules.length; i++) {
        if (rules[i].id === id) {
          rules[i] = {
            ...rules[i],
            categories: {
              type: categoriesPermission === true ? "include" : "exclude",
              elements: selectedCategories,
            },
            brands: {
              type: brandsPermission === true ? "include" : "exclude",
              elements: selectedBrands,
            },
            type: status,
          };
          break;
        }
      }
      setRules(JSON.parse(JSON.stringify(rules)));
      localStorage.setItem("rules", JSON.stringify(rules));
      setSelectedRule("");
      setSelectedCategories(null);
      setSelectedBrands(null);
      setIsOptionsOpen(false);
      setIsUpdatingRule(false);
      return;
    }
    if (localStorage.getItem("rules") === null) {
      localStorage.setItem(
        "rules",
        JSON.stringify([
          {
            categories: {
              type: categoriesPermission === true ? "include" : "exclude",
              elements: selectedCategories,
            },
            brands: {
              type: brandsPermission === true ? "include" : "exclude",
              elements: selectedBrands,
            },
            type: status,
            id: v4(),
          },
        ])
      );
    } else {
      const existing = JSON.parse(localStorage.getItem("rules") || "[]");
      existing.push({
        categories: {
          type: categoriesPermission === true ? "include" : "exclude",
          elements: selectedCategories,
        },
        brands: {
          type: brandsPermission === true ? "include" : "exclude",
          elements: selectedBrands,
        },
        type: status,
        id: v4(),
      });
      localStorage.setItem("rules", JSON.stringify(existing));
    }

    setRules(JSON.parse(localStorage.getItem("rules") || "[]"));
    setSelectedCategories(null);
    setSelectedBrands(null);
    setCategoriesPermission(null);
    setBrandsPermission(null);
    setLatestRule(false);
  };
  const handleStatus = (id: string, status: number) => {
    for (let i = 0; i < rules.length; i++) {
      if (rules[i].id === id) {
        rules[i].type = status;
        break;
      }
    }
    setRules(JSON.parse(JSON.stringify(rules)));
    localStorage.setItem("rules", JSON.stringify(rules));
  };

  const handleDelete = (id: string) => {
    const afterDelete = rules.filter((each: any) => each.id !== id);
    localStorage.setItem("rules", JSON.stringify(afterDelete));
    setRules(afterDelete);
  };
  return (
    <div className="w-full h-[100vh] overflow-y-auto pe-10">
      <Navbar />
      <div className="bg-[#0E0E0E] rounded-[24px] p-5 text-[18px] w-full h-fit px-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-5">
            <img src={offer} alt="offer" />
            <div>
              There's a custom rewards plan for you which will increase your
              customer engagement.
              <br />
              Dont worry we have excluded your competitor brands.
            </div>
          </div>
          <button className="border-0 outline-none bg-[#005DF2] rounded px-5 py-2 text-white">
            Apply
          </button>
        </div>
      </div>
      <div className="font-bold text-[20px] mt-5">Rewards Display</div>
      <div className="flex items-center gap-10 mt-5 font-bold">
        {fields.map((each: any) => {
          return (
            <div
              className={
                each.type === field
                  ? "bg-[#005dF2] rounded-[100px] px-5 py-1"
                  : "rounded-[100px] px-5 py-1 cursor-pointer"
              }
              onClick={() => {
                typeRules(each.type);
                setField(each.type);
              }}
            >
              {each.name}
            </div>
          );
        })}
      </div>
      <div className="bg-[#0E0E0E] rounded-[24px] p-5 text-[18px] w-full h-fit px-10 mt-5">
        <div className="grid grid-cols-2">
          <div className="col-span-1">
            <div className="font-bold text-[20px]">Brand Categories</div>
          </div>
          <div className="col-span-1">
            <div className="text-[20px] font-bold">Brands</div>
          </div>
        </div>
        <div
          className="bg-[#005DF2] px-5 py-2 rounded text-[14px] w-fit mt-5 cursor-pointer"
          onClick={() => {
            setSelectedCategories(null);
            setSelectedBrands(null);
            setIsOptionsOpen(false);
            setSelectedRule("");
            setIsUpdatingRule(false);
            setLatestRule(true);
            setCategoriesPermission(null);
            setBrandsPermission(null);
          }}
        >
          Add New Rule
        </div>
        {latestRule && (
          <div className="mt-5 text-[12px] relative bg-[#33312f]/20 rounded pb-[50px] p-2">
            <div className="flex items-center gap-2 absolute bottom-[5px] left-1/2 transform -translate-x-1/2">
              <button
                className="bg-[#B3AEAA] px-5 py-2 rounded"
                onClick={() => handleSave(0)}
              >
                Save Draft
              </button>
              <button
                className="bg-[#005DF2] px-5 py-2 rounded"
                onClick={() => handleSave(1)}
              >
                Publish
              </button>
            </div>
            <div className="grid grid-cols-2">
              <div className="col-span-1">
                <div className="flex items-center gap-2">
                  <div className="rounded h-[40px] bg-[#B3AEAA] w-[5px]"></div>
                  <div
                    className={
                      "p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer opacity-[0.5]" +
                      (categoriesPermission === false ? " shadow-white" : "")
                    }
                    onClick={() => setCategoriesPermission(false)}
                  >
                    exclude
                  </div>
                  <div
                    className={
                      "p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer opacity-[0.5]" +
                      (categoriesPermission === true ? " shadow-white" : "")
                    }
                    onClick={() => setCategoriesPermission(true)}
                  >
                    include
                  </div>
                  <Select
                    options={categories}
                    isMulti={true}
                    styles={style}
                    placeholder="category"
                    value={selectedCategories}
                    onChange={(e: any) => setSelectedCategories(e)}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="flex items-center gap-2">
                  <div
                    className={
                      "p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer opacity-[0.5]" +
                      (brandsPermission === false ? " shadow-white" : "")
                    }
                    onClick={() => setBrandsPermission(false)}
                  >
                    exclude
                  </div>
                  <div
                    className={
                      "p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer opacity-[0.5]" +
                      (brandsPermission === true ? " shadow-white" : "")
                    }
                    onClick={() => setBrandsPermission(true)}
                  >
                    include
                  </div>
                  <Select
                    options={brands}
                    isMulti={true}
                    styles={style}
                    placeholder="brands"
                    value={selectedBrands}
                    onChange={(e: any) => setSelectedBrands(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="text-[12px]">
          {rules.map((each: any, i: number) => {
            return (
              <div
                className={
                  "grid grid-cols-2 mt-5 p-2 relative" +
                  (each.id === selectedRule && isUpdatingRule
                    ? " bg-[#33312f]/20 rounded pb-[50px]"
                    : "")
                }
              >
                {isUpdatingRule && selectedRule === each.id && (
                  <div className="flex items-center gap-2 absolute bottom-[5px] left-1/2 transform -translate-x-1/2">
                    <button
                      className="bg-[#B3AEAA] px-5 py-2 rounded"
                      onClick={() => handleSave(0, each.id)}
                    >
                      Save Draft
                    </button>
                    <button
                      className="bg-[#005DF2] px-5 py-2 rounded"
                      onClick={() => handleSave(1, each.id)}
                    >
                      Publish
                    </button>
                  </div>
                )}
                <div className="col-span-1">
                  <div className="flex items-center gap-2">
                    {each.type === 1 ? (
                      <div className="rounded h-[40px] bg-[#12A113] w-[5px]"></div>
                    ) : each.type === 2 ? (
                      <div className="rounded h-[40px] bg-[#DB0008] w-[5px]"></div>
                    ) : (
                      <div className="rounded h-[40px] bg-[#B3AEAA] w-[5px]"></div>
                    )}
                    {isUpdatingRule && selectedRule === each.id && (
                      <>
                        <div
                          className={
                            "p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer opacity-[0.5]" +
                            (categoriesPermission === false
                              ? " shadow-white"
                              : "")
                          }
                          onClick={() => setCategoriesPermission(false)}
                        >
                          exclude
                        </div>
                        <div
                          className={
                            "p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer opacity-[0.5]" +
                            (categoriesPermission === true
                              ? " shadow-white"
                              : "")
                          }
                          onClick={() => setCategoriesPermission(true)}
                        >
                          include
                        </div>
                      </>
                    )}
                    {each.categories?.type === "exclude" &&
                      isUpdatingRule === false && (
                        <div className="p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer">
                          exclude
                        </div>
                      )}
                    {each.categories?.type === "include" &&
                      isUpdatingRule === false && (
                        <div className="p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer">
                          include
                        </div>
                      )}
                    {isUpdatingRule && selectedRule === each.id ? (
                      <Select
                        options={categories}
                        isMulti={true}
                        styles={style}
                        placeholder="category"
                        value={selectedCategories}
                        onChange={(e: any) => setSelectedCategories(e)}
                        menuPlacement={
                          i === rules.length - 1 ? "top" : "bottom"
                        }
                      />
                    ) : (
                      each.categories?.elements?.map((each: any) => {
                        return <SelectedName name={each.label} />;
                      })
                    )}
                    {/* <Select
                      options={categories}
                      isMulti={true}
                      styles={style}
                      placeholder="category"
                      value={selectedCategories}
                      onChange={(e: any) => setSelectedCategories(e)}
                    /> */}
                  </div>
                </div>
                <div className="col-span-1 flex items-center justify-between">
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-2 w-full">
                      {isUpdatingRule && selectedRule === each.id && (
                        <>
                          <div className="flex items-center gap-2">
                            <div
                              className={
                                "p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer opacity-[0.5]" +
                                (brandsPermission === false
                                  ? " shadow-white"
                                  : "")
                              }
                              onClick={() => setBrandsPermission(false)}
                            >
                              exclude
                            </div>
                            <div
                              className={
                                "p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer opacity-[0.5]" +
                                (brandsPermission === true
                                  ? " shadow-white"
                                  : "")
                              }
                              onClick={() => setBrandsPermission(true)}
                            >
                              include
                            </div>
                          </div>
                        </>
                      )}
                      {each.brands?.type === "exclude" &&
                        isUpdatingRule === false && (
                          <div className="p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer">
                            exclude
                          </div>
                        )}
                      {each.brands?.type === "include" &&
                        isUpdatingRule === false && (
                          <div className="p-[8px] rounded custom-bg text-[#005DF2] cursor-pointer">
                            include
                          </div>
                        )}
                      {isUpdatingRule && selectedRule === each.id ? (
                        <>
                          <div className=" w-full">
                            <Select
                              options={brands}
                              isMulti={true}
                              styles={style}
                              placeholder="brands"
                              value={selectedBrands}
                              onChange={(e: any) => setSelectedBrands(e)}
                              menuPlacement={
                                i === rules.length - 1 ? "top" : "bottom"
                              }
                            />
                          </div>
                        </>
                      ) : (
                        each.brands?.elements?.map((each: any) => {
                          return <SelectedName name={each.label} />;
                        })
                      )}
                    </div>
                    <div className="relative">
                      <div
                        className="text-[16px] cursor-pointer"
                        onClick={() => {
                          setSelectedRule(each.id);
                          setIsOptionsOpen(!isOptionsOpen);
                          setIsUpdatingRule(false);
                          setSelectedCategories(null);
                          setSelectedBrands(null);
                          setLatestRule(false);
                          setCategoriesPermission(
                            each.categories.type === "include" ? true : false
                          );
                          setBrandsPermission(
                            each.brands.type === "include" ? true : false
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </div>
                      {isOptionsOpen && each.id === selectedRule && (
                        <div className="absolute left-[-120px] w-[120px]">
                          <div
                            className="flex items-center gap-2 bg-[#FFFFFF] p-2 rounded text-[#000000] mt-1 cursor-pointer"
                            onClick={() => {
                              setIsUpdatingRule(true);
                              setSelectedCategories(each.categories.elements);
                              setIsOptionsOpen(false);
                              setSelectedBrands(each.brands.elements);
                            }}
                          >
                            <img src={edit} alt="edit" />
                            Edit
                          </div>
                          {[0, 2].includes(each.type) && (
                            <div
                              className="flex items-center gap-2 bg-[#FFFFFF] p-2 rounded text-[#000000] mt-1 cursor-pointer"
                              onClick={() => handleStatus(each.id, 1)}
                            >
                              <img src={green} alt="green" />
                              Mark Active
                            </div>
                          )}
                          {[0, 1].includes(each.type) && (
                            <div
                              className="flex items-center gap-2 bg-[#FFFFFF] p-2 rounded text-[#000000] mt-1 cursor-pointer"
                              onClick={() => handleStatus(each.id, 2)}
                            >
                              <img src={red} alt="red" />
                              Mark Inactive
                            </div>
                          )}
                          <div
                            className="flex items-center gap-2 bg-[#FFFFFF] p-2 rounded text-[#000000] mt-1 cursor-pointer"
                            onClick={() => handleDelete(each.id)}
                          >
                            <img src={bin} alt="bin" />
                            Delete
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ClubConsole;

// {
//   brands: {
//     type: "exclude/include",
//     items: [],
//   },
//   categories: {
//     type: "exclude/include",
//     items: []
//   },
//   id: any,
//   type: number
// }

import UserOverallAnalytics from "./userOverallAnalytics";

const UserAnalytics = () => {
  return (
    <div className="bg-[#F9F9F9] px-10 h-[calc(100vh-133px)] overflow-y-auto">
      <UserOverallAnalytics />
      {/* <div className="h-[3px] bg-[#CEB0Fa] rounded my-10"></div>
      <UserFilterAnalytics graph={tGraph2} /> */}
    </div>
  );
};

export default UserAnalytics;

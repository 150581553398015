import SnipeSocial from "../../containers/snipeSocial/snipeSocial";
import Sidebar from "../../containers/sidebar/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import useHomePathFinder from "../../hooks/useHomePathFinder";
import { useEffect } from "react";

const SnipeSocialIndex = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [hasAccess, availablePaths] = useHomePathFinder(location.pathname);
    useEffect(() => {
        if (hasAccess) {
            // console.log("granted");
        } else {
            navigate(
                availablePaths?.length > 0 ? availablePaths[0] : "/settings"
            );
        }
    }, []);
    return (
        <div className="text-white  min-h-screen">
            <div className="flex">
                <Sidebar />
                <SnipeSocial />
            </div>
        </div>
    );
};

export default SnipeSocialIndex;

import { useSelector } from "react-redux";
import Value from "../../users/management/value";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { customStyles } from "../../../containers/gamification/campaign";
import back from "../../../assets/svg/back.svg";

interface IStreakCardProps {
    streak: any;
    setSelectedStreak: (val: string) => void;
    setAddOrUpdateStreak: (val: boolean) => void;
    handleDelete: (ref_id: any) => void;
}

const StreakCard = ({
    streak,
    setSelectedStreak,
    setAddOrUpdateStreak,
    handleDelete,
}: IStreakCardProps) => {
    const { getEventsResp } = useSelector((state: any) => state.events);
    const { getTokensResp } = useSelector((state: any) => state.tokens);
    const { getUserVariableResp } = useSelector((state: any) => state.user);
    const { getMultipliersResp } = useSelector(
        (state: any) => state.multipliers
    );
    const [events, setEvents] = useState<any[]>([]);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    useEffect(() => {
        let data: any[] = [];
        if (streak?.activities) {
            data = [
                ...streak.activities?.daily_activities?.activities,
                ...streak.activities?.periodic_activities?.activities,
                ...streak.activities?.user_state_activities?.activities,
                ...streak.activities?.campaign_activities?.activities,
            ];
        }
        setEvents(data);
    }, [streak]);
    return (
        <div>
            <div className="bg-[#FFF2F1] rounded-lg w-full p-5 mt-5">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                        <div className="text-xl font-bold">{streak.name}</div>
                        <div>|</div>
                        {/* <div className="flex items-center gap-1">
              <div className="text-[12px]">Created By:</div>
              <div className="text-[12px]">User name</div>
            </div>
            <div>|</div> */}
                        <div className="flex items-center gap-1">
                            <div className="text-[12px]">Priority: </div>
                            <div className="text-[12px]">{streak.priority}</div>
                        </div>
                    </div>
                    <div className="px-3 py-1 bg-[#BDBDBD] text-white font-bold rounded">
                        Saved
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-3 bg-[#FF7B5F] rounded-t-lg text-white font-bold px-3 py-2 mt-5">
                    {/* <div className="col-span-1">Notification method</div> */}
                    <div className="col-span-1">Which streak</div>
                    <div className="col-span-1">For which events</div>
                    <div className="col-span-1">For which users</div>
                    <div className="col-span-1">To reward them</div>
                </div>
                <div className="bg-[#FFE8E6] rounded-b-lg px-3 py-2 grid grid-cols-4 gap-3">
                    <div className="col-span-1 flex items-start">
                        {streak.name ? (
                            <Value
                                name={streak.name}
                                value={""}
                                operator={"|"}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="col-span-1">
                        {events?.map((each: any, i: number) => {
                            return (
                                <div
                                    className=" text-sm grid grid-cols-4"
                                    key={"streak-event-" + i}
                                >
                                    <div className="col-span-3">
                                        <div className="flex items-center gap-1 w-full overflow-x-auto">
                                            <Value
                                                key={
                                                    "streak-event-" + each.event
                                                }
                                                name={
                                                    getEventsResp.data?.value?.find(
                                                        (e: any) =>
                                                            e._id === each.event
                                                    )?.name
                                                }
                                                value={""}
                                                operator={"|"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="col-span-1">
                        <div
                            className="cursor-pointer"
                            // onClick={() => handleOpen("to-users")}
                        >
                            <div className="flex items-center flex-wrap">
                                {streak.user_filter?.strings
                                    ?.filter(
                                        (each: any) => each.values?.length > 0
                                    )
                                    ?.map((each: any) => {
                                        return (
                                            <Value
                                                name={
                                                    getUserVariableResp.data?.value?.find(
                                                        (ele: any) =>
                                                            ele._id ===
                                                            each.variable_id
                                                    )?.name
                                                }
                                                value={
                                                    ["in", "nin"].includes(
                                                        each.operation
                                                    )
                                                        ? `[${each.values?.join(
                                                              ", "
                                                          )}]`
                                                        : each.values
                                                }
                                                operator={each.operation}
                                                key={
                                                    "user-filter-strings-" +
                                                    each.variable_id
                                                }
                                            />
                                        );
                                    })}
                            </div>
                            <div className="flex items-center flex-wrap">
                                {streak.user_filter?.numbers
                                    ?.filter(
                                        (each: any) => each.values?.length > 0
                                    )
                                    ?.map((each: any) => {
                                        return (
                                            <Value
                                                name={
                                                    getUserVariableResp.data?.value?.find(
                                                        (ele: any) =>
                                                            ele._id ===
                                                            each.variable_id
                                                    )?.name
                                                }
                                                value={
                                                    ["bet"].includes(
                                                        each.operation
                                                    )
                                                        ? `[${each.values?.join(
                                                              ","
                                                          )}]`
                                                        : each.values
                                                }
                                                operator={each.operation}
                                                key={
                                                    "user-filter-numbers-" +
                                                    each.variable_id
                                                }
                                            />
                                        );
                                    })}
                            </div>
                            <div className="flex items-center flex-wrap">
                                {streak.user_filter?.booleans?.map(
                                    (each: any) => {
                                        return (
                                            <Value
                                                name={
                                                    getUserVariableResp.data?.value?.find(
                                                        (ele: any) =>
                                                            ele._id ===
                                                            each.variable_id
                                                    )?.name
                                                }
                                                value={
                                                    each.values === true
                                                        ? "True"
                                                        : "False"
                                                }
                                                key={
                                                    "user-filter-booleans-" +
                                                    each.variable_id
                                                }
                                            />
                                        );
                                    }
                                )}
                            </div>
                            <div className="flex items-center flex-wrap">
                                {streak.user_filter?.dates?.map((each: any) => {
                                    return (
                                        <Value
                                            name={
                                                getUserVariableResp.data?.value?.find(
                                                    (ele: any) =>
                                                        ele._id ===
                                                        each.variable_id
                                                )?.name
                                            }
                                            value={
                                                new Date(each.value[0])
                                                    .toISOString()
                                                    .split("T")[0]
                                            }
                                            operator={each.operation}
                                            multi={each.operation === "bet"}
                                            secondVal={
                                                new Date(
                                                    each.value[1] ||
                                                        new Date().getTime()
                                                )
                                                    .toISOString()
                                                    .split("T")[0]
                                            }
                                            key={
                                                "user-filter-dates-" +
                                                each.variable_id
                                            }
                                        />
                                    );
                                })}
                            </div>
                            {/* <Add text={"ADD RULES HERE"} /> */}
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="grid grid-cols-5">
                            {streak.rewards?.tokens && (
                                <>
                                    <div className="col-span-2 mt-1">
                                        Tokens:
                                    </div>
                                    <div className="col-span-3 overflow-x-auto">
                                        <div className="flex items-center flex-wrap">
                                            {streak.rewards?.tokens?.map(
                                                (each: any) => {
                                                    return (
                                                        <Value
                                                            name={
                                                                getTokensResp.data?.value?.find(
                                                                    (
                                                                        ele: any
                                                                    ) =>
                                                                        ele._id ===
                                                                        each.token_id
                                                                )?.name
                                                            }
                                                            value={each.value}
                                                            operator={
                                                                each.operation
                                                            }
                                                            key={
                                                                "user-filter-tokens-" +
                                                                each.token_id
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {streak.rewards?.multiplier && (
                                <>
                                    <div className="col-span-2 mt-1">
                                        Multiplier:
                                    </div>
                                    <div className="col-span-3 justify-start items-start">
                                        <Value
                                            name={
                                                getMultipliersResp.data?.value?.find(
                                                    (ele: any) =>
                                                        ele._id ===
                                                        streak.rewards
                                                            ?.multiplier
                                                )?.name
                                            }
                                            value={
                                                getMultipliersResp.data?.value?.find(
                                                    (ele: any) =>
                                                        ele._id ===
                                                        streak.rewards
                                                            ?.multiplier
                                                )?.value
                                            }
                                            operator={""}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-3 mt-5">
                    <button
                        className="outline-none bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-1"
                        onClick={() => {
                            setSelectedStreak(streak._id);
                            setAddOrUpdateStreak(true);
                        }}
                    >
                        Edit
                    </button>
                    <button
                        className="outline-none bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-1"
                        onClick={() => {
                            setDeleteModalVisible(true);
                        }}
                    >
                        Delete
                    </button>
                </div>
            </div>
            <Modal
                style={customStyles}
                isOpen={deleteModalVisible}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setDeleteModalVisible(false)}
                shouldCloseOnEsc={true}
            >
                <div className="p-5 max-h-[600px] overflow-y-auto">
                    Do you really want to delete the streak?
                    <div className="w-full mt-5 flex items-center justify-end gap-5">
                        <div className="flex flex-row">
                            <div className="text-[#5F5E76] text-lg rounded cursor-pointer py-3 px-1">
                                {" "}
                                <img
                                    src={back}
                                    alt="back"
                                    className="w-[20px] h-[20px]"
                                />
                            </div>

                            <div
                                className="text-[#5F5E76] text-lg rounded cursor-pointer py-2"
                                onClick={() => {
                                    setDeleteModalVisible(false);
                                }}
                            >
                                Cancel
                            </div>
                        </div>
                        <button
                            className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-5 py-2"
                            onClick={() => {
                                handleDelete(streak._id);
                                setDeleteModalVisible(false);
                            }}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default StreakCard;

import Restricted from "../../containers/restricted/restricted";
import Sidebar from "../../containers/sidebar/sidebar";
import { useUserAuth } from "../../hooks/useAuthContextProvider";

const RestrictedIndex = () => {
  const { user } = useUserAuth();
  return (
    <div className="text-white h-screen">
      <div className="flex">
        {user && <Sidebar />}
        <Restricted />
      </div>
    </div>
  );
};

export default RestrictedIndex;

import Gamification from "../../containers/gamification/gamification";
import Sidebar from "../../containers/sidebar/sidebar";

const SupportIndex = () => {
  return (
    <div className="text-white  min-h-screen">
      <div className="flex gap-10">
        <Sidebar />
        <Gamification />
      </div>
    </div>
  );
};

export default SupportIndex;

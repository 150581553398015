import { useNavigate } from "react-router-dom";

const Accordion = ({
  title,
  isOpen,
  onClick,
  eachGroupType,
  children,
  empty,
}: any) => {
  return (
    <>
      {empty === undefined && (
        <div
          className={
            "rounded-lg mb-4 p-4 cursor-pointer bg-[#FFF2F1] shadow-lg" +
            (isOpen ? " is-opened" : " custom-accordion")
          }
          onClick={onClick}
        >
          <div className="flex justify-between items-center  w-full">
            {title}
          </div>
          <div className="flex justify-between items-center">
            {/* <div className="fs-5 text-text-grey">
          Asked in:{" "}
          <span
            className={
              "fw-bold" + (isOpen ? " text-text-dark-grey" : " text-white")
            }
          >
            2020, 2021
          </span>
        </div> */}
          </div>
          <div
            className={`transition-height duration-300 ${
              isOpen ? " h-full overflow-y-auto" : "h-0 overflow-hidden"
            }`}
          >
            <div className="p-4">{children}</div>
          </div>
        </div>
      )}
      {empty && (
        <div className="custom-accordion rounded-lg mb-4 p-4 cursor-pointer bg-[#FFF2F1] shadow-lg">
          <div className="flex justify-between items-center w-full">
            {title}
          </div>
        </div>
      )}
    </>
  );
};

export default Accordion;

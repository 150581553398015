import { useEffect, useState } from "react";
import Accordion from "./accordian";
import Modal from "react-modal";
import { customStyles } from "../gamification/campaign";
import Text from "../../components/gamification/modals/nudge/text";
import { useDispatch, useSelector } from "react-redux";
import {
  addMultiplier,
  deleteMultiplier,
  getMultipliers,
  updateMultiplier,
} from "../../redux/token/multipliersActions";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import Loader from "../../components/common/loader";
import { toggleConfirmationPopup } from "../../redux/api/apiActions";
import Value from "../../components/users/management/value";
import { getRequest } from "../../utils/apiRequests";
import store from "../../redux/store";
import { endPoints } from "../../utils/apiEndPoints";
import Select from "../../components/gamification/modals/nudge/select";

const Multipliers = () => {
  const [activeAccordion, setActiveAccordion] = useState<number>(0);
  const [newToken, setNewToken] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [times, setTimes] = useState<string>("");
  const [expiryHours, setExpiryHours] = useState<string>("");
  const [expiryType, setExpiryType] = useState<string>("");
  const [transactions, setTransactions] = useState<Array<any>>([]);
  const [activeMultiplier, setActiveMultiplier] = useState<string>("");
  const [tokens, setTokens] = useState<Array<any>>([]);
  const { user } = useUserAuth();
  const [tComplete, setTComplete] = useState<boolean>(false);

  const expiryMap: { [key: string]: number } = {
    "After Claiming": 1,
  };

  const dispatch = useDispatch();
  const fetchTokens = async () => {
    try {
      const data = await getRequest(
        `${(store.getState().developerTools as any).url}/${
          endPoints.allTokens
        }`,
        { Authorization: `Bearer ${user.accessToken}` }
      );
      setTokens(data.data.value);
      setTComplete(true);
    } catch (e: any) {
      setTComplete(false);
    }
  };
  useEffect(() => {
    fetchTokens();
  }, []);
  const {
    isGettingMultipliers,
    getMultipliersResp,
    getMultipliersRespStatus,
    getMultipliersError,
    getMultipliersErrorMsg,
    isAddingMultiplier,
    addMultiplierRespStatus,
    addMultiplierError,
    isUpdatingMultiplier,
    updateMultiplierRespStatus,
    updateMultiplierError,
    isDeletingMultiplier,
    deleteMultiplierRespStatus,
    deleteMultiplierError,
  } = useSelector((state: any) => state.multipliers);
  const { getTokensResp } = useSelector((state: any) => state.tokens);

  // const { inputs } = useSelector((state: any) => state.events);
  const reset = () => {
    setName("");
    setTimes("");
    setTransactions([]);
    setActiveMultiplier("");
    setNewToken(false);
    setExpiryType("");
    setExpiryHours("");
  };
  const update = (e: any) => {
    e.preventDefault();
    // e.preventDefault();
    if (activeMultiplier) {
      dispatch(
        updateMultiplier({
          name: name,
          value: parseInt(times),
          image: "a",
          multiplier_id: activeMultiplier,
          tokens: transactions,
          token: user.accessToken,
          expiry_hours: parseInt(expiryHours),
          expiry_type: expiryMap[expiryType],
          deleted: false,
          // ratio: ratio,
          // client_id: "64d0bc127eff0438835d15d7",
        })
      );
    } else {
      dispatch(
        addMultiplier({
          name: name,
          value: parseInt(times),
          image: "a",
          tokens: transactions,
          token: user.accessToken,
          expiry_hours: parseInt(expiryHours),
          expiry_type: expiryMap[expiryType],
          deleted: false,
          // client_id: "64d0bc127eff0438835d15d7",
        })
      );
    }
  };

  const handleAccordionClick = (index: any) => {
    if (activeAccordion === index) {
      setActiveAccordion(-1);
    } else {
      setActiveAccordion(index);
    }
  };

  const title = (i: any) => {
    return (
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-3">
          <div className="">
            <div className="flex items-center gap-3">
              <div className="font-bold">{i.name}</div>

              {/* <div className="flex items-center gap-1">
                <div className="text-[12px]">Created by</div>
                <img
                  src={p}
                  alt="p"
                  className="w-[30px] h-[30px] rounded-full object-cover"
                />
                <div className="text-[12px]">User name</div>
              </div> */}
            </div>
            <div className="flex items-center gap-3 mt-1">
              {/* <div>
                Created on -<b> DD/MM/YYYY</b>
              </div> */}
              <div>
                Multipler: <b>{i.value}x</b>
              </div>
              |
              <div>
                Expiry time: <b>{i.expiry_hours} hrs</b>
              </div>
              |
              <div>
                Created on:{" "}
                <b>{new Date(i.created_at).toISOString()?.split("T")?.[0]}</b>
              </div>
              | Active on:
              <span className="flex items-center gap-3 max-w-[200px] overflow-x-auto">
                {i.tokens.map((each: any) => {
                  return (
                    <Value
                      name={
                        getTokensResp?.data?.value?.find(
                          (ele: any) => ele._id === each
                        )?.name
                      }
                      value={""}
                      operator={"|"}
                    />
                  );
                })}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div
            className="text-white cursor-pointer font-bold bg-[#FF7B5F] rounded-full px-5 py-2"
            onClick={() => {
              setName(i.name);
              setTimes(i.value);
              setExpiryHours(i.expiry_hours);
              setExpiryType("After Claiming");
              setActiveMultiplier(i._id);
              setTransactions(i.tokens);
              setNewToken(true);
            }}
          >
            Modify
          </div>
          <div
            className="text-white cursor-pointer font-bold bg-[#FF7B5F] rounded-full px-5 py-2"
            onClick={() => {
              dispatch(
                toggleConfirmationPopup({
                  isConfirmation: true,
                  confirmationFunction: () =>
                    dispatch(
                      deleteMultiplier({
                        token: user.accessToken,
                        multiplier_id: i._id,
                      })
                    ),
                  confirmationText:
                    "Do you really want to delete the multiplier?",
                })
              );
            }}
          >
            Delete
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (
      addMultiplierRespStatus === 200 ||
      updateMultiplierRespStatus === 200 ||
      deleteMultiplierRespStatus === 200
    ) {
      reset();
      dispatch(getMultipliers({ token: user.accessToken }));
      dispatch(toggleConfirmationPopup({}));
    } else if (
      addMultiplierError ||
      updateMultiplierError ||
      deleteMultiplierError
    ) {
      alert("Something went wrong");
    }
  }, [isAddingMultiplier, isUpdatingMultiplier, isDeletingMultiplier]);

  return (
    <div className="bg-[#F9F9F9] px-10 h-[calc(100vh-133px)] overflow-y-auto pt-5">
      <div className="flex items-center justify-between mb-5">
        <div className="text-xl font-bold">All Multipliers</div>
        <button
          className="px-3 py-2 text-white font-bold bg-[#FF7B5F] cursor-pointer rounded"
          onClick={() => {
            // setAdd(false);
            setNewToken(true);
          }}
        >
          Add Multiplier
        </button>
      </div>
      {getMultipliersRespStatus === 200 &&
        getMultipliersResp.data.value.length === 0 && (
          <div className="py-5 text-center">Add a multipler!</div>
        )}
      {isGettingMultipliers && (
        <div className="flex items-center justify-center">
          <Loader heavy={true} />
        </div>
      )}
      {getMultipliersError && getMultipliersErrorMsg}
      {getMultipliersRespStatus === 200 &&
        getMultipliersResp.data.value.map((each: any, i: number) => {
          return (
            <Accordion
              key={i}
              title={title(each)}
              onClick={() => handleAccordionClick(i)}
              isOpen={activeAccordion === i}
              eachGroupType={[]}
              empty={true}
            ></Accordion>
          );
        })}
      <Modal
        style={customStyles}
        isOpen={newToken}
        onRequestClose={() => reset()}
        shouldCloseOnOverlayClick={true}
      >
        <form onSubmit={update}>
          <div className="p-5">
            <div className="flex justify-between w-[750px]">
              <div>
                <div className="text-2xl text-[#4C4C66] font-bold">
                  Add Multiplier
                </div>
                <div className="text-[#3189B4] mt-3">Multiplier details..</div>
              </div>
              <div
                className="text-xl text-[#4C4C66] cursor-pointer"
                onClick={() => reset()}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                    fill="#4C4C66"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-5 flex gap-5">
              <div className="w-full">
                <div className="text-[14px] text-[#4C4C66]">
                  Name of multiplier
                </div>
                <Text
                  text={name}
                  setText={setName}
                  place={"- eg: coin of fortune -"}
                  full={true}
                  required={true}
                />
                <div className="text-[14px] text-[#4C4C66] mt-3">
                  Enter value of multiplier
                </div>
                <Text
                  text={times}
                  setText={setTimes}
                  place={"- eg: for coins from future -"}
                  type={"number"}
                  full={true}
                />
                <div className="text-[14px] text-[#4C4C66] mt-3">
                  Expiry time in hours
                </div>
                <div className="flex items-center gap-3 mt-3 w-full bg-[#FFE8E6] rounded p-3">
                  <Text
                    text={expiryHours}
                    setText={setExpiryHours}
                    place={"Enter a number"}
                    full={false}
                    required={true}
                    type={"number"}
                  />
                  <Select
                    selected={expiryType}
                    setSelected={setExpiryType}
                    values={["After Claiming"]}
                    // default={"After Claiming"}
                    required={true}
                  />
                </div>
                <div className="text-[14px] text-[#4C4C66] mt-3">
                  Select tokens for the multiplier
                </div>
                {
                  <div className="mt-3 bg-[#FFE8E6] rounded w-full max-h-[150px] overflow-y-auto p-3 flex flex-col gap-3">
                    {tComplete === false && <Loader />}
                    {tokens.map((each: any) => {
                      return (
                        <div
                          className={
                            "rounded border-2 border-[#FFAFA8]  cursor-pointer p-3" +
                            (transactions.includes(each._id)
                              ? " bg-white"
                              : " bg-[#FFF2F2]")
                          }
                        >
                          <div
                            className={"flex items-center gap-3"}
                            onClick={() => {
                              if (transactions.includes(each._id)) {
                                setTransactions(
                                  transactions.filter(
                                    (element: any) => element !== each._id
                                  )
                                );
                              } else {
                                setTransactions(
                                  Array.from(
                                    new Set([...transactions, each._id])
                                  )
                                );
                              }
                            }}
                          >
                            {transactions.includes(each._id) ? (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                  fill="#5F5E76"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                  fill="#FFAFA8"
                                />
                              </svg>
                            )}
                            <div className="flex items-center gap-3">
                              <img
                                src={each.image}
                                alt={each.image}
                                className="w-[30px]"
                              />
                              <div className="text-lg font-bold">
                                {each.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                }
              </div>
            </div>
            <div className="flex items-center justify-end px-5 my-5">
              <button
                className="px-3 py-2 text-white text-xl font-bold bg-[#FF7B5F] cursor-pointer rounded"
                type={"submit"}
                disabled={isAddingMultiplier || isUpdatingMultiplier}
              >
                {isAddingMultiplier || isUpdatingMultiplier ? (
                  <Loader />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};
export default Multipliers;

import {
  GET_ALL_ROLES_INIT,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  ADD_ROLE_INIT,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  UPDATE_ROLE_INIT,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE_INIT,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  UPDATE_CLIENT_INIT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
} from "../actionTypes";
import { ISettingsProps } from "../../models/settings/settings";

const initialState = {
  isGettingAllRoles: false,
  getAllRolesResp: [],
  getAllRolesRespStatus: 0,
  getAllRolesError: false,
  getAllRolesErrorMsg: "",
  isAddingRole: false,
  addRoleResp: {},
  addRoleRespStatus: 0,
  addRoleError: false,
  addRoleErrorMsg: "",
  isUpdatingRole: false,
  updateRoleResp: {},
  updateRoleRespStatus: 0,
  updateRoleError: false,
  updateRoleErrorMsg: "",
  isDeletingRole: false,
  deleteRoleResp: {},
  deleteRoleRespStatus: 0,
  deleteRoleError: false,
  deleteRoleErrorMsg: "",
  isUpdatingClient: false,
  updateClientResp: {},
  updateClientRespStatus: 0,
  updateClientError: false,
  updateClientErrorMsg: "",
};

const settings = (state: ISettingsProps = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_ROLES_INIT:
      return {
        ...state,
        isGettingAllRoles: true,
        getAllRolesError: false,
        getAllRolesRespStatus: 0,
      };

    case GET_ALL_ROLES_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingAllRoles: false,
        getAllRolesResp: data,
        getAllRolesRespStatus: 200,
      };
    }

    case GET_ALL_ROLES_FAILURE:
      const { error } = action;
      return {
        ...state,
        isGettingAllRoles: false,
        getAllRolesError: true,
        getAllRolesErrorMsg: error,
        getAllRolesRespStatus: 400,
      };

    case ADD_ROLE_INIT:
      return {
        ...state,
        isAddingRole: true,
        addRoleError: false,
        addRoleRespStatus: 0,
      };

    case ADD_ROLE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingRole: false,
        addRoleResp: data,
        addRoleRespStatus: 200,
      };
    }

    case ADD_ROLE_FAILURE:
      // const { error } = action;
      return {
        ...state,
        isAddingRole: false,
        addRoleError: true,
        addRoleErrorMsg: action.error.message,
        addRoleRespStatus: 400,
      };

    case UPDATE_ROLE_INIT:
      return {
        ...state,
        isUpdatingRole: true,
        updateRoleError: false,
        updateRoleRespStatus: 0,
      };

    case UPDATE_ROLE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingRole: false,
        updateRoleResp: data,
        updateRoleRespStatus: 200,
      };
    }

    case UPDATE_ROLE_FAILURE:
      // const { error } = action;
      return {
        ...state,
        isUpdatingRole: false,
        updateRoleError: true,
        updateRoleErrorMsg: action.error.message,
        updateRoleRespStatus: 400,
      };

    case DELETE_ROLE_INIT:
      return {
        ...state,
        isDeletingRole: true,
        deleteRoleError: false,
        deleteRoleRespStatus: 0,
      };

    case DELETE_ROLE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isDeletingRole: false,
        deleteRoleResp: data,
        deleteRoleRespStatus: 200,
      };
    }

    case DELETE_ROLE_FAILURE:
      // const { error } = action;
      return {
        ...state,
        isDeletingRole: false,
        deleteRoleError: true,
        deleteRoleErrorMsg: action.error.message,
        deleteRoleRespStatus: 400,
      };

    case UPDATE_CLIENT_INIT:
      return {
        ...state,
        isUpdatingClient: true,
        updateClientError: false,
        updateClientRespStatus: 0,
      };

    case UPDATE_CLIENT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingClient: false,
        updateClientResp: data,
        updateClientRespStatus: 200,
      };
    }

    case UPDATE_CLIENT_FAILURE:
      // const { error } = action;
      return {
        ...state,
        isUpdatingClient: false,
        updateClientError: true,
        updateClientErrorMsg: action.error.message,
        updateClientRespStatus: 400,
      };
    default:
      return state;
  }
};

export default settings;

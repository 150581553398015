import LineChart from "../../containers/tokens/chart";

type ParamProps = {
  info: string;
  value: string;
  data?: any;
  loss?: boolean;
};

const Param = ({ info, value, data, loss }: ParamProps) => {
  return (
    <div className="w-full rounded border-2 border-[#EFF0F6]">
      <div className="bg-white p-3">
        <div className="text-[14px]">{info}</div>
        <div className="text-3xl text-black font-bold mt-3">{value}</div>
        {data ? <LineChart data={data} plain={true} loss={loss} /> : null}
      </div>
    </div>
  );
};
export default Param;

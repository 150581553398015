import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_TOKENS_INIT,
  UPDATE_TOKEN_INIT,
  ADD_TOKEN_INIT,
  GET_ANALYTICS_INIT,
  GET_TOKEN_HISTORY_INIT,
} from "../actionTypes";
import {
  getTokensSuccess,
  getTokensFailure,
  updateTokenSuccess,
  updateTokenFailure,
  addTokenSuccess,
  addTokenFailure,
  getAnalyticsSuccess,
  getAnalyticsFailure,
  getTokenHistorySuccess,
  getTokenHistoryFailure,
} from "./tokenActions";
import { getRequest, postRequest } from "../../utils/apiRequests";
import { endPoints } from "../../utils/apiEndPoints";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebaseConfig";
import store from "../store";

function* getTokens(action: any): any {
  try {
    const data = yield call(
      postRequest,
      `${(store.getState().developerTools as any).url}/${endPoints.readToken}`,
      {},
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(getTokensSuccess(data));
    } else {
      yield put(getTokensFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(getTokensFailure(err.message));
  }
}

export function* getTokensSaga() {
  yield takeLatest(GET_TOKENS_INIT, getTokens);
}

function* updateToken(action: any): any {
  try {
    const fI = async () => {
      if (
        action.payload.params.image &&
        typeof action.payload.params.image !== "string"
      ) {
        const params = action.payload.params.image;
        const imageRef = ref(
          storage,
          `Client/${action.payload.params.clientId}/Token/${params.name}`
        );
        await uploadBytes(imageRef, params);
        const response = await getDownloadURL(imageRef);
        return response;
      } else {
        return action.payload.params.image || "a";
      }
    };
    const image = yield call(fI);
    const { token, clientId, ...reqData } = action.payload.params;
    const data = yield call(
      postRequest,
      `${(store.getState().developerTools as any).url}/${
        endPoints.updateToken
      }`,
      { ...reqData, image: image },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(updateTokenSuccess(data));
    } else {
      yield put(updateTokenFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(updateTokenFailure(err.message));
  }
}

export function* updateTokenSaga() {
  yield takeLatest(UPDATE_TOKEN_INIT, updateToken);
}

function* addToken(action: any): any {
  try {
    const fI = async () => {
      if (action.payload.params.image) {
        const params = action.payload.params.image;
        const imageRef = ref(
          storage,
          `Client/${action.payload.params.clientId}Token/${params.name}`
        );
        await uploadBytes(imageRef, params);
        const response = await getDownloadURL(imageRef);
        return response;
      } else {
        return "a";
      }
    };
    const { token, clientId, ...reqData } = action.payload.params;
    const image = yield call(fI);
    const data = yield call(
      postRequest,
      `${(store.getState().developerTools as any).url}/${endPoints.addToken}`,
      { ...reqData, image: image },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(addTokenSuccess(data));
    } else {
      yield put(addTokenFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(addTokenFailure(err.message));
  }
}

export function* addTokenSaga() {
  yield takeLatest(ADD_TOKEN_INIT, addToken);
}

function* getAnalytics(action: any): any {
  try {
    // const { token, ...reqData } = action.payload.params;
    const data = yield call(
      getRequest,
      `${(store.getState().developerTools as any).url}/${endPoints.analytics}`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(getAnalyticsSuccess(data));
    } else {
      yield put(getAnalyticsFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(getAnalyticsFailure(err.message));
  }
}

export function* getAnalyticsSaga() {
  yield takeLatest(GET_ANALYTICS_INIT, getAnalytics);
}

function* getTokenHistory(action: any): any {
  try {
    // const { token, ...reqData } = action.payload.params;
    const data = yield call(
      getRequest,
      `${(store.getState().developerTools as any).url}/${
        endPoints.tokenHistory
      }`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(getTokenHistorySuccess(data));
    } else {
      yield put(getTokenHistoryFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(getTokenHistoryFailure(err.message));
  }
}

export function* getTokenHistorySaga() {
  yield takeLatest(GET_TOKEN_HISTORY_INIT, getTokenHistory);
}

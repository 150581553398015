import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_EVENTS_INIT,
  UPDATE_EVENT_INIT,
  ADD_EVENT_INIT,
  DELETE_EVENT_INIT,
} from "../actionTypes";
import {
  getEventsSuccess,
  getEventsFailure,
  updateEventSuccess,
  updateEventFailure,
  addEventSuccess,
  addEventFailure,
  deleteEventSuccess,
  deleteEventFailure,
} from "./eventActions";
import { getRequest, postRequest } from "../../utils/apiRequests";
import { endPoints } from "../../utils/apiEndPoints";
import store from "../store";

function* getEvents(action: any): any {
  try {
    const data = yield call(
      getRequest,
      `${(store.getState().developerTools as any).url}/${endPoints.readEvent}`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(getEventsSuccess(data));
    } else {
      yield put(getEventsFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(getEventsFailure(err.message));
  }
}

export function* getEventsSaga() {
  yield takeLatest(GET_EVENTS_INIT, getEvents);
}

function* updateEvent(action: any): any {
  try {
    const { token, ...reqData } = action.payload.params;
    const data = yield call(
      postRequest,
      `${(store.getState().developerTools as any).url}/${
        endPoints.updateEvent
      }`,
      { ...reqData },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(updateEventSuccess(data));
    } else {
      yield put(updateEventFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(updateEventFailure(err.message));
  }
}

export function* updateEventSaga() {
  yield takeLatest(UPDATE_EVENT_INIT, updateEvent);
}

function* addEvent(action: any): any {
  try {
    const { token, ...reqData } = action.payload.params;
    const data = yield call(
      postRequest,
      `${(store.getState().developerTools as any).url}/${endPoints.addEvent}`,
      { ...reqData },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(addEventSuccess(data));
    } else {
      yield put(addEventFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(addEventFailure(err.message));
  }
}

export function* addEventSaga() {
  yield takeLatest(ADD_EVENT_INIT, addEvent);
}

function* deleteEvent(action: any): any {
  try {
    const { token, ...reqData } = action.payload.params;
    const data = yield call(
      postRequest,
      `${(store.getState().developerTools as any).url}/${
        endPoints.deleteEvent
      }`,
      { ...reqData },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(deleteEventSuccess(data));
    } else {
      yield put(deleteEventFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(deleteEventFailure(err.message));
  }
}

export function* deleteEventSaga() {
  yield takeLatest(DELETE_EVENT_INIT, deleteEvent);
}

import {
  ADD_USER_VARIABLE_INIT,
  ADD_USER_VARIABLE_SUCCESS,
  ADD_USER_VARIABLE_FAILURE,
  UPDATE_USER_VARIABLE_INIT,
  UPDATE_USER_VARIABLE_SUCCESS,
  UPDATE_USER_VARIABLE_FAILURE,
  GET_USER_VARIABLE_INIT,
  GET_USER_VARIABLE_SUCCESS,
  GET_USER_VARIABLE_FAILURE,
  DELETE_USER_VARIABLE_INIT,
  DELETE_USER_VARIABLE_SUCCESS,
  DELETE_USER_VARIABLE_FAILURE,
  SEARCH_USER_WITH_ID_INIT,
  SEARCH_USER_WITH_ID_SUCCESS,
  SEARCH_USER_WITH_ID_FAILURE,
  GET_USER_TRANSACTION_HISTORY_INIT,
  GET_USER_TRANSACTION_HISTORY_SUCCESS,
  GET_USER_TRANSACTION_HISTORY_FAILURE,
  GET_USER_TOKEN_TRANSACTION_HISTORY_INIT,
  GET_USER_TOKEN_TRANSACTION_HISTORY_SUCCESS,
  GET_USER_TOKEN_TRANSACTION_HISTORY_FAILURE,
  FILTER_CREDIT_INIT,
  FILTER_CREDIT_SUCCESS,
  FILTER_CREDIT_FAILURE,
  FILTER_CREDIT_INDIVIDUAL_INIT,
  FILTER_CREDIT_INDIVIDUAL_SUCCESS,
  FILTER_CREDIT_INDIVIDUAL_FAILURE,
  ADD_CUSTOM_TOKEN_USER,
  ADD_CUSTOM_TOKEN_FINAL_USER,
  ADD_CUSTOM_TOKEN_VALUE_USER,
  ADD_CUSTOM_TOKEN_INDIVIDUAL_USER,
  ADD_CUSTOM_TOKEN_VALUE_INDIVIDUAL_USER,
  UPDATE_USER_MULTIPLIER_INPUT,
  UPDATE_USER_INDIVIDUAL_MULTIPLIER_INPUT,
} from "../actionTypes";

export const addUserVariableSuccess = (data: any) => {
  return {
    type: ADD_USER_VARIABLE_SUCCESS,
    data,
  };
};

export const addUserVariableFailure = (error: any) => {
  return {
    type: ADD_USER_VARIABLE_FAILURE,
    error,
  };
};

export const addUserVariable = (params: any) => {
  const payload = { params };
  return {
    type: ADD_USER_VARIABLE_INIT,
    payload,
  };
};

export const updateUserVariableSuccess = (data: any) => {
  return {
    type: UPDATE_USER_VARIABLE_SUCCESS,
    data,
  };
};

export const updateUserVariableFailure = (error: any) => {
  return {
    type: UPDATE_USER_VARIABLE_FAILURE,
    error,
  };
};

export const updateUserVariable = (params: any) => {
  const payload = { params };
  return {
    type: UPDATE_USER_VARIABLE_INIT,
    payload,
  };
};

export const getUserVariableSuccess = (data: any) => {
  return {
    type: GET_USER_VARIABLE_SUCCESS,
    data,
  };
};

export const getUserVariableFailure = (error: any) => {
  return {
    type: GET_USER_VARIABLE_FAILURE,
    error,
  };
};

export const getUserVariable = (params: any) => {
  const payload = { params };
  return {
    type: GET_USER_VARIABLE_INIT,
    payload,
  };
};

export const deleteUserVariableSuccess = (data: any) => {
  return {
    type: DELETE_USER_VARIABLE_SUCCESS,
    data,
  };
};

export const deleteUserVariableFailure = (error: any) => {
  return {
    type: DELETE_USER_VARIABLE_FAILURE,
    error,
  };
};

export const deleteUserVariable = (params: any) => {
  const payload = { params };
  return {
    type: DELETE_USER_VARIABLE_INIT,
    payload,
  };
};

export const searchUserWithIdSuccess = (data: any) => {
  return {
    type: SEARCH_USER_WITH_ID_SUCCESS,
    data,
  };
};

export const searchUserWithIdFailure = (error: any) => {
  return {
    type: SEARCH_USER_WITH_ID_FAILURE,
    error,
  };
};

export const searchUserWithId = (params: any) => {
  const payload = { params };
  return {
    type: SEARCH_USER_WITH_ID_INIT,
    payload,
  };
};

export const getUserTransactionHistorySuccess = (data: any) => {
  return {
    type: GET_USER_TRANSACTION_HISTORY_SUCCESS,
    data,
  };
};

export const getUserTransactionHistoryFailure = (error: any) => {
  return {
    type: GET_USER_TRANSACTION_HISTORY_FAILURE,
    error,
  };
};

export const getUserTransactionHistory = (params: any) => {
  const payload = { params };
  return {
    type: GET_USER_TRANSACTION_HISTORY_INIT,
    payload,
  };
};
export const getUserTokenTransactionHistorySuccess = (data: any) => {
  return {
    type: GET_USER_TOKEN_TRANSACTION_HISTORY_SUCCESS,
    data,
  };
};

export const getUserTokenTransactionHistoryFailure = (error: any) => {
  return {
    type: GET_USER_TOKEN_TRANSACTION_HISTORY_FAILURE,
    error,
  };
};

export const getUserTokenTransactionHistory = (params: any) => {
  const payload = { params };
  return {
    type: GET_USER_TOKEN_TRANSACTION_HISTORY_INIT,
    payload,
  };
};

export const filterCreditSuccess = (data: any) => {
  return {
    type: FILTER_CREDIT_SUCCESS,
    data,
  };
};

export const filterCreditFailure = (error: any) => {
  return {
    type: FILTER_CREDIT_FAILURE,
    error,
  };
};

export const filterCredit = (params: any) => {
  const payload = { params };
  return {
    type: FILTER_CREDIT_INIT,
    payload,
  };
};

export const filterCreditIndividualSuccess = (data: any) => {
  return {
    type: FILTER_CREDIT_INDIVIDUAL_SUCCESS,
    data,
  };
};

export const filterCreditIndividualFailure = (error: any) => {
  return {
    type: FILTER_CREDIT_INDIVIDUAL_FAILURE,
    error,
  };
};

export const filterCreditIndividual = (params: any) => {
  const payload = { params };
  return {
    type: FILTER_CREDIT_INDIVIDUAL_INIT,
    payload,
  };
};

export const addCustomTokenUser = (params: any) => {
  return {
    type: ADD_CUSTOM_TOKEN_USER,
    params,
  };
};

export const addCustomTokenFinalUser = (params: any) => {
  return {
    type: ADD_CUSTOM_TOKEN_FINAL_USER,
    params,
  };
};

export const addCustomTokenValueUser = (data: any) => {
  return {
    type: ADD_CUSTOM_TOKEN_VALUE_USER,
    data,
  };
};

export const addCustomTokenIndividualUser = (params: any) => {
  return {
    type: ADD_CUSTOM_TOKEN_INDIVIDUAL_USER,
    params,
  };
};

export const addCustomTokenValueIndividualUser = (data: any) => {
  return {
    type: ADD_CUSTOM_TOKEN_VALUE_INDIVIDUAL_USER,
    data,
  };
};

export const updateUserMultiplierInput = (data: any) => {
  return {
    type: UPDATE_USER_MULTIPLIER_INPUT,
    data,
  };
};

export const updateUserIndividualMultiplierInput = (data: any) => {
  return {
    type: UPDATE_USER_INDIVIDUAL_MULTIPLIER_INPUT,
    data,
  };
};

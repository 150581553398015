import { IContestState } from "../../models/contest/contest";
import {
    ADD_CONTEST_FAILURE,
    ADD_CONTEST_INIT,
    ADD_CONTEST_SUCCESS,
    DELETE_CONTEST_FAILURE,
    DELETE_CONTEST_INIT,
    DELETE_CONTEST_SUCCESS,
    GET_CONTEST_SUCCESS,
    UPDATE_CONTEST_FAILURE,
    UPDATE_CONTEST_INIT,
    GET_CONTEST_FAILURE,
    GET_CONTEST_INIT,
    UPDATE_CONTEST_SUCCESS,
} from "../../redux/actionTypes";

const initialState = {
    isGettingContests: false,
    getContestsResp: [],
    getContestsRespStatus: 0,
    getContestsError: false,
    getContestsErrorMsg: "",
    isUpdatingContest: false,
    updateContestError: false,
    updateContestErrorMsg: "",
    updateContestResp: [],
    updateContestRespStatus: 0,
    isAddingContest: false,
    addContestError: false,
    addContestErrorMsg: "",
    addContestResp: [],
    addContestRespStatus: 0,
    isDeletingContest: false,
    deleteContestError: false,
    deleteContestErrorMsg: "",
    deleteContestResp: [],
    deleteContestRespStatus: 0,
};

const contests = (state: IContestState = initialState, action: any) => {
    switch (action.type) {
        case GET_CONTEST_INIT:
            return {
                ...state,
                isGettingContest: true,
                getContestError: false,
                getContestRespStatus: 0,
            };

        case GET_CONTEST_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isGettingContest: false,
                getContestResp: data,
                getContestRespStatus: 200,
            };
        }

        case GET_CONTEST_FAILURE:
            const { error } = action;
            return {
                ...state,
                isGettingContest: false,
                getContestError: true,
                getContestErrorMsg: error,
                getContestRespStatus: 400,
            };

        case UPDATE_CONTEST_INIT:
            return {
                ...state,
                isUpdatingContest: true,
                updateContestError: false,
                updateContestRespStatus: 0,
            };

        case UPDATE_CONTEST_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isUpdatingContest: false,
                updateContestResp: data,
                updateContestRespStatus: 200,
            };
        }

        case UPDATE_CONTEST_FAILURE:
            return {
                ...state,
                isUpdatingContest: false,
                updateContestError: true,
                updateContestErrorMsg: action.error,
                updateContestRespStatus: 400,
            };

        case ADD_CONTEST_INIT:
            return {
                ...state,
                isAddingContest: true,
                addContestError: false,
                addContestRespStatus: 0,
            };

        case ADD_CONTEST_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isAddingContest: false,
                addContestResp: data,
                addContestRespStatus: 200,
            };
        }

        case ADD_CONTEST_FAILURE:
            return {
                ...state,
                isAddingContest: false,
                addContestError: true,
                addContestErrorMsg: action.error,
                addContestRespStatus: 400,
            };

        case DELETE_CONTEST_INIT:
            return {
                ...state,
                isDeletingContest: true,
                deleteContestError: false,
                deleteContestRespStatus: 0,
            };

        case DELETE_CONTEST_SUCCESS: {
            const { data } = action;
            return {
                ...state,
                isDeletingContest: false,
                deleteContestResp: data,
                deleteContestRespStatus: 200,
            };
        }

        case DELETE_CONTEST_FAILURE:
            return {
                ...state,
                isDeletingContest: false,
                deleteContestError: true,
                deleteContestErrorMsg: action.error,
                deleteContestRespStatus: 400,
            };

        default:
            return state;
    }
};

export default contests;

import { useEffect, useState } from "react";
import Navbar from "../../components/common/navbar";
import { useDispatch } from "react-redux";
import { getTokens } from "../../redux/token/tokenActions";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { getEvents } from "../../redux/token/eventActions";
import UserAnalytics from "./analytics/userAnalytics";
import UserVariables from "./variables/userVariables";
import UserManagement from "./management/userManagement";

const Users = () => {
  const { user } = useUserAuth();
  const [active, setActive] = useState<string>("analytics");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTokens({ token: user.accessToken }));
    dispatch(getEvents({ token: user.accessToken }));
  }, []);
  return (
    <div className="w-full h-[100vh] overflow-y-auto text-[#4C4C66]">
      <div className="px-10">
        <Navbar />
        <div className="flex items-center gap-3 mt-10">
          <div
            className={
              "text-lg cursor-pointer" +
              (active === "analytics"
                ? " border-b-2 border-[#4C4C66] font-bold"
                : "")
            }
            onClick={() => setActive("analytics")}
          >
            Analytics
          </div>
          <div
            className={
              "text-lg cursor-pointer" +
              (active === "variables"
                ? " border-b-2 border-[#4C4C66] font-bold"
                : "")
            }
            onClick={() => {
              setActive("variables");
            }}
          >
            Variables
          </div>
          <div
            className={
              "text-lg cursor-pointer" +
              (active === "management"
                ? " border-b-2 border-[#4C4C66] font-bold"
                : "")
            }
            onClick={() => {
              setActive("management");
            }}
          >
            Management
          </div>
        </div>
      </div>
      {active === "analytics" && <UserAnalytics />}
      {active === "variables" && <UserVariables />}
      {active === "management" && <UserManagement />}
    </div>
  );
};

export default Users;

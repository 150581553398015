import InternalServerError from "../../containers/internalServerError/internalServerError";
import Sidebar from "../../containers/sidebar/sidebar";
import { useUserAuth } from "../../hooks/useAuthContextProvider";

const InternalServerErrorIndex = () => {
  const { user } = useUserAuth();
  return (
    <div className="text-white h-screen">
      <div className="flex">
        {user && <Sidebar />}
        <InternalServerError />
      </div>
    </div>
  );
};

export default InternalServerErrorIndex;

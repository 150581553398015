import {
    ADD_CONTEST_FAILURE,
    ADD_CONTEST_INIT,
    ADD_CONTEST_SUCCESS,
    DELETE_CONTEST_FAILURE,
    DELETE_CONTEST_INIT,
    DELETE_CONTEST_SUCCESS,
    GET_CONTEST_SUCCESS,
    UPDATE_CONTEST_FAILURE,
    UPDATE_CONTEST_INIT,
    GET_CONTEST_FAILURE,
    GET_CONTEST_INIT,
    UPDATE_CONTEST_SUCCESS,
} from "../actionTypes";

export const getContestSuccess = (data: any) => {
    return {
        type: GET_CONTEST_SUCCESS,
        data,
    };
};

export const getContestFailure = (error: any) => {
    return {
        type: GET_CONTEST_FAILURE,
        error,
    };
};

export const getContest = (params: any) => {
    const payload = { params };
    return {
        type: GET_CONTEST_INIT,
        payload,
    };
};

export const updateContestSuccess = (data: any) => {
    return {
        type: UPDATE_CONTEST_SUCCESS,
        data,
    };
};

export const updateContestFailure = (error: any) => {
    return {
        type: UPDATE_CONTEST_FAILURE,
        error,
    };
};

export const updateContest = (params: any) => {
    const payload = { params };
    return {
        type: UPDATE_CONTEST_INIT,
        payload,
    };
};

export const addContestSuccess = (data: any) => {
    return {
        type: ADD_CONTEST_SUCCESS,
        data,
    };
};

export const addContestFailure = (error: any) => {
    return {
        type: ADD_CONTEST_FAILURE,
        error,
    };
};

export const addContest = (params: any) => {
    const payload = { params };
    return {
        type: ADD_CONTEST_INIT,
        payload,
    };
};

export const deleteContestSuccess = (data: any) => {
    return {
        type: DELETE_CONTEST_SUCCESS,
        data,
    };
};

export const deleteContestFailure = (error: any) => {
    return {
        type: DELETE_CONTEST_FAILURE,
        error,
    };
};

export const deleteContest = (params: any) => {
    const payload = { params };
    return {
        type: DELETE_CONTEST_INIT,
        payload,
    };
};

export const GET_OTP_INIT = "GET_OTP_INIT";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_FAILURE = "GET_OTP_FAILURE";

export const VERIFY_OTP_INIT = "VERIFY_OTP_INIT";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const RESET_OTP = "RESET_OTP";

export const FIREBASE_SIGNED_OUT = "FIREBASE_SIGNED_OUT";

export const GET_USER_INIT = "GET_USER_INIT";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const CLIENT_ONBOARDING_INIT = "CLIENT_ONBOARDING_INIT";
export const CLIENT_ONBOARDING_SUCCESS = "CLIENT_ONBOARDING_SUCCESS";
export const CLIENT_ONBOARDING_FAILURE = "CLIENT_ONBOARDING_FAILURE";

export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const ADD_NUDGE = "ADD_NUDGE";
export const DELETE_NUDGE = "DELETE_NUDGE";
export const ADD_USER_FILTER = " ADD_USER_FILTER";
export const ADD_WHEN = "ADD_WHEN";
export const ADD_ACTIVITY = " ADD_ACTIVITY";

export const UPDATE_EVENT_TOKEN_INPUT = "UPDATE_EVENT_TOKEN_INPUT";

export const GET_TOKENS_INIT = "GET_TOKENS_INIT";
export const GET_TOKENS_SUCCESS = "GET_TOKENS_SUCCESS";
export const GET_TOKENS_FAILURE = "GET_TOKENS_FAILURE";

export const UPDATE_TOKEN_INIT = "UPDATE_TOKEN_INIT";
export const UPDATE_TOKEN_SUCCESS = "UPDATE_TOKEN_SUCCESS";
export const UPDATE_TOKEN_FAILURE = "UPDATE_TOKEN_FAILURE";

export const ADD_TOKEN_INIT = "ADD_TOKEN_INIT";
export const ADD_TOKEN_SUCCESS = "ADD_TOKEN_SUCCESS";
export const ADD_TOKEN_FAILURE = "ADD_TOKEN_FAILURE";

export const GET_TOKEN_HISTORY_INIT = "GET_TOKEN_HISTORY_INIT";
export const GET_TOKEN_HISTORY_SUCCESS = "GET_TOKEN_HISTORY_SUCCESS";
export const GET_TOKEN_HISTORY_FAILURE = "GET_TOKEN_HISTORY_FAILURE";

export const RESET_TOKEN = "RESET_TOKEN";

export const GET_EVENTS_INIT = "GET_EVENTS_INIT";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";

export const UPDATE_EVENT_INIT = "UPDATE_EVENT_INIT";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";

export const ADD_EVENT_INIT = "ADD_EVENT_INIT";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAILURE = "ADD_EVENT_FAILURE";

export const DELETE_EVENT_INIT = "DELETE_EVENT_INIT";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCSS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";

export const GET_API_INIT = "GET_API_INIT";
export const GET_API_SUCCESS = "GET_API_SUCCESS";
export const GET_API_FAILURE = "GET_API_FAILURE";

export const UPDATE_API_INIT = "UPDATE_API_INIT";
export const UPDATE_API_SUCCESS = "UPDATE_API_SUCCESS";
export const UPDATE_API_FAILURE = "UPDATE_API_FAILURE";

export const ADD_API_INIT = "ADD_API_INIT";
export const ADD_API_SUCCESS = "ADD_API_SUCCESS";
export const ADD_API_FAILURE = "ADD_API_FAILURE";

export const DELETE_API_INIT = "DELETE_API_INIT";
export const DELETE_API_SUCCESS = "DELETE_API_SUCCESS";
export const DELETE_API_FAILURE = "DELETE_API_FAILURE";

export const GET_ANALYTICS_INIT = "GET_ANALYTICS_INIT";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_ANALYTICS_FAILURE = "GET_ANALYTICS_FAILURE";

export const TOGGLE_CONFIRMATION_POPUP = "TOGGLE_CONFIRMATION_POPUP";

export const TOGGLE_MODE_ENVIRONMENT = "TOGGLE_MODE_ENVIRONMENT";

export const GET_ALL_ROLES_INIT = "GET_ALL_ROLES_INIT";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const GET_ALL_ROLES_FAILURE = "GET_ALL_ROLES_FAILURE";

export const ADD_ROLE_INIT = "ADD_ROLE_INIT";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILURE = "ADD_ROLE_FAILURE";

export const UPDATE_ROLE_INIT = "UPDATE_ROLE_INIT";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";

export const DELETE_ROLE_INIT = "DELETE_ROLE_INIT";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

export const UPDATE_CLIENT_INIT = "UPDATE_CLIENT_INIT";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";

export const ADD_USER_VARIABLE_INIT = "ADD_USER_VARIABLE_INIT";
export const ADD_USER_VARIABLE_SUCCESS = "ADD_USER_VARIABLE_SUCCESS";
export const ADD_USER_VARIABLE_FAILURE = "ADD_USER_VARIABLE_FAILURE";

export const UPDATE_USER_VARIABLE_INIT = "UPDATE_USER_VARIABLE_INIT";
export const UPDATE_USER_VARIABLE_SUCCESS = "UPDATE_USER_VARIABLE_SUCCESS";
export const UPDATE_USER_VARIABLE_FAILURE = "UPDATE_USER_VARIABLE_FAILURE";

export const GET_USER_VARIABLE_INIT = "GET_USER_VARIABLE_INIT";
export const GET_USER_VARIABLE_SUCCESS = "GET_USER_VARIABLE_SUCCESS";
export const GET_USER_VARIABLE_FAILURE = "GET_USER_VARIABLE_FAILURE";

export const DELETE_USER_VARIABLE_INIT = "DELETE_USER_VARIABLE_INIT";
export const DELETE_USER_VARIABLE_SUCCESS = "DELETE_USER_VARIABLE_SUCCESS";
export const DELETE_USER_VARIABLE_FAILURE = "DELETE_USER_VARIABLE_FAILURE";

export const TOGGLE_CUSTOM_TOKEN_MODAL = "TOGGLE_CUSTOM_TOKEN__MODAL";

export const SEARCH_USER_WITH_ID_INIT = "SEARCH_USER_WITH_ID_INIT";
export const SEARCH_USER_WITH_ID_SUCCESS = "SEARCH_USER_WITH_ID_SUCCESS";
export const SEARCH_USER_WITH_ID_FAILURE = "SEARCH_USER_WITH_ID_FAILURE";

export const GET_USER_TRANSACTION_HISTORY_INIT =
    "GET_USER_TRANSACTION_HISTORY_INIT";
export const GET_USER_TRANSACTION_HISTORY_SUCCESS =
    "GET_USER_TRANSACTION_HISTORY_SUCCESS";
export const GET_USER_TRANSACTION_HISTORY_FAILURE =
    "GET_USER_TRANSACTION_HISTORY_FAILURE";

export const ADD_CUSTOM_TOKEN_USER = "ADD_CUSTOM_TOKEN_USER";
export const ADD_CUSTOM_TOKEN_FINAL_USER = "ADD_CUSTOM_TOKEN_FINAL_USER";
export const ADD_CUSTOM_TOKEN_VALUE_USER = "ADD_CUSTOM_TOKEN_VALUE_USER";

export const ADD_CUSTOM_TOKEN_INDIVIDUAL_USER =
    "ADD_CUSTOM_TOKEN_INDIVIDUAL_USER";
export const ADD_CUSTOM_TOKEN_VALUE_INDIVIDUAL_USER =
    "ADD_CUSTOM_TOKEN_VALUE_INDIVIDUAL_USER";

export const FILTER_CREDIT_INIT = "FILTER_CREDIT_INIT";
export const FILTER_CREDIT_SUCCESS = "FILTER_CREDIT_SUCCESS";
export const FILTER_CREDIT_FAILURE = "FILTER_CREDIT_FAILURE";

export const FILTER_CREDIT_INDIVIDUAL_INIT = "FILTER_CREDIT_INDIVIDUAL_INIT";
export const FILTER_CREDIT_INDIVIDUAL_SUCCESS =
    "FILTER_CREDIT_INDIVIDUAL_SUCCESS";
export const FILTER_CREDIT_INDIVIDUAL_FAILURE =
    "FILTER_CREDIT_INDIVIDUAL_FAILURE";
// export const ADD_NUDGE = "ADD_NUDGE";
// export const DELETE_NUDGE = "DELETE_NUDGE";
// export const ADD_USER_FILTER = " ADD_USER_FILTER";
// export const ADD_WHEN = "ADD_WHEN";
// export const ADD_ACTIVITY = " ADD_ACTIVITY";

export const ADD_MULTIPLIER_INIT = "ADD_MULTIPLIER_INIT";
export const ADD_MULTIPLIER_SUCCESS = "ADD_MULTIPLIER_SUCCESS";
export const ADD_MULTIPLIER_FAILURE = "ADD_MULTIPLIER_FAILURE";

export const UPDATE_MULTIPLIER_INIT = "UPDATE_MULTIPLIER_INIT";
export const UPDATE_MULTIPLIER_SUCCESS = "UPDATE_MULTIPLIER_SUCCESS";
export const UPDATE_MULTIPLIER_FAILURE = "UPDATE_MULTIPLIER_FAILURE";

export const GET_MULTIPLIERS_INIT = "GET_MULTIPLIERS_INIT";
export const GET_MULTIPLIERS_SUCCESS = "GET_MULTIPLIERS_SUCCESS";
export const GET_MULTIPLIERS_FAILURE = "GET_MULTIPLIERS_FAILURE";

export const DELETE_MULTIPLIER_INIT = "DELETE_MULTIPLIER_INIT";
export const DELETE_MULTIPLIER_SUCCESS = "DELETE_MULTIPLIER_SUCCESS";
export const DELETE_MULTIPLIER_FAILURE = "DELETE_MULTIPLIER_FAILURE";

export const ADD_STREAK_INIT = "ADD_STREAK_INIT";
export const ADD_STREAK_SUCCESS = "ADD_STREAK_SUCCESS";
export const ADD_STREAK_FAILURE = "ADD_STREAK_FAILURE";

export const UPDATE_STREAK_INIT = "UPDATE_STREAK_INIT";
export const UPDATE_STREAK_SUCCESS = "UPDATE_STREAK_SUCCESS";
export const UPDATE_STREAK_FAILURE = "UPDATE_STREAK_FAILURE";

export const GET_STREAKS_INIT = "GET_STREAKS_INIT";
export const GET_STREAKS_SUCCESS = "GET_STREAKS_SUCCESS";
export const GET_STREAKS_FAILURE = "GET_STREAKS_FAILURE";

export const DELETE_STREAK_INIT = "DELETE_STREAK_INIT";
export const DELETE_STREAK_SUCCESS = "DELETE_STREAK_SUCCESS";
export const DELETE_STREAK_FAILURE = "DELETE_STREAK_FAILURE";

export const UPDATE_STREAK_TOKEN_INPUT = "UPDATE_STREAK_TOKEN_INPUT";
export const UPDATE_STREAK_TOKEN_MILESTONE_INPUT =
    "UPDATE_STREAK_TOKEN_MILESTONE_INPUT";
export const UPDATE_STREAK_TOKEN_REWARD_INPUT =
    "UPDATE_STREAK_TOKEN_REWARD_INPUT";

export const UPDATE_STREAK_EVENT_INPUT = "UPDATE_STREAK_EVENT_INPUT";
export const UPDATE_STREAK_MULTIPLIER_INPUT = "UPDATE_STREAK_MULTIPLIER_INPUT";
export const UPDATE_REWARD_MULTIPLIER_INPUT = "UPDATE_REWARD_MULTIPLIER_INPUT";
export const UPDATE_USER_MULTIPLIER_INPUT = "UPDATE_USER_MULTIPLIER_INPUT";
export const UPDATE_USER_INDIVIDUAL_MULTIPLIER_INPUT =
    "UPDATE_USER_INDIVIDUAL_MULTIPLIER_INPUT";

export const RESET_STREAKS_STATES = "RESET_STREAKS_STATES";
export const ADD_REFERRAL_INIT = "ADD_REFERRAL_INIT";
export const ADD_REFERRAL_SUCCESS = "ADD_REFERRAL_SUCCESS";
export const ADD_REFERRAL_FAILURE = "ADD_REFERRAL_FAILURE";

export const GET_REFERRAL_INIT = "GET_REFERRAL_INIT";
export const GET_REFERRAL_SUCCESS = "GET_REFERRAL_SUCCESS";
export const GET_REFERRAL_FAILURE = "GET_REFERRAL_FAILURE";

export const UPDATE_REFERRAL_INIT = "UPDATE_REFERRAL_INIT";
export const UPDATE_REFERRAL_SUCCESS = "UPDATE_REFERRAL_SUCCESS";
export const UPDATE_REFERRAL_FAILURE = "UPDATE_REFERRAL_FAILURE";

export const DELETE_REFERRAL_INIT = "DELETE_REFERRAL_INIT";
export const DELETE_REFERRAL_SUCCESS = "DELETE_REFERRAL_SUCCESS";
export const DELETE_REFERRAL_FAILURE = "DELETE_REFERRAL_FAILURE";

export const GET_USER_TOKEN_TRANSACTION_HISTORY_INIT =
    "GET_USER_TOKEN_TRANSACTION_HISTORY_INIT";
export const GET_USER_TOKEN_TRANSACTION_HISTORY_SUCCESS =
    "GET_USER_TOKEN_TRANSACTION_HISTORY_SUCCESS";
export const GET_USER_TOKEN_TRANSACTION_HISTORY_FAILURE =
    "GET_USER_TOKEN_TRANSACTION_HISTORY_FAILURE";

export const ADD_MILESTONE_INIT = "ADD_MILESTONE_INIT";
export const ADD_MILESTONE_SUCCESS = "ADD_MILESTONE_SUCCESS";
export const ADD_MILESTONE_FAILURE = "ADD_MILESTONE_FAILURE";

export const GET_MILESTONE_INIT = "GET_MILESTONE_INIT";
export const GET_MILESTONE_SUCCESS = "GET_MILESTONE_SUCCESS";
export const GET_MILESTONE_FAILURE = "GET_MILESTONE_FAILURE";

export const UPDATE_MILESTONE_INIT = "UPDATE_MILESTONE_INIT";
export const UPDATE_MILESTONE_SUCCESS = "UPDATE_MILESTONE_SUCCESS";
export const UPDATE_MILESTONE_FAILURE = "UPDATE_MILESTONE_FAILURE";

export const DELETE_MILESTONE_INIT = "DELETE_MILESTONE_INIT";
export const DELETE_MILESTONE_SUCCESS = "DELETE_MILESTONE_SUCCESS";
export const DELETE_MILESTONE_FAILURE = "DELETE_MILESTONE_FAILURE";

export const ADD_CONTEST_INIT = "ADD_CONTEST_INIT";
export const ADD_CONTEST_SUCCESS = "ADD_CONTEST_SUCCESS";
export const ADD_CONTEST_FAILURE = "ADD_CONTEST_FAILURE";

export const GET_CONTEST_INIT = "GET_CONTEST_INIT";
export const GET_CONTEST_SUCCESS = "GET_CONTEST_SUCCESS";
export const GET_CONTEST_FAILURE = "GET_CONTEST_FAILURE";

export const UPDATE_CONTEST_INIT = "UPDATE_CONTEST_INIT";
export const UPDATE_CONTEST_SUCCESS = "UPDATE_CONTEST_SUCCESS";
export const UPDATE_CONTEST_FAILURE = "UPDATE_CONTEST_FAILURE";

export const DELETE_CONTEST_INIT = "DELETE_CONTEST_INIT";
export const DELETE_CONTEST_SUCCESS = "DELETE_CONTEST_SUCCESS";
export const DELETE_CONTEST_FAILURE = "DELETE_CONTEST_FAILURE";

export const ADD_ATTRIBUTE_INIT = "ADD_ATTRIBUTE_INIT";
export const ADD_ATTRIBUTE_SUCCESS = "ADD_ATTRIBUTE_SUCCESS";
export const ADD_ATTRIBUTE_FAILURE = "ADD_ATTRIBUTE_FAILURE";

export const GET_ATTRIBUTE_INIT = "GET_ATTRIBUTE_INIT";
export const GET_ATTRIBUTE_SUCCESS = "GET_ATTRIBUTE_SUCCESS";
export const GET_ATTRIBUTE_FAILURE = "GET_ATTRIBUTE_FAILURE";

export const UPDATE_ATTRIBUTE_INIT = "UPDATE_ATTRIBUTE_INIT";
export const UPDATE_ATTRIBUTE_SUCCESS = "UPDATE_ATTRIBUTE_SUCCESS";
export const UPDATE_ATTRIBUTE_FAILURE = "UPDATE_ATTRIBUTE_FAILURE";

export const DELETE_ATTRIBUTE_INIT = "DELETE_ATTRIBUTE_INIT";
export const DELETE_ATTRIBUTE_SUCCESS = "DELETE_ATTRIBUTE_SUCCESS";
export const DELETE_ATTRIBUTE_FAILURE = "DELETE_ATTRIBUTE_FAILURE";

import { useState } from "react";
import CampaignCard from "../../components/gamification/campaignCard";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "../../redux/gamification/gamificationActions";
import User from "../../components/gamification/modals/user";
import Nudge from "../../components/gamification/modals/nudge/nudge";
import TheyGet from "../../components/gamification/modals/nudge/theyGet";
import When from "../../components/gamification/modals/nudge/when";
import Which from "../../components/gamification/modals/nudge/which";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0%",
    border: "none",
  },
  overlay: {
    zIndex: "1000",
    background: "rgba(1, 1, 1, 0.1)",
    backdropFilter: "blur(7px)",
  },
};
const Campaign = () => {
  const [add, setAdd] = useState<boolean>(false);
  const { active, type } = useSelector((state: any) => state.gamification);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(toggleModal({ id: "" }));
  };
  return (
    <div>
      <div className="flex items-center justify-between mt-14">
        <div className="text-2xl font-bold">Campaigns</div>
        <div className="flex items-center gap-3">
          <input
            type="text"
            className="border-2 border-[#FFF2F1] rounded px-5 py-2 outline-none"
            placeholder="Search"
          />
          <div
            className="flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
            onClick={() => setAdd(!add)}
          >
            Add Campaign
          </div>
        </div>
      </div>
      {<CampaignCard add={add} />}
      <Modal
        style={customStyles}
        isOpen={active}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => handleClose()}
        shouldCloseOnEsc={true}
      >
        {type === "user" && <User />}
        {type === "nudge" && <Nudge />}
        {type === "get" && <TheyGet />}
        {type === "when" && <When />}
        {type === "which" && <Which />}
      </Modal>
    </div>
  );
};
export default Campaign;

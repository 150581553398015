import {
  GET_TOKENS_INIT,
  GET_TOKENS_SUCCESS,
  GET_TOKENS_FAILURE,
  UPDATE_TOKEN_INIT,
  UPDATE_TOKEN_SUCCESS,
  UPDATE_TOKEN_FAILURE,
  ADD_TOKEN_INIT,
  ADD_TOKEN_SUCCESS,
  ADD_TOKEN_FAILURE,
  GET_ANALYTICS_INIT,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAILURE,
  GET_TOKEN_HISTORY_INIT,
  GET_TOKEN_HISTORY_SUCCESS,
  GET_TOKEN_HISTORY_FAILURE,
  RESET_TOKEN,
} from "../actionTypes";

export const getTokensSuccess = (data: any) => {
  return {
    type: GET_TOKENS_SUCCESS,
    data,
  };
};

export const getTokensFailure = (error: any) => {
  return {
    type: GET_TOKENS_FAILURE,
    error,
  };
};

export const getTokens = (params: any) => {
  const payload = { params };
  return {
    type: GET_TOKENS_INIT,
    payload,
  };
};

export const updateTokenSuccess = (data: any) => {
  return {
    type: UPDATE_TOKEN_SUCCESS,
    data,
  };
};

export const updateTokenFailure = (error: any) => {
  return {
    type: UPDATE_TOKEN_FAILURE,
    error,
  };
};

export const updateToken = (params: any) => {
  const payload = { params };
  return {
    type: UPDATE_TOKEN_INIT,
    payload,
  };
};

export const addTokenSuccess = (data: any) => {
  return {
    type: ADD_TOKEN_SUCCESS,
    data,
  };
};

export const addTokenFailure = (error: any) => {
  return {
    type: ADD_TOKEN_FAILURE,
    error,
  };
};

export const addToken = (params: any) => {
  const payload = { params };
  return {
    type: ADD_TOKEN_INIT,
    payload,
  };
};

export const getAnalyticsSuccess = (data: any) => {
  return {
    type: GET_ANALYTICS_SUCCESS,
    data,
  };
};

export const getAnalyticsFailure = (error: any) => {
  return {
    type: GET_ANALYTICS_FAILURE,
    error,
  };
};

export const getAnalytics = (params: any) => {
  const payload = { params };
  return {
    type: GET_ANALYTICS_INIT,
    payload,
  };
};

export const getTokenHistorySuccess = (data: any) => {
  return {
    type: GET_TOKEN_HISTORY_SUCCESS,
    data,
  };
};

export const getTokenHistoryFailure = (error: any) => {
  return {
    type: GET_TOKEN_HISTORY_FAILURE,
    error,
  };
};

export const getTokenHistory = (params: any) => {
  const payload = { params };
  return {
    type: GET_TOKEN_HISTORY_INIT,
    payload,
  };
};

export const resetToken = (data: any) => {
  return {
    type: RESET_TOKEN,
    data,
  };
};

import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Add from "../../gamification/add";
import {
  addCampaign,
  toggleCustomTokenModal,
} from "../../../redux/user/customTokenActions";
import Value from "./value";
import { useUserAuth } from "../../../hooks/useAuthContextProvider";
import { filterCredit } from "../../../redux/user/userActions";
import Loader from "../../common/loader";
// import json from "./json";

type CustomTokenProps = {
  //   add: boolean;
  title: string;
  isGroup: boolean;
};

const CustomToken = ({ title, isGroup }: CustomTokenProps) => {
  const { user } = useUserAuth();
  const dispatch = useDispatch();

  const { id } = useSelector((state: any) => state.customToken);
  const { isGettingTokens, getTokensResp, getTokensRespStatus } = useSelector(
    (state: any) => state.tokens
  );
  const {
    getUserVariableRespStatus,
    getUserVariableResp,
    userFinalFilter,
    inputs,
    isFilterCredit,
    userMultiplier,
  } = useSelector((state: any) => state.user);

  const { getMultipliersResp } = useSelector((state: any) => state.multipliers);

  const handleOpen = (type: string) => {
    const i = uuidv4();
    if (id) {
    } else {
      dispatch(addCampaign({ id: i }));
      // const exists = localStorage.getItem("campaigns");
    }
    dispatch(toggleCustomTokenModal({ id: id ? id : i, type: type }));
  };
  return (
    <div>
      <div className="bg-[#FFF2F1] rounded-lg w-full p-5 mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="text-xl font-bold">{title}</div>
          </div>
        </div>
        <div className="grid grid-cols-2 bg-[#FF7B5F] rounded-t-lg text-white font-bold px-3 py-2 mt-5">
          {/* <div className="col-span-1">Notification method</div> */}
          <div className="col-span-1">To users with</div>
          <div className="col-span-1">What to send</div>
        </div>
        <div className="bg-[#FFE8E6] rounded-b-lg px-3 py-2 grid grid-cols-2">
          <div className="col-span-1">
            <div
              className="cursor-pointer"
              onClick={() => handleOpen("to-users")}
            >
              <div className="flex items-center flex-wrap">
                {getUserVariableRespStatus === 200 &&
                  userFinalFilter.strings
                    ?.filter((each: any) => each.values?.length > 0)
                    ?.map((each: any) => {
                      return (
                        <Value
                          name={
                            getUserVariableResp.data.value?.find(
                              (ele: any) => ele._id === each.variable_id
                            ).name
                          }
                          value={
                            ["in", "nin"].includes(each.operation)
                              ? `[${each.values?.join(", ")}]`
                              : each.values
                          }
                          operator={each.operation}
                        />
                      );
                    })}
              </div>
              <div className="flex items-center flex-wrap">
                {getUserVariableRespStatus === 200 &&
                  userFinalFilter.numbers
                    ?.filter((each: any) => each.values?.length > 0)
                    ?.map((each: any) => {
                      return (
                        <Value
                          name={
                            getUserVariableResp.data.value?.find(
                              (ele: any) => ele._id === each.variable_id
                            ).name
                          }
                          value={
                            ["bet"].includes(each.operation)
                              ? `[${each.values?.join(",")}]`
                              : each.values
                          }
                          operator={each.operation}
                        />
                      );
                    })}
              </div>
              <div className="flex items-center flex-wrap">
                {getUserVariableRespStatus === 200 &&
                  userFinalFilter.booleans?.map((each: any) => {
                    return (
                      <Value
                        name={
                          getUserVariableResp.data.value?.find(
                            (ele: any) => ele._id === each.variable_id
                          ).name
                        }
                        value={each.values}
                      />
                    );
                  })}
              </div>
              <div className="flex items-center flex-wrap">
                {getUserVariableRespStatus === 200 &&
                  userFinalFilter.dates?.map((each: any) => {
                    return (
                      <Value
                        name={
                          getUserVariableResp.data?.value?.find(
                            (ele: any) => ele._id === each.variable_id
                          )?.name
                        }
                        value={
                          new Date(each.value[0]).toISOString().split("T")[0]
                        }
                        operator={each.operation}
                        multi={each.operation === "bet"}
                        secondVal={
                          new Date(each.value[1] || new Date().getTime())
                            .toISOString()
                            .split("T")[0]
                        }
                      />
                    );
                  })}
              </div>
              <Add text={"ADD RULES HERE"} />
            </div>
          </div>
          <div className="col-span-1">
            <div className="">
              <div className="grid grid-cols-4 gap-3">
                <div className="col-span-1 mt-1">Tokens:</div>
                <div className="col-span-3">
                  {getTokensRespStatus === 200 && (
                    <div className="flex items-center flex-wrap gap-3">
                      {Object.values(inputs)
                        ?.filter((each: any) => each.selected === true)
                        ?.map((each: any) => {
                          const token = getTokensResp.data?.value?.find(
                            (ele: any) => ele._id === each.token_id
                          );
                          return <Value name={token.name} value={each.value} />;
                        })}
                      {isGettingTokens ? (
                        <Loader />
                      ) : (
                        <div
                          className="cursor-pointer"
                          onClick={() => handleOpen("what")}
                        >
                          <Add text={"ADD RULES HERE"} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="col-span-1 mt-1">Multipliers:</div>
                <div className="col-span-3">
                  <div className="flex items-center flex-wrap gap-1">
                    {userMultiplier && (
                      <Value
                        name={
                          getMultipliersResp.data?.value?.find(
                            (ele: any) => ele._id === userMultiplier
                          )?.name
                        }
                        value={""}
                        operator={"|"}
                      />
                    )}
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        handleOpen("what-multipliers");
                      }}
                    >
                      <Add text={"Add token"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3 mt-5">
          <button
            className="bg-[#FF7B5F] rounded-[100px] cursor-pointer text-white shadow-lg px-5 py-2"
            onClick={() => {
              if (userMultiplier) {
                dispatch(
                  filterCredit({
                    ...userFinalFilter,
                    tokens: Object.values(inputs),
                    token: user.accessToken,
                    multiplier: userMultiplier,
                  })
                );
              } else {
                dispatch(
                  filterCredit({
                    ...userFinalFilter,
                    tokens: Object.values(inputs),
                    token: user.accessToken,
                  })
                );
              }
            }}
            disabled={isFilterCredit}
          >
            {isFilterCredit ? <Loader /> : "Send Tokens"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default CustomToken;

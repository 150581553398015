import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
const ProtectedRoute = ({ children }: any) => {
  const { user, userDetails } = useUserAuth();
  const location = useLocation();

  if (user) {
    if (userDetails?.name === undefined) {
      return (
        <div className="h-[100vh] flex justify-center items-center ">
          Fetching details
        </div>
      );
    }
  }
  if (!user && userDetails?.email === undefined) {
    return <Navigate to="/login" />;
  }

  if (
    (userDetails?.client_id?.is_onboarded === true ||
      userDetails?.role !== 1) &&
    location.pathname === "/onboarding"
  ) {
    return <Navigate to="/tokens" />;
  }

  if (
    userDetails?.client_id?.is_onboarded === false &&
    userDetails?.role === 1 &&
    location.pathname !== "/onboarding"
  ) {
    return <Navigate to="/onboarding" />;
  } else {
    return children;
  }
};
export default ProtectedRoute;

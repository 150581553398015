type RadioProps = {
  setSelected: (val: any) => void;
  each: any;
  multi: boolean;
  selected: any;
  white?: boolean;
};

const Radio = ({ setSelected, each, multi, selected, white }: RadioProps) => {
  return (
    <div>
      <div
        className={
          "cursor-pointer bg-[#FFE8E6] p-2 flex items-center gap-2 rounded-full" +
          (multi ? " bg-white" : "") +
          (white ? " bg-white" : "")
        }
        onClick={() => {
          setSelected(
            multi
              ? selected.includes(each)
                ? selected.filter((s: string) => s !== each)
                : Array.from(new Set([...selected, each]))
              : each
          );
        }}
      >
        {(multi === false && selected === each) ||
        (multi && selected.includes(each)) ? (
          <div className="h-[16px] w-[16px] rounded-full bg-[#3189B4] flex items-center justify-center">
            <div className="h-[7px] w-[7px] rounded-full bg-white"></div>
          </div>
        ) : (
          <div className="h-[16px] w-[16px] rounded-full border border-[#3189B4] bg-white"></div>
        )}
        <div className="text-[#5F5E76]">{each}</div>
      </div>
    </div>
  );
};
export default Radio;

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toggleCustomTokenModal } from "../../../../redux/user/customTokenActions";
import Text from "../../../gamification/modals/nudge/text";
import Loader from "../../../common/loader";
import { addCustomTokenValueUser } from "../../../../redux/user/userActions";

const UsersWhat = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState<Array<string>>([""]);
  const { isGettingTokens, getTokensResp, getTokensRespStatus } = useSelector(
    (state: any) => state.tokens
  );

  const { inputs } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (getTokensRespStatus === 200) {
      dispatch(
        addCustomTokenValueUser({
          e: inputs,
        })
      );
      setActive(
        Object.keys(inputs).filter(
          (each: any, i: number) =>
            (Object.values(inputs) as any)[i].selected === true
        )
      );
    }
  }, [isGettingTokens]);
  // console.log(active);
  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        dispatch(toggleCustomTokenModal(""));
      }}
    >
      <div className="flex justify-between w-[700px] px-5 pt-5 pb-5">
        <div>
          <div className="text-2xl text-[#4C4C66] font-bold">Select Tokens</div>
          <div className="text-[#3189B4] mt-3">
            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Praesentium, aperiam quis in explicabo a aliquid libero dicta
            repudiandae consequatur deleniti enim et, harum sapiente veritatis
            dolores? Similique minima mollitia enim? */}
          </div>
        </div>
        <div
          className="text-xl text-[#4C4C66] cursor-pointer"
          onClick={() => dispatch(toggleCustomTokenModal(""))}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
              fill="#4C4C66"
            />
          </svg>
        </div>
      </div>
      {isGettingTokens && (
        <div className="flex items-center justify-center">
          <Loader heavy={true} />
        </div>
      )}
      {getTokensRespStatus === 200 && (
        <div className="px-5 max-h-[500px] overflow-y-auto">
          <div className="mt-10 text-[#5F5E76] text-xl mb-5">
            Select rule(s):
          </div>
          <div className="mt-3 bg-[#FFE8E6] rounded w-full max-h-[150px] overflow-y-auto p-3 flex flex-col gap-3">
            {getTokensResp?.data?.value?.map((each: any, i: number) => {
              return (
                <div
                  className={
                    "rounded border-2 border-[#FFAFA8]  cursor-pointer p-3" +
                    (active.includes(each._id) ? " bg-white" : " bg-[#FFF2F2]")
                  }
                >
                  <div
                    className={"flex items-center gap-3"}
                    onClick={() => {
                      if (active.includes(each._id)) {
                        dispatch(
                          addCustomTokenValueUser({
                            id: each._id,
                            value: "",
                            selected: false,
                          })
                        );
                        setActive(
                          active.filter((element: any) => element !== each._id)
                        );
                      } else {
                        dispatch(
                          addCustomTokenValueUser({
                            id: each._id,
                            value: "",
                            selected: true,
                          })
                        );
                        setActive(Array.from(new Set([...active, each._id])));
                      }
                    }}
                  >
                    {active.includes(each._id) ? (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="#5F5E76"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                          fill="#FFAFA8"
                        />
                      </svg>
                    )}
                    <div className="flex items-center gap-3">
                      <img
                        src={each.image}
                        alt={each.image}
                        className="w-[30px]"
                      />
                      <div className="text-lg font-bold">{each.name}</div>
                    </div>
                  </div>
                  {active.includes(each._id) ? (
                    <div
                      className="mt-3"
                      onClick={(e: any) => e.stopPropagation()}
                    >
                      <div className="mt-1 text-[14px] text-[#4C4C66]">
                        Enter value
                      </div>
                      <Text
                        text={inputs[each._id]?.["value"]}
                        setText={(e: any) => {
                          dispatch(
                            addCustomTokenValueUser({
                              id: each._id,
                              value: e,
                              selected: true,
                            })
                          );
                        }}
                        place={"e.g 5"}
                        full={false}
                        required={true}
                        type={"number"}
                      />
                      <div className="mt-3 text-[14px] text-[#4C4C66]">
                        Note : Enter value of the transaction amount to be given
                        as token
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="flex items-center justify-end px-5 my-5">
        <button
          className="px-3 py-2 text-white text-xl font-bold bg-[#FF7B5F] cursor-pointer rounded"
          type={"submit"}
        >
          Continue
        </button>
      </div>
    </form>
  );
};
export default UsersWhat;

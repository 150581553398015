import { useState } from "react";
import Navbar from "../../components/common/navbar";
import SnipeShare from "./snipeShare/snipeShare";


const SnipeSocial = () => {
  const [active, setActive] = useState<string>("Snipe-share");
  return (
    <div className="w-full h-[100vh] overflow-y-auto text-[#4C4C66]">
      <div className="px-10">
        <Navbar />
        <div className="flex items-center gap-3 mt-10">
          <div
            className={
              "text-lg cursor-pointer" +
              (active === "Snipe-share"
                ? " border-b-2 border-[#4C4C66] font-bold"
                : "")
            }
            onClick={() => setActive("Snipe-share")}
          >
            Social Share
          </div>
        </div>
      </div>
      {active === "Snipe-share" && <SnipeShare />}
    </div>
  );
};

export default SnipeSocial;

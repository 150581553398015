import Business from "../../containers/business/business";
import Sidebar from "../../containers/sidebar/sidebar";

const BusinessIndex = () => {
  return (
    <div className="text-white  min-h-screen">
      <div className="flex gap-10">
        <Sidebar />
        <Business />
      </div>
    </div>
  );
};

export default BusinessIndex;

import {
  GET_ALL_ROLES_INIT,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  ADD_ROLE_INIT,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  UPDATE_ROLE_INIT,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  DELETE_ROLE_INIT,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  UPDATE_CLIENT_INIT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
} from "../actionTypes";

export const getAllRolesSuccess = (data: any) => {
  return {
    type: GET_ALL_ROLES_SUCCESS,
    data,
  };
};

export const getAllRolesFailure = (error: any) => {
  return {
    type: GET_ALL_ROLES_FAILURE,
    error,
  };
};

export const getAllRoles = (params: any) => {
  const payload = { params };
  return {
    type: GET_ALL_ROLES_INIT,
    payload,
  };
};

export const addRoleSuccess = (data: any) => {
  return {
    type: ADD_ROLE_SUCCESS,
    data,
  };
};

export const addRoleFailure = (error: any) => {
  return {
    type: ADD_ROLE_FAILURE,
    error,
  };
};

export const addRole = (params: any) => {
  const payload = { params };
  return {
    type: ADD_ROLE_INIT,
    payload,
  };
};

export const updateRoleSuccess = (data: any) => {
  return {
    type: UPDATE_ROLE_SUCCESS,
    data,
  };
};

export const updateRoleFailure = (error: any) => {
  return {
    type: UPDATE_ROLE_FAILURE,
    error,
  };
};

export const updateRole = (params: any) => {
  const payload = { params };
  return {
    type: UPDATE_ROLE_INIT,
    payload,
  };
};

export const deleteRoleSuccess = (data: any) => {
  return {
    type: DELETE_ROLE_SUCCESS,
    data,
  };
};

export const deleteRoleFailure = (error: any) => {
  return {
    type: DELETE_ROLE_FAILURE,
    error,
  };
};

export const deleteRole = (params: any) => {
  const payload = { params };
  return {
    type: DELETE_ROLE_INIT,
    payload,
  };
};

export const updateClientSuccess = (data: any) => {
  return {
    type: UPDATE_CLIENT_SUCCESS,
    data,
  };
};

export const updateClientFailure = (error: any) => {
  return {
    type: UPDATE_CLIENT_FAILURE,
    error,
  };
};

export const updateClient = (params: any) => {
  const payload = { params };
  return {
    type: UPDATE_CLIENT_INIT,
    payload,
  };
};

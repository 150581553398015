import { put, call, takeLatest } from "redux-saga/effects";
import {
    ADD_MILESTONE_INIT,
    DELETE_MILESTONE_INIT,
    GET_MILESTONE_INIT,
    UPDATE_MILESTONE_INIT,
} from "../actionTypes";
import {
    addMilestoneSuccess,
    addMilestoneFailure,
    deleteMilestoneSuccess,
    deleteMilestoneFailure,
    updateMilestoneSuccess,
    updateMilestoneFailure,
    getMilestoneSuccess,
    getMilestoneFailure,
} from "./milestoneActions";
import {
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
} from "../../utils/apiRequests";
import { endPoints } from "../../utils/apiEndPoints";
import store from "../store";

function* getMilestone(action: any): any {
    try {
        const data = yield call(
            getRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.getAllMilestones
            }`,
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(getMilestoneSuccess(data));
        } else {
            yield put(getMilestoneFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(getMilestoneFailure(err.message));
    }
}

export function* getMilestoneSaga() {
    yield takeLatest(GET_MILESTONE_INIT, getMilestone);
}

function* updateMilestone(action: any): any {
    try {
        const { token, _id, ...reqData } = action.payload.params;
        const data = yield call(
            patchRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.updateMilestone
            }/${_id}`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(updateMilestoneSuccess(data));
        } else {
            yield put(updateMilestoneFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(updateMilestoneFailure(err.message));
    }
}

export function* updateMilestoneSaga() {
    yield takeLatest(UPDATE_MILESTONE_INIT, updateMilestone);
}

function* addMilestone(action: any): any {
    try {
        const { token, ...reqData } = action.payload.params;
        const data = yield call(
            postRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.addMilestone
            }`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(addMilestoneSuccess(data));
        } else {
            yield put(addMilestoneFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(addMilestoneFailure(err.message));
    }
}

export function* addMilestoneSaga() {
    yield takeLatest(ADD_MILESTONE_INIT, addMilestone);
}

function* deleteMilestone(action: any): any {
    try {
        const { token, _id } = action.payload.params;
        console.log("action.payload.params", action.payload.params);
        const data = yield call(
            deleteRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.deleteMilestone
            }/${_id}`,
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        console.log("data", data);
        if (!data.error) {
            yield put(deleteMilestoneSuccess(data));
        } else {
            yield put(deleteMilestoneFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(deleteMilestoneFailure(err.message));
    }
}

export function* deleteMilestoneSaga() {
    yield takeLatest(DELETE_MILESTONE_INIT, deleteMilestone);
}

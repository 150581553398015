import Navbar from "../../components/common/navbar";
import restricted from "../../assets/svg/restricted.svg";
import { useUserAuth } from "../../hooks/useAuthContextProvider";

const Restricted = () => {
  const { user } = useUserAuth();
  return (
    <div className="block w-full">
      {user && (
        <div className="px-10">
          <Navbar />
        </div>
      )}
      <div
        className={
          "h-[calc(100vh-70px)] w-full flex items-center justify-center bg-[#F9F9F9]" +
          (user ? " h-screen" : "")
        }
      >
        <div className="w-[700px]">
          <img
            src={restricted}
            alt="restricted"
            className="h-[400px] w-[700px] object-contain"
          />
          <div className="text-lg text-center text-[#3189B4] mt-5">
            Looks like you found some bug! We’re on to fix it.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Restricted;

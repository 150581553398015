// import notificationb from "../../assets/svg/notificationb.svg";
import pfi from "../../assets/png/image 62.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
// import { useState } from "react";
import ToggleSwitch from "../deeveloperTools/changeEnv";

const Navbar = () => {
    const location = useLocation();
    const { userDetails } = useUserAuth();
    const navigate = useNavigate();
    return (
        <div className="flex items-center justify-between">
            <div className="text-[42px] text-[#4C4C66] font-bold">
                {location.pathname === "/"
                    ? "Home"
                    : location.pathname === "/analytics"
                    ? "Analytics"
                    : location.pathname === "/tokens"
                    ? "Token Management System"
                    : location.pathname === "/gamification"
                    ? "Gamification"
                    : location.pathname === "/club-console"
                    ? "CLUB Console"
                    : location.pathname === "/developer-tools"
                    ? "DEV tools"
                    : location.pathname === "/business"
                    ? "Business"
                    : location.pathname === "/settings"
                    ? "Settings"
                    : location.pathname === "/support"
                    ? "Support"
                    : location.pathname === "/users"
                    ? "Users"
                    : location.pathname === "/social"
                    ? "Snipe Social"
                    : "Snipe Dashboard"}
            </div>
            <div className="flex items-center gap-5">
                {/* <div className="flex items-center gap-3 gradient-button">
          <img src={notificationb} alt="notificationb" className="w-[12px]" />
          <div>15 new notification</div>
        </div> */}
                {location.pathname !== "/settings" && <ToggleSwitch />}
                <div
                    className="flex items-center gap-3 relative cursor-pointer"
                    onClick={() => navigate("/settings")}
                >
                    <img
                        src={pfi}
                        alt="pfi"
                        className="rounded-full border-2 border-[#FF409A] w-[34px] h-[34px] object-cover"
                    />
                    <div className="text-[#4C4C66] text-md font-bold cursor-pointer">
                        {userDetails?.name}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Navbar;



import {
    ADD_REFERRAL_INIT,
    ADD_REFERRAL_SUCCESS,
    ADD_REFERRAL_FAILURE,
    GET_REFERRAL_INIT,
    GET_REFERRAL_SUCCESS,
    GET_REFERRAL_FAILURE,
    UPDATE_REFERRAL_INIT,
    UPDATE_REFERRAL_SUCCESS,
    UPDATE_REFERRAL_FAILURE,
    DELETE_REFERRAL_INIT,
    DELETE_REFERRAL_SUCCESS,
    DELETE_REFERRAL_FAILURE,

  } from "../actionTypes";
  
  export const addReferralSuccess = (data: any) => {
    return {
      type: ADD_REFERRAL_SUCCESS,
      data,
    };
  };
  
  export const addReferralFailure = (error: any) => {
    return {
      type: ADD_REFERRAL_FAILURE,
      error,
    };
  };
  
  export const addReferral = (params: any) => {
    const payload = { params };
    return {
      type: ADD_REFERRAL_INIT,
      payload,
    };
  };
  
  export const getReferralsSuccess = (data: any) => {
    return {
      type: GET_REFERRAL_SUCCESS,
      data,
    };
  };
  
  export const getReferralsFailure = (error: any) => {
    return {
      type: GET_REFERRAL_FAILURE,
      error,
    };
  };
  
  export const getReferrals = (params: any) => {
    const payload = { params };
    return {
      type: GET_REFERRAL_INIT,
      payload,
    };
  };
  export const updateReferralSuccess = (data: any) => {
  return {
    type: UPDATE_REFERRAL_SUCCESS,
    data,
  };
};

export const updateReferralFailure = (error: any) => {
  return {
    type: UPDATE_REFERRAL_FAILURE,
    error,
  };
};

export const updateReferral = (params: any) => {
  const payload = { params };
  return {
    type: UPDATE_REFERRAL_INIT,
    payload,
  };
};

// Delete Referral Actions
export const deleteReferralSuccess = (data: any) => {
  return {
    type: DELETE_REFERRAL_SUCCESS,
    data,
  };
};

export const deleteReferralFailure = (error: any) => {
  return {
    type: DELETE_REFERRAL_FAILURE,
    error,
  };
};

export const deleteReferral = (params: any) => {
  const payload = { params };
  return {
    type: DELETE_REFERRAL_INIT,
    payload,
  };
};
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import pfi from "../../assets/png/image 62.png";
import copyIcon from "../../assets/svg/copy.svg";
import searchIcon from "../../assets/svg/search.svg";
import Loader from "../../components/common/loader";
import ToggleSwitch from "../../components/deeveloperTools/changeEnv";
import Text from "../../components/gamification/modals/nudge/text";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { deleteEvent, getEvents } from "../../redux/token/eventActions";
import AddEvents from "./addEvents";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: "0%",
        border: "none",
    },
    overlay: {
        zIndex: "1000",
        background: "rgba(1, 1, 1, 0.1)",
        backdropFilter: "blur(7px)",
    },
};

const EventContainer = () => {
    const { user } = useUserAuth();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [addingEvent, setAddingEvent] = useState(false);
    const [active, setActive] = useState<string>("manage-events");
    const [search, setSearch] = useState<string>("");
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [editData, setEditData] = useState<any>(null);

    const {
        isGettignEvents,
        getEventsResp,
        getEventsRespStatus,
        getEventsError,
        getEventsErrorMsg,
        isDeletingEvent,
        deleteEventRespStatus,
        deleteEventError,
    } = useSelector((state: any) => state.events);

    const handleDeleteEvent = (id: string) => {
        dispatch(deleteEvent({ token: user.accessToken, event_id: id }));
    };

    useEffect(() => {
        dispatch(getEvents({ token: user.accessToken }));
    }, []);

    useEffect(() => {
        if (deleteEventRespStatus === 200) {
            setDeleteModalVisible(false);
            dispatch(getEvents({ token: user.accessToken }));
        }
    }, [deleteEventRespStatus]);

    return (
        <div className="bg-[#F9F9F9] px-10 overflow-y-auto w-full">
            <div className="flex items-center justify-between bg-[#ffffff] w-full left-0 px-10">
                <div className="text-[42px] text-[#4C4C66] font-bold">
                    {addingEvent ? "Add Event" : "Events"}
                </div>
                <div className="flex items-center gap-5">
                    {location.pathname !== "/settings" && <ToggleSwitch />}
                    <div
                        className="flex items-center gap-3 relative cursor-pointer"
                        onClick={() => navigate("/settings")}
                    >
                        <img
                            src={pfi}
                            alt="pfi"
                            className="rounded-full border-2 border-[#FF409A] w-[34px] h-[34px] object-cover"
                        />
                        <div className="text-[#4C4C66] text-md font-bold cursor-pointer">
                            {user?.name}
                        </div>
                    </div>
                </div>
            </div>
            {addingEvent ? (
                <AddEvents
                    closeAddEvents={() => {
                        setAddingEvent(false);
                        setEditData(null);
                        dispatch(getEvents({ token: user.accessToken }));
                    }}
                    data={editData ? editData : null}
                />
            ) : (
                <div className="overflow-y-auto w-full gap-5 mt-10">
                    <div className="flex items-center gap-5">
                        <div
                            className={
                                "text-lg cursor-pointer text-[#4C4C66] w-fit" +
                                (active === "view-analytics"
                                    ? " border-b-2 border-[#4C4C66] font-bold"
                                    : "")
                            }
                            onClick={() => {
                                setActive("view-analytics");
                            }}
                        >
                            View Analytics
                        </div>
                        <div
                            className={
                                "text-lg cursor-pointer text-[#4C4C66] w-fit" +
                                (active === "manage-events"
                                    ? " border-b-2 border-[#4C4C66] font-bold"
                                    : "")
                            }
                            onClick={() => {
                                setActive("manage-events");
                            }}
                        >
                            Manage Events
                        </div>
                    </div>

                    {active === "manage-events" && (
                        <>
                            <div className="mt-3">
                                <span className="font-bold font-DMSans text-[16px] text-[#4C4C66]">
                                    List of Events
                                </span>
                            </div>
                            <div className="bg-[#ffffff] rounded-lg p-4 border-[2px] mt-1">
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex flex-row items-center gap-3 w-[30%] text-[#4C4C66]">
                                        <Text
                                            text={search}
                                            setText={setSearch}
                                            place={"- search in Events -"}
                                            full={true}
                                            type={"text"}
                                            required={false}
                                            icon={searchIcon}
                                        />
                                    </div>
                                    <button
                                        className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                                        onClick={() => {
                                            setAddingEvent(true);
                                        }}
                                    >
                                        Add a New Event +
                                    </button>
                                </div>
                                {/* {isGettingContest && (
                                    <div className="flex items-center justify-center mt-7">
                                        <Loader heavy={true} />
                                    </div>
                                )}
                                {getContestError && (
                                    <div className="flex items-center justify-center mt-7 text-[#4C4C66]">
                                        {getContestErrorMsg}
                                    </div>
                                )} */}
                                <div className="mt-2 w-full text-[#4C4C66] rounded-lg overflow-auto">
                                    {isGettignEvents || isDeletingEvent ? (
                                        <div className="flex items-center justify-center mt-7">
                                            <Loader heavy={true} />
                                        </div>
                                    ) : null}
                                    {getEventsRespStatus === 200 && (
                                        <table className="w-full text-sm border-collapse border-2 rounded-lg">
                                            <thead>
                                                <tr className="bg-[#f9f9f9]">
                                                    <th className="font-semibold text-[16px] p-2 text-Grey-400 w-[200px]">
                                                        Event Name
                                                    </th>
                                                    <th className="font-semibold text-[16px] p-2 text-Grey-400 text-center w-[260px]">
                                                        Event Description
                                                    </th>
                                                    <th className="font-semibold text-[16px] p-2 text-Grey-400">
                                                        Event ID
                                                    </th>
                                                    <th className="font-semibold text-[16px] p-2 text-Grey-400">
                                                        Created On
                                                    </th>
                                                    <th className="font-semibold text-[16px] p-2 text-Grey-400">
                                                        Updated 0n
                                                    </th>
                                                    <th className="font-semibold text-[16px] p-2 text-Grey-400">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getEventsRespStatus === 200 &&
                                                    getEventsResp.data?.value
                                                        .filter((item: any) => {
                                                            return item.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    search.toLowerCase()
                                                                );
                                                        })
                                                        .map(
                                                            (
                                                                event: any,
                                                                index: number
                                                            ) => (
                                                                <tr
                                                                    key={
                                                                        "event-" +
                                                                        index
                                                                    }
                                                                    className={
                                                                        index %
                                                                            2 ===
                                                                        0
                                                                            ? "bg-[#ffffff]"
                                                                            : "bg-[#f9f9f9]"
                                                                    }
                                                                >
                                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                                        {
                                                                            event.name
                                                                        }
                                                                    </td>

                                                                    <td className="p-2 text-[14px] font-semibold text-center">
                                                                        {
                                                                            event.description
                                                                        }
                                                                    </td>
                                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                                        <div className="flex flex-row gap-x-2 items-center justify-center">
                                                                            {
                                                                                event._id
                                                                            }
                                                                            <img
                                                                                src={
                                                                                    copyIcon
                                                                                }
                                                                                alt="copy"
                                                                                className="w-[30px] h-[30px] cursor-pointer"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        event._id
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                                        20 Jan
                                                                        2023
                                                                    </td>
                                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                                        20 Jan
                                                                        2023
                                                                    </td>
                                                                    <td className="p-2 text-center text-[15px] font-semibold">
                                                                        <div className="flex flex-row gap-1 items-center flex-wrap">
                                                                            <button
                                                                                className="bg-[#FF7B5F] text-white rounded-lg px-2 py-1"
                                                                                onClick={() => {
                                                                                    setEditData(
                                                                                        event
                                                                                    );
                                                                                    setAddingEvent(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </button>
                                                                            <button
                                                                                className="bg-[#FF7B5F] text-white rounded-lg px-2 py-1"
                                                                                onClick={() => {
                                                                                    setDeleteModalVisible(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                    <Modal
                                                                        style={
                                                                            customStyles
                                                                        }
                                                                        isOpen={
                                                                            deleteModalVisible
                                                                        }
                                                                        ariaHideApp={
                                                                            false
                                                                        }
                                                                    >
                                                                        <div className="p-4 bg-[#F9F9F9]">
                                                                            <h2 className="text-xl font-semibold mb-4">
                                                                                Confirm
                                                                                Delete
                                                                            </h2>
                                                                            <p>
                                                                                Are
                                                                                you
                                                                                sure
                                                                                you
                                                                                want
                                                                                to
                                                                                Delete
                                                                                Event?
                                                                            </p>
                                                                            <div className="mt-6 space-x-4 w-full flex justify-end">
                                                                                <button
                                                                                    onClick={() => {
                                                                                        setDeleteModalVisible(
                                                                                            false
                                                                                        );
                                                                                    }}
                                                                                    className="px-4 py-2 rounded text-black border border-black"
                                                                                >
                                                                                    No
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        handleDeleteEvent(
                                                                                            event._id
                                                                                        );
                                                                                    }}
                                                                                    className="bg-[#FF7B5F] text-white px-4 py-2 rounded"
                                                                                >
                                                                                    {isDeletingEvent ? (
                                                                                        <Loader
                                                                                            heavy={
                                                                                                true
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        "Yes"
                                                                                    )}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Modal>
                                                                </tr>
                                                            )
                                                        )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>

                                {/* {getContestRespStatus === 200 &&
                                    getContestResp.data?.value.length > 0 && (
                                    )} */}
                            </div>
                        </>
                    )}
                    {active === "view-analytics" && <></>}
                </div>
            )}
        </div>
    );
};

export default EventContainer;

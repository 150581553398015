import { useDispatch } from "react-redux";
import { useState } from "react";
import { toggleCustomTokenModal } from "../../../../redux/user/customTokenActions";
import Text from "../../../gamification/modals/nudge/text";
import { addCustomTokenIndividualUser } from "../../../../redux/user/userActions";

const UsersIndividualWith = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  // const [stringType, setStringType] = useState<string>("");
  // const [stringVal, setStringVal] = useState<string>("");
  // const [numberType, setNumberType] = useState<string>("");
  // const [numberVal, setNumberVal] = useState<Array<number>>([]);
  // const [numberLowerVal, setNumberLowerVal] = useState<number>(0);
  // const [numberUpperVal, setNumberUpperVal] = useState<number>(0);
  // const [dateType, setDateType] = useState<string>("");
  // const [dateVal, setDateVal] = useState<Array<any>>([null]);
  // const [dateLowerVal, setDateLowerVal] = useState<any>(null);
  // const [dateUpperVal, setDateUpperVal] = useState<any>(null);
  // const [booleanVal, setBooleanVal] = useState<string>("False");

  const handleChange = (e: any) => {
    e.preventDefault();
    if (search.length !== 24) {
      alert("Incorrect Id");
      return;
    }
    dispatch(addCustomTokenIndividualUser({ search: search }));
    dispatch(toggleCustomTokenModal(""));
  };
  // console.log(active);
  return (
    <div className="w-full">
      <div className="flex justify-between w-[700px] px-5 pt-5 pb-5">
        <div>
          <div className="text-2xl text-[#4C4C66] font-bold">User filter</div>
          <div className="text-[#3189B4] mt-3">Enter Snipe User ID</div>
        </div>
        <div
          className="text-xl text-[#4C4C66] cursor-pointer"
          onClick={() => dispatch(toggleCustomTokenModal(""))}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
              fill="#4C4C66"
            />
          </svg>
        </div>
      </div>
      <div className="mt-5 w-full px-5">
        <div className="text-sm text-[#4C4C66]">Add user id</div>
        <form
          className="flex items-center gap-3 w-full mb-5"
          onSubmit={handleChange}
        >
          <Text
            text={search}
            setText={setSearch}
            place={"- eg : sdfb28374rb2389 -"}
            full={true}
            required={true}
          />
          <button
            className="bg-[#FF7b5F] rounded text-white font-bold shadow-lg px-5 py-2 border-none"
            type={"submit"}
          >
            {"Add"}
          </button>
        </form>
      </div>
    </div>
  );
};
export default UsersIndividualWith;

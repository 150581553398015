import { put, call, takeLatest } from "redux-saga/effects";
import {
  ADD_API_INIT,
  UPDATE_API_INIT,
  GET_API_INIT,
  DELETE_API_INIT,
} from "../actionTypes";
import {
  addApiSuccess,
  addApiFailure,
  updateApiSuccess,
  updateApiFailure,
  getApiSuccess,
  getApiFailure,
  deleteApiSuccess,
  deleteApiFailure,
} from "./apiActions";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../utils/apiRequests";
import { endPoints } from "../../utils/apiEndPoints";
import store from "../store";

function* addApi(action: any): any {
  try {
    const data = yield call(
      postRequest,
      `${(store.getState().developerTools as any).url}/${endPoints.api}`,
      { type: action.payload.params.type },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(addApiSuccess(data));
    } else {
      yield put(addApiFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(addApiFailure(err.message));
  }
}

export function* addApiSaga() {
  yield takeLatest(ADD_API_INIT, addApi);
}

function* updateApi(action: any): any {
  try {
    const { token, ...reqData } = action.payload.params;
    const data = yield call(
      patchRequest,
      `${(store.getState().developerTools as any).url}/${endPoints.updateApi}`,
      { ...reqData },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(updateApiSuccess(data));
    } else {
      yield put(updateApiFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(updateApiFailure(err.message));
  }
}

export function* updateApiSaga() {
  yield takeLatest(UPDATE_API_INIT, updateApi);
}

function* getApi(action: any): any {
  try {
    const data = yield call(
      getRequest,
      `${(store.getState().developerTools as any).url}/${endPoints.readApi}`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(getApiSuccess(data));
    } else {
      yield put(getApiFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(getApiFailure(err.message));
  }
}

export function* getApiSaga() {
  yield takeLatest(GET_API_INIT, getApi);
}

function* deleteApi(action: any): any {
  try {
    const data = yield call(
      deleteRequest,
      `${(store.getState().developerTools as any).url}/${
        endPoints.deleteApi
      }?type=${action.payload.params.apiId}`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(deleteApiSuccess(data));
    } else {
      yield put(deleteApiFailure(data.message));
    }
  } catch (err: any) {
    yield put(deleteApiFailure(err.message));
  }
}

export function* deleteApiSaga() {
  yield takeLatest(DELETE_API_INIT, deleteApi);
}

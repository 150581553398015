import { call, put, takeLatest } from "redux-saga/effects";
import { endPoints } from "../../utils/apiEndPoints";
import {
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
} from "../../utils/apiRequests";
import {
    ADD_ATTRIBUTE_INIT,
    DELETE_ATTRIBUTE_INIT,
    GET_ATTRIBUTE_INIT,
    UPDATE_ATTRIBUTE_INIT,
} from "../actionTypes";
import store from "../store";
import {
    addAttributeFailure,
    addAttributeSuccess,
    deleteAttributeFailure,
    deleteAttributeSuccess,
    getAttributeFailure,
    getAttributeSuccess,
    updateAttributeFailure,
    updateAttributeSuccess,
} from "./attributeAction";

function* getAttribute(action: any): any {
    try {
        const data = yield call(
            getRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.getAllAttributes
            }/${action.payload.params.event_id}`,
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(getAttributeSuccess(data));
        } else {
            yield put(getAttributeFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(getAttributeFailure(err.message));
    }
}

export function* getAttributeSaga() {
    yield takeLatest(GET_ATTRIBUTE_INIT, getAttribute);
}

function* updateAttribute(action: any): any {
    try {
        const { token, _id, ...reqData } = action.payload.params;
        const data = yield call(
            patchRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.updateAttribute
            }/${_id}`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(updateAttributeSuccess(data));
        } else {
            yield put(updateAttributeFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(updateAttributeFailure(err.message));
    }
}

export function* updateContestSaga() {
    yield takeLatest(UPDATE_ATTRIBUTE_INIT, updateAttribute);
}

function* addAttribute(action: any): any {
    try {
        const { token, ...reqData } = action.payload.params;
        const data = yield call(
            postRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.addAttribute
            }`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(addAttributeSuccess(data));
        } else {
            yield put(addAttributeFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(addAttributeFailure(err.message));
    }
}

export function* addAttributeSaga() {
    yield takeLatest(ADD_ATTRIBUTE_INIT, addAttribute);
}

function* deleteAttribute(action: any): any {
    try {
        const { _id } = action.payload.params;
        const data = yield call(
            deleteRequest,
            `${(store.getState().developerTools as any).url}/${
                endPoints.deleteAttribute
            }/${_id}`,
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(deleteAttributeSuccess(data));
        } else {
            yield put(deleteAttributeFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(deleteAttributeFailure(err.message));
    }
}

export function* deleteContestSaga() {
    yield takeLatest(DELETE_ATTRIBUTE_INIT, deleteAttribute);
}

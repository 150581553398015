import { useRef, useState } from "react";
import Navbar from "../../components/common/navbar";
// import Campaign from "./campaign";
import Streaks from "./streaks";

const Gamification = () => {
  const [active, setActive] = useState<string>("immersive-gamification");
  const ref: any = useRef();
  return (
    <div
      className="w-full h-[100vh] overflow-y-auto text-[#4C4C66]"
      id="immersive-gamification"
      ref={ref}
    >
      <div className="px-10">
        <Navbar />
      </div>
      <div className="flex items-center gap-5 mt-10 px-10">
        {/* <div
          className={
            "text-lg cursor-pointer" +
            (active === "gamification-nudges"
              ? " border-b-2 border-[#4C4C66] font-bold"
              : "")
          }
          onClick={() => setActive("gamification-nudges")}
        >
          Gamification Nudges
        </div> */}
        <div
          className={
            "text-lg cursor-pointer" +
            (active === "immersive-gamification"
              ? " border-b-2 border-[#4C4C66] font-bold"
              : "")
          }
          onClick={() => {
            setActive("immersive-gamification");
          }}
        >
          Immersive Gamification
        </div>
      </div>
      {/* {active === "gamification-nudges" && <Campaign />} */}
      {active === "immersive-gamification" && <Streaks ref={ref} />}
    </div>
  );
};

export default Gamification;

// {
//   brands: {
//     type: "exclude/include",
//     items: [],
//   },
//   categories: {
//     type: "exclude/include",
//     items: []
//   },
//   id: any,
//   type: number
// }

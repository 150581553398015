import arrow from "../../assets/svg/arrow.svg";
type Props = {
    isOpen: boolean;
    onToggle: () => void;
    title: string;
    children: React.ReactNode;
    icon?: React.ReactNode;
    classname?: string;
};

const Accordion = (props: Props) => {
    return (
        <div
            className={
                "rounded-lg w-full p-4 bg-[#ffffff] border-[2px] " +
                    props.classname || ""
            }
        >
            <div
                className="flex items-center justify-between cursor-pointer"
                onClick={props.onToggle}
            >
                <div className="flex items-center gap-x-1">
                    {props.icon}
                    <div className="text-[#4C4C66] text-lg font-bold">
                        {props.title}
                    </div>
                </div>
                <div
                    className={
                        "transition-all duration-700 transform" +
                        (props.isOpen ? " rotate-180" : "rotate-0")
                    }
                >
                    <img
                        src={arrow}
                        alt="arrow"
                        className="w-[15px] h-[15px]"
                    />
                </div>
            </div>
            {props.isOpen ? (
                <div className="overflow-hidden transition-all  duration-700">
                    {props.children}
                </div>
            ) : null}
        </div>
    );
};

export default Accordion;

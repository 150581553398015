import {
  TOGGLE_CUSTOM_TOKEN_MODAL,
  ADD_CAMPAIGN,
  ADD_NUDGE,
  DELETE_NUDGE,
  ADD_USER_FILTER,
  ADD_WHEN,
  ADD_ACTIVITY,
} from "../actionTypes";

export const toggleCustomTokenModal = (params: any) => {
  return {
    type: TOGGLE_CUSTOM_TOKEN_MODAL,
    data: params,
  };
};
export const addCampaign = (params: any) => {
  return {
    type: ADD_CAMPAIGN,
    data: params,
  };
};
export const addNudge = (params: any) => {
  return {
    type: ADD_NUDGE,
    data: params,
  };
};
export const deleteNudge = (params: any) => {
  return {
    type: DELETE_NUDGE,
    data: params,
  };
};
export const addUserFilter = (params: any) => {
  return {
    type: ADD_USER_FILTER,
    data: params,
  };
};
export const addWhen = (params: any) => {
  return {
    type: ADD_WHEN,
    data: params,
  };
};
export const addActivity = (params: any) => {
  return {
    type: ADD_ACTIVITY,
    data: params,
  };
};

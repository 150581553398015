import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import tickIcon from "../../assets/png/image 64.png";
import deleteIcon from "../../assets/svg/cancel.svg";
import optionIcon from "../../assets/svg/options.svg";
import searchIcon from "../../assets/svg/search.svg";
import Accordion from "../../components/common/accordion";
import Loader from "../../components/common/loader";
import Text from "../../components/gamification/modals/nudge/text";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import {
    addAttribute,
    deleteAttribute,
    getAttribute,
    updateAttribute,
} from "../../redux/attribute/attributeAction";
import { addEvent, updateEvent } from "../../redux/token/eventActions";

type AddEventsProps = {
    closeAddEvents: () => void;
    data?: any;
};

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: "0%",
        border: "none",
    },
    overlay: {
        zIndex: "1000",
        background: "rgba(1, 1, 1, 0.1)",
        backdropFilter: "blur(7px)",
    },
};

const AddEvents = (props: AddEventsProps) => {
    const { user } = useUserAuth();
    const dispatch = useDispatch();
    const [activeAccordion, setActiveAccordion] = useState<number>(1);
    const [eventName, setEventName] = useState<string>("");
    const [eventAlias, setEventAlias] = useState<string>("");
    const [eventDescription, setEventDescription] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [attributeDropdownIndex, setAttributeDropdownIndex] =
        useState<number>(-1);
    const [editAttributeIndex, setEditAttributeIndex] = useState<number>(-1);
    const [deleteModalVisible, setDeleteModalVisible] =
        useState<boolean>(false);
    const [attributeData, setAttributeData] = useState<any>([]);
    const [attributeName, setAttributeName] = useState<string>("");
    const [attributeAlias, setAttributeAlias] = useState<string>("");
    const [attributeDescription, setAttributeDescription] =
        useState<string>("");
    const [addingAttribute, setAddingAttribute] = useState(false);
    const [eventId, setEventId] = useState<string>("");

    useEffect(() => {
        if (props.data) {
            setEventId(props.data._id);
            setEventName(props.data.name);
            setEventAlias(props.data.alias);
            setEventDescription(props.data.description);
            dispatch(
                getAttribute({
                    token: user.accessToken,
                    event_id: props.data._id,
                })
            );
        }
    }, [props.data]);

    const {
        isGettignEvents,
        getEventsResp,
        getEventsRespStatus,
        getEventsError,
        getEventsErrorMsg,
        isAddingEvent,
        addEventResp,
        addEventRespStatus,
        addEventError,
        isUpdatingEvent,
        updateEventResp,
        updateEventRespStatus,
        updateEventError,
        isDeletingEvent,
        deleteEventRespStatus,
        deleteEventError,
    } = useSelector((state: any) => state.events);

    useEffect(() => {
        if (addEventRespStatus === 200 || updateEventRespStatus === 200) {
            if (!props.data) {
                setEventId(addEventResp.data.value._id);
            }
        } else if (addEventRespStatus >= 400 || updateEventRespStatus >= 400) {
            alert("Something went wrong");
        }
    }, [addEventRespStatus, updateEventRespStatus]);

    const {
        isGettingAttributes,
        getAttributesResp,
        getAttributesRespStatus,
        getAttributesError,
        getAttributesErrorMsg,
        isUpdatingAttribute,
        updateAttributeError,
        updateAttributeErrorMsg,
        updateAttributeResp,
        updateAttributeRespStatus,
        isAddingAttribute,
        addAttributeError,
        addAttributeErrorMsg,
        addAttributeResp,
        addAttributeRespStatus,
        isDeletingAttribute,
        deleteAttributeError,
        deleteAttributeErrorMsg,
        deleteAttributeResp,
        deleteAttributeRespStatus,
    } = useSelector((state: any) => state.attribute);

    useEffect(() => {
        if (eventId !== "") {
            dispatch(
                getAttribute({ token: user.accessToken, event_id: eventId })
            );
        }
    }, [eventId]);

    // useEffect(() => {
    //     if (addAttributeRespStatus === 200) {
    //         console.log("addAttributeResp", addAttributeResp);
    //     }
    // }, [addAttributeRespStatus]);

    const reset = () => {
        setDeleteModalVisible(false);
        setAddingAttribute(false);
        setEditAttributeIndex(-1);
        setAttributeDropdownIndex(-1);
        setAttributeName("");
        setAttributeAlias("");
        setAttributeDescription("");
    };

    useEffect(() => {
        if (
            addAttributeRespStatus === 200 ||
            deleteAttributeRespStatus === 200 ||
            updateAttributeRespStatus === 200
        ) {
            reset();
            dispatch(
                getAttribute({ token: user.accessToken, event_id: eventId })
            );
        }
    }, [
        addAttributeRespStatus,
        deleteAttributeRespStatus,
        updateAttributeRespStatus,
    ]);

    const deleteAttributeHandler = (id: string) => {
        dispatch(deleteAttribute({ token: user.accessToken, _id: id }));
    };

    useEffect(() => {
        if (getAttributesRespStatus === 200) {
            setAttributeData(getAttributesResp.data.value);
        }
    }, [getAttributesResp, getAttributesRespStatus]);

    const handleSaveEditAttribute = () => {
        let reqData = {
            code: attributeName,
            alias: attributeAlias,
            description: attributeDescription,
        };
        if (editAttributeIndex === -1) {
            dispatch(
                addAttribute({
                    token: user.accessToken,
                    event_id: eventId,
                    ...reqData,
                })
            );
        } else {
            let { created_at, updated_at, __v, client_id, ...data } =
                attributeData[editAttributeIndex];
            dispatch(
                updateAttribute({
                    token: user.accessToken,
                    ...data,
                    ...reqData,
                })
            );
        }
        setAddingAttribute(false);
    };

    const handleSaveEvent = () => {
        let data = {
            name: eventName,
            // alias: eventAlias,
            description: eventDescription,
        };
        if (props.data || eventId != "") {
            dispatch(
                updateEvent({
                    token: user.accessToken,
                    event_id: props.data.event_id || eventId,
                    ...data,
                })
            );
        } else {
            dispatch(
                addEvent({
                    token: user.accessToken,
                    ...data,
                })
            );
        }
        setActiveAccordion(2);
    };

    return (
        <div className="overflow-y-auto w-full gap-5 mt-10">
            <div className="flex items-center justify-between">
                <div className="text-xl">
                    <span className="text-[#4C4C66]">Add / Modify Events</span>
                </div>
            </div>
            <div className="flex flex-col w-full gap-y-2 mt-4 mb-4">
                <Accordion
                    isOpen={activeAccordion === 1}
                    onToggle={() => {
                        if (activeAccordion === 1) {
                            setActiveAccordion(-1);
                        } else {
                            setActiveAccordion(1);
                        }
                    }}
                    title="Basic Details"
                >
                    <div className="w-full text-[#4C4C66]">
                        <div className="flex flex-row w-full items-center justify-between mt-1 rounded-md">
                            <div className="w-full mt-2">
                                <span className="font-[500] font-DMSans text-[12px]">
                                    <div
                                        className={`flex flex-col w-[45%] gap-y-1`}
                                    >
                                        Event Name*
                                    </div>
                                </span>
                                <Text
                                    text={eventName}
                                    setText={setEventName}
                                    place={"- eg: coin of fortune -"}
                                    full={true}
                                    required={true}
                                />
                            </div>
                            {/* <div className={`flex flex-col w-[48%] gap-y-1`}>
                            </div>
                            <div className={`flex flex-col w-[48%] gap-y-1`}>
                                <span className="font-[500] font-DMSans text-[12px]">
                                    <div
                                        className={`flex flex-col w-[45%] gap-y-1`}
                                    >
                                        Event Alias*
                                    </div>
                                </span>
                                <Text
                                    text={eventAlias}
                                    setText={setEventAlias}
                                    place={"- eg: coin of fortune -"}
                                    full={true}
                                    required={true}
                                />
                            </div> */}
                        </div>
                        <div className="w-full mt-2">
                            <span className="font-[500] font-DMSans text-[12px]">
                                <div
                                    className={`flex flex-col w-[45%] gap-y-1`}
                                >
                                    Event Description*
                                </div>
                            </span>
                            <Text
                                text={eventDescription}
                                setText={setEventDescription}
                                place={"- eg: coin of fortune -"}
                                full={true}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-end gap-3 mt-2">
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={props.closeAddEvents}
                        >
                            Cancel Changes
                        </button>
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={handleSaveEvent}
                        >
                            {isAddingEvent || isUpdatingEvent ? (
                                <Loader heavy={true} />
                            ) : (
                                "Save & Next"
                            )}
                        </button>
                    </div>
                </Accordion>

                <Accordion
                    isOpen={activeAccordion === 2}
                    onToggle={() => {
                        if (activeAccordion === 2) {
                            setActiveAccordion(-1);
                        } else {
                            setActiveAccordion(2);
                        }
                    }}
                    title="Attributes"
                >
                    <div className="w-full text-[#4C4C66]">
                        <div className="flex items-center justify-between w-full mt-1">
                            <div className="flex flex-row items-center gap-3 w-[30%]">
                                <Text
                                    text={search}
                                    setText={setSearch}
                                    place={"- search in Attributes -"}
                                    full={true}
                                    type={"text"}
                                    required={false}
                                    icon={searchIcon}
                                />
                            </div>
                            <button
                                className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                                onClick={() => {
                                    setAddingAttribute(true);
                                    setAttributeData([
                                        ...attributeData,
                                        {
                                            code: "",
                                            alias: "",
                                            description: "",
                                        },
                                    ]);
                                }}
                                disabled={addingAttribute}
                            >
                                Add a new Attribute +
                            </button>
                        </div>
                        {isGettingAttributes && (
                            <div className="flex items-center justify-center mt-7">
                                <Loader heavy={true} />
                            </div>
                        )}
                        {getAttributesRespStatus === 200 &&
                        attributeData.length !== 0 ? (
                            <div className="mt-2 w-full text-[#4C4C66] rounded-lg overflow-auto">
                                <table className="w-full text-sm border-collapse border-2 rounded-lg">
                                    <thead>
                                        <tr className="bg-[#f9f9f9]">
                                            <th className="font-semibold text-[16px] p-2 text-Grey-400 text-start">
                                                Attribute Name
                                            </th>
                                            <th className="font-semibold text-[16px] p-2 text-Grey-400 text-start">
                                                Attribute Alias
                                            </th>
                                            <th className="font-semibold text-[16px] p-2 text-Grey-400 text-start w-[280px]">
                                                Attribute Description
                                            </th>
                                            <th className="font-semibold text-[16px] p-2 text-Grey-400 text-center">
                                                Created On
                                            </th>
                                            <th className="font-semibold text-[16px] p-2 text-Grey-400 text-center">
                                                Updated 0n
                                            </th>
                                            <th className="font-semibold text-[16px] p-2 text-Grey-400 text-center">
                                                Options
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attributeData
                                            .filter((item: any) => {
                                                return item?.code
                                                    .toLowerCase()
                                                    .includes(
                                                        search.toLowerCase()
                                                    );
                                            })
                                            .map((item: any, index: number) => (
                                                <tr
                                                    key={"attribute-" + index}
                                                    className={
                                                        index % 2 === 0
                                                            ? "bg-[#ffffff]"
                                                            : "bg-[#f9f9f9]"
                                                    }
                                                >
                                                    <td className="p-2 text-[14px] font-semibold">
                                                        {editAttributeIndex ===
                                                            index ||
                                                        (addingAttribute &&
                                                            index ===
                                                                attributeData.length -
                                                                    1) ? (
                                                            <Text
                                                                text={
                                                                    attributeName
                                                                }
                                                                setText={
                                                                    setAttributeName
                                                                }
                                                                place={
                                                                    "- eg: coin of fortune -"
                                                                }
                                                                full={true}
                                                                required={true}
                                                            />
                                                        ) : (
                                                            <>{item.code}</>
                                                        )}
                                                    </td>
                                                    <td className="p-2 text-[14px] font-semibold">
                                                        {editAttributeIndex ===
                                                            index ||
                                                        (addingAttribute &&
                                                            index ===
                                                                attributeData.length -
                                                                    1) ? (
                                                            <Text
                                                                text={
                                                                    attributeAlias
                                                                }
                                                                setText={
                                                                    setAttributeAlias
                                                                }
                                                                place={
                                                                    "- eg: coin of fortune -"
                                                                }
                                                                full={true}
                                                                required={true}
                                                            />
                                                        ) : (
                                                            <>{item.alias}</>
                                                        )}
                                                    </td>
                                                    <td className="p-2 text-[14px] font-semibold text-justify">
                                                        {editAttributeIndex ===
                                                            index ||
                                                        (addingAttribute &&
                                                            index ===
                                                                attributeData.length -
                                                                    1) ? (
                                                            <Text
                                                                text={
                                                                    attributeDescription
                                                                }
                                                                setText={
                                                                    setAttributeDescription
                                                                }
                                                                place={
                                                                    "- eg: coin of fortune -"
                                                                }
                                                                full={true}
                                                                required={true}
                                                            />
                                                        ) : (
                                                            <>
                                                                {
                                                                    item.description
                                                                }
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                        {editAttributeIndex ===
                                                            index ||
                                                        (addingAttribute &&
                                                            index ===
                                                                attributeData.length -
                                                                    1)
                                                            ? "-"
                                                            : "20 Jan 2023"}
                                                    </td>
                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                        {editAttributeIndex ===
                                                            index ||
                                                        (addingAttribute &&
                                                            index ===
                                                                attributeData.length -
                                                                    1)
                                                            ? "-"
                                                            : "20 Jan 2023"}
                                                    </td>
                                                    <td className="p-2 text-center text-[15px] font-semibold">
                                                        {!(
                                                            editAttributeIndex ===
                                                                index ||
                                                            (addingAttribute &&
                                                                index ===
                                                                    attributeData.length -
                                                                        1)
                                                        ) ? (
                                                            <div className="flex flex-row items-center justify-center">
                                                                <div className="bg-[#fff] border-[1px] p-2 rounded-full w-fit">
                                                                    <img
                                                                        src={
                                                                            optionIcon
                                                                        }
                                                                        alt="optionIcon"
                                                                        className="cursor-pointer"
                                                                        onClick={() => {
                                                                            attributeDropdownIndex ===
                                                                            index
                                                                                ? setAttributeDropdownIndex(
                                                                                      -1
                                                                                  )
                                                                                : setAttributeDropdownIndex(
                                                                                      index
                                                                                  );
                                                                        }}
                                                                    />
                                                                    {attributeDropdownIndex ===
                                                                    index ? (
                                                                        <ul className="list-none p-2 absolute bg-white border rounded">
                                                                            <li
                                                                                className="cursor-pointer"
                                                                                onClick={() => {
                                                                                    setEditAttributeIndex(
                                                                                        index
                                                                                    );
                                                                                    setAttributeDropdownIndex(
                                                                                        -1
                                                                                    );
                                                                                    setAddingAttribute(
                                                                                        false
                                                                                    );
                                                                                    setAttributeName(
                                                                                        item.code
                                                                                    );
                                                                                    setAttributeAlias(
                                                                                        item.alias
                                                                                    );
                                                                                    setAttributeDescription(
                                                                                        item.description
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Edit
                                                                            </li>
                                                                            <div className="border-b-[1px] my-[5px]"></div>
                                                                            <li
                                                                                className="cursor-pointer"
                                                                                onClick={() => {
                                                                                    setDeleteModalVisible(
                                                                                        true
                                                                                    );
                                                                                    setAttributeDropdownIndex(
                                                                                        -1
                                                                                    );
                                                                                    setAddingAttribute(
                                                                                        false
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </li>
                                                                        </ul>
                                                                    ) : null}
                                                                    <Modal
                                                                        style={
                                                                            customStyles
                                                                        }
                                                                        isOpen={
                                                                            deleteModalVisible
                                                                        }
                                                                        ariaHideApp={
                                                                            false
                                                                        }
                                                                    >
                                                                        <div className="p-4 bg-[#F9F9F9]">
                                                                            <h2 className="text-xl font-semibold mb-4">
                                                                                Confirm
                                                                                Delete
                                                                            </h2>
                                                                            <p>
                                                                                Are
                                                                                you
                                                                                sure
                                                                                you
                                                                                want
                                                                                to
                                                                                Delete
                                                                                Attribute?
                                                                            </p>
                                                                            <div className="mt-6 space-x-4 w-full flex justify-end">
                                                                                <button
                                                                                    onClick={() => {
                                                                                        setDeleteModalVisible(
                                                                                            false
                                                                                        );
                                                                                    }}
                                                                                    className="px-4 py-2 rounded text-black border border-black"
                                                                                >
                                                                                    No
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        deleteAttributeHandler(
                                                                                            item._id
                                                                                        );
                                                                                    }}
                                                                                    className="bg-[#FF7B5F] text-white px-4 py-2 rounded"
                                                                                >
                                                                                    {isDeletingAttribute ? (
                                                                                        <Loader
                                                                                            heavy={
                                                                                                true
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        "Yes"
                                                                                    )}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Modal>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="flex flex-row items-center gap-x-4 justify-center">
                                                                {isUpdatingAttribute ? (
                                                                    <Loader
                                                                        heavy={
                                                                            true
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={
                                                                            tickIcon
                                                                        }
                                                                        alt="tickIcon"
                                                                        className="cursor-pointer w-[20px] h-[20px]"
                                                                        onClick={
                                                                            handleSaveEditAttribute
                                                                        }
                                                                    />
                                                                )}
                                                                <img
                                                                    src={
                                                                        deleteIcon
                                                                    }
                                                                    alt="deleteIcon"
                                                                    className="cursor-pointer w-[30px] h-[30px]"
                                                                    onClick={() => {
                                                                        if (
                                                                            addingAttribute
                                                                        ) {
                                                                            setAttributeData(
                                                                                (
                                                                                    prevData: any[]
                                                                                ) => {
                                                                                    let data =
                                                                                        [
                                                                                            ...prevData,
                                                                                        ].filter(
                                                                                            (
                                                                                                _,
                                                                                                index
                                                                                            ) => {
                                                                                                if (
                                                                                                    index !==
                                                                                                    prevData.length -
                                                                                                        1
                                                                                                ) {
                                                                                                    return true;
                                                                                                }
                                                                                                return false;
                                                                                            }
                                                                                        );
                                                                                    return [
                                                                                        ...data,
                                                                                    ];
                                                                                }
                                                                            );
                                                                            setAddingAttribute(
                                                                                false
                                                                            );
                                                                        }
                                                                        setEditAttributeIndex(
                                                                            -1
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="flex items-center justify-center mt-7">
                                {!isGettingAttributes && (
                                    <p>No Attributes Found</p>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="flex items-center justify-end gap-3 mt-2">
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={props.closeAddEvents}
                        >
                            Cancel Changes
                        </button>
                        <button
                            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                            onClick={() => {
                                setActiveAccordion(-1);
                            }}
                        >
                            Save
                        </button>
                    </div>
                </Accordion>

                <div className="flex items-center justify-end gap-3">
                    <button
                        className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                        onClick={props.closeAddEvents}
                    >
                        Cancel
                    </button>
                    <button
                        className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                        onClick={props.closeAddEvents}
                    >
                        Save Event
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddEvents;

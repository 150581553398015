import {
  GET_MULTIPLIERS_INIT,
  GET_MULTIPLIERS_SUCCESS,
  GET_MULTIPLIERS_FAILURE,
  UPDATE_MULTIPLIER_INIT,
  UPDATE_MULTIPLIER_SUCCESS,
  UPDATE_MULTIPLIER_FAILURE,
  ADD_MULTIPLIER_INIT,
  ADD_MULTIPLIER_SUCCESS,
  ADD_MULTIPLIER_FAILURE,
  DELETE_MULTIPLIER_INIT,
  DELETE_MULTIPLIER_SUCCESS,
  DELETE_MULTIPLIER_FAILURE,
} from "../actionTypes";

export const getMultipliersSuccess = (data: any) => {
  return {
    type: GET_MULTIPLIERS_SUCCESS,
    data,
  };
};

export const getMultipliersFailure = (error: any) => {
  return {
    type: GET_MULTIPLIERS_FAILURE,
    error,
  };
};

export const getMultipliers = (params: any) => {
  const payload = { params };
  return {
    type: GET_MULTIPLIERS_INIT,
    payload,
  };
};

export const updateMultiplierSuccess = (data: any) => {
  return {
    type: UPDATE_MULTIPLIER_SUCCESS,
    data,
  };
};

export const updateMultiplierFailure = (error: any) => {
  return {
    type: UPDATE_MULTIPLIER_FAILURE,
    error,
  };
};

export const updateMultiplier = (params: any) => {
  const payload = { params };
  return {
    type: UPDATE_MULTIPLIER_INIT,
    payload,
  };
};

export const addMultiplierSuccess = (data: any) => {
  return {
    type: ADD_MULTIPLIER_SUCCESS,
    data,
  };
};

export const addMultiplierFailure = (error: any) => {
  return {
    type: ADD_MULTIPLIER_FAILURE,
    error,
  };
};

export const addMultiplier = (params: any) => {
  const payload = { params };
  return {
    type: ADD_MULTIPLIER_INIT,
    payload,
  };
};

export const deleteMultiplierSuccess = (data: any) => {
  return {
    type: DELETE_MULTIPLIER_SUCCESS,
    data,
  };
};

export const deleteMultiplierFailure = (error: any) => {
  return {
    type: DELETE_MULTIPLIER_FAILURE,
    error,
  };
};

export const deleteMultiplier = (params: any) => {
  const payload = { params };
  return {
    type: DELETE_MULTIPLIER_INIT,
    payload,
  };
};

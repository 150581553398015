import { useDispatch, useSelector } from "react-redux";
// import Text from "../modals/nudge/text";
import {
    updateStreakEventInput,
    // updateStreakTokenMilestoneInput,
} from "../../../redux/streak/streakActions";
import Loader from "../../common/loader";
export interface IStreakEventsProps {
    eventDay: number;
    setShowEvents: () => void;
}
const StreakEvents = ({ eventDay, setShowEvents }: IStreakEventsProps) => {
    const dispatch = useDispatch();
    const { events } = useSelector((state: any) => state.streaks);
    const { isGettingEvents, getEventsResp, getEventsRespStatus } = useSelector(
        (state: any) => state.events
    );

    const handleAdd = (eventId: string) => {
        dispatch(
            updateStreakEventInput({ eventId: eventId, eventDay: eventDay })
        );
    };

    return (
        <div className="p-5 w-[750px]">
            <div className="mt-5 flex flex-col w-full gap-3">
                <div className="flex justify-between">
                    <div>
                        <div className="text-2xl text-[#4C4C66] font-bold">
                            Add Day - {eventDay} Event
                        </div>
                    </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => setShowEvents()}
                    >
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                                fill="#4C4C66"
                            />
                        </svg>
                    </div>
                </div>
                {isGettingEvents && (
                    <div className="flex items-center justify-center">
                        <Loader heavy={true} />
                    </div>
                )}
                {getEventsRespStatus === 200 &&
                    getEventsResp.data?.value?.map((each: any, i: number) => {
                        return (
                            <div
                                className={
                                    "rounded border-2 border-[#FFAFA8]  cursor-pointer p-3" +
                                    (events?.[eventDay]?.events?.includes(
                                        each._id
                                    )
                                        ? " bg-white"
                                        : " bg-[#FFF2F2]")
                                }
                            >
                                <div
                                    className={"flex items-center gap-3"}
                                    onClick={() => handleAdd(each._id)}
                                >
                                    {events?.[eventDay]?.events?.includes(
                                        each._id
                                    ) ? (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                fill="#5F5E76"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                fill="#FFAFA8"
                                            />
                                        </svg>
                                    )}
                                    <div className="flex items-center gap-3">
                                        <div className="text-lg font-bold">
                                            {each.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className="mt-10 mb-5 px-5 flex items-center justify-end">
                <div
                    className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
                    onClick={() => setShowEvents()}
                >
                    Continue
                </div>
            </div>
        </div>
    );
};

export default StreakEvents;

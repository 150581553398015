import eye from "../../assets/svg/eye.svg";
import penc from "../../assets/svg/penc.svg";
import bin from "../../assets/svg/bin.svg";
import { useDispatch } from "react-redux";
import { deleteNudge } from "../../redux/gamification/gamificationActions";

type AddProps = {
  text: string;
  id?: string;
};

const Add = ({ text, id }: AddProps) => {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center gap-3">
      <div className="flex items-center gap-[3px] px-2 py-[1.6px] bg-white rounded-[100px] w-fit mt-1 border border-[#FFAFA8]">
        <svg
          width="7"
          height="6"
          viewBox="0 0 7 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="3.5" cy="3" r="3" fill="#8880A1" />
        </svg>
        <div>-</div>
        <div className="flex items-center gap-3">
          <div className="text-[12px] w-[70px] line-clamp-1 text-ellipsis text-center">
            {text}
          </div>
        </div>
        {id === undefined && (
          <>
            <div>-</div>
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.1 5.4H9.5V6.6H7.1V9H5.9V6.6H3.5V5.4H5.9V3H7.1V5.4ZM6.5 12C4.9087 12 3.38258 11.3679 2.25736 10.2426C1.13214 9.11742 0.5 7.5913 0.5 6C0.5 4.4087 1.13214 2.88258 2.25736 1.75736C3.38258 0.632141 4.9087 0 6.5 0C8.0913 0 9.61742 0.632141 10.7426 1.75736C11.8679 2.88258 12.5 4.4087 12.5 6C12.5 7.5913 11.8679 9.11742 10.7426 10.2426C9.61742 11.3679 8.0913 12 6.5 12ZM6.5 10.8C7.77304 10.8 8.99394 10.2943 9.89411 9.39411C10.7943 8.49394 11.3 7.27304 11.3 6C11.3 4.72696 10.7943 3.50606 9.89411 2.60589C8.99394 1.70571 7.77304 1.2 6.5 1.2C5.22696 1.2 4.00606 1.70571 3.10589 2.60589C2.20571 3.50606 1.7 4.72696 1.7 6C1.7 7.27304 2.20571 8.49394 3.10589 9.39411C4.00606 10.2943 5.22696 10.8 6.5 10.8Z"
                fill="#8880A1"
              />
            </svg>
          </>
        )}
      </div>
      {id && (
        <div className="flex items-center gap-1">
          <img src={eye} alt="eye" className="bg-white rounded-full p-1" />
          <img src={penc} alt="penc" className="bg-white rounded-full p-1" />
          <img
            src={bin}
            alt="bin"
            className="bg-white rounded-full p-1 cursor-pointer"
            onClick={() => dispatch(deleteNudge({ id: id }))}
          />
        </div>
      )}
    </div>
  );
};
export default Add;

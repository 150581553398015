import { takeLatest } from "redux-saga/effects";
import {
  ADD_NUDGE,
  ADD_USER_FILTER,
  ADD_WHEN,
  ADD_ACTIVITY,
} from "../actionTypes";
import {
  addNudge,
  addUserFilter,
  addWhen,
  addActivity,
} from "./gamificationActions";
// import json from "../../components/gamification/json";

function* addNudgeInit(action: any): any {
  try {
    yield addNudge(action);
    // const exists = localStorage.getItem("campaign");
    // if (exists) {
    //   const all = JSON.parse(localStorage.getItem("campaigns") || "");
    //   localStorage.setItem(
    //     "campaigns",
    //     JSON.stringify([...all, { ...json, id: "" }])
    //   );
    // } else {
    //   localStorage.setItem("campaigns", JSON.stringify([{ ...json, id: "" }]));
    // }
  } catch (err: any) {
    console.log(err);
  }
}

export function* addNudgeSaga() {
  yield takeLatest(ADD_NUDGE, addNudgeInit);
}
function* addUserFilterInit(action: any): any {
  try {
    yield addUserFilter(action);
  } catch (err: any) {
    console.log(err);
  }
}

export function* addUserFilterSaga() {
  yield takeLatest(ADD_USER_FILTER, addUserFilterInit);
}
function* addWhenInit(action: any): any {
  try {
    yield addWhen(action);
  } catch (err: any) {
    console.log(err);
  }
}

export function* addWhenSaga() {
  yield takeLatest(ADD_WHEN, addWhenInit);
}

function* addActivityInit(action: any): any {
  try {
    yield addActivity(action);
  } catch (err: any) {
    console.log(err);
  }
}

export function* addActivitySaga() {
  yield takeLatest(ADD_ACTIVITY, addActivityInit);
}

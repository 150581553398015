import EventContainer from "../../containers/events";
import Sidebar from "../../containers/sidebar/sidebar";

const EventIndex = () => {
    return (
        <div className="text-white  min-h-screen">
            <div className="flex ">
                <Sidebar />
                <EventContainer />
            </div>
        </div>
    );
};

export default EventIndex;

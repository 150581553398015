import {
  TOGGLE_MODAL,
  ADD_CAMPAIGN,
  ADD_NUDGE,
  DELETE_NUDGE,
  ADD_USER_FILTER,
  ADD_WHEN,
  ADD_ACTIVITY,
} from "../../redux/actionTypes";
import { IGamificationState } from "../../models/gamification/gamification";

const initialState = {
  active: false,
  type: "",
  id: "",
  is_published: false,
  campaign_name: "",
  created_by: "",
  client_id: "",
  filter_count: 0,
  nudges: [],
  visible_to: {
    apply_filter: false,
    age: {
      apply_filter: false,
      start: 0,
      end: 0,
    },
    gender: {
      apply_filter: false,
      value: [],
    },
    location: {
      apply_filter: false,
      countries: [],
      states: [],
      cities: [],
    },
    avg_order_value: {
      apply_filter: false,
      operator: ">",
      value: 0,
    },
    usage_frequency: {
      apply_filter: false,
      value: 0,
    },
  },
  when: {
    apply_filter: false,
    start_date: new Date(0),
    end_date: {
      apply_filter: false,
      date: null,
    },
    workdays: {
      apply_filter: false,
      days: [],
    },
  },
  activity: {
    apply_filter: false,
    activities: [
      {
        activity_id: "",
        time: [],
      },
    ],
  },
};

const gamification = (
  state: IGamificationState = initialState,
  action: any
) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      const id = action.data.id;
      if (state.active === false) {
        return {
          ...state,
          active: true,
          type: action.data.type,
          id: id,
        };
      } else {
        return {
          ...state,
          active: false,
          type: "",
          id: "",
        };
      }
    case ADD_CAMPAIGN:
      return {
        ...state,
        id: action.data.id,
      };
    case ADD_NUDGE:
      var campaignId = action.data.id;
      return {
        ...state,
        id: campaignId,
        nudges: action.data.nudges,
      };
    case DELETE_NUDGE:
      const nudges = state.nudges.filter(
        (each: any) => each.nudge !== action.data.id
      );
      return {
        ...state,
        nudges: nudges,
      };
    case ADD_USER_FILTER:
      campaignId = action.data.id;
      return {
        ...state,
        id: campaignId,
        visible_to: action.data.userFilter,
      };
    case ADD_WHEN:
      campaignId = action.data.id;
      return {
        ...state,
        id: campaignId,
        when: action.data.when,
      };
    case ADD_ACTIVITY:
      campaignId = action.data.id;
      return {
        ...state,
        id: campaignId,
        activity: action.data.activity,
      };

    default:
      return state;
  }
};

export default gamification;

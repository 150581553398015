import { call, put, takeLatest } from "redux-saga/effects";
import { getRequest, postRequest } from "../../utils/apiRequests";
// import { db } from "../../firebaseConfig";
// import { getDoc, doc } from "firebase/firestore";
import {
    CLIENT_ONBOARDING_INIT,
    GET_OTP_INIT,
    GET_USER_INIT,
    VERIFY_OTP_INIT,
} from "../actionTypes";
import {
    clientOnboardingFailure,
    clientOnboardingSuccess,
    getOTPFailure,
    getOTPSuccess,
    getUserFailure,
    getUserSuccess,
    verifyOTPFailure,
    verifyOTPSuccess,
} from "./authActions";

function* getOTP(action: any): any {
    try {
        const headers = {
            "Content-Type": "application/json",
        };

        const body = action.payload.params;
        const prodUrl = action.payload.params.client_user_name
            ? `${process.env.REACT_APP_PROD}/auth/signupdev`
            : `${process.env.REACT_APP_PROD}/auth/login/email`;
        const devUrl = action.payload.params.client_user_name
            ? `${process.env.REACT_APP_DEV}/auth/signupdev`
            : `${process.env.REACT_APP_PROD}/auth/login/email`;
        const prodData = yield call(postRequest, prodUrl, body, headers);
        const devData = yield call(postRequest, devUrl, body, headers);
        if (!devData.error && !prodData.error) {
            yield put(getOTPSuccess(devData));
        } else {
            yield put(getOTPFailure(devData));
        }
    } catch (err: any) {
        yield put(getOTPFailure(err.message));
    }
}

export function* getOTPSaga() {
    yield takeLatest(GET_OTP_INIT, getOTP);
}

function* verifyOTP(action: any): any {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        const url = `${process.env.REACT_APP_PROD}/auth/verifyPhoneOtp`;
        const data = yield call(
            postRequest,
            url,
            action.payload.params,
            headers
        );
        if (!data.error) {
            yield put(verifyOTPSuccess(data));
        } else {
            yield put(verifyOTPFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(verifyOTPFailure(err.message));
    }
}

export function* verifyOTPSaga() {
    yield takeLatest(VERIFY_OTP_INIT, verifyOTP);
}

function* getUser(action: any): any {
    try {
        const data = yield call(
            getRequest,
            `${process.env.REACT_APP_PROD}/client-user`,
            {
                Authorization: `Bearer ${action.payload.params.token}`,
            }
        );
        if (!data.error) {
            yield put(getUserSuccess(data.data.value));
        } else {
            yield put(getUserFailure(data.message));
        }
    } catch (err: any) {
        console.log(err);
        yield put(getUserFailure(err.message));
    }
}

export function* getUserSaga() {
    yield takeLatest(GET_USER_INIT, getUser);
}

function* clientOnboarding(action: any): any {
    try {
        const { token, ...reqData } = action.payload.params;
        const data = yield call(
            postRequest,
            `${process.env.REACT_APP_PROD}/clients/onboard-client`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(clientOnboardingSuccess(data.data.value));
        } else {
            yield put(clientOnboardingFailure(data.message));
        }
    } catch (err: any) {
        yield put(clientOnboardingFailure(err.message));
    }
}

export function* clientOnboardingSaga() {
    yield takeLatest(CLIENT_ONBOARDING_INIT, clientOnboarding);
}

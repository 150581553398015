import Modal from "react-modal";
import { customStyles } from "../../../containers/gamification/campaign";
import { useEffect, useState } from "react";
import Value from "../../../components/users/management/value";
import back from "../../../assets/svg/back.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserTokenTransactionHistory } from "../../../redux/user/userActions";
import { useUserAuth } from "../../../hooks/useAuthContextProvider";

interface ComponentProps {
  showModal: boolean;
  search: string;
  // handleConti: () => void;
  handleCloseModalEvent: () => void;
  // selectedItem: any;
  // setSelectedItem: (selectedItem: any) => void;
  events: [];
}
const UserTransactionHistory = ({
  showModal,
  handleCloseModalEvent,
  search,
  events,
}: ComponentProps) => {
  const [activate, setActivate] = useState<string>("All");
  const { getTokensResp } = useSelector((state: any) => state.tokens);
  const [groupedEvents, setGroupedEvents] = useState<any>({});
  const [sortedEvents, setSortedEvents] = useState<any>([]);

  const {
    getUserTokenTransactionHistoryResp,
    getUserTokenTransactionHistoryRespStatus,
  } = useSelector((state: any) => state.user);
  const { user } = useUserAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUserTokenTransactionHistory({
        token: user.accessToken,
        userId: search,
        transaction_type:
          activate.toLowerCase() === "all" ? "" : activate.toLowerCase(),
      })
    );
  }, [activate]);

  useEffect(() => {
    if (
      getUserTokenTransactionHistoryRespStatus === 200 &&
      getUserTokenTransactionHistoryResp?.data?.value?.length > 0
    ) {
      const groupedEvent =
        getUserTokenTransactionHistoryResp?.data?.value?.reduce(
          (acc: any, event: any) => {
            const createdDate = new Date(event?.created_at);
            const months = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            const date = createdDate.getDate();
            const month = months[createdDate.getMonth()];
            const year = createdDate.getFullYear();

            const getDaySuffix = (day: number) => {
              if (day >= 11 && day <= 13) {
                return "th";
              }
              switch (day % 10) {
                case 1:
                  return "st";
                case 2:
                  return "nd";
                case 3:
                  return "rd";
                default:
                  return "th";
              }
            };

            const dayWithSuffix = date + getDaySuffix(date);

            const formattedDateString = `${dayWithSuffix} ${month} ${year}`;

            if (!acc[formattedDateString]) {
              acc[formattedDateString] = [];
            }
            acc[formattedDateString].push(event);
            return acc;
          },
          {}
        );

      const sortedDates = Object.keys(groupedEvent).sort(
        (a, b) => (new Date(b) as any) - (new Date(a) as any)
      );

      setGroupedEvents(groupedEvent);
      setSortedEvents(sortedDates);
    }
  }, [
    getUserTokenTransactionHistoryResp,
    getUserTokenTransactionHistoryRespStatus,
  ]);

  return (
    <div>
      {" "}
      <Modal
        style={customStyles}
        isOpen={showModal}
        onRequestClose={handleCloseModalEvent}
        shouldCloseOnOverlayClick={true}
      >
        <form>
          <div className="p-5 h-[660px] overflow-y-scroll">
            <div className="flex justify-between w-[750px]">
              <div>
                <div className="text-2xl font-bold text-[#4C4C66] font-bold">
                  Transaction History
                </div>
              </div>

              <div className="cursor-pointer" onClick={handleCloseModalEvent}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                    fill="#4C4C66"
                  />
                </svg>
              </div>
            </div>

            <div className="flex items-center gap-6 mt-10">
              <div
                className={
                  "text-lg cursor-pointer text-[#5F5E76]" +
                  (activate === "All"
                    ? " border-b-2 border-[#5F5E76] font-bold"
                    : "")
                }
                onClick={() => setActivate("All")}
              >
                All
              </div>
              <div
                className={
                  "text-lg cursor-pointer text-[#5F5E76]" +
                  (activate === "Credit"
                    ? " border-b-2 border-[#5F5E76] font-bold"
                    : "")
                }
                onClick={() => setActivate("Credit")}
              >
                Credit
              </div>
              <div
                className={
                  "text-lg cursor-pointer text-[#5F5E76]" +
                  (activate === "Debit"
                    ? " border-b-2 border-[#5F5E76] font-bold"
                    : "")
                }
                onClick={() => setActivate("Debit")}
              >
                Debit
              </div>
              <div
                className={
                  "text-lg cursor-pointer text-[#5F5E76]" +
                  (activate === "Pending"
                    ? " border-b-2 border-[#5F5E76] font-bold"
                    : "")
                }
                onClick={() => setActivate("Pending")}
              >
                Pending
              </div>
              <div
                className={
                  "text-lg cursor-pointer text-[#5F5E76]" +
                  (activate === "both"
                    ? " border-b-2 border-[#5F5E76] font-bold"
                    : "")
                }
                onClick={() => setActivate("both")}
              >
                both
              </div>
              <div
                className={
                  "text-lg cursor-pointer text-[#5F5E76]" +
                  (activate === "Failed"
                    ? " border-b-2 border-[#5F5E76] font-bold"
                    : "")
                }
                onClick={() => setActivate("Failed")}
              >
                Failed
              </div>
            </div>
            {activate &&
            getUserTokenTransactionHistoryRespStatus === 200 &&
            getUserTokenTransactionHistoryResp?.data?.value?.length > 0 ? (
              <>
                {sortedEvents &&
                  sortedEvents.length > 0 &&
                  sortedEvents.map((date: any) => {
                    return (
                      <div className="mt-5 bg-[#FFF2F1] rounded p-5 max-h-[330px] overflow-y-scroll">
                        <div className="grid grid-cols-3">
                          <div className="col-span-1 text-lg cursor-pointer text-[#5F5E76]">
                            {date}
                          </div>
                        </div>
                        {groupedEvents[date].map((event: any) => (
                          <div
                            className="bg-[#FFE8E6]  p-2 border-b-2 border-[#CEB0FA]"
                            key={event._id}
                          >
                            <div className="grid gap-4 grid-cols-2">
                              <div className="col-span-1 flex flex-row border-r-2 border-[#CEB0FA]">
                                {" "}
                                <div className="text-md cursor-pointer text-[#5F5E76] mr-5 bg-[#fff] pr-2 pl-2 rounded-full">
                                  {event.transaction_type}
                                </div>
                                <div className="text-lg cursor-pointer text-[#5F5E76]">
                                  {event.description}
                                </div>
                              </div>
                              {getTokensResp &&
                              getTokensResp?.data?.value.length > 0
                                ? event?.token_value.map((token: any) => {
                                    return (
                                      <div className="col-span-1 text-lg cursor-pointer text-[#5F5E76]">
                                        <div className="flex flex-row">
                                          <Value
                                            key={token._id}
                                            name={
                                              getTokensResp?.data?.value?.find(
                                                (t: any) =>
                                                  t._id === token.token_id
                                              )?.name
                                            }
                                            value={token.value}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  })}
              </>
            ) : (
              ""
            )}
          </div>
        </form>
        <div className="mt-10 mb-5 px-5 flex items-center justify-between">
          <div className="flex flex-row">
            <div className="text-[#5F5E76] text-lg rounded cursor-pointer py-3 px-1">
              {" "}
              <img src={back} alt="back" className="w-[20px] h-[20px]" />
            </div>

            <div
              className="text-[#5F5E76] text-lg rounded cursor-pointer py-2"
              onClick={handleCloseModalEvent}
            >
              Cancel
            </div>
          </div>

          <div
            className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
            // onClick={handleConti}
          >
            Continue
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default UserTransactionHistory;

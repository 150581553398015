import Modal from "react-modal";
import Radio from "../../components/gamification/modals/nudge/radio";
import DateContainer from "../../components/gamification/modals/nudge/date";
import { customStyles } from "../../containers/gamification/campaign";
import Text from "../../components/gamification/modals/nudge/text";
import back from "../../assets/svg/back.svg";

interface ComponentProps {
  showModal: boolean;
  handleCloseModal: () => void;
  handleCont: () => void;
  name: string;
  setName: (name: string) => void;
  priority: any;
  setPriority: (priority: any) => void;
  general: string;
  setGeneral: (general: string) => void;
  date: Date | null;
  setDate: (date: Date | null) => void;
  datee: Date | null;
  setDatee: (datee: Date | null) => void;
}

const AddReferral = ({
  showModal,
  handleCloseModal,
  handleCont,
  name,
  setName,
  priority,
  setPriority,
  general,
  setGeneral,
  date,
  setDate,
  datee,
  setDatee,
}: ComponentProps) => {
  return (
    <Modal
      style={customStyles}
      isOpen={showModal}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={true}
    >
      <form onSubmit={handleCont}>
        <div className="p-5">
          <div className="flex justify-between w-[750px]">
            <div>
              <div className="text-2xl text-[#4C4C66] font-bold">
                Add a Referral Program
              </div>
            </div>
            <div className="cursor-pointer" onClick={handleCloseModal}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                  fill="#4C4C66"
                />
              </svg>
            </div>
          </div>
          <div className="mt-5 flex items-center gap-5 w-[750px]">
            <div className="w-3/4">
              <div className="text-[14px] text-[#4C4C66]">
                Name of the Referral Program*
              </div>
              <Text
                text={name}
                setText={setName}
                required={true}
                place={"- eg : birthday streak -"}
                full={true}
              />
            </div>
            <div className="">
              <div className="text-[14px] text-[#4C4C66]">Priority*</div>
              <Text
                text={priority}
                setText={setPriority}
                place={"- eg : 1 -"}
                full={false}
                type={"number"}
                required={true}
              />
            </div>
          </div>
          <div className="text-[14px] text-[#4C4C66] mt-5 ">
            Select the end date and start date
          </div>
          <div className="bg-[#FFE8E6]">
            <div className="flex items-center gap-3  p-3">
              <div>
                <div className="text-[#5F5E76] text-[12px]">Start date*</div>
                <DateContainer
                  selected={
                    date ? new Date(date)?.toISOString()?.split("T")?.[0] : ""
                  }
                  setSelected={(val) => {
                    if (val) {
                      setDate(new Date(val));
                    } else {
                      setDate(null);
                    }
                  }}
                  required={true}
                />
              </div>
              <div>
                <div className="text-[#5F5E76] text-[12px]">End date</div>
                <DateContainer
                  selected={
                    datee ? new Date(datee)?.toISOString()?.split("T")?.[0] : ""
                  }
                  setSelected={(val) => {
                    if (val) {
                      setDatee(new Date(val));
                    } else {
                      setDatee(null);
                    }
                  }}
                  required={false}
                />
              </div>
            </div>
          </div>
          <div className="text-[14px] text-[#4C4C66] mt-5 ">Referral Type</div>
          <div className="flex items-center gap-3 mt-1">
            {["Code Based"].map((each: any) => {
              return (
                <Radio
                  selected={general}
                  setSelected={setGeneral}
                  each={each}
                  multi={false}
                />
              );
            })}
          </div>
        </div>
        <div className="mt-10 mb-5 px-5 flex items-center justify-between">
          <div className="flex flex-row">
            <div className="text-[#5F5E76] text-lg rounded cursor-pointer py-3 px-1">
              {" "}
              <img src={back} alt="back" className="w-[20px] h-[20px]" />
            </div>

            <div
              className="text-[#5F5E76] text-lg rounded cursor-pointer py-2"
              onClick={handleCloseModal}
            >
              Cancel
            </div>
          </div>

          <button
            className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
            type={"submit"}
          >
            Continue
          </button>
        </div>
      </form>
    </Modal>
  );
};
export default AddReferral;

import { useState } from "react";
import coin from "../../../assets/png/coin.png";
import starl from "../../../assets/png/star-light.png";
import star from "../../../assets/png/star.png";
import arrow from "../../../assets/svg/arrow.svg";
import editD from "../../../assets/svg/editD.svg";
import deleteD from "../../../assets/svg/deleteD.svg";

type Props = {
    event: any;
    handleSetDefault: () => void;
    handleDelete: () => void;
    handleEdit: () => void;
};

const EventVariationCard = (props: Props) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    return (
        <div className="w-full border-2 border-[#FFAFA8] px-3 py-2 outline-none rounded">
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center gap-x-1">
                    {isCollapsed ? (
                        <img
                            src={props.event.is_default ? star : starl}
                            alt="star"
                            className="w-4 h-4 object-contain"
                            onClick={() => {
                                props.handleSetDefault();
                            }}
                        />
                    ) : null}
                    <img src={coin} alt="coin" className="w-6 h-6" />
                    <span>{props.event.name}</span>
                    <span>|</span>
                    <span>
                        {props.event.is_default
                            ? "Default"
                            : `Scheduled on - ${
                                  typeof props.event.date == "string"
                                      ? props.event.date
                                            .toString()
                                            .split("T")[0]
                                      : props.event.date.toDateString()
                              }`}
                    </span>
                </div>
                <div className="flex flex-row items-center gap-x-2">
                    <img
                        src={editD}
                        alt="edit"
                        className="w-5 h-5"
                        onClick={props.handleEdit}
                    />
                    <img
                        src={deleteD}
                        alt="delete"
                        className="w-5 h-5"
                        onClick={props.handleDelete}
                    />
                    <div
                        className="flex flex-row items-center gap-x-1"
                        onClick={() => {
                            setIsCollapsed(false);
                        }}
                    >
                        {isCollapsed ? (
                            <>
                                <span>View Details</span>
                                <img
                                    src={arrow}
                                    alt="arrow"
                                    className="w-2 h-2"
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isCollapsed ? null : (
                <div className="mt-2 flex flex-col bg-[#FFE8E6] w-full">
                    <div className="flex flex-row items-center px-3 py-2 w-full">
                        <span className="w-[20%]">Description</span>
                        <span className="w-[80%]">
                            {props.event.description}
                        </span>
                    </div>
                    <div className="flex flex-row items-center px-3 py-2 w-full">
                        <span className="w-[20%]">Redirect URL</span>
                        <span className="w-[80%]">
                            {props.event.redirect_url}
                        </span>
                    </div>
                </div>
            )}
            <div
                className="flex flex-row items-center gap-x-1 justify-end"
                onClick={() => {
                    setIsCollapsed(true);
                }}
            >
                {isCollapsed ? null : (
                    <>
                        <span> Hide Details</span>
                        <img
                            src={arrow}
                            alt="arrow"
                            className="w-2 h-2 rotate-180"
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default EventVariationCard;

import { useEffect, useState } from "react";
import dots from "../../assets/svg/dots.svg";
import Param from "../../components/tokens/param";
import Accordion from "./accordian";
import LineChart from "./chart";
import Modal from "react-modal";
import p from "../../assets/png/image 62.png";
import { customStyles } from "../gamification/campaign";
import Text from "../../components/gamification/modals/nudge/text";
import Radio from "../../components/gamification/modals/nudge/radio";
import DateContainer from "../../components/gamification/modals/nudge/date";
import { useDispatch, useSelector } from "react-redux";
import {
  addToken,
  getAnalytics,
  getTokenHistory,
  getTokens,
  updateToken,
} from "../../redux/token/tokenActions";
import { decimalToRatioString } from "../../utils/helps";
import Loader from "../../components/common/loader";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
function numberToString(num: number) {
  if (num < 10000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000) % 10 === 0
      ? num / 1000 + "K"
      : (num / 1000).toFixed(1) + "K";
  } else {
    return (num / 1000000).toFixed(1) + "M";
  }
}
const Token = () => {
  const { user, userDetails } = useUserAuth();
  const dispatch = useDispatch();

  const {
    isGettingTokens,
    getTokensResp,
    getTokensRespStatus,
    getTokensError,
    getTokensErrorMsg,
    isUpdatingToken,
    updateTokenError,
    updateTokenRespStatus,
    isAddingToken,
    addTokenError,
    addTokenRespStatus,
    isGettingAnalytics,
    getAnalyticsError,
    getAnalyticsErrorMsg,
    getAnalyticsResp,
    getAnalyticsRespStatus,
    isGettingTokenHistory,
    getTokenHistoryResp,
    getTokenHistoryRespStatus,
  } = useSelector((state: any) => state.tokens);

  const [activeAccordion, setActiveAccordion] = useState<number>(0); //active accoridion opened
  const [add, setAdd] = useState<boolean>(false); //more options (history and add)
  const [tImage, setTImage] = useState<any>(null); //token image
  const [newToken, setNewToken] = useState<boolean>(false); //token modak
  const [name, setName] = useState<string>(""); //token name
  const [ratio, setRatio] = useState<string>("1"); //token ratio
  const [monetized, setMonetized] = useState<string>("Monetized"); //[Monetized, Non-monetized]
  const [expiry, setExpiry] = useState<string>("Never"); //[Never, Custom limit]
  const [customExpiry, setCustomExpiry] = useState<any>(null); //date if expiry is custom limit
  const [history, setHistory] = useState<boolean>(false); //history modal
  const [rMap, setRMap] = useState<any>(null); //map token id with token name
  const [tGraph, setTGraph] = useState<any>([
    //token graph
    {
      name: "Token 1",
      data: [
        [new Date("2023-06-01").getTime(), 10],
        [new Date("2023-06-15").getTime(), 15],
        [new Date("2023-07-01").getTime(), 8],
        [new Date("2023-07-15").getTime(), 12],
        [new Date("2023-08-01").getTime(), 18],
        [new Date("2023-08-15").getTime(), 18],
        // Add more data points as needed
      ],
    },
    {
      name: "Token 2",
      data: [
        [new Date("2023-06-01").getTime(), 11],
        [new Date("2023-06-15").getTime(), 9],
        [new Date("2023-07-01").getTime(), 14],
        [new Date("2023-07-15").getTime(), 11],
        [new Date("2023-08-01").getTime(), 19],
        [new Date("2023-08-15").getTime(), 17],
      ],
    },
  ]);

  const [activeToken, setActiveToken] = useState<string>(""); //active token selected

  //refetch tokens if token is added or updated
  useEffect(() => {
    if (updateTokenRespStatus === 200 || addTokenRespStatus === 200) {
      dispatch(getTokens({ token: user?.accessToken }));
    }
  }, [isUpdatingToken, isAddingToken]);

  //fetch analytics once tokens are received
  useEffect(() => {
    if (getTokensRespStatus === 200) {
      if (getAnalyticsRespStatus !== 200) {
        dispatch(getAnalytics({ token: user.accessToken }));
      }
    }
  }, [isGettingTokens]);

  //format fetched analytics into a form where graph package can read data
  const graph = () => {
    let temp1: any = {};
    let temp2: any = {};
    for (let i = 0; i < getTokensResp.data.value.length; i++) {
      temp1[getTokensResp.data.value[i]._id] = getTokensResp.data.value[i].name;
      temp2[getTokensResp.data.value[i].name] = getTokensResp.data.value[i]._id;
    }
    // setTMap(temp1);
    setRMap(temp2);
    let temp = [];
    for (let j = 0; j < getAnalyticsResp?.data?.value?.tokens?.length; j++) {
      temp.push({
        name: temp1[getAnalyticsResp?.data?.value?.tokens?.[j]?.token_id],
      });
      const stamps = Object.keys(
        getAnalyticsResp?.data?.value?.tokens?.[j]?.monthly_distribution
      );
      const values = Object.values(
        getAnalyticsResp?.data?.value?.tokens?.[j]?.monthly_distribution
      );
      const stampsWithDates = stamps.map((each: any) => {
        const [year, month] = each.split(",");
        const d = new Date(`${year}-${month}-1`);
        return d;
      });
      temp[temp.length - 1] = {
        ...temp[temp.length - 1],
        data:
          stampsWithDates?.length < 1
            ? []
            : [
                [new Date().setDate(stampsWithDates?.[0]?.getDate() - 1), 0],
                [
                  ...stampsWithDates.map((each: any, i: any) => [
                    each,
                    values[i],
                  ]),
                ],
              ],
      };
    }
    setTGraph(temp);
  };

  //set graph
  useEffect(() => {
    if (getAnalyticsRespStatus === 200) {
      graph();
    }
  }, [isGettingAnalytics]);

  //add or update token, if activeToken is true then update else add
  const update = (e: any) => {
    e.preventDefault();
    if (activeToken) {
      dispatch(
        updateToken({
          name: name,
          // ratio: ratio,
          // client_id: "64d0bc127eff0438835d15d7",
          image: tImage,
          type: monetized,
          expiry_date: expiry !== "Never" ? new Date(customExpiry) : null,
          token_id: activeToken,
          token: user?.accessToken,
          clientId: userDetails?.client_id?.name,
        })
      );
    } else {
      dispatch(
        addToken({
          name: name,
          image: tImage,
          ratio: parseFloat(ratio),
          // client_id: "64d0bc127eff0438835d15d7",
          type: monetized,
          expiry_date: expiry !== "Never" ? new Date(customExpiry) : null,
          token: user?.accessToken,
          clientId: userDetails?.client_id?.name,
        })
      );
    }
  };

  //fetch token history
  useEffect(() => {
    if (getTokenHistoryRespStatus !== 200) {
      dispatch(getTokenHistory({ token: user.accessToken }));
    }
  }, [history]);

  //reset modal inputs once data is updated
  useEffect(() => {
    if (updateTokenRespStatus === 200) {
      resetModal();
    } else if (updateTokenError) {
      alert("Something went wrong");
    }
  }, [isUpdatingToken]);

  //reset modal inputs once data is added
  useEffect(() => {
    if (addTokenRespStatus === 200) {
      resetModal();
    } else if (addTokenError) {
      alert("Something went wrong");
    }
  }, [isAddingToken]);

  //set selected accordion
  const handleAccordionClick = (index: any) => {
    if (activeAccordion === index) {
      setActiveAccordion(-1);
    } else {
      setActiveAccordion(index);
    }
    <div className="flex items-center gap-1">
      <div className="text-[12px]">Created by</div>
      <img
        src={p}
        alt="p"
        className="w-[30px] h-[30px] rounded-full object-cover"
      />
      <div className="text-[12px]">User name</div>
    </div>;
  };

  // token in list form (when accordion is collapsed)
  const title = (i: any) => {
    return (
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-3">
          <img src={i.image} alt="token" className="w-[50px]" />
          <div className="">
            <div className="flex items-center gap-3">
              <div className="font-bold">{i.name}</div>
            </div>
            <div className="flex items-center gap-1">
              <div>
                Ratio - <b>{decimalToRatioString(i.ratio)}</b>
              </div>
              |<div>{i.type}</div>|
              <div>
                Expiration time -{" "}
                {i.expiry_date
                  ? new Date(i.expiry_date).toLocaleDateString("en-IN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                  : "No expiry"}
              </div>
            </div>
          </div>
        </div>
        <div
          className="text-white cursor-pointer font-bold bg-[#FF7B5F] rounded-full px-5 py-2"
          onClick={() => {
            setNewToken(true);
            setName(i.name);
            setRatio(i.ratio);
            setMonetized(i.type);
            setExpiry(i.expiry_date ? "Custom limit" : "Never");
            setCustomExpiry(
              i.expiry_date
                ? new Date(i.expiry_date).toISOString().split("T")?.[0]
                : null
            );
            setActiveToken(i._id);
            setTImage(i.image);
          }}
        >
          Modify
        </div>
      </div>
    );
  };

  //token history format for each
  const eachHistory = (i: any) => {
    const updatedFields = i.message
      ?.split('"')
      ?.filter((field: any) => field.trim() !== "")
      ?.slice(1);
    return (
      <div className="flex items-center justify-between w-full bg-[#FFE8E6] shadow-lg p-4 mt-2">
        <div className="flex items-center gap-3">
          <img src={i.new_state?.image} alt="token" className="w-[50px]" />
          <div className="">
            <div className="flex items-center gap-3">
              <div className="font-bold">{i.new_state?.name}</div>|
              <div className="flex items-center gap-1">
                <div className="text-[12px]">
                  {i.previous_state ? "Modified By" : "Created By"}
                </div>
                <img
                  src={p}
                  alt="p"
                  className="w-[15px] h-[15px] border-2 border-[#FF409A] rounded-full object-cover"
                />
                <div className="text-[12px]">
                  {i.updated_by?.name || "User"}
                </div>
              </div>
            </div>
            {i.previous_state && (
              <div className="flex items-center flex-wrap gap-1">
                {updatedFields.map((each: any, e: number) => {
                  if (each === "image") {
                    return <b>Updated token image</b>;
                  } else {
                    return (
                      <>
                        <div>
                          <b>{each}: </b>From{" "}
                          {i.previous_state[each] === null
                            ? "null"
                            : i.previous_state[each]}{" "}
                          to {i.new_state[each]}{" "}
                        </div>
                        {e !== updatedFields.length - 1 && "|"}
                      </>
                    );
                  }
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  const resetModal = () => {
    setName("");
    setRatio("1");
    setMonetized("Monetized");
    setExpiry("Never");
    setCustomExpiry(null);
    setNewToken(false);
    setTImage(null);
    setActiveToken("");
  };
  return (
    <div className="bg-[#F9F9F9] px-10 h-[calc(100vh-133px)] overflow-y-auto">
      {isGettingAnalytics && (
        <div className="flex items-center justify-center">
          <Loader heavy={true} />
        </div>
      )}
      {getAnalyticsError && getAnalyticsErrorMsg}
      {getAnalyticsRespStatus === 200 && (
        <>
          <div className="mt-5 flex items-center justify-between">
            <div className="text-xl font-bold">Overall analytics</div>
            <div className="relative">
              {/* <div
              className="flex items-center cursor-pointer gap-3"
              onClick={() => setFilter(!filter)}
            >
              {date}
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.76876 0.23964C10.0771 0.55916 10.0771 1.0772 9.76876 1.39672L5.55824 5.76036C5.24993 6.07988 4.75006 6.07988 4.44176 5.76036L0.231231 1.39672C-0.0770771 1.0772 -0.0770771 0.55916 0.231231 0.23964C0.53954 -0.0798796 1.03941 -0.0798797 1.34772 0.23964L5 4.02473L8.65228 0.23964C8.96059 -0.07988 9.46046 -0.07988 9.76876 0.23964Z"
                  fill="#4C4C66"
                  fill-opacity="0.84"
                />
              </svg>
            </div> */}
              {/* {filter && (
              <div className="flex flex-col gap-1 absolute top-7 right-0 rounded shadow bg-[#FFF2F1] z-10 w-[150px] p-3">
                <div
                  onClick={() => {
                    setFilter(false);
                    setDate("Last 12 months");
                  }}
                >
                  Last 12 months
                </div>
                <div
                  onClick={() => {
                    setFilter(false);
                    setDate("Last 6 months");
                  }}
                >
                  Last 6 months
                </div>
                <div
                  onClick={() => {
                    setFilter(false);
                    setDate("Last 3 months");
                  }}
                >
                  Last 3 months
                </div>
                <div onClick={()=>setShowCustomFilter(!showCustomFilter)}>Custom date</div>
                {showCustomFilter && <div className="absolute right-0 top-32 rounded shadow bg-[#FFF2F1] z-10 p-3">
                  <div className="text-[14px] text-[#4C4C66] mb-1">
                    Start from
  </div>
                  <DateContainer selected={customStart} setSelected={setCustomStart} required={false} />
                  <div className="text-[14px] text-[#4C4C66] mt-3 mb-1">
                    End on
  </div>
                  <DateContainer selected={customEnd} setSelected={setCustomEnd} required={false} min={customStart}/>
                  </div>
                  }
              </div>
            )} */}
            </div>
          </div>
          <div className="grid grid-cols-24 gap-5 mt-3">
            <div className="col-span-10">
              <div className="grid grid-cols-2 gap-3">
                <div className="col-span-1">
                  <Param
                    info={"Tokens in circulation (≈₹)"}
                    value={numberToString(
                      getAnalyticsResp?.data?.value?.tokens?.reduce(
                        (a: any, b: any) => {
                          a += b.circulation;
                          return a;
                        },
                        0
                      ) || 0
                    )}
                  />
                  <div className="mt-3">
                    <Param
                      info={"Token Earn Rate"}
                      value={`${(
                        getAnalyticsResp?.data?.value?.tokens?.reduce(
                          (a: any, b: any) => {
                            a += b.earn_rate;
                            return a;
                          },
                          0
                        ) /
                          (getAnalyticsResp?.data?.value?.tokens?.length ||
                            1) || 0
                      ).toFixed(2)}`}
                      // data={one[date]}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <Param
                    info={"Tokens redeemed (≈₹)"}
                    value={numberToString(
                      getAnalyticsResp?.data?.value?.tokens?.reduce(
                        (a: any, b: any) => {
                          a += b.redeemed;
                          return a;
                        },
                        0
                      ) || 0
                    )}
                  />
                  <div className="mt-3">
                    <Param
                      info={"Token Spend Rate"}
                      value={`${(
                        getAnalyticsResp?.data?.value?.tokens?.reduce(
                          (a: any, b: any) => {
                            a += b.spend_rate;
                            return a;
                          },
                          0
                        ) /
                          (getAnalyticsResp?.data?.value?.tokens?.length ||
                            1) || 0
                      ).toFixed(2)}`}
                      // data={one[date]}
                      // loss={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-11">
              <LineChart data={tGraph} />
            </div>
            {/* <div className="col-span-3">
            <Param info={"PY growth"} value={"+69%"} />
            <div className="mt-3">
              <Param info={"lorem"} value={"+69%"} />
            </div>
          </div> */}
          </div>
          <div className="h-[3px] bg-[#CEB0FA] rounded w-full my-10"></div>
        </>
      )}
      <div className="flex items-center justify-between mb-5">
        <div className="text-xl font-bold">Tokens</div>
        <div className="relative">
          <img
            src={dots}
            alt="dots"
            className="cursor-pointer h-5"
            onClick={() => setAdd(!add)}
          />
          {add && (
            <div className="absolute top-7 right-0 rounded shadow bg-[#FFF2F1] w-[150px] p-3">
              <div
                className="cursor-pointer"
                onClick={() => setHistory(!history)}
              >
                View History
              </div>
              <div
                className="cursor-pointer mt-1"
                onClick={() => {
                  setAdd(false);
                  resetModal();
                  setNewToken(!newToken);
                }}
              >
                Add a new token
              </div>
            </div>
          )}
        </div>
      </div>
      {getTokensRespStatus === 200 && getTokensResp.data.value.length === 0 && (
        <div className="py-5 text-center">Add a token!</div>
      )}
      {isGettingTokens && (
        <div className="flex items-center justify-center mt-5">
          <Loader heavy={true} />
        </div>
      )}
      {getTokensError && getTokensErrorMsg}
      {getTokensRespStatus === 200 &&
        getTokensResp.data.value.map((each: any, i: number) => {
          return (
            <Accordion
              key={i}
              title={title(each)}
              onClick={() => handleAccordionClick(i)}
              isOpen={activeAccordion === i}
              eachGroupType={[]}
            >
              {getAnalyticsRespStatus === 200 &&
                rMap &&
                getAnalyticsResp?.data?.value?.tokens?.map((eachG: any) => {
                  if (eachG?.token_id === each?._id) {
                    const g = tGraph.find(
                      (tGE: any) => rMap[tGE?.name] === eachG?.token_id
                    );
                    return (
                      <>
                        .
                        <div className="grid grid-cols-12 gap-5">
                          <div className="col-span-7">
                            {g && <LineChart data={[g]} />}
                          </div>
                          <div className="col-span-5">
                            <div className="grid grid-cols-2 gap-3">
                              <div className="col-span-1">
                                <Param
                                  info={"Tokens in circulation (≈₹)"}
                                  value={numberToString(eachG.circulation || 0)}
                                />
                                <div className="mt-3">
                                  <Param
                                    info={"Token Earn Rate"}
                                    value={`${
                                      eachG.earn_rate?.toFixed(2) || 0
                                    }`}
                                    // data={one[date]}
                                  />
                                </div>
                              </div>
                              <div className="col-span-1">
                                <Param
                                  info={"Tokens claimed (≈₹)"}
                                  value={numberToString(eachG.redeemed || 0)}
                                />
                                <div className="mt-3">
                                  <Param
                                    info={"Token Spend Rate"}
                                    value={`${
                                      eachG.spend_rate?.toFixed(2) || 0
                                    }`}
                                    // data={one[date]}
                                    // loss={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
            </Accordion>
          );
        })}
      <Modal
        style={customStyles}
        isOpen={newToken}
        onRequestClose={() => resetModal()}
        shouldCloseOnOverlayClick={true}
      >
        <form onSubmit={update}>
          <div className="p-5">
            <div className="flex justify-between w-[750px]">
              <div>
                <div className="text-2xl text-[#4C4C66] font-bold">
                  Add Token
                </div>
                {/* <div className="text-[#3189B4] mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Praesentium, aperiam quis in explicabo a aliquid libero dicta
                  repudiandae consequatur deleniti enim et, harum sapiente
                  veritatis dolores? Similique minima mollitia enim?
                </div> */}
              </div>
              <div
                className="text-xl text-[#4C4C66] cursor-pointer"
                onClick={() => setNewToken(false)}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                    fill="#4C4C66"
                  />
                </svg>
              </div>
            </div>
            <div className="mt-5 flex gap-5">
              <div>
                <div className="text-[14px] text-[#4C4C66]">
                  Add image for token
                </div>
                <label htmlFor={"in"}>
                  <div className="w-[150px] h-[150px] object-cover border-2 border-dashed border-[#FFAFa8] rounded flex items-center justify-center text-center mt-1 text-[14px] cursor-pointer p-5">
                    {tImage ? (
                      <img
                        src={
                          typeof tImage === "string"
                            ? tImage
                            : URL.createObjectURL(tImage)
                        }
                        alt="t"
                      />
                    ) : (
                      "Drag & Drop or upload"
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  id="in"
                  className="hidden"
                  onChange={(e: any) => setTImage(e.target.files[0])}
                />
              </div>
              <div className="w-[3px] bg-[#CEB0Fa]"></div>
              <div className="w-full">
                <div className="text-[14px] text-[#4C4C66]">Name of token</div>
                <Text
                  text={name}
                  setText={setName}
                  place={"- eg: coin of fortune -"}
                  full={true}
                />
                <div className="flex items-center justify-between mt-3 gap-7">
                  <div>
                    <div className="text-[14px] text-[#4C4C66]">Type</div>
                    <div className="flex items-center gap-3">
                      {["Monetized", "Nonmonetized"].map((each: any) => {
                        return (
                          <Radio
                            selected={monetized}
                            setSelected={setMonetized}
                            each={each}
                            multi={false}
                          />
                        );
                      })}
                    </div>
                  </div>
                  {monetized === "Monetized" && (
                    <div>
                      <div className="text-[14px] text-[#4C4C66]">Value</div>
                      <div className="flex items-center gap-1">
                        <Text
                          text={ratio}
                          setText={setRatio}
                          place={"1"}
                          full={false}
                          type={"number"}
                        />
                        =<div>₹1</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  <div className="text-[14px] text-[#4C4C66]">
                    Expiry date (not necessary)
                  </div>
                  <div className="flex items-center gap-3">
                    {["Never", "Custom limit"].map((each: string) => {
                      return (
                        <Radio
                          selected={expiry}
                          setSelected={setExpiry}
                          each={each}
                          multi={false}
                        />
                      );
                    })}
                  </div>
                  {expiry === "Custom limit" && (
                    <div className="mt-3">
                      <DateContainer
                        selected={customExpiry}
                        setSelected={setCustomExpiry}
                        required={true}
                        min={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end px-5 my-5">
              <button
                className="outline-none px-3 py-2 text-white text-xl font-bold bg-[#FF7B5F] cursor-pointer rounded"
                disabled={isUpdatingToken || isAddingToken}
                type="submit"
              >
                {isUpdatingToken || isAddingToken ? <Loader /> : "Continue"}
              </button>
            </div>
          </div>
        </form>
      </Modal>
      <Modal isOpen={history} style={customStyles}>
        <div className="p-5">
          <div className="flex justify-between w-[565px]">
            <div>
              <div className="text-2xl text-[#4C4C66] font-bold">
                View History
              </div>
              {/* <div className="text-[#3189B4] mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Praesentium, aperiam quis in explicabo a aliquid libero dicta
                  repudiandae consequatur deleniti enim et, harum sapiente
                  veritatis dolores? Similique minima mollitia enim?
                </div> */}
            </div>
            <div
              className="text-xl text-[#4C4C66] cursor-pointer"
              onClick={() => setHistory(false)}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                  fill="#4C4C66"
                />
              </svg>
            </div>
          </div>
          {isGettingTokenHistory ? (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div className="mt-5 max-h-[300px] overflow-y-auto max-w-[565px]">
              {getTokenHistoryRespStatus === 200 &&
                getTokenHistoryResp.data.value?.map((each: any) =>
                  eachHistory(each)
                )}
              {getTokenHistoryRespStatus === 200 &&
                getTokenHistoryResp.data.value.length < 1 &&
                "History not found"}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default Token;

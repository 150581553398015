import { put, call, takeLatest } from "redux-saga/effects";
import { baseURL, endPoints } from "../../utils/apiEndPoints";
import { getRequest, postRequest } from "../../utils/apiRequests";
import { storage } from "../../firebaseConfig";
// import { getDoc, doc } from "firebase/firestore";
import {
    GET_ALL_ROLES_INIT,
    ADD_ROLE_INIT,
    UPDATE_ROLE_INIT,
    DELETE_ROLE_INIT,
    UPDATE_CLIENT_INIT,
} from "../actionTypes";
import {
    getAllRolesSuccess,
    getAllRolesFailure,
    addRoleSuccess,
    addRoleFailure,
    updateRoleSuccess,
    updateRoleFailure,
    deleteRoleSuccess,
    deleteRoleFailure,
    updateClientSuccess,
    updateClientFailure,
} from "./settingsActions";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

function* getAllRoles(action: any): any {
    try {
        const data = yield call(getRequest, `${baseURL}/${endPoints.roles}`, {
            Authorization: `Bearer ${action.payload.params.token}`,
        });
        if (!data.error) {
            yield put(getAllRolesSuccess(data));
        } else {
            yield put(getAllRolesFailure(data));
        }
    } catch (err: any) {
        yield put(getAllRolesFailure(err.message));
    }
}

export function* getAllRolesSaga() {
    yield takeLatest(GET_ALL_ROLES_INIT, getAllRoles);
}

function* addRole(action: any): any {
    try {
        const { token, ...reqData } = action.payload.params;
        const devData = yield call(
            postRequest,
            `${process.env.REACT_APP_DEV}/${endPoints.addRole}`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        const prodData = yield call(
            postRequest,
            `${process.env.REACT_APP_PROD}/${endPoints.addRole}`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!devData.error && !prodData.error) {
            yield put(addRoleSuccess(devData));
        } else {
            yield put(addRoleFailure(devData));
        }
    } catch (err: any) {
        yield put(addRoleFailure(err.message));
    }
}

export function* addRoleSaga() {
    yield takeLatest(ADD_ROLE_INIT, addRole);
}

function* updateRole(action: any): any {
    try {
        const { token, ...reqData } = action.payload.params;
        const data = yield call(
            postRequest,
            `${baseURL}/${endPoints.addRole}`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(updateRoleSuccess(data));
        } else {
            yield put(updateRoleFailure(data));
        }
    } catch (err: any) {
        yield put(updateRoleFailure(err.message));
    }
}

export function* updateRoleSaga() {
    yield takeLatest(UPDATE_ROLE_INIT, updateRole);
}

function* deleteRole(action: any): any {
    try {
        const { token, ...reqData } = action.payload.params;
        const data = yield call(
            postRequest,
            `${baseURL}/${endPoints.revokeRole}`,
            { ...reqData },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(deleteRoleSuccess(data));
        } else {
            yield put(deleteRoleFailure(data));
        }
    } catch (err: any) {
        yield put(deleteRoleFailure(err.message));
    }
}

export function* deleteRoleSaga() {
    yield takeLatest(DELETE_ROLE_INIT, deleteRole);
}

function* updateClient(action: any): any {
    try {
        const fI = async () => {
            if (
                action.payload.params.logo &&
                typeof action.payload.params.logo !== "string"
            ) {
                const params = action.payload.params.logo;
                const imageRef = ref(
                    storage,
                    `Client/${action.payload.params.clientId}/Logo/${params.name}`
                );
                await uploadBytes(imageRef, params);
                const response = await getDownloadURL(imageRef);
                return response;
            } else {
                return action.payload.params.logo || "a";
            }
        };
        const image = yield call(fI);
        const fI2 = async () => {
            if (
                action.payload.params.banner &&
                typeof action.payload.params.banner !== "string"
            ) {
                const params = action.payload.params.banner;
                const imageRef = ref(
                    storage,
                    `Client/${action.payload.params.clientId}/Logo/${params.name}`
                );
                await uploadBytes(imageRef, params);
                const response = await getDownloadURL(imageRef);
                return response;
            } else {
                return action.payload.params.banner || "a";
            }
        };
        const image2 = yield call(fI2);
        const { token, clientId, ...reqData } = action.payload.params;
        const data = yield call(
            postRequest,
            `${baseURL}/${endPoints.updateClient}`,
            { ...reqData, logo: image, banner: image2 },
            { Authorization: `Bearer ${action.payload.params.token}` }
        );
        if (!data.error) {
            yield put(updateClientSuccess(data));
        } else {
            yield put(updateClientFailure(data));
        }
    } catch (err: any) {
        yield put(updateClientFailure(err.message));
    }
}

export function* updateClientSaga() {
    yield takeLatest(UPDATE_CLIENT_INIT, updateClient);
}

import {
  UPDATE_EVENT_TOKEN_INPUT,
  GET_EVENTS_INIT,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  UPDATE_EVENT_INIT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  ADD_EVENT_INIT,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  DELETE_EVENT_INIT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
} from "../actionTypes";

export const updateEventTokenInput = (params: any) => {
  return {
    type: UPDATE_EVENT_TOKEN_INPUT,
    data: params,
  };
};

export const getEventsSuccess = (data: any) => {
  return {
    type: GET_EVENTS_SUCCESS,
    data,
  };
};

export const getEventsFailure = (error: any) => {
  return {
    type: GET_EVENTS_FAILURE,
    error,
  };
};

export const getEvents = (params: any) => {
  const payload = { params };
  return {
    type: GET_EVENTS_INIT,
    payload,
  };
};

export const updateEventSuccess = (data: any) => {
  return {
    type: UPDATE_EVENT_SUCCESS,
    data,
  };
};

export const updateEventFailure = (error: any) => {
  return {
    type: UPDATE_EVENT_FAILURE,
    error,
  };
};

export const updateEvent = (params: any) => {
  const payload = { params };
  return {
    type: UPDATE_EVENT_INIT,
    payload,
  };
};

export const addEventSuccess = (data: any) => {
  return {
    type: ADD_EVENT_SUCCESS,
    data,
  };
};

export const addEventFailure = (error: any) => {
  return {
    type: ADD_EVENT_FAILURE,
    error,
  };
};

export const addEvent = (params: any) => {
  const payload = { params };
  return {
    type: ADD_EVENT_INIT,
    payload,
  };
};

export const deleteEventSuccess = (data: any) => {
  return {
    type: DELETE_EVENT_SUCCESS,
    data,
  };
};

export const deleteEventFailure = (error: any) => {
  return {
    type: DELETE_EVENT_FAILURE,
    error,
  };
};

export const deleteEvent = (params: any) => {
  const payload = { params };
  return {
    type: DELETE_EVENT_INIT,
    payload,
  };
};

import {
  GET_STREAKS_INIT,
  GET_STREAKS_SUCCESS,
  GET_STREAKS_FAILURE,
  UPDATE_STREAK_INIT,
  UPDATE_STREAK_SUCCESS,
  UPDATE_STREAK_FAILURE,
  ADD_STREAK_INIT,
  ADD_STREAK_SUCCESS,
  ADD_STREAK_FAILURE,
  DELETE_STREAK_INIT,
  DELETE_STREAK_SUCCESS,
  DELETE_STREAK_FAILURE,
  UPDATE_STREAK_TOKEN_INPUT,
  UPDATE_STREAK_TOKEN_MILESTONE_INPUT,
  UPDATE_STREAK_TOKEN_REWARD_INPUT,
  UPDATE_STREAK_EVENT_INPUT,
  UPDATE_STREAK_MULTIPLIER_INPUT,
  UPDATE_REWARD_MULTIPLIER_INPUT,
  RESET_STREAKS_STATES,
} from "../../redux/actionTypes";
import { IStreaksState } from "../../models/streaks/streaks";

const initialState = {
  isGettignStreaks: false,
  getStreaksResp: [],
  getStreaksRespStatus: 0,
  getStreaksError: false,
  getStreaksErrorMsg: "",
  isUpdatingStreak: false,
  updateStreakError: false,
  updateStreakErrorMsg: "",
  updateStreakResp: [],
  updateStreakRespStatus: 0,
  isAddingStreak: false,
  addStreakError: false,
  addStreakErrorMsg: "",
  addStreakResp: [],
  addStreakRespStatus: 0,
  isDeletingStreak: false,
  deleteStreakError: false,
  deleteStreakErrorMsg: "",
  deleteStreakResp: [],
  deleteStreakRespStatus: 0,
  tokens: {},
  tokensMilestone: {},
  tokensReward: {},
  events: {},
  multipliers: "",
  rewardMultipliers: "",
};

const streaks = (state: IStreaksState = initialState, action: any) => {
  switch (action.type) {
    case GET_STREAKS_INIT:
      return {
        ...state,
        isGettignStreaks: true,
        getStreaksError: false,
        getStreaksRespStatus: 0,
      };

    case GET_STREAKS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettignStreaks: false,
        getStreaksResp: data,
        getStreaksRespStatus: 200,
      };
    }

    case GET_STREAKS_FAILURE:
      const { error } = action;
      return {
        ...state,
        isGettignStreaks: false,
        getStreaksError: true,
        getStreaksErrorMsg: error,
        getStreaksRespStatus: 400,
      };

    case UPDATE_STREAK_INIT:
      return {
        ...state,
        isUpdatingStreak: true,
        updateStreakError: false,
        updateStreakRespStatus: 0,
      };

    case UPDATE_STREAK_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingStreak: false,
        updateStreakResp: data,
        updateStreakRespStatus: 200,
      };
    }

    case UPDATE_STREAK_FAILURE:
      return {
        ...state,
        isUpdatingStreak: false,
        updateStreakError: true,
        updateStreakErrorMsg: action.error,
        updateStreakRespStatus: 400,
      };

    case ADD_STREAK_INIT:
      return {
        ...state,
        isAddingStreak: true,
        addStreakError: false,
        addStreakRespStatus: 0,
      };

    case ADD_STREAK_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingStreak: false,
        addStreakResp: data,
        addStreakRespStatus: 200,
      };
    }

    case ADD_STREAK_FAILURE:
      return {
        ...state,
        isAddingStreak: false,
        addStreakError: true,
        addStreakErrorMsg: action.error,
        addStreakRespStatus: 400,
      };

    case DELETE_STREAK_INIT:
      return {
        ...state,
        isDeletingStreak: true,
        deleteStreakError: false,
        deleteStreakRespStatus: 0,
      };

    case DELETE_STREAK_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isDeletingStreak: false,
        deleteStreakResp: data,
        deleteStreakRespStatus: 200,
      };
    }

    case DELETE_STREAK_FAILURE:
      return {
        ...state,
        isDeletingStreak: false,
        deleteStreakError: true,
        deleteStreakErrorMsg: action.error,
        deleteStreakRespStatus: 400,
      };

    case UPDATE_STREAK_TOKEN_INPUT:
      const id = action.data.id;
      const value = action.data.value;
      if (action.data.e) {
        let temp: any = {};
        for (let i = 0; i < action.data.e?.length; i++) {
          temp[action.data.e[i].token_id] = action.data.e[i];
        }
        if (action.data.e.length < 1) {
          return {
            ...state,
            tokens: {},
          };
        }
        return {
          ...state,
          tokens: { ...state.tokens, ...temp },
        };
      }
      return {
        ...state,
        tokens: id
          ? {
              ...state.tokens,
              [id]: { token_id: id, value: parseFloat(value) },
            }
          : {},
      };

    case UPDATE_STREAK_TOKEN_MILESTONE_INPUT:
      const idM = action.data.id;
      const valueM = action.data.value;
      if (action.data.e) {
        let temp: any = {};
        for (let i = 0; i < action.data.e?.length; i++) {
          temp[action.data.e[i].token_id] = action.data.e[i];
        }
        if (action.data.e.length < 1) {
          return {
            ...state,
            tokensMilestone: {},
          };
        }
        return {
          ...state,
          tokensMilestone: { ...state.tokensMilestone, ...temp },
        };
      }
      return {
        ...state,
        tokensMilestone: idM
          ? {
              ...state.tokensMilestone,
              [idM]: { token_id: idM, value: parseFloat(valueM) },
            }
          : {},
      };

    case UPDATE_STREAK_TOKEN_REWARD_INPUT:
      const idR = action.data.id;
      const valueR = action.data.value;
      if (action.data.e) {
        let temp: any = {};
        for (let i = 0; i < action.data.e?.length; i++) {
          temp[action.data.e[i].token_id] = action.data.e[i];
        }
        if (action.data.e.length < 1) {
          return {
            ...state,
            tokensReward: {},
          };
        }
        return {
          ...state,
          tokensReward: { ...state.tokensReward, ...temp },
        };
      }
      return {
        ...state,
        tokensReward: idR
          ? {
              ...state.tokensReward,
              [idR]: { token_id: idR, value: parseFloat(valueR) },
            }
          : {},
      };

    case UPDATE_STREAK_EVENT_INPUT:
      // {
      //   day1: {
      //     events: [eventId1, eventId1, eventId3],
      //     randomize: false
      //   },
      //   day2: {
      //     events: [eventId1, eventId1, eventId3],
      //     randomize: false
      //   },
      // }
      if (action.data.e) {
        let temp: any = {};
        for (let i = 0; i < action.data.e?.length; i++) {
          temp[`${i + 1}`] = action.data.e[i];
        }
        return {
          ...state,
          events: temp,
        };
      }
      const day = action.data.eventDay;
      const dayPresent = state.events[day];
      const event = action.data.eventId;
      let eventPresent = false;
      if (dayPresent) {
        if (action.data.randomize === true || action.data.randomize === false) {
          return {
            ...state,
            events: {
              ...state.events,
              [day]: {
                ...state.events[day],
                randomize: action.data.randomize,
              },
            },
          };
        }
        if (dayPresent.events?.find((each: string) => each === event)) {
          eventPresent = true;
        }
        return {
          ...state,
          events: {
            ...state.events,
            [day]: {
              ...state.events[day],
              events: eventPresent
                ? dayPresent.events?.filter((each: string) => each !== event)
                : Array.from(new Set([...dayPresent.events, event])),
            },
          },
        };
      } else {
        if (action.data.randomize === true || action.data.randomize === false) {
          return {
            ...state,
            events: {
              ...state.events,
              [day]: {
                events: [],
                randomize: action.data.randomize,
              },
            },
          };
        }
        return {
          ...state,
          events: {
            ...state.events,
            [day]: {
              events: [event],
              randomize: false,
            },
          },
        };
      }

    case UPDATE_STREAK_MULTIPLIER_INPUT:
      return {
        ...state,
        multipliers: action.data.multiplierId,
      };

    case UPDATE_REWARD_MULTIPLIER_INPUT:
      return {
        ...state,
        rewardMultipliers: action.data.multiplierId,
      };

    case RESET_STREAKS_STATES:
      return {
        ...state,
        tokens: {},
        tokensMilestone: {},
        tokensReward: {},
        events: {},
        multipliers: "",
        rewardMultipliers: "",
      };

    default:
      return state;
  }
};

export default streaks;

import { useState } from "react";
import Tile from "../../components/common/tile";
import Navbar from "../../components/common/navbar";

const Analytics = () => {
  const [audience, setAudience] = useState<number>(1);
  const [activities, setActivities] = useState<number>(1);
  const [activityParticipants, setActivityParticipants] = useState<number>(1);
  const [priceAndRewards, setPriceAndRewards] = useState<number>(1);

  const filters = [
    {
      name: "Last 24 hours",
      type: 1,
    },
    {
      name: "Last 07 Days",
      type: 7,
    },
    {
      name: "Last 30 Days",
      type: 30,
    },
  ];

  return (
    <div className="flex flex-col gap-5 w-full h-[100vh] overflow-y-auto pe-10">
      <Navbar />
      <div className=" rounded-[24px] p-5 text-[18px]">
        <div className="flex items-center justify-between">
          <div>Audience</div>
          <div className="flex items-center gap-2">
            {filters.map((each: any) => {
              return (
                <div
                  className={
                    "cursor-pointer" +
                    (each.type === audience
                      ? " custom-linear-bg px-3 py-2 rounded-[8px]"
                      : "")
                  }
                  onClick={() => setAudience(each.type)}
                >
                  {each.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 mt-10">
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={100300}
              increase={4}
              name={"Total Active Users"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"percentage"}
              number={90}
              increase={4}
              name={"User Retention Rate"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={100300}
              increase={4}
              name={"CLUB Sessions"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"time"}
              number={100300}
              increase={4}
              name={"Average Session Duration"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"percentage"}
              number={10}
              decrease={4}
              name={"Churn Rate"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={10000}
              decrease={4}
              name={"Interations"}
            />
          </div>
        </div>
      </div>
      <div className=" rounded-[24px] p-5 text-[18px]">
        <div className="flex items-center justify-between">
          <div>Activities</div>
          <div className="flex items-center gap-2">
            {filters.map((each: any) => {
              return (
                <div
                  className={
                    "cursor-pointer" +
                    (each.type === activities
                      ? " custom-linear-bg px-3 py-2 rounded-[8px]"
                      : "")
                  }
                  onClick={() => setActivities(each.type)}
                >
                  {each.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 mt-10">
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={10}
              increase={4}
              name={"Number Of Activities"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={100000}
              increase={4}
              name={"Impressions"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"time"}
              number={100300}
              increase={4}
              name={"Average Activity Duration"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"money"}
              number={5220}
              decrease={4}
              name={"Revenue From Activities"}
            />
          </div>
        </div>
      </div>
      <div className=" rounded-[24px] p-5 text-[18px]">
        <div className="flex items-center justify-between">
          <div>Activity Participants</div>
          <div className="flex items-center gap-2">
            {filters.map((each: any) => {
              return (
                <div
                  className={
                    "cursor-pointer" +
                    (each.type === activityParticipants
                      ? " custom-linear-bg px-3 py-2 rounded-[8px]"
                      : "")
                  }
                  onClick={() => setActivityParticipants(each.type)}
                >
                  {each.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 mt-10">
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={100300}
              increase={4}
              name={"Number Of Participants"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={10000}
              increase={4}
              name={"Total Points Distributed"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"percentage"}
              number={84.56}
              decrease={4}
              name={"Participants Transacting"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"money"}
              number={329.34}
              increase={4}
              name={"Avg. Transaction Value"}
            />
          </div>
        </div>
      </div>
      <div className=" rounded-[24px] p-5 text-[18px]">
        <div className="flex items-center justify-between">
          <div>Points & Rewards</div>
          <div className="flex items-center gap-2">
            {filters.map((each: any) => {
              return (
                <div
                  className={
                    "cursor-pointer" +
                    (each.type === priceAndRewards
                      ? " custom-linear-bg px-3 py-2 rounded-[8px]"
                      : "")
                  }
                  onClick={() => setPriceAndRewards(each.type)}
                >
                  {each.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="grid grid-cols-4 gap-5 mt-10">
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={100300}
              increase={4}
              name={"Total Points Earned"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={1000}
              increase={4}
              name={"Avg. Points Per User"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"percentage"}
              number={90}
              decrease={4}
              name={"Total Rewards Reedemed"}
            />
          </div>
          <div className="col-span-1">
            <Tile
              type={"number"}
              number={0.45}
              increase={4}
              name={"Avg. Rewards Per User"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;

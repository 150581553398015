export function decimalToRatioString(decimal: number) {
  // Find the greatest common divisor (GCD) using Euclidean algorithm
  function gcd(a: any, b: any): any {
    return b === 0 ? a : gcd(b, a % b);
  }
  let multiplier;

  if (Number.isInteger(decimal)) {
    multiplier = 10 ** (decimal.toString() + ".0").split(".")[1].length;
  } else {
    multiplier = 10 ** decimal.toString().split(".")[1].length;
  }

  const numerator = decimal * multiplier;
  const denominator = multiplier;
  const commonDivisor = gcd(numerator, denominator);

  const ratioNumerator = numerator / commonDivisor;
  const ratioDenominator = denominator / commonDivisor;

  return `${ratioNumerator}:${ratioDenominator}`;
}

export const RE_DIGIT = new RegExp(/^\d+$/);

import { useDispatch, useSelector } from "react-redux";
import Text from "../modals/nudge/text";
import { updateStreakTokenRewardInput } from "../../../redux/streak/streakActions";
export interface IRewardTokensProps {
    rewardTokens: Array<string>;
    setRewardTokens: (val: any) => void;
    tokensResp: any;
    setShowTokensReward: () => void;
}
const RewardTokens = ({
    rewardTokens,
    setRewardTokens,
    tokensResp,
    setShowTokensReward,
}: IRewardTokensProps) => {
    const dispatch = useDispatch();
    const { tokensReward } = useSelector((state: any) => state.streaks);
    return (
        <form
            onSubmit={(e: any) => {
                e.preventDefault();
                setShowTokensReward();
            }}
        >
            <div className="p-5 w-[750px]">
                <div className="flex justify-between">
                    <div>
                        <div className="text-2xl text-[#4C4C66] font-bold">
                            Add Tokens
                        </div>
                    </div>
                    <div
                        className="cursor-pointer"
                        onClick={() => setShowTokensReward()}
                    >
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                                fill="#4C4C66"
                            />
                        </svg>
                    </div>
                </div>
                <div className="mt-5 flex flex-col w-full gap-3 overflow-y-auto max-h-[550px]">
                    {tokensResp.map((each: any, i: number) => {
                        return (
                            <div
                                className={
                                    "rounded border-2 border-[#FFAFA8]  cursor-pointer p-3" +
                                    (rewardTokens.includes(each._id)
                                        ? " bg-white"
                                        : " bg-[#FFF2F2]")
                                }
                            >
                                <div
                                    className={"flex items-center gap-3"}
                                    onClick={() => {
                                        if (rewardTokens.includes(each._id)) {
                                            setRewardTokens(
                                                rewardTokens.filter(
                                                    (element: string) =>
                                                        element !== each._id
                                                )
                                            );
                                        } else {
                                            setRewardTokens(
                                                Array.from(
                                                    new Set([
                                                        ...rewardTokens,
                                                        each._id,
                                                    ])
                                                )
                                            );
                                        }
                                    }}
                                >
                                    {rewardTokens.includes(each._id) ? (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                fill="#5F5E76"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                fill="#FFAFA8"
                                            />
                                        </svg>
                                    )}
                                    <div className="flex items-center gap-3">
                                        <img
                                            src={each.image}
                                            alt={each.image}
                                            className="w-[30px]"
                                        />
                                        <div className="text-lg font-bold">
                                            {each.name}
                                        </div>
                                    </div>
                                </div>
                                {rewardTokens.includes(each._id) && (
                                    <div
                                        className="mt-3"
                                        onClick={(e: any) =>
                                            e.stopPropagation()
                                        }
                                    >
                                        <div className="mt-1 text-[14px] text-[#4C4C66]">
                                            Number of tokens to give
                                        </div>
                                        <Text
                                            text={
                                                tokensReward[each._id]?.[
                                                    "value"
                                                ]
                                            }
                                            setText={(e: any) => {
                                                dispatch(
                                                    updateStreakTokenRewardInput(
                                                        {
                                                            id: each._id,
                                                            value: e,
                                                        }
                                                    )
                                                );
                                            }}
                                            place={"e.g 5"}
                                            full={false}
                                            required={true}
                                            type={"number"}
                                        />
                                        <div className="mt-3 text-[14px] text-[#4C4C66]">
                                            Note : Enter of the transaction
                                            amount to be given as token
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="mt-10 mb-5 px-5 flex items-center justify-end">
                    <button
                        className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
                        type={"submit"}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </form>
    );
};

export default RewardTokens;

import ClubConsole from "../../containers/club-console/clubConsole";
import Sidebar from "../../containers/sidebar/sidebar";

const ClubConsoleIndex = () => {
  return (
    <div className="text-white  min-h-screen">
      <div className="flex gap-10">
        <Sidebar />
        <ClubConsole />
      </div>
    </div>
  );
};

export default ClubConsoleIndex;

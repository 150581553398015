import Analytics from "../../containers/analytics/analytics";
import Sidebar from "../../containers/sidebar/sidebar";

const AnalyticsIndex = () => {
  return (
    <div className="text-white  min-h-screen">
      <div className="flex gap-10">
        <Sidebar />
        <Analytics />
      </div>
    </div>
  );
};

export default AnalyticsIndex;

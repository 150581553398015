type SelectProps = {
    values: Array<string>;
    selected: string;
    setSelected: (val: string) => void;
    required?: boolean;
    default?: any;
    classname?: string;
};

const Select = ({
    values,
    selected,
    setSelected,
    required,
    classname = "",
}: SelectProps) => {
    return (
        <div onClick={(e: any) => e.stopPropagation()}>
            <select
                className={`px-3 py-2 border-2 border-[#FFAFA8] outline-none rounded bg-white ${classname}`}
                onChange={(e: any) => setSelected(e.target.value)}
                required={required}
                value={selected}
            >
                <option value={""}>Select</option>
                {values.map((each: string) => {
                    return <option value={each}>{each}</option>;
                })}
            </select>
        </div>
    );
};
export default Select;

import {
  ADD_API_INIT,
  ADD_API_SUCCESS,
  ADD_API_FAILURE,
  GET_API_INIT,
  GET_API_SUCCESS,
  GET_API_FAILURE,
  UPDATE_API_INIT,
  UPDATE_API_SUCCESS,
  UPDATE_API_FAILURE,
  DELETE_API_INIT,
  DELETE_API_SUCCESS,
  DELETE_API_FAILURE,
  TOGGLE_CONFIRMATION_POPUP,
} from "../actionTypes";

export const addApiSuccess = (data: any) => {
  return {
    type: ADD_API_SUCCESS,
    data,
  };
};

export const addApiFailure = (error: any) => {
  return {
    type: ADD_API_FAILURE,
    error,
  };
};

export const addApi = (params: any) => {
  const payload = { params };
  return {
    type: ADD_API_INIT,
    payload,
  };
};

export const updateApiSuccess = (data: any) => {
  return {
    type: UPDATE_API_SUCCESS,
    data,
  };
};

export const updateApiFailure = (error: any) => {
  return {
    type: UPDATE_API_FAILURE,
    error,
  };
};

export const updateApi = (params: any) => {
  const payload = { params };
  return {
    type: UPDATE_API_INIT,
    payload,
  };
};

export const getApiSuccess = (data: any) => {
  return {
    type: GET_API_SUCCESS,
    data,
  };
};

export const getApiFailure = (error: any) => {
  return {
    type: GET_API_FAILURE,
    error,
  };
};

export const getApi = (params: any) => {
  const payload = { params };
  return {
    type: GET_API_INIT,
    payload,
  };
};

export const deleteApiSuccess = (data: any) => {
  return {
    type: DELETE_API_SUCCESS,
    data,
  };
};

export const deleteApiFailure = (error: any) => {
  return {
    type: DELETE_API_FAILURE,
    error,
  };
};

export const deleteApi = (params: any) => {
  const payload = { params };
  return {
    type: DELETE_API_INIT,
    payload,
  };
};

export const toggleConfirmationPopup = (data: any) => {
  return {
    type: TOGGLE_CONFIRMATION_POPUP,
    data,
  };
};

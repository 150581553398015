// import Bug from "../../containers/bug/bug";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../containers/sidebar/sidebar";
import Tokens from "../../containers/tokens/tokens";
import useHomePathFinder from "../../hooks/useHomePathFinder";
import { useEffect } from "react";

const TokensIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hasAccess, availablePaths] = useHomePathFinder(location.pathname);
  useEffect(() => {
    if (hasAccess) {
      // console.log("granted");
    } else {
      navigate(availablePaths?.length > 0 ? availablePaths[0] : "/settings");
    }
  }, []);
  return (
    <div className="text-white min-h-screen">
      <div className="flex ">
        <Sidebar />
        <Tokens />
      </div>
    </div>
  );
};

export default TokensIndex;

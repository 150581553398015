import {
  ADD_REFERRAL_INIT,
  ADD_REFERRAL_SUCCESS,
  ADD_REFERRAL_FAILURE,
  GET_REFERRAL_INIT,
  GET_REFERRAL_SUCCESS,
  GET_REFERRAL_FAILURE,
  UPDATE_REFERRAL_INIT,
  UPDATE_REFERRAL_SUCCESS,
  UPDATE_REFERRAL_FAILURE,
  DELETE_REFERRAL_INIT,
  DELETE_REFERRAL_SUCCESS,
  DELETE_REFERRAL_FAILURE,
} from "../actionTypes";
import { IReferralsState } from "../../models/referral/referral";
const initialState = {
  isGettingReferrals: false,
  getReferralsResp: [],
  getReferralsRespStatus: 0,
  getReferralsError: false,
  getReferralsErrorMsg: "",
  isUpdatingReferral: false,
  updateReferralError: false,
  updateReferralErrorMsg: "",
  updateReferralResp: [],
  updateReferralRespStatus: 0,
  isAddingReferral: false,
  addReferralError: false,
  addReferralErrorMsg: "",
  addReferralResp: [],
  addReferralRespStatus: 0,
  isDeletingReferral: false,
  deleteReferralError: false,
  deleteReferralErrorMsg: "",
  deleteReferralResp: [],
  deleteReferralRespStatus: 0,
  tokens: {},
  tokensMilestone: {},
  tokensReward: {},
  events: {},
  multipliers: "",
  rewardMultipliers: "",
};
const referral = (state: IReferralsState = initialState, action: any) => {
  switch (action.type) {
    case GET_REFERRAL_INIT:
      return {
        ...state,
        isGettingReferrals: true,
        getReferralsError: false,
        getReferralsRespStatus: 0,
      };

    case GET_REFERRAL_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingReferrals: false,
        getReferralsResp: data,
        getReferralsRespStatus: 200,
      };
    }

    case GET_REFERRAL_FAILURE:
      return {
        ...state,
        isGettingReferrals: false,
        getReferralsError: true,
        getReferralsErrorMsg: action.error,
        getReferralsRespStatus: 400,
      };

    case UPDATE_REFERRAL_INIT:
      return {
        ...state,
        isUpdatingReferral: true,
        updateReferralError: false,
        updateReferralRespStatus: 0,
      };

    case UPDATE_REFERRAL_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingReferral: false,
        updateReferralResp: data,
        updateReferralRespStatus: 200,
      };
    }

    case UPDATE_REFERRAL_FAILURE:
      return {
        ...state,
        isUpdatingReferral: false,
        updateReferralError: true,
        updateReferralErrorMsg: action.error,
        updateReferralRespStatus: 400,
      };

    case ADD_REFERRAL_INIT:
      return {
        ...state,
        isAddingReferral: true,
        addReferralError: false,
        addReferralRespStatus: 0,
      };

    case ADD_REFERRAL_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingReferral: false,
        addReferralResp: data,
        addReferralRespStatus: 200,
      };
    }

    case ADD_REFERRAL_FAILURE:
      return {
        ...state,
        isAddingReferral: false,
        addReferralError: true,
        addReferralErrorMsg: action.error,
        addReferralRespStatus: 400,
      };

    case DELETE_REFERRAL_INIT:
      return {
        ...state,
        isDeletingReferral: true,
        deleteReferralError: false,
        deleteReferralRespStatus: 0,
      };

    case DELETE_REFERRAL_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isDeletingReferral: false,
        deleteReferralResp: data,
        deleteReferralRespStatus: 200,
      };
    }

    case DELETE_REFERRAL_FAILURE:
      return {
        ...state,
        isDeletingReferral: false,
        deleteReferralError: true,
        deleteReferralErrorMsg: action.error,
        deleteReferralRespStatus: 400,
      };

    default:
      return state;
  }
};
export default referral;

import { put, call, takeLatest } from "redux-saga/effects";
import { baseURL, endPoints } from "../../utils/apiEndPoints";
import {
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
} from "../../utils/apiRequests";
import {
    ADD_STREAK_INIT,
    UPDATE_STREAK_INIT,
    GET_STREAKS_INIT,
    DELETE_STREAK_INIT,
} from "../actionTypes";
import {
    addStreakSuccess,
    addStreakFailure,
    updateStreakSuccess,
    updateStreakFailure,
    getStreaksSuccess,
    getStreaksFailure,
    deleteStreakSuccess,
    deleteStreakFailure,
} from "./streakActions";

function* addStreak(action: any): any {
    try {
        const { token, ...reqData } = action.payload.params;
        const data = yield call(
            postRequest,
            `${baseURL}/${endPoints.addStreak}`,
            { ...reqData.streakData },
            {
                Authorization: `Bearer ${token}`,
            }
        );
        if (!data.error) {
            yield put(addStreakSuccess(data));
        } else {
            yield put(addStreakFailure(data));
        }
    } catch (err: any) {
        yield put(addStreakFailure(err.message));
    }
}

export function* addStreakSaga() {
    yield takeLatest(ADD_STREAK_INIT, addStreak);
}

function* updateStreak(action: any): any {
    try {
        const { token, streak_id, ...reqData } = action.payload.params;
        const data = yield call(
            patchRequest,
            `${baseURL}/${endPoints.updateStreak}/${streak_id}`,
            { ...reqData },
            {
                Authorization: `Bearer ${action.payload.params.token}`,
            }
        );
        if (!data.error) {
            yield put(updateStreakSuccess(data));
        } else {
            yield put(updateStreakFailure(data));
        }
    } catch (err: any) {
        yield put(updateStreakFailure(err.message));
    }
}

export function* updateStreakSaga() {
    yield takeLatest(UPDATE_STREAK_INIT, updateStreak);
}

function* getStreaks(action: any): any {
    try {
        // const { token, ...reqData } = action.payload.params;
        const data = yield call(
            getRequest,
            `${baseURL}/${endPoints.getAllStreaks}`,
            {
                Authorization: `Bearer ${action.payload.params.token}`,
            }
        );
        if (!data.error) {
            yield put(getStreaksSuccess(data));
        } else {
            yield put(getStreaksFailure(data));
        }
    } catch (err: any) {
        yield put(getStreaksFailure(err.message));
    }
}

export function* getStreaksSaga() {
    yield takeLatest(GET_STREAKS_INIT, getStreaks);
}

function* deleteStreak(action: any): any {
    try {
        const { token, id } = action.payload.params;
        const data = yield call(
            deleteRequest,
            `${baseURL}/${endPoints.deleteStreak}/${id}`,
            {
                Authorization: `Bearer ${token}`,
            }
        );
        if (!data.error) {
            yield put(deleteStreakSuccess(data));
        } else {
            yield put(deleteStreakFailure(data));
        }
    } catch (err: any) {
        yield put(deleteStreakFailure(err.message));
    }
}

export function* deleteStreakSaga() {
    yield takeLatest(DELETE_STREAK_INIT, deleteStreak);
}

import Bug from "../../containers/bug/bug";
import Sidebar from "../../containers/sidebar/sidebar";
import { useUserAuth } from "../../hooks/useAuthContextProvider";

const BugIndex = () => {
  const { user } = useUserAuth();
  return (
    <div className="text-white h-screen">
      <div className="flex">
        {user && <Sidebar />}
        <Bug />
      </div>
    </div>
  );
};

export default BugIndex;

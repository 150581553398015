import { useEffect, useState } from "react";
import Text from "../../components/gamification/modals/nudge/text";
import { useDispatch, useSelector } from "react-redux";
import { clientOnboarding } from "../../redux/auth/authActions";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/loader";

const Onboarding = () => {
    const { user } = useUserAuth();
    const [cEmail, setCEmail] = useState<string>("");
    const [cLegalName, setCLegalName] = useState<string>("");
    const [cSector, setCSector] = useState<string>("Food Aggregators");
    const [cPersons, setCPersons] = useState<string>("<50");
    const [cTeam, setCTeam] = useState<string>("");

    const [more, setMore] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isClientOnboarding, clientOnboardingRespStatus } = useSelector(
        (state: any) => state.auth
    );
    useEffect(() => {
        if (clientOnboardingRespStatus === 200) {
            navigate(0);
        }
    }, [isClientOnboarding]);
    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (more) {
            dispatch(
                clientOnboarding({
                    company_legal_name: cLegalName,
                    category: cSector,
                    client_user_email: cEmail,
                    employees: cPersons,
                    inhouse_tech_team: cTeam === "Yes" ? true : false,
                    token: user.accessToken,
                })
            );
        } else {
            setMore(true);
        }
    };
    return (
        <div className="w-full">
            <div className="custom-bg h-[100vh] p-10">
                <div className="flex items-center justify-end">
                    <div className="flex items-center gap-3">
                        <div className="text-3xl text-[#65EECB]">
                            snipeit.<span className="text-[#82C8F1]">ai</span>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center h-full">
                    <div className="bg-white rounded-lg p-10 w-full max-w-[700px]">
                        <div className="text-xl text-[#4C4C66] font-bold">
                            {more
                                ? `Tell us more about ${cLegalName}`
                                : "Help us with basic details"}
                        </div>
                        <form onSubmit={handleSubmit}>
                            {more ? (
                                <>
                                    <div className="text-[14px] text-[#4C4C66] mt-5">
                                        Which sector does {cLegalName} serve?
                                    </div>
                                    <select
                                        className="bg-white border-2 border-[#FFAFA8] px-3 py-2 outline-none rounded w-full text-[#4C4C66]"
                                        onChange={(e: any) =>
                                            setCSector(e.target.value)
                                        }
                                    >
                                        {[
                                            "Food Aggregators",
                                            "Restaurants and Cafes",
                                            "Pet and Pet Care",
                                            "Finance and Investing",
                                            "D2C Shopping",
                                            "Fashion ANd Lifestyle",
                                            "Travel and Hospitality",
                                            "News and Infotainment",
                                            "Health and Wellness",
                                            "Education and E-learning",
                                            "Other",
                                        ].map((each: string) => {
                                            return (
                                                <option value={each}>
                                                    {each}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div className="mt-3 text-[14px] text-[#4C4C66]">
                                        How many people work for {cLegalName}
                                    </div>
                                    <select
                                        className="bg-white border-2 border-[#FFAFA8] px-3 py-2 outline-none rounded w-full text-[#4C4C66]"
                                        onChange={(e: any) =>
                                            setCPersons(e.target.value)
                                        }
                                    >
                                        {[
                                            "<50",
                                            "50-100",
                                            "200-1000",
                                            ">1000",
                                        ].map((each: string) => {
                                            return (
                                                <option value={each}>
                                                    {each}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div className="mt-3 text-[14px] text-[#4C4C66]">
                                        Does {cLegalName} have in-house
                                        tech-team?
                                    </div>
                                    <select
                                        className="bg-white border-2 border-[#FFAFA8] px-3 py-2 outline-none rounded w-full text-[#4C4C66]"
                                        onChange={(e: any) =>
                                            setCTeam(e.target.value)
                                        }
                                    >
                                        {["Yes", "No"].map((each: string) => {
                                            return (
                                                <option value={each}>
                                                    {each}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </>
                            ) : (
                                <>
                                    <div className="text-[14px] text-[#4C4C66] mt-5">
                                        Company Email ID
                                    </div>
                                    <Text
                                        text={cEmail}
                                        setText={setCEmail}
                                        place={"Enter your company email id"}
                                        full={true}
                                        required={true}
                                    />
                                    <div className="mt-3 text-[14px] text-[#4C4C66]">
                                        Company Legal Name
                                    </div>
                                    <Text
                                        text={cLegalName}
                                        setText={setCLegalName}
                                        place={
                                            "Enter the legal name of your company"
                                        }
                                        full={true}
                                        required={true}
                                    />
                                </>
                            )}
                            <button
                                className="outline-none text-lg text-white font-bold flex items-center justify-center bg-[#FF7B5F] rounded mt-10 w-full p-3"
                                type="submit"
                                disabled={isClientOnboarding}
                            >
                                {isClientOnboarding ? <Loader /> : "Next"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Onboarding;

import { useEffect, useRef } from "react";

type SelectProps = {
    selected: string;
    setSelected: (val: string) => void;
    required: boolean;
    min?: any;
    classname?: string;
    showMessage?: boolean;
};

const DateContainer = ({
    selected,
    setSelected,
    required,
    min,
    classname,
    showMessage = false,
}: SelectProps) => {
    const ref = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (showMessage) {
            ref.current?.reportValidity();
        }
    }, [showMessage]);
    return (
        <div className={classname ? classname : ""}>
            <input
                ref={ref}
                type="date"
                value={selected}
                onChange={(e: any) => setSelected(e.target.value)}
                className="px-3 py-2 border-2 border-[#FFAFA8] outline-none rounded bg-white w-full"
                required={required ? true : false}
                min={min}
            />
        </div>
    );
};
export default DateContainer;

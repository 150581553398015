import { useEffect, useState } from "react";
import Navbar from "../../components/common/navbar";
import Token from "./token";
import Event from "./event";
import { useDispatch, useSelector } from "react-redux";
import { getTokens } from "../../redux/token/tokenActions";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { getEvents } from "../../redux/token/eventActions";
import ErrorBoundary from "../../components/common/errorBoundary";
import Bug from "../bug/bug";
import Multipliers from "./multipliers";
import { getMultipliers } from "../../redux/token/multipliersActions";
import Milestone from "./milestone";
import { getMilestone } from "../../redux/token/milestoneActions";

const Tokens = () => {
    const { user } = useUserAuth();
    const [active, setActive] = useState<string>("tokens");
    const { getTokensRespStatus } = useSelector((state: any) => state.tokens);
    const { getEventsRespStatus } = useSelector((state: any) => state.events);
    const { getMultipliersRespStatus } = useSelector(
        (state: any) => state.multipliers
    );
    const { getMilestoneRespStatus } = useSelector(
        (state: any) => state.milestone
    );
    const dispatch = useDispatch();
    useEffect(() => {
        if (getTokensRespStatus !== 200) {
            dispatch(getTokens({ token: user.accessToken }));
        }
        if (getEventsRespStatus !== 200) {
            dispatch(getEvents({ token: user.accessToken }));
        }
        if (getMultipliersRespStatus !== 200) {
            dispatch(getMultipliers({ token: user.accessToken }));
        }
        if (getMilestoneRespStatus !== 200) {
            dispatch(getMilestone({ token: user.accessToken }));
        }
    }, []);
    return (
        <ErrorBoundary fallbackComponent={<Bug />}>
            <div className="w-full h-[100vh] overflow-y-auto text-[#4C4C66]">
                <div className="px-10">
                    <Navbar />
                </div>
                <div className="flex items-center gap-5 mt-10 px-10">
                    <div
                        className={
                            "text-lg cursor-pointer" +
                            (active === "tokens"
                                ? " border-b-2 border-[#4C4C66] font-bold"
                                : "")
                        }
                        onClick={() => setActive("tokens")}
                    >
                        Tokens
                    </div>
                    <div
                        className={
                            "text-lg cursor-pointer" +
                            (active === "events"
                                ? " border-b-2 border-[#4C4C66] font-bold"
                                : "")
                        }
                        onClick={() => {
                            setActive("events");
                        }}
                    >
                        Events
                    </div>
                    <div
                        className={
                            "text-lg cursor-pointer" +
                            (active === "multipliers"
                                ? " border-b-2 border-[#4C4C66] font-bold"
                                : "")
                        }
                        onClick={() => {
                            setActive("multipliers");
                        }}
                    >
                        Multipliers
                    </div>
                    {/* <div
                        className={
                            "text-lg cursor-pointer" +
                            (active === "milestones"
                                ? " border-b-2 border-[#4C4C66] font-bold"
                                : "")
                        }
                        onClick={() => {
                            setActive("milestones");
                        }}
                    >
                        Milestones
                    </div> */}
                </div>
                {active === "tokens" && <Token />}
                {active === "events" && <Event />}
                {active === "multipliers" && <Multipliers />}
                {/* {active === "milestones" && <Milestone />} */}
            </div>
        </ErrorBoundary>
    );
};

export default Tokens;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import copy from "../../assets/svg/copy.svg";
import invisible from "../../assets/svg/invisible.svg";
import visible from "../../assets/svg/visible.svg";
import Loader from "../../components/common/loader";
import Navbar from "../../components/common/navbar";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import {
  addApi,
  deleteApi,
  getApi,
  toggleConfirmationPopup,
  updateApi,
} from "../../redux/api/apiActions";

const DeveloperTools = () => {
  const [accessProdShow, setAccessProdShow] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { user } = useUserAuth();
  const {
    isGettingApi,
    getApiResp,
    getApiRespStatus,
    getApiError,
    getApiErrorMsg,
    isAddingApi,
    addApiError,
    addApiRespStatus,
    isUpdatingApi,
    updateApiRespStatus,
    updateApiError,
    isDeletingApi,
    deleteApiRespStatus,
    deleteApiError,
  } = useSelector((state: any) => state.api);
  useEffect(() => {
    if (getApiRespStatus !== 200) {
      dispatch(getApi({ token: user.accessToken }));
    }
  }, []);

  useEffect(() => {
    if (
      addApiRespStatus === 200 ||
      updateApiRespStatus === 200 ||
      deleteApiRespStatus === 200
    ) {
      dispatch(getApi({ token: user.accessToken }));
      dispatch(toggleConfirmationPopup({ isConfirmation: false }));
      setAccessProdShow(false);
      // setAccessDevShow(false);
    } else if (addApiError || updateApiError || deleteApiError) {
      alert("Something went wrong");
    }
  }, [isAddingApi, isUpdatingApi, isDeletingApi]);
  const { isProduction } = useSelector((state: any) => state.developerTools);
  return (
    <div className="w-full h-[100vh] overflow-y-auto">
      <div className="px-10">
        <Navbar />
      </div>
      <div className="bg-[#F9F9F9] px-10 h-full">
        <div className="text-[#4C4C66] text-3xl font-bold  py-5">
          API Credentails
        </div>
        {isGettingApi && (
          <div className="flex items-center justify-center mt-5">
            <Loader heavy={true} />
          </div>
        )}
        {getApiError && getApiErrorMsg}
        {getApiRespStatus === 200 && getApiResp.data.value.api_key && (
          <>
            {
              <div className="bg-white rounded border border-[#EFF0F6] p-3 mt-5">
                <div className="flex items-center justify-between">
                  {isProduction && (
                    <div className="text-lg font-bold text-[#FF2E2E]">
                      Production
                    </div>
                  )}
                  {isProduction === false && (
                    <div className="text-lg font-bold text-[#1D9C5F]">
                      Development
                    </div>
                  )}
                  <div className="flex items-center gap-1">
                    {isUpdatingApi ? (
                      <Loader small={true} heavy={true} />
                    ) : (
                      <div
                        className="text-[#4C4C66] cursor-pointer"
                        onClick={() =>
                          dispatch(
                            toggleConfirmationPopup({
                              isConfirmation: true,
                              confirmationFunction: () =>
                                dispatch(
                                  updateApi({
                                    token: user.accessToken,
                                    expires_at: new Date(),
                                  })
                                ),
                              confirmationText:
                                "Do you really want to refresh the key?",
                            })
                          )
                        }
                      >
                        Refresh
                      </div>
                    )}
                    <div className="text-[#4C4C66]">|</div>
                    {isDeletingApi ? (
                      <Loader small={true} heavy={true} />
                    ) : (
                      <div
                        className="text-[#4C4C66] cursor-pointer"
                        onClick={() =>
                          dispatch(
                            toggleConfirmationPopup({
                              isConfirmation: true,
                              confirmationFunction: () =>
                                dispatch(
                                  deleteApi({ token: user.accessToken })
                                ),
                              confirmationText:
                                "Do you really want to delete the api key?",
                            })
                          )
                        }
                      >
                        Revoke
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <div className="col-span-3">
                    <div className="text-sm text-[#4C4C66]">App access key</div>
                    <div className="mt-3 border-2 border-[#FFAFA8] border-dashed p-3 rounded">
                      <div className="flex items-center justify-between">
                        {
                          <div className="text-[#4C4C66]">
                            {accessProdShow
                              ? getApiResp.data.value.api_key
                              : "***********************************************************"}
                          </div>
                        }
                        <div className="flex items-center gap-1">
                          <div className="text-[#4C4C66]">
                            {
                              <img
                                src={accessProdShow ? visible : invisible}
                                alt="v"
                                className="w-[30px] h-[30px] cursor-pointer pt-1"
                                onClick={() =>
                                  setAccessProdShow(!accessProdShow)
                                }
                              />
                            }
                          </div>
                          <div className="text-[#4C4C66]">|</div>
                          <div className="text-[#4C4C66]">
                            <img
                              src={copy}
                              alt="c"
                              className="w-[30px] h-[30px] cursor-pointer pt-1"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  getApiResp.data.value.api_key
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3">
                    <div className="text-sm text-[#4C4C66] mt-3">
                      API Base URL
                    </div>
                    <div className="mt-3 border-2 border-[#FFAFA8] border-dashed p-3 rounded">
                      <div className="flex items-center justify-between">
                        {
                          <div className="text-[#4C4C66]">
                            {isProduction
                              ? "https://tms-prod.snipeit.ai"
                              : "https://tms-dev.snipeit.ai"}
                          </div>
                        }
                        <div className="flex items-center gap-1">
                          <div className="text-[#4C4C66]">
                            <img
                              src={copy}
                              alt="c"
                              className="w-[30px] h-[30px] cursor-pointer pt-1"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  isProduction
                                    ? "https://tms-prod.snipeit.ai"
                                    : "https://tms-dev.snipeit.ai"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-span-1">
                  <div className="text-sm text-[#4C4C66]">App access key</div>
                  <div className="mt-3 border-2 border-[#FFAFA8] border-dashed p-3 rounded">
                    <div className="flex items-center justify-between">
                      <div className="text-[#4C4C66]">
                        {privateProdShow ? privateProd : "******************"}
                      </div>
                      <div className="flex items-center gap-1">
                        <div className="text-[#4C4C66]">
                          <img
                            src={privateProdShow ? visible : invisible}
                            alt="v"
                            className="w-[30px] h-[30px] cursor-pointer pt-1"
                            onClick={() => setPrivateProdShow(!privateProdShow)}
                          />
                        </div>
                        <div className="text-[#4C4C66]">|</div>
                        <div className="text-[#4C4C66]">
                          <img
                            src={copy}
                            alt="c"
                            className="w-[30px] h-[30px] cursor-pointer pt-1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            }
          </>
        )}
        {getApiRespStatus === 200 &&
          getApiResp.data.value.api_key === undefined && (
            <div className="bg-white rounded border border-[#EFF0F6] p-3">
              <div className="text-lg text-[#4C4C66] text-center">
                Oops! Looks like you dont have any active API Key
              </div>
              <div className="flex items-center justify-center">
                <div
                  className="text-lg rounded bg-[#FFF2F1] cursor-pointer text-[#4C4C66] mt-5 px-5 py-2"
                  onClick={() => {
                    dispatch(addApi({ token: user.accessToken }));
                  }}
                >
                  {isAddingApi ? <Loader /> : "Add a key"}
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default DeveloperTools;

// {
//   brands: {
//     type: "exclude/include",
//     items: [],
//   },
//   categories: {
//     type: "exclude/include",
//     items: []
//   },
//   id: any,
//   type: number
// }

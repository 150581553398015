import DeveloperTools from "../../containers/developerTools/developerTools";
// import Gamification from "../../containers/gamification/gamification";
import Sidebar from "../../containers/sidebar/sidebar";

const DeveloperToolsIndex = () => {
  return (
    <div className="text-white  min-h-screen">
      <div className="flex ">
        <Sidebar />
        <DeveloperTools />
      </div>
    </div>
  );
};

export default DeveloperToolsIndex;

import { useState } from "react";
import nudgeCard from "../../../../assets/svg/nudgeCard.svg";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import {
  addNudge,
  toggleModal,
} from "../../../../redux/gamification/gamificationActions";

const all = [uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4(), uuidv4()];
const NudgeCard = () => {
  const [selected, setSelected] = useState<Array<number>>([]);
  const addNudges = () => {
    dispatch(
      addNudge({
        id: "",
        nudges: selected.map((each: any) => {
          return {
            nudge: each,
            visibility: false,
          };
        }),
      })
    );
    dispatch(toggleModal(""));
  };
  const dispatch = useDispatch();
  return (
    <div className="w-full">
      <div className="flex items-center justify-between px-5 mt-5 pt-3 border-t-2 border-dashed border-[#FE7B5F]">
        <div className="text-[#5F5E76]">Templates for: Category name</div>
        <div className="text-[#5F5E76]">Selected {selected.length}</div>
      </div>
      <div className="mt-5 h-[430px] overflow-y-auto">
        <div className="grid grid-cols-3 gap-5 px-5">
          {all.map((each: any, i: number) => {
            return (
              <div
                className="rounded bg-[#FFF2F1] w-[200px] max-w-[200px] overflow-hidden cursor-pointer shadow-lg relative"
                onClick={() => {
                  setSelected(
                    selected.includes(each)
                      ? selected.filter((element: number) => element !== each)
                      : Array.from(new Set([...selected, each]))
                  );
                }}
              >
                <img src={nudgeCard} alt="nudgeCard" className="w-full" />
                <div className="absolute top-2 right-2">
                  {selected.includes(each) ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                        fill="#5F5E76"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 0.5H1.5C1.10218 0.5 0.720644 0.658035 0.43934 0.93934C0.158035 1.22064 0 1.60218 0 2V17C0 17.3978 0.158035 17.7794 0.43934 18.0607C0.720644 18.342 1.10218 18.5 1.5 18.5H16.5C16.8978 18.5 17.2794 18.342 17.5607 18.0607C17.842 17.7794 18 17.3978 18 17V2C18 1.60218 17.842 1.22064 17.5607 0.93934C17.2794 0.658035 16.8978 0.5 16.5 0.5ZM1.5 17V2H16.5V17H1.5Z"
                        fill="#4C4C66"
                      />
                    </svg>
                  )}
                </div>
                <div className="p-3">
                  <div className="rounded-[100px] flex items-center gap-2 border border-[#FE7B5F] px-1 py-[1px] w-fit">
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="3" cy="3" r="3" fill="#6200EE" />
                    </svg>
                    <div className="text-[12px]">Nudge Catergory</div>
                  </div>
                  <div className="mt-3 text-[#4C4C76] font-bold">
                    Nudge name
                  </div>
                  <div className="text-[#3189B4] text-[12px] line-clamp-2 text-ellipsis">
                    {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Enim consequatur eligendi culpa, esse laborum ratione
                    aliquam nobis possimus deserunt iure fugit blanditiis
                    mollitia animi numquam dolorum, recusandae laudantium.
                    Velit, ratione! */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-5 mb-5 px-5 flex items-center justify-end">
        <div
          className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
          onClick={() => addNudges()}
        >
          Continue
        </div>
      </div>
    </div>
  );
};
export default NudgeCard;

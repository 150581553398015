import {
  ADD_USER_VARIABLE_INIT,
  ADD_USER_VARIABLE_SUCCESS,
  ADD_USER_VARIABLE_FAILURE,
  UPDATE_USER_VARIABLE_INIT,
  UPDATE_USER_VARIABLE_SUCCESS,
  UPDATE_USER_VARIABLE_FAILURE,
  GET_USER_VARIABLE_INIT,
  GET_USER_VARIABLE_SUCCESS,
  GET_USER_VARIABLE_FAILURE,
  DELETE_USER_VARIABLE_INIT,
  DELETE_USER_VARIABLE_SUCCESS,
  DELETE_USER_VARIABLE_FAILURE,
  SEARCH_USER_WITH_ID_INIT,
  SEARCH_USER_WITH_ID_SUCCESS,
  SEARCH_USER_WITH_ID_FAILURE,
  GET_USER_TRANSACTION_HISTORY_INIT,
  GET_USER_TRANSACTION_HISTORY_SUCCESS,
  GET_USER_TRANSACTION_HISTORY_FAILURE,
  GET_USER_TOKEN_TRANSACTION_HISTORY_INIT,
  GET_USER_TOKEN_TRANSACTION_HISTORY_SUCCESS,
  GET_USER_TOKEN_TRANSACTION_HISTORY_FAILURE,
  FILTER_CREDIT_INIT,
  FILTER_CREDIT_SUCCESS,
  FILTER_CREDIT_FAILURE,
  FILTER_CREDIT_INDIVIDUAL_INIT,
  FILTER_CREDIT_INDIVIDUAL_SUCCESS,
  FILTER_CREDIT_INDIVIDUAL_FAILURE,
  ADD_CUSTOM_TOKEN_USER,
  ADD_CUSTOM_TOKEN_FINAL_USER,
  ADD_CUSTOM_TOKEN_VALUE_USER,
  ADD_CUSTOM_TOKEN_INDIVIDUAL_USER,
  ADD_CUSTOM_TOKEN_VALUE_INDIVIDUAL_USER,
  UPDATE_USER_MULTIPLIER_INPUT,
  UPDATE_USER_INDIVIDUAL_MULTIPLIER_INPUT,
} from "../actionTypes";
import { IUserProps } from "../../models/user/user";

const initialState = {
  isAddingUserVariable: false,
  addUserVariableResp: [],
  addUserVariableRespStatus: 0,
  addUserVariableError: false,
  addUserVariableErrorMsg: "",
  isUpdatingUserVariable: false,
  updateUserVariableResp: [],
  updateUserVariableRespStatus: 0,
  updateUserVariableError: false,
  updateUserVariableErrorMsg: "",
  isGettingUserVariable: false,
  getUserVariableResp: [],
  getUserVariableRespStatus: 0,
  getUserVariableError: false,
  getUserVariableErrorMsg: "",
  isDeletingUserVariable: false,
  deleteUserVariableResp: [],
  deleteUserVariableRespStatus: 0,
  deleteUserVariableError: false,
  deleteUserVariableErrorMsg: "",
  isSearchingUserWithId: false,
  searchUserWithIdResp: [],
  searchUserWithIdRespStatus: 0,
  searchUserWithIdError: false,
  searchUserWithIdErrorMsg: "",
  isGettingUserTransactionHistory: false,
  getUserTransactionHistoryResp: [],
  getUserTransactionHistoryRespStatus: 0,
  getUserTransactionHistoryError: false,
  getUserTransactionHistoryErrorMsg: "",
  isGettingUserTokenTransactionHistory: false,
  getUserTokenTransactionHistoryResp: [],
  getUserTokenTransactionHistoryRespStatus: 0,
  getUserTokenTransactionHistoryError: false,
  getUserTokenTransactionHistoryErrorMsg: "",
  isFilterCredit: false,
  filterCreditResp: [],
  filterCreditRespStatus: 0,
  filterCreditError: false,
  filterCreditErrorMsg: "",
  isFilterCreditIndividual: false,
  filterCreditIndividualResp: [],
  filterCreditIndividualRespStatus: 0,
  filterCreditIndividualError: false,
  filterCreditIndividualErrorMsg: "",
  userFilter: {
    strings: [],
    numbers: [],
    booleans: [],
    dates: [],
  },
  userFinalFilter: {
    strings: [],
    numbers: [],
    booleans: [],
    dates: [],
  },
  inputs: {},
  individualUserFilter: [],
  individualInputs: [],
  userMultiplier: "",
  userIndividualMultiplier: "",
};

const user = (state: IUserProps = initialState, action: any) => {
  switch (action.type) {
    case ADD_USER_VARIABLE_INIT:
      return {
        ...state,
        isAddingUserVariable: true,
        addUserVariableError: false,
        addUserVariableRespStatus: 0,
      };

    case ADD_USER_VARIABLE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingUserVariable: false,
        addUserVariableResp: data,
        addUserVariableRespStatus: 200,
      };
    }

    case ADD_USER_VARIABLE_FAILURE:
      return {
        ...state,
        isAddingUserVariable: false,
        addUserVariableError: true,
        addUserVariableErrorMsg: action.error,
        addUserVariableRespStatus: 400,
      };

    case UPDATE_USER_VARIABLE_INIT:
      return {
        ...state,
        isUpdatingUserVariable: true,
        updateUserVariableError: false,
        updateUserVariableRespStatus: 0,
      };

    case UPDATE_USER_VARIABLE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingUserVariable: false,
        updateUserVariableResp: data,
        updateUserVariableRespStatus: 200,
      };
    }

    case UPDATE_USER_VARIABLE_FAILURE:
      return {
        ...state,
        isUpdatingUserVariable: false,
        updateUserVariableError: true,
        updateUserVariableErrorMsg: action.error,
        updateUserVariableRespStatus: 400,
      };

    case GET_USER_VARIABLE_INIT:
      return {
        ...state,
        isGettingUserVariable: true,
        getUserVariableError: false,
        getUserVariableRespStatus: 0,
      };

    case GET_USER_VARIABLE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingUserVariable: false,
        getUserVariableResp: data,
        getUserVariableRespStatus: 200,
      };
    }

    case GET_USER_VARIABLE_FAILURE:
      return {
        ...state,
        isGettingUserVariable: false,
        getUserVariableError: true,
        getUserVariableErrorMsg: action.error,
        getUserVariableRespStatus: 400,
      };

    case DELETE_USER_VARIABLE_INIT:
      return {
        ...state,
        isDeletingUserVariable: true,
        deleteUserVariableError: false,
        deleteUserVariableRespStatus: 0,
      };

    case DELETE_USER_VARIABLE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isDeletingUserVariable: false,
        deleteUserVariableResp: data,
        deleteUserVariableRespStatus: 200,
      };
    }

    case DELETE_USER_VARIABLE_FAILURE:
      return {
        ...state,
        isDeletingUserVariable: false,
        deleteUserVariableError: true,
        deleteUserVariableErrorMsg: action.error,
        deleteUserVariableRespStatus: 400,
      };

    case SEARCH_USER_WITH_ID_INIT:
      return {
        ...state,
        isSearchingUserWithId: true,
        searchUserWithIdError: false,
        searchUserWithIdRespStatus: 0,
      };

    case SEARCH_USER_WITH_ID_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isSearchingUserWithId: false,
        searchUserWithIdResp: data,
        searchUserWithIdRespStatus: 200,
      };
    }

    case SEARCH_USER_WITH_ID_FAILURE:
      return {
        ...state,
        isSearchingUserWithId: false,
        searchUserWithIdError: true,
        searchUserWithIdErrorMsg: action.error,
        searchUserWithIdRespStatus: 400,
      };

    case GET_USER_TRANSACTION_HISTORY_INIT:
      return {
        ...state,
        isGettingUserTransactionHistory: true,
        getUserTransactionHistoryError: false,
        getUserTransactionHistoryRespStatus: 0,
      };

    case GET_USER_TRANSACTION_HISTORY_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingUserTransactionHistory: false,
        getUserTransactionHistoryResp: data,
        getUserTransactionHistoryRespStatus: 200,
      };
    }

    case GET_USER_TRANSACTION_HISTORY_FAILURE:
      return {
        ...state,
        isGettingUserTransactionHistory: false,
        getUserTransactionHistoryError: true,
        getUserTransactionHistoryErrorMsg: action.error,
        getUserTransactionHistoryRespStatus: 400,
      };
      case GET_USER_TOKEN_TRANSACTION_HISTORY_INIT:
        return {
          ...state,
          isGettingUserTokenTransactionHistory: true,
          getUserTokenTransactionHistoryError: false,
          getUserTokenTransactionHistoryRespStatus: 0,
        };
  
      case GET_USER_TOKEN_TRANSACTION_HISTORY_SUCCESS: {
        const { data } = action;
        return {
          ...state,
          isGettingUserTokenTransactionHistory: false,
          getUserTokenTransactionHistoryResp: data,
          getUserTokenTransactionHistoryRespStatus: 200,
        };
      }
  
      case GET_USER_TOKEN_TRANSACTION_HISTORY_FAILURE:
        return {
          ...state,
          isGettingUserTokenTransactionHistory: false,
          getUserTokenTransactionHistoryError: true,
          getUserTokenTransactionHistoryErrorMsg: action.error,
          getUserTokenTransactionHistoryRespStatus: 400,
        };
    case FILTER_CREDIT_INIT:
      return {
        ...state,
        isFilterCredit: true,
        filterCreditError: false,
        filterCreditRespStatus: 0,
      };

    case FILTER_CREDIT_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isFilterCredit: false,
        filterCreditResp: data,
        filterCreditRespStatus: 200,
      };
    }

    case FILTER_CREDIT_FAILURE:
      return {
        ...state,
        isFilterCredit: false,
        filterCreditError: true,
        filterCreditErrorMsg: action.error,
        filterCreditRespStatus: 400,
      };

    case FILTER_CREDIT_INDIVIDUAL_INIT:
      return {
        ...state,
        isFilterCreditIndividual: true,
        filterCreditIndividualError: false,
        filterCreditIndividualRespStatus: 0,
      };

    case FILTER_CREDIT_INDIVIDUAL_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isFilterCreditIndividual: false,
        filterCreditIndividualResp: data,
        filterCreditIndividualRespStatus: 200,
      };
    }

    case FILTER_CREDIT_INDIVIDUAL_FAILURE:
      return {
        ...state,
        isFilterCreditIndividual: false,
        filterCreditIndividualError: true,
        filterCreditIndividualErrorMsg: action.error,
        filterCreditIndividualRespStatus: 400,
      };

    case ADD_CUSTOM_TOKEN_USER:
      if (action.params.e) {
        return {
          ...state,
          userFilter: action.params.e,
        };
      }
      if (action.params.newData) {
        return {
          ...state,
          userFilter: {
            ...state.userFilter,
            ...action.params.newData,
          },
        };
      } else {
        const typeMap: { [key: number]: string } = {
          1: "strings",
          2: "numbers",
          3: "booleans",
          4: "dates",
        };
        const type = typeMap[action.params.type];
        let latestUserFilter: any = {
          ...state.userFilter,
        };
        latestUserFilter[type] = latestUserFilter[type]?.map((each: any) => {
          if (each.variable_id === action.params.variableId) {
            if (action.params.type === 3) {
              return {
                ...each,
                values: action.params.values === "True" ? true : false,
              };
            }
            if (action.params.second) {
              return {
                ...each,
                values: [action.params.values, action.params.second],
                operation: action.params.operation || each.operation,
              };
            }
            return {
              ...each,
              values:
                action.params.values?.[0] === "" // empty
                  ? []
                  : action.params.operation === "con" // as a string
                  ? action.params.values?.[0]
                  : action.params.type === 1 && // string arr plitted by ","
                    (action.params.operation === "in" ||
                      action.params.operation === "nin")
                  ? action.params.values?.[0]?.split(",")
                  : action.params.values,
              operation: action.params.operation || each.operation,
            };
          } else {
            return each;
          }
        });
        return {
          ...state,
          userFilter: latestUserFilter,
        };
      }

    case ADD_CUSTOM_TOKEN_FINAL_USER:
      if (action.params.e) {
        const { _id, ...finalData } = action.params.e;
        return {
          ...state,
          userFinalFilter: finalData,
        };
      }
      return {
        ...state,
        userFinalFilter: {
          strings: [
            ...state.userFinalFilter.strings,
            ...state.userFilter.strings.filter((each: any) =>
              action.params.active?.includes(each.variable_id)
            ),
          ],
          numbers: [
            ...state.userFinalFilter.numbers,
            ...state.userFilter.numbers.filter((each: any) =>
              action.params.active?.includes(each.variable_id)
            ),
          ],
          booleans: [
            ...state.userFinalFilter.booleans,
            ...state.userFilter.booleans.filter((each: any) =>
              action.params.active?.includes(each.variable_id)
            ),
          ],
          dates: [
            ...state.userFinalFilter.dates,
            ...state.userFilter.dates
              .filter((each: any) =>
                action.params.active?.includes(each.variable_id)
              )
              ?.map((each: any) => {
                return {
                  variable_id: each.variable_id,
                  value: each.values,
                  operation: each.operation,
                };
              }),
          ],
        },
      };
    case ADD_CUSTOM_TOKEN_VALUE_USER:
      const id = action.data.id;
      const value = action.data.value;
      const selected = action.data.selected;
      if (action.data.e) {
        return {
          ...state,
          inputs: { ...state.inputs },
        };
      }
      return {
        ...state,
        inputs: id
          ? {
              ...state.inputs,
              [id]: {
                token_id: id,
                value: parseFloat(value),
                selected: selected,
              },
            }
          : {},
      };

    case ADD_CUSTOM_TOKEN_INDIVIDUAL_USER:
      return {
        ...state,
        individualUserFilter: Array.from(
          new Set([...state.individualUserFilter, action.params.search])
        ),
      };
    case ADD_CUSTOM_TOKEN_VALUE_INDIVIDUAL_USER:
      const iId = action.data.id;
      const iValue = action.data.value;
      const iSelected = action.data.selected;
      if (action.data.e) {
        return {
          ...state,
          individualInputs: { ...state.individualInputs },
        };
      }
      return {
        ...state,
        individualInputs: iId
          ? {
              ...state.individualInputs,
              [iId]: {
                token_id: iId,
                value: parseFloat(iValue),
                selected: iSelected,
              },
            }
          : {},
      };

    case UPDATE_USER_MULTIPLIER_INPUT:
      return {
        ...state,
        userMultiplier: action.data.multiplierId,
      };

    case UPDATE_USER_INDIVIDUAL_MULTIPLIER_INPUT:
      return {
        ...state,
        userIndividualMultiplier: action.data.multiplierId,
      };
    default:
      return state;
  }
};

export default user;

import {
    ADD_MILESTONE_FAILURE,
    ADD_MILESTONE_INIT,
    ADD_MILESTONE_SUCCESS,
    DELETE_MILESTONE_FAILURE,
    DELETE_MILESTONE_INIT,
    DELETE_MILESTONE_SUCCESS,
    GET_MILESTONE_FAILURE,
    GET_MILESTONE_INIT,
    GET_MILESTONE_SUCCESS,
    UPDATE_MILESTONE_FAILURE,
    UPDATE_MILESTONE_INIT,
    UPDATE_MILESTONE_SUCCESS,
} from "../actionTypes";

export const getMilestoneSuccess = (data: any) => {
    return {
        type: GET_MILESTONE_SUCCESS,
        data,
    };
};

export const getMilestoneFailure = (error: any) => {
    return {
        type: GET_MILESTONE_FAILURE,
        error,
    };
};

export const getMilestone = (params: any) => {
    const payload = { params };
    return {
        type: GET_MILESTONE_INIT,
        payload,
    };
};

export const updateMilestoneSuccess = (data: any) => {
    return {
        type: UPDATE_MILESTONE_SUCCESS,
        data,
    };
};

export const updateMilestoneFailure = (error: any) => {
    return {
        type: UPDATE_MILESTONE_FAILURE,
        error,
    };
};

export const updateMilestone = (params: any) => {
    const payload = { params };
    return {
        type: UPDATE_MILESTONE_INIT,
        payload,
    };
};

export const addMilestoneSuccess = (data: any) => {
    return {
        type: ADD_MILESTONE_SUCCESS,
        data,
    };
};

export const addMilestoneFailure = (error: any) => {
    return {
        type: ADD_MILESTONE_FAILURE,
        error,
    };
};

export const addMilestone = (params: any) => {
    const payload = { params };
    return {
        type: ADD_MILESTONE_INIT,
        payload,
    };
};

export const deleteMilestoneSuccess = (data: any) => {
    return {
        type: DELETE_MILESTONE_SUCCESS,
        data,
    };
};

export const deleteMilestoneFailure = (error: any) => {
    return {
        type: DELETE_MILESTONE_FAILURE,
        error,
    };
};

export const deleteMilestone = (params: any) => {
    const payload = { params };
    return {
        type: DELETE_MILESTONE_INIT,
        payload,
    };
};

type TileProps = {
  type: string;
  number: number;
  increase?: number;
  decrease?: number;
  name: string;
};

const Tile = (props: TileProps) => {
  function numberToString(num: number) {
    if (num < 10000) {
      return num.toString();
    } else if (num < 1000000) {
      return (num / 1000) % 10 === 0
        ? num / 1000 + "K"
        : (num / 1000).toFixed(1) + "K";
    } else {
      return (num / 1000000).toFixed(1) + "M";
    }
  }
  //B

  return (
    <div className="custom-bg text-white rounded-[8px] px-4 py-5">
      <div className="flex items-center justify-center gap-5 text-[32px] font-bold">
        {props.type === "money" ? "₹" : ""}
        {props.type === "time" ? (
          <div>
            1 <span className="text-sm">min </span>12{" "}
            <span className="text-sm">secs</span>
          </div>
        ) : (
          numberToString(props.number)
        )}
        {props.type === "percentage" ? "%" : ""}
        <div className="text-[16px]">
          {props.increase ? (
            <div className="text-[#00FF38] flex items-center gap-1">
              <div>{props.increase}%</div>
              <div>&uarr;</div>
            </div>
          ) : props.decrease ? (
            <div className="text-[#DB0008] flex items-center gap-1">
              <div>{props.decrease}%</div>
              <div>&darr;</div>
            </div>
          ) : (
            <div className="text-[#B3AEAA] flex items-center gap-1">0%</div>
          )}
        </div>
      </div>
      <div className="mt-3 text-center text-[16px]">{props.name}</div>
    </div>
  );
};

export default Tile;

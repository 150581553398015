import Sidebar from "../../containers/sidebar/sidebar";
import Users from "../../containers/users/users";

const UserIndex = () => {
  return (
    <div className="text-white min-h-screen">
      <div className="flex ">
        <Sidebar />
        <Users />
      </div>
    </div>
  );
};

export default UserIndex;

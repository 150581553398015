import { useEffect, useState } from "react";
import CustomToken from "../../../components/users/management/customToken";
import Modal from "react-modal";
import { customStyles } from "../../gamification/campaign";
import { useDispatch, useSelector } from "react-redux";
import { toggleCustomTokenModal } from "../../../redux/user/customTokenActions";
import UsersWith from "../../../components/users/management/modals/usersWith";
import UserManagementHistory from "./userManagementHistory";
import {
  addCustomTokenFinalUser,
  getUserVariable,
} from "../../../redux/user/userActions";
import { useUserAuth } from "../../../hooks/useAuthContextProvider";
import UsersWhat from "../../../components/users/management/modals/usersWhat";
import { getTokens } from "../../../redux/token/tokenActions";
import CustomIndividualToken from "../../../components/users/management/customIndividualToken";
import UsersIndividualWhat from "../../../components/users/management/modals/usersIndividualWhat";
import UsersIndividualWith from "../../../components/users/management/modals/usersIndividualWith";
import UserMultipliers from "../../../components/users/management/modals/userMultiplier";
import { getMultipliers } from "../../../redux/token/multipliersActions";
import UserIndividualMultipliers from "../../../components/users/management/modals/userIndividualMultipliers";

const UserManagement = () => {
  const { user } = useUserAuth();
  // const [menu, setMenu] = useState<boolean>(false);
  const [history, setHistory] = useState<boolean>(false);
  const { active, type } = useSelector((state: any) => state.customToken);
  const { getUserVariableRespStatus } = useSelector((state: any) => state.user);
  const { getTokensRespStatus } = useSelector((state: any) => state.tokens);
  const { getMultipliersRespStatus } = useSelector(
    (state: any) => state.multipliers
  );
  useEffect(() => {
    if (getUserVariableRespStatus !== 200) {
      dispatch(getUserVariable({ token: user.accessToken }));
    }
    if (getTokensRespStatus !== 200) {
      dispatch(getTokens({ token: user.accessToken }));
    }
    if (getMultipliersRespStatus !== 200) {
      dispatch(getMultipliers({ token: user.accessToken }));
    }
    dispatch(
      addCustomTokenFinalUser({
        e: {
          strings: [],
          numbers: [],
          booleans: [],
          dates: [],
        },
      })
    );
  }, []);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(toggleCustomTokenModal({ id: "" }));
  };
  return (
    <div className="bg-[#F9F9F9] px-10">
      <div className="flex items-center justify-between text-[#4C4C66] py-10">
        <div className="text-xl font-bold">Add tokens manually</div>
        <button
          className="outline-none px-3 py-2 text-white font-bold bg-[#FF7B5F] cursor-pointer rounded"
          onClick={() => {
            // setMenu(false);
            setHistory(true);
          }}
        >
          View User History
        </button>
      </div>
      <CustomToken title={"Send tokens to a user group"} isGroup={true} />
      <CustomIndividualToken
        title={"Send tokens to individual users"}
        isGroup={false}
      />
      <Modal
        style={customStyles}
        isOpen={active}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => handleClose()}
        shouldCloseOnEsc={true}
      >
        {/* {type === "notification-method" && <Notification />} */}
        {type === "to-users" && <UsersWith />}
        {type === "what" && <UsersWhat />}
        {type === "what-multipliers" && <UserMultipliers />}
        {type === "to-individual-users" && <UsersIndividualWith />}
        {type === "individual-what" && <UsersIndividualWhat />}
        {type === "individual-what-multipliers" && (
          <UserIndividualMultipliers />
        )}
      </Modal>
      <UserManagementHistory history={history} setHistory={setHistory} />
    </div>
  );
};

export default UserManagement;

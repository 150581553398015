import Modal from "react-modal";
import { customStyles } from "../../gamification/campaign";
import Text from "../../../components/gamification/modals/nudge/text";
import Radio from "../../../components/gamification/modals/nudge/radio";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserVariable,
  getUserVariable,
  updateUserVariable,
} from "../../../redux/user/userActions";
import { useUserAuth } from "../../../hooks/useAuthContextProvider";
import Loader from "../../../components/common/loader";
import { useEffect } from "react";

export interface IAddVariableProps {
  name: string;
  setName: (val: string) => void;
  type: string;
  setType: (val: string) => void;
  open: boolean;
  setOpen: (val: boolean) => void;
  userVariable: string;
  setUserVariable: (val: string) => void;
}
export const typeMap: { [key: string]: number } = {
  String: 1,
  Number: 2,
  Boolean: 3,
  Date: 4,
};

const AddVariable = ({
  name,
  setName,
  type,
  setType,
  open,
  setOpen,
  userVariable,
  setUserVariable,
}: IAddVariableProps) => {
  const { user } = useUserAuth();
  const dispatch = useDispatch();

  const {
    isAddingUserVariable,
    addUserVariableRespStatus,
    addUserVariableError,
    isUpdatingUserVariable,
    updateUserVariableRespStatus,
    updateUserVariableError,
  } = useSelector((state: any) => state.user);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (userVariable) {
      dispatch(
        updateUserVariable({
          token: user.accessToken,
          name: name.trim(),
          type: typeMap[type],
          variableId: userVariable,
        })
      );
    } else {
      dispatch(
        addUserVariable({
          token: user.accessToken,
          name: name.trim(),
          type: typeMap[type],
        })
      );
    }
  };

  const resetModal = () => {
    setName("");
    setType("Date");
    setOpen(false);
    setUserVariable("");
  };

  useEffect(() => {
    if (addUserVariableRespStatus === 200) {
      resetModal();
      dispatch(getUserVariable({ token: user.accessToken }));
    } else if (addUserVariableError) {
      alert("Something went wrong");
    }
  }, [isAddingUserVariable]);

  useEffect(() => {
    if (updateUserVariableRespStatus === 200) {
      resetModal();
      dispatch(getUserVariable({ token: user.accessToken }));
    } else if (updateUserVariableError) {
      alert("Something went wrong");
    }
  }, [isUpdatingUserVariable]);

  return (
    <div className="text-[#4C4C66]">
      <Modal
        isOpen={open}
        style={customStyles}
        onRequestClose={() => resetModal()}
      >
        <form onSubmit={handleSubmit}>
          <div className="p-10">
            <div className="flex justify-between w-[600px]">
              <div>
                <div className="text-2xl text-[#4C4C66] font-bold">
                  Add Variable
                </div>
              </div>
              <div
                className="text-xl text-[#4C4C66] cursor-pointer"
                onClick={() => resetModal()}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                    fill="#4C4C66"
                  />
                </svg>
              </div>
              {/* <div className="text-[#3189B4] mt-3 w-[75%]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Praesentium, aperiam quis in explicabo a aliquid libero dicta
              repudiandae consequatur deleniti enim et, harum sapiente veritatis
              dolores? Similique minima mollitia enim?
            </div> */}
            </div>

            <div className="mt-10">
              <div className="text-sm">Custom variable name</div>
              <Text
                text={name}
                setText={setName}
                place={"variable"}
                full={true}
                required={true}
                type={"text"}
              />
            </div>
            <div className="text-sm mt-3">Data type</div>
            <div className="flex items-center gap-5">
              {["Date", "Number", "String", "Boolean"].map((each: string) => {
                return (
                  <Radio
                    selected={type}
                    setSelected={setType}
                    multi={false}
                    each={each}
                  />
                );
              })}
            </div>
            <div className="mt-10 flex items-center justify-end">
              <button
                className="bg-[#FF7B5F] rounded text-white font-bold cursor-pointer px-5 py-2"
                disabled={isAddingUserVariable || isUpdatingUserVariable}
              >
                {isAddingUserVariable || isUpdatingUserVariable ? (
                  <Loader />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AddVariable;

import { useEffect, useState } from "react";
import Navbar from "../../components/common/navbar";
import l from "../../assets/png/image 62.png";
import editPencil from "../../assets/svg/edit.svg";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import dots from "../../assets/svg/dots.svg";
import Modal from "react-modal";
import { customStyles } from "../gamification/campaign";
import add from "../../assets/svg/add.svg";
import Text from "../../components/gamification/modals/nudge/text";
import Loader from "../../components/common/loader";
import { useDispatch, useSelector } from "react-redux";
import {
    addRole,
    deleteRole,
    getAllRoles,
    updateClient,
} from "../../redux/settings/settingsActions";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../redux/auth/authActions";
import { toggleConfirmationPopup } from "../../redux/api/apiActions";
import ObjectID from "bson-objectid";

const Settings = () => {
    const { user, userDetails, logOut } = useUserAuth();
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [isAddRole, setIsAddRole] = useState<boolean>(false);
    const [activeRole, setActiveRole] = useState<string>("");
    const [newClientDetails, setNewClientDetails] = useState<boolean>(false);
    const [tImage, setTImage] = useState<any>(null);
    const [t2Image, setT2Image] = useState<any>(null);
    const [cName, setCName] = useState<string>("");
    const [cBusinessName, setCBusinessName] = useState<string>("");
    const [option, setOption] = useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        isGettingAllRoles,
        getAllRolesResp,
        getAllRolesRespStatus,
        getAllRolesError,
        getAllRolesErrorMsg,
        isAddingRole,
        addRoleRespStatus,
        addRoleError,
        isUpdatingClient,
        updateClientRespStatus,
        updateClientError,
    } = useSelector((state: any) => state.settings);

    useEffect(() => {
        if (getAllRolesRespStatus !== 200) {
            dispatch(getAllRoles({ token: user.accessToken }));
        }
    }, []);
    useEffect(() => {
        if (addRoleRespStatus === 200) {
            setName("");
            setEmail("");
            setPhone("");
            setRole("");
            setIsAddRole(false);
            setActiveRole("");
            dispatch(getAllRoles({ token: user.accessToken }));
        } else if (addRoleError) {
            alert("Something went wrong");
        }
    }, [isAddingRole]);

    useEffect(() => {
        if (updateClientRespStatus === 200) {
            setCName("");
            setCBusinessName("");
            setNewClientDetails(false);
            setTImage(null);
            setT2Image(null);
            dispatch(getUser({ token: user.accessToken }));
        } else if (updateClientError) {
            alert("Something went wrong");
        }
    }, [isUpdatingClient]);
    const handleSave = (e: any) => {
        e.preventDefault();
        if (activeRole.length === 0) {
            dispatch(
                addRole({
                    token: user.accessToken,
                    name: name,
                    email: email,
                    phone: phone,
                    role: mMap[role],
                    client_user_id: new ObjectID().toString(),
                })
            );
        } else {
            // dispatch(updateRole({token:user.accessToken, name:name, email:email, phone:phone,role:mMap[role]}))
        }
    };
    const rMap: any = {
        1: "Admin",
        2: "Product Manager",
        3: "Marketing",
        4: "Developer",
        5: "E-Commerce",
    };
    const mMap: any = {
        Admin: 1,
        "Product Manager": 2,
        Marketing: 3,
        Developer: 4,
        "E-Commerce": 5,
    };
    const update = (e: any) => {
        e.preventDefault();
        dispatch(
            updateClient({
                logo: tImage,
                banner: t2Image,
                name: cName,
                company_legal_name: cBusinessName,
                clientId: userDetails?.client_id?.name,
                token: user.accessToken,
            })
        );
    };
    return (
        <div className="w-full h-[100vh] overflow-y-auto pe-10">
            <Navbar />
            <div className="mt-10">
                <div className="relative w-full rounded-t overflow-hidden">
                    <img
                        src={
                            userDetails?.client_id?.banner ||
                            "https://static.vecteezy.com/system/resources/thumbnails/013/993/672/small/corporate-business-social-media-cover-or-web-banner-design-real-estate-social-media-cover-design-template-free-vector.jpg"
                        }
                        alt="l"
                        className="h-[300px] w-full object-cover "
                    />
                    <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-stone-900 via-transparent to-transparent"></div>
                    <div className="absolute bottom-5 flex items-center justify-between w-full px-5">
                        <div className="flex items-center  gap-3">
                            <img
                                src={
                                    userDetails?.client_id?.logo ||
                                    "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/corporate-company-logo-design-template-2402e0689677112e3b2b6e0f399d7dc3_screen.jpg?ts=1561532453"
                                }
                                alt=""
                                className="h-[70px] w-[70px] rounded object-cover"
                            />
                            <div>
                                <div className="text-lg text-white font-bold">
                                    {userDetails?.client_id?.name}
                                </div>
                                <div className="text-white">
                                    {userDetails?.client_id?.company_legal_name}
                                </div>
                            </div>
                        </div>
                        <div
                            className="bg-[#FF7B5F] text-sm text-white cursor-pointer font-bold rounded-[100px] px-5 py-2"
                            onClick={() => {
                                setCName(userDetails?.client_id?.name);
                                setCBusinessName(
                                    userDetails?.client_id?.company_legal_name
                                );
                                setTImage(userDetails?.client_id?.logo);
                                setT2Image(userDetails?.client_id?.banner);
                                setNewClientDetails(true);
                            }}
                        >
                            Edit details
                        </div>
                    </div>
                </div>
                <div className="bg-[#F9F9F9] border border-[#EFF0F6] flex items-center justify-between rounded px-5 py-5">
                    <div className="flex items-center gap-5">
                        <div className="relative">
                            <img
                                src={l}
                                alt="l"
                                className="h-[70px] w-[70px] rounded object-cover"
                            />
                            <img
                                src={editPencil}
                                alt="editPencil"
                                className="w-[25px] h-[25px] cursor-pointer bg-[#9D9BC0] rounded p-1 absolute bottom-[-10px] right-[-10px]"
                            />
                        </div>
                        <div>
                            <div className="text-lg text-[#4C4C66] font-bold">
                                {userDetails?.name}
                            </div>
                            <div className="text-[#4C4C66]">
                                {rMap[userDetails?.role]}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <div className="text-sm text-[#4C4C4C]">Tutorial</div>
                        <div className="text-sm text-[#4C4C66]">Help</div>
                        <div
                            className="bg-[#FF7B5F] text-sm rounded-[100px] text-white cursor-pointer font-bold px-5 py-2"
                            onClick={async () => {
                                await logOut();
                                navigate("/login");
                            }}
                        >
                            Sign out
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-2xl text-[#4C4C66] font-bold my-10">
                Manage Team
            </div>
            {isGettingAllRoles && (
                <div className="flex items-center justify-center mt-5">
                    <Loader heavy={true} />
                </div>
            )}
            <div className="grid grid-cols-3 gap-5">
                {getAllRolesError && getAllRolesErrorMsg}
                {getAllRolesRespStatus === 200 &&
                    getAllRolesResp.data.value.map((each: any) => {
                        return (
                            <div className="col-span-1">
                                <div className="bg-[#FFE8E6] p-5 rounded shadow-lg">
                                    <div className="flex justify-between">
                                        <div>
                                            <div className="text-[10px] text-[#8880A1]">
                                                {rMap[each.role]}
                                            </div>
                                            <div className="text-[#4C4C66] font-bold">
                                                {each.name}
                                            </div>
                                            <div className="text-sm text-[#4C4C66]">
                                                {each.email}
                                            </div>
                                            <div className="text-sm text-[#4C4C66]">
                                                Last sign in:{" "}
                                                {
                                                    new Date(each.last_login)
                                                        .toISOString()
                                                        .split("T")[0]
                                                }
                                            </div>
                                        </div>
                                        <div className="relative">
                                            <img
                                                src={dots}
                                                alt="dots"
                                                className="h-[25px] w-[25px] cursor-pointer"
                                                onClick={() => {
                                                    setOption(!option);
                                                    setActiveRole(each._id);
                                                }}
                                            />
                                            {each._id === activeRole &&
                                                option && (
                                                    <div className="absolute right-0 top-5 text-[#4C4C66] bg-white rounded shadow-lg w-[150px]">
                                                        <div
                                                            className="px-5 py-2 cursor-pointer"
                                                            onClick={() =>
                                                                dispatch(
                                                                    toggleConfirmationPopup(
                                                                        {
                                                                            isConfirmation:
                                                                                true,
                                                                            confirmationFunction:
                                                                                () =>
                                                                                    dispatch(
                                                                                        deleteRole(
                                                                                            {
                                                                                                token: user.accessToken,
                                                                                                userId: each._id,
                                                                                            }
                                                                                        )
                                                                                    ),
                                                                            confirmationText:
                                                                                "Do you really want to delete the role?",
                                                                        }
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            Delete
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                {getAllRolesRespStatus === 200 && (
                    <div className="col-span-1">
                        <div className="border-2 border-[#FFAFA8] border-dashed flex items-center justify-center h-full w-full p-5 rounded shadow-lg">
                            <div
                                className="flex items-center rounded bg-[#FFE8E6] cursor-pointer gap-2 px-5 py-2"
                                onClick={() => setIsAddRole(true)}
                            >
                                <div className="text-[#4C4C66]">
                                    Add a new member
                                </div>
                                <img
                                    src={add}
                                    alt="add"
                                    className="h-[10px] w-[10px]"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Modal isOpen={isAddRole} style={customStyles}>
                <form onSubmit={handleSave}>
                    <div className="p-10">
                        <div className="flex justify-between">
                            <div>
                                <div className="text-2xl text-[#4C4C66] font-bold">
                                    Add member
                                </div>
                                {/* <div className="text-[#3189B4] mt-3">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Praesentium, aperiam quis in explicabo a aliquid libero dicta
                    repudiandae consequatur deleniti enim et, harum sapiente
                    veritatis dolores? Similique minima mollitia enim?
                  </div> */}
                            </div>
                            <div
                                className="text-xl text-[#4C4C66] cursor-pointer"
                                onClick={() => {
                                    setName("");
                                    setEmail("");
                                    setPhone("");
                                    setRole("Finance");
                                    setIsAddRole(false);
                                    setActiveRole("");
                                }}
                            >
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                                        fill="#4C4C66"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="text-lg mt-5">
                            Note : The user(s) WILL have to verify themselves
                            once you send an invitation link
                        </div>
                        <div className="mt-5">
                            <div className="text-sm text-[#4C4C66]">
                                Name of the member
                            </div>
                            <Text
                                text={name}
                                setText={setName}
                                place={"- eg : John Doe -"}
                                full={true}
                                required={true}
                            />
                            <div className="mt-2 text-sm text-[#4C4C66]">
                                Email
                            </div>
                            <Text
                                text={email}
                                setText={setEmail}
                                place={"- eg : johdoe@company.mail -"}
                                full={true}
                                required={true}
                            />
                            <div className="mt-2 text-sm text-[#4C4C66]">
                                Phone
                            </div>
                            <Text
                                text={phone}
                                setText={setPhone}
                                place={"- eg : +XX XXXXX XXXXX -"}
                                full={true}
                                type={"number"}
                                required={true}
                            />
                            {!activeRole && (
                                <>
                                    <div className="mt-2 text-sm text-[#4C4C66]">
                                        Role
                                    </div>
                                    <select
                                        className="bg-white border-2 border-[#FFAFA8] px-3 py-2 outline-none rounded w-full text-[#4C4C66]"
                                        onChange={(e: any) =>
                                            setRole(e.target.value)
                                        }
                                        value={role}
                                        required={true}
                                    >
                                        <option value={""}>Select Role</option>
                                        {getAllRolesRespStatus === 200 &&
                                            [
                                                "Product Manager",
                                                "Marketing",
                                                "Developer",
                                                "E-Commerce",
                                            ].map((each: string) => {
                                                return (
                                                    <option value={each}>
                                                        {each}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center justify-end px-10 mt-5 mb-10">
                        <button
                            className="outline-none px-3 py-2 text-white text-xl font-bold bg-[#FF7B5F] cursor-pointer rounded"
                            type="submit"
                            disabled={isAddingRole}
                        >
                            {isAddingRole ? <Loader /> : "Add member"}
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal
                style={customStyles}
                isOpen={newClientDetails}
                onRequestClose={() => setNewClientDetails(false)}
                shouldCloseOnOverlayClick={true}
            >
                <form onSubmit={update}>
                    <div className="p-5">
                        <div className="flex justify-between w-[750px]">
                            <div>
                                <div className="text-2xl text-[#4C4C66] font-bold">
                                    Add Token
                                </div>
                                {/* <div className="text-[#3189B4] mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Praesentium, aperiam quis in explicabo a aliquid libero dicta
                  repudiandae consequatur deleniti enim et, harum sapiente
                  veritatis dolores? Similique minima mollitia enim?
                </div> */}
                            </div>
                            <div
                                className="text-xl text-[#4C4C66] cursor-pointer"
                                onClick={() => setNewClientDetails(false)}
                            >
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                                        fill="#4C4C66"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="mt-5 flex gap-5">
                            <div>
                                <div className="text-[14px] text-[#4C4C66]">
                                    Logo
                                </div>
                                <label htmlFor={"in"}>
                                    <div className="w-[150px] h-[150px] object-cover border-2 border-dashed border-[#FFAFa8] rounded flex items-center justify-center text-center mt-1 text-[14px] cursor-pointer p-5">
                                        {tImage ? (
                                            <img
                                                src={
                                                    typeof tImage === "string"
                                                        ? tImage
                                                        : URL.createObjectURL(
                                                              tImage
                                                          )
                                                }
                                                alt="t"
                                            />
                                        ) : (
                                            "Drag & Drop or upload"
                                        )}
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    id="in"
                                    className="hidden"
                                    onChange={(e: any) =>
                                        setTImage(e.target.files[0])
                                    }
                                />
                            </div>
                            <div className="w-[3px] bg-[#CEB0Fa]"></div>
                            <div className="w-full">
                                <div className="text-[14px] text-[#4C4C66] mb-1">
                                    Company name
                                </div>
                                <Text
                                    text={cName}
                                    setText={setCName}
                                    required={true}
                                    place={"- eg: Name -"}
                                    full={true}
                                />
                                <div className="text-[14px] text-[#4C4C66] mt-3 mb-1">
                                    Company Legal Name
                                </div>
                                <Text
                                    text={cBusinessName}
                                    setText={setCBusinessName}
                                    required={true}
                                    place={"- eg: Name PVT LTD -"}
                                    full={true}
                                />
                            </div>
                        </div>
                        <div className="text-[14px] text-[#4C4C66] mt-3">
                            Banner
                        </div>
                        <label htmlFor={"in2"}>
                            <div className="w-[100%] h-[150px] object-cover border-2 border-dashed border-[#FFAFa8] rounded flex items-center justify-center text-center mt-1 text-[14px] cursor-pointer p-5">
                                {t2Image ? (
                                    <img
                                        src={
                                            typeof t2Image === "string"
                                                ? t2Image
                                                : URL.createObjectURL(t2Image)
                                        }
                                        alt="t"
                                    />
                                ) : (
                                    "Drag & Drop or upload"
                                )}
                            </div>
                        </label>
                        <input
                            type="file"
                            id="in2"
                            className="hidden"
                            onChange={(e: any) => setT2Image(e.target.files[0])}
                        />
                        <div className="flex items-center justify-end px-5 my-5">
                            <button
                                className="outline-none px-3 py-2 text-white text-xl font-bold bg-[#FF7B5F] cursor-pointer rounded"
                                disabled={isUpdatingClient}
                                type="submit"
                            >
                                {isUpdatingClient ? <Loader /> : "Continue"}
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default Settings;

// {
//   brands: {
//     type: "exclude/include",
//     items: [],
//   },
//   categories: {
//     type: "exclude/include",
//     items: []
//   },
//   id: any,
//   type: number
// }

import { put, call, takeLatest } from "redux-saga/effects";
import { baseURL, endPoints } from "../../utils/apiEndPoints";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../utils/apiRequests";
import {
  ADD_REFERRAL_INIT,
  UPDATE_REFERRAL_INIT,
  GET_REFERRAL_INIT,
  DELETE_REFERRAL_INIT,
} from "../actionTypes";
import {
  addReferralSuccess,
  addReferralFailure,
  updateReferralSuccess,
  updateReferralFailure,
  getReferralsSuccess,
  getReferralsFailure,
  deleteReferralSuccess,
  deleteReferralFailure,
} from "./referralActions";

function* addReferral(action: any): any {
  try {
    const { token, ...reqData } = action.payload.params;
    const data = yield call(
      postRequest,
      `${baseURL}/${endPoints.addReferral}`,
      { ...reqData },
      {
        Authorization: `Bearer ${action.payload.params.token}`,
      }
    );
    if (!data.error) {
      yield put(addReferralSuccess(data));
    } else {
      yield put(addReferralFailure(data));
    }
  } catch (err: any) {
    yield put(addReferralFailure(err.message));
  }
}

export function* addReferralSaga() {
  yield takeLatest(ADD_REFERRAL_INIT, addReferral);
}

function* updateReferral(action: any): any {
  try {
    const { token, referral_id, ...reqData } = action.payload.params;
    const data = yield call(
      patchRequest,
      `${baseURL}/${endPoints.updateReferral}/${referral_id}`,
      { ...reqData },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (!data.error) {
      yield put(updateReferralSuccess(data));
    } else {
      yield put(updateReferralFailure(data));
    }
  } catch (err: any) {
    yield put(updateReferralFailure(err.message));
  }
}

export function* updateReferralSaga() {
  yield takeLatest(UPDATE_REFERRAL_INIT, updateReferral);
}

function* getReferrals(action: any): any {
  try {
    const data = yield call(getRequest, `${baseURL}/${endPoints.getReferral}`, {
      Authorization: `Bearer ${action.payload.params.token}`,
    });
    if (!data.error) {
      yield put(getReferralsSuccess(data));
    } else {
      yield put(getReferralsFailure(data));
    }
  } catch (err: any) {
    yield put(getReferralsFailure(err.message));
  }
}

export function* getReferralsSaga() {
  yield takeLatest(GET_REFERRAL_INIT, getReferrals);
}

function* deleteReferral(action: any): any {
  try {
    const { token, referral_id } = action.payload.params;
    const data = yield call(
      deleteRequest,
      `${baseURL}/${endPoints.deleteReferral}/${referral_id}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (!data.error) {
      yield put(deleteReferralSuccess(data));
    } else {
      yield put(deleteReferralFailure(data));
    }
  } catch (err: any) {
    yield put(deleteReferralFailure(err.message));
  }
}

export function* deleteReferralSaga() {
  yield takeLatest(DELETE_REFERRAL_INIT, deleteReferral);
}

import { useEffect, useState } from "react";
import Param from "../../../components/tokens/param";
import LineChart from "../../tokens/chart";
import { useDispatch, useSelector } from "react-redux";
import { getAnalytics } from "../../../redux/token/tokenActions";
import { useUserAuth } from "../../../hooks/useAuthContextProvider";
import Loader from "../../../components/common/loader";

interface IUserOverAllAnalyticsProps {
    // graph: any;
}

// eslint-disable-next-line no-empty-pattern
const UserOverallAnalytics = ({}: IUserOverAllAnalyticsProps) => {
    const dispatch = useDispatch();
    function numberToString(num: number) {
        if (num < 10000) {
            return num.toString();
        } else if (num < 1000000) {
            return (num / 1000) % 10 === 0
                ? num / 1000 + "K"
                : (num / 1000).toFixed(1) + "K";
        } else {
            return (num / 1000000).toFixed(1) + "M";
        }
    }
    const { user } = useUserAuth();
    const [tGraph, setTGraph] = useState<any>(null);
    const [mGraph, setMGraph] = useState<any>(null);

    const {
        isGettingAnalytics,
        getAnalyticsResp,
        getAnalyticsRespStatus,
        getAnalyticsError,
        getAnalyticsErrorMsg,
    } = useSelector((state: any) => state.tokens);
    useEffect(() => {
        if (getAnalyticsRespStatus !== 200) {
            dispatch(getAnalytics({ token: user.accessToken }));
        }
        if (getAnalyticsRespStatus === 200) {
            graph();
        }
    }, []);

    useEffect(() => {
        if (getAnalyticsRespStatus === 200) {
            graph();
        }
    }, [isGettingAnalytics]);
    const graph = () => {
        let temp = [];
        let temp2 = [];
        temp.push({
            name: "User Growth",
        });
        temp2.push({
            name: "User MAU",
        });
        const stamps = Object.keys(
            getAnalyticsResp?.data?.value?.user_growth || {}
        );
        const values = Object.values(
            getAnalyticsResp?.data?.value?.user_growth || {}
        );
        const stampsWithDates = stamps?.map((each: any) => {
            const [year, month] = each.split(",");
            const d = new Date(`${year}-${month}-1`);
            return d;
        });
        temp[temp.length - 1] = {
            ...temp[temp.length - 1],
            data:
                stampsWithDates?.length < 1
                    ? []
                    : [
                          [
                              new Date().setDate(
                                  stampsWithDates?.[0]?.getDate() - 1
                              ),
                              0,
                          ],
                          ...stampsWithDates?.map((each: any, i: any) => [
                              each,
                              values[i],
                          ]),
                      ],
        };

        const stamps2 = Object.keys(getAnalyticsResp?.data?.value?.mau || {});
        const values2 = Object.values(getAnalyticsResp?.data?.value?.mau || {});
        const stampsWithDates2 = stamps2?.map((each: any) => {
            const [year, month] = each.split(",");
            const d = new Date(`${year}-${month}-1`);
            return d;
        });
        temp2[temp2.length - 1] = {
            ...temp2[temp2.length - 1],
            data:
                stampsWithDates2?.length < 1
                    ? []
                    : [
                          [
                              new Date().setDate(
                                  stampsWithDates2?.[0]?.getDate() - 1
                              ),
                              0,
                          ],
                          ...stampsWithDates2?.map((each: any, i: any) => [
                              each,
                              values2[i],
                          ]),
                      ],
        };
        setTGraph(temp);
        setMGraph(temp2);
    };
    return (
        <>
            {isGettingAnalytics && (
                <div className="flex items-center justify-center h-full w-full">
                    <Loader heavy={true} />
                </div>
            )}
            {getAnalyticsError && getAnalyticsErrorMsg}
            {getAnalyticsRespStatus === 200 && tGraph && (
                <div>
                    <div className="flex items-center justify-between pt-10">
                        <div className="text-xl font-bold">
                            Overall analytics
                        </div>
                        {/* <div className="relative">
              <div
                className="flex items-center cursor-pointer gap-3"
                onClick={() => setFilter(!filter)}
              >
                {date}
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.76876 0.23964C10.0771 0.55916 10.0771 1.0772 9.76876 1.39672L5.55824 5.76036C5.24993 6.07988 4.75006 6.07988 4.44176 5.76036L0.231231 1.39672C-0.0770771 1.0772 -0.0770771 0.55916 0.231231 0.23964C0.53954 -0.0798796 1.03941 -0.0798797 1.34772 0.23964L5 4.02473L8.65228 0.23964C8.96059 -0.07988 9.46046 -0.07988 9.76876 0.23964Z"
                    fill="#4C4C66"
                    fill-opacity="0.84"
                  />
                </svg>
              </div>
              {filter && (
                <div className="flex flex-col gap-1 absolute top-7 right-0 rounded shadow bg-[#FFF2F1] z-10 w-[150px] p-3">
                  <div
                    onClick={() => {
                      setFilter(false);
                      setDate("Last 12 months");
                    }}
                  >
                    Last 12 months
                  </div>
                  <div
                    onClick={() => {
                      setFilter(false);
                      setDate("Last 6 months");
                    }}
                  >
                    Last 6 months
                  </div>
                  <div
                    onClick={() => {
                      setFilter(false);
                      setDate("Last 3 months");
                    }}
                  >
                    Last 3 months
                  </div>
                  <div onClick={() => setShowCustomFilter(!showCustomFilter)}>
                    Custom date
                  </div>
                  {showCustomFilter && (
                    <div className="absolute right-0 top-32 rounded shadow bg-[#FFF2F1] z-10 p-3">
                      <div className="text-[14px] text-[#4C4C66] mb-1">
                        Start from
                      </div>
                      <DateContainer
                        selected={customStart}
                        setSelected={setCustomStart}
                        required={false}
                      />
                      <div className="text-[14px] text-[#4C4C66] mt-3 mb-1">
                        End on
                      </div>
                      <DateContainer
                        selected={customEnd}
                        setSelected={setCustomEnd}
                        required={false}
                        min={customStart}
                      />
                    </div>
                  )}
                </div>
              )}
            </div> */}
                    </div>
                    <div className="grid grid-cols-24 gap-5 mt-3">
                        <div className="col-span-5">
                            <Param
                                info={"Total Users"}
                                value={numberToString(
                                    getAnalyticsResp?.data?.value
                                        ?.total_users || 0
                                )}
                            />
                            <div className="mt-3">
                                <Param
                                    info={"Transacting Users"}
                                    value={numberToString(
                                        getAnalyticsResp?.data?.value
                                            ?.transacting_users || 0
                                    )}
                                    // data={one[date]}
                                />
                            </div>
                        </div>
                        <div className="col-span-9">
                            <LineChart
                                data={tGraph}
                                title={"User growth graph"}
                            />
                        </div>
                        <div className="col-span-10">
                            <LineChart
                                data={mGraph}
                                title={"Monthly Active Users"}
                            />
                        </div>
                        {/* <div className="col-span-3">
              <Param info={"PY growth"} value={"+69%"} />
              <div className="mt-3">
                <Param info={"lorem"} value={"+69%"} />
              </div>
            </div> */}
                    </div>
                </div>
            )}
        </>
    );
};

export default UserOverallAnalytics;

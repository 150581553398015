import {
  ADD_STREAK_INIT,
  ADD_STREAK_SUCCESS,
  ADD_STREAK_FAILURE,
  UPDATE_STREAK_INIT,
  UPDATE_STREAK_SUCCESS,
  UPDATE_STREAK_FAILURE,
  GET_STREAKS_INIT,
  GET_STREAKS_SUCCESS,
  GET_STREAKS_FAILURE,
  DELETE_STREAK_INIT,
  DELETE_STREAK_SUCCESS,
  DELETE_STREAK_FAILURE,
  UPDATE_STREAK_TOKEN_INPUT,
  UPDATE_STREAK_TOKEN_MILESTONE_INPUT,
  UPDATE_STREAK_TOKEN_REWARD_INPUT,
  UPDATE_STREAK_EVENT_INPUT,
  UPDATE_STREAK_MULTIPLIER_INPUT,
  UPDATE_REWARD_MULTIPLIER_INPUT,
  RESET_STREAKS_STATES,
} from "../actionTypes";

export const addStreakSuccess = (data: any) => {
  return {
    type: ADD_STREAK_SUCCESS,
    data,
  };
};

export const addStreakFailure = (error: any) => {
  return {
    type: ADD_STREAK_FAILURE,
    error,
  };
};

export const addStreak = (params: any) => {
  const payload = { params };
  return {
    type: ADD_STREAK_INIT,
    payload,
  };
};

export const updateStreakSuccess = (data: any) => {
  return {
    type: UPDATE_STREAK_SUCCESS,
    data,
  };
};

export const updateStreakFailure = (error: any) => {
  return {
    type: UPDATE_STREAK_FAILURE,
    error,
  };
};

export const updateStreak = (params: any) => {
  const payload = { params };
  return {
    type: UPDATE_STREAK_INIT,
    payload,
  };
};

export const getStreaksSuccess = (data: any) => {
  return {
    type: GET_STREAKS_SUCCESS,
    data,
  };
};

export const getStreaksFailure = (error: any) => {
  return {
    type: GET_STREAKS_FAILURE,
    error,
  };
};

export const getStreaks = (params: any) => {
  const payload = { params };
  return {
    type: GET_STREAKS_INIT,
    payload,
  };
};

export const deleteStreakSuccess = (data: any) => {
  return {
    type: DELETE_STREAK_SUCCESS,
    data,
  };
};

export const deleteStreakFailure = (error: any) => {
  return {
    type: DELETE_STREAK_FAILURE,
    error,
  };
};

export const deleteStreak = (params: any) => {
  const payload = { params };
  return {
    type: DELETE_STREAK_INIT,
    payload,
  };
};

export const updateStreakTokenInput = (data: any) => {
  return {
    type: UPDATE_STREAK_TOKEN_INPUT,
    data,
  };
};
export const updateStreakTokenMilestoneInput = (data: any) => {
  return {
    type: UPDATE_STREAK_TOKEN_MILESTONE_INPUT,
    data,
  };
};
export const updateStreakTokenRewardInput = (data: any) => {
  return {
    type: UPDATE_STREAK_TOKEN_REWARD_INPUT,
    data,
  };
};

export const updateStreakEventInput = (data: any) => {
  return {
    type: UPDATE_STREAK_EVENT_INPUT,
    data,
  };
};

export const updateStreakMultiplierInput = (data: any) => {
  return {
    type: UPDATE_STREAK_MULTIPLIER_INPUT,
    data,
  };
};

export const updateRewardMultiplierInput = (data: any) => {
  return {
    type: UPDATE_REWARD_MULTIPLIER_INPUT,
    data,
  };
};

export const resetStreaksStates = (data: any) => {
  return {
    type: RESET_STREAKS_STATES,
    data,
  };
};

import store from "../redux/store";

export const baseURL = store.getState()?.developerTools["isProduction"]
    ? process.env.REACT_APP_PROD
    : process.env.REACT_APP_DEV;

export const endPoints = {
    readToken: "token-management/dashboard/read-token",
    updateToken: "token-management/dashboard/update-token",
    addToken: "token-management/dashboard/create-token",
    readEvent: "event-management/dashboard/read-all-events",
    updateEvent: "event-management/dashboard/update-event",
    addEvent: "event-management/dashboard/create-event",
    deleteEvent: "event-management/dashboard/delete-event",
    readMultiplier: "dashboard/multipliers",
    updateMultiplier: "dashboard/multipliers",
    addMultiplier: "dashboard/multipliers/create",
    deleteMultiplier: "dashboard/multipliers",
    api: "dashboard/api-key/create-api-key",
    updateApi: "dashboard/api-key/update-api-key",
    readApi: "dashboard/api-key/read-api-key",
    allTokens: "token-management/dashboard/get-all-client-tokens",
    deleteApi: "dashboard/api-key/delete-api-key",
    analytics: "analytics",
    tokenHistory: "token-management/dashboard/read-all-token-detail-history",
    roles: "client-user/get-all-client-user",
    addRole: "client-user/add-role",
    revokeRole: "client-user/revoke-role",
    updateClient: "clients/update-client",
    addVariable: "dashboard/variables/create",
    updateVariable: "dashboard/variables/update",
    getVariables: "dashboard/variables/get-all",
    deleteVariable: "dashboard/variables/delete",
    userHistory: "dashboard/users/user-history",
    userTransactionHistory: "dashboard/users/user-history",
    userTransactionTokenHistory:
        "token-management/dashboard/read-token-user-history",
    filterCredit: "token-management/dashboard/filter-and-credit",
    filterCreditIndividual: "dashboard/users/credit-tokens",
    addReferral: "dashboard/snipe-social/create-referral-program",
    updateReferral: "dashboard/snipe-social/update-referral-program",
    deleteReferral: "dashboard/snipe-social/delete-referral-program",
    getReferral: "dashboard/snipe-social/referral-program",
    addStreak: "dashboard/new-streaks",
    updateStreak: "dashboard/new-streaks",
    deleteStreak: "dashboard/new-streaks",
    getAllStreaks: "dashboard/new-streaks",
    addMilestone: "dashboard/milestone-events",
    updateMilestone: "dashboard/milestone-events",
    deleteMilestone: "dashboard/milestone-events",
    getAllMilestones: "dashboard/milestone-events",
    addContest: "snipe-milestone-contests",
    updateContest: "snipe-milestone-contests",
    deleteContest: "snipe-milestone-contests",
    getAllContests: "snipe-milestone-contests",
    addAttribute: "snipe-milestone-contests/create-event-attributes",
    updateAttribute: "snipe-milestone-contests/update-event-attributes",
    deleteAttribute: "snipe-milestone-contests/delete-event-attributes",
    getAllAttributes: "snipe-milestone-contests/event-attributes",
};

import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import pfi from "../../assets/png/image 62.png";
import searchIcon from "../../assets/svg/search.svg";
import Loader from "../../components/common/loader";
import ToggleSwitch from "../../components/deeveloperTools/changeEnv";
import Text from "../../components/gamification/modals/nudge/text";
import Value from "../../components/users/management/value";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import { deleteContest, getContest } from "../../redux/contest/contestAction";
import store from "../../redux/store";
import { endPoints } from "../../utils/apiEndPoints";
import { getRequest } from "../../utils/apiRequests";
import AddMilestone from "./addMilestone";

const ContestContainer = () => {
    const { user } = useUserAuth();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState<string>("manage-contests");
    const [addingMilestone, setAddingMilestone] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [contestData, setContestData] = useState<any>();
    const [selectedContest, setSelectedContest] = useState<any>(null);
    const [events, setEvents] = useState<[]>([]);
    const [tokens, setTokens] = useState<[]>([]);
    const [openDelContestModal, setOpenDelContestModal] = useState<number>(-1);
    const fetchTokens = async () => {
        try {
            const data = await getRequest(
                `${(store.getState().developerTools as any).url}/${
                    endPoints.allTokens
                }`,
                { Authorization: `Bearer ${user.accessToken}` }
            );
            setTokens(data.data.value);
        } catch (e: any) {}
    };

    const fetchEvents = async () => {
        try {
            const data = await getRequest(
                `${(store.getState().developerTools as any).url}/${
                    endPoints.readEvent
                }`,
                { Authorization: `Bearer ${user.accessToken}` }
            );
            setEvents(data.data.value);
        } catch (e: any) {}
    };

    useEffect(() => {
        fetchTokens();
        fetchEvents();
    }, []);

    const handleDel = (ind: number) => {
        setOpenDelContestModal(ind);
    };

    const handleCancelSave = () => {
        setOpenDelContestModal(-1);
    };

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "0%",
            border: "none",
        },
        overlay: {
            zIndex: "1000",
            background: "rgba(1, 1, 1, 0.1)",
            backdropFilter: "blur(7px)",
        },
    };
    const {
        isGettingContest,
        addContestResp,
        addContestRespStatus,
        getContestResp,
        getContestRespStatus,
        getContestError,
        getContestErrorMsg,
        deleteContestResp,
        deleteContestRespStatus,
    } = useSelector((state: any) => state.contest);

    useEffect(() => {
        if (getContestRespStatus !== 200) {
            dispatch(getContest({ token: user.accessToken }));
        }
    }, []);

    useEffect(() => {
        if (deleteContestRespStatus === 200) {
            dispatch(getContest({ token: user.accessToken }));
        }
    }, [deleteContestResp, deleteContestRespStatus]);

    useEffect(() => {
        if (addContestRespStatus === 200) {
            dispatch(getContest({ token: user.accessToken }));
        }
    }, [addContestResp, addContestRespStatus]);

    useEffect(() => {
        if (getContestRespStatus === 200) {
            setContestData(getContestResp.data.value);
        }
    }, [getContestResp, getContestRespStatus]);

    const handleEdit = (contest: any) => {
        setSelectedContest(contest);
        setAddingMilestone(true);
    };

    const handleDelete = (contest: any) => {
        dispatch(deleteContest({ token: user.accessToken, _id: contest._id }));
    };

    return (
        <div className="bg-[#F9F9F9] px-10 overflow-y-auto w-full">
            <div className="flex items-center justify-between bg-[#ffffff] w-full left-0 px-10">
                <div className="text-[42px] text-[#4C4C66] font-bold">
                    {addingMilestone ? "Add Milestone" : "Contests"}
                </div>
                <div className="flex items-center gap-5">
                    {location.pathname !== "/settings" && <ToggleSwitch />}
                    <div
                        className="flex items-center gap-3 relative cursor-pointer"
                        onClick={() => navigate("/settings")}
                    >
                        <img
                            src={pfi}
                            alt="pfi"
                            className="rounded-full border-2 border-[#FF409A] w-[34px] h-[34px] object-cover"
                        />
                        <div className="text-[#4C4C66] text-md font-bold cursor-pointer">
                            {user?.name}
                        </div>
                    </div>
                </div>
            </div>
            {addingMilestone ? (
                <AddMilestone
                    closeAddMilestone={() => {
                        setAddingMilestone(false);
                        setSelectedContest(null);
                    }}
                    data={selectedContest ? selectedContest : null}
                    events={events}
                    tokens={tokens}
                />
            ) : (
                <div className="overflow-y-auto w-full gap-5 mt-10">
                    <div className="flex items-center gap-5">
                        <div
                            className={
                                "text-lg cursor-pointer text-[#4C4C66] w-fit" +
                                (active === "view-analytics"
                                    ? " border-b-2 border-[#4C4C66] font-bold"
                                    : "")
                            }
                            onClick={() => {
                                setActive("view-analytics");
                            }}
                        >
                            View Analytics
                        </div>
                        <div
                            className={
                                "text-lg cursor-pointer text-[#4C4C66] w-fit" +
                                (active === "manage-contests"
                                    ? " border-b-2 border-[#4C4C66] font-bold"
                                    : "")
                            }
                            onClick={() => {
                                setActive("manage-contests");
                            }}
                        >
                            Manage Contests
                        </div>
                    </div>

                    {active === "manage-contests" && (
                        <>
                            <div className="mt-3">
                                <span className="font-bold font-DMSans text-[16px] text-[#4C4C66]">
                                    List of Contests
                                </span>
                            </div>
                            <div className="bg-[#ffffff] rounded-lg p-4 border-[2px] mt-1">
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex flex-row items-center gap-3 w-[30%]">
                                        <Text
                                            text={search}
                                            setText={setSearch}
                                            place={"- search in Contest -"}
                                            full={true}
                                            type={"text"}
                                            required={false}
                                            icon={searchIcon}
                                        />
                                    </div>
                                    <button
                                        className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                                        onClick={() => {
                                            setAddingMilestone(true);
                                        }}
                                    >
                                        Add a New Contest +
                                    </button>
                                </div>
                                {isGettingContest && (
                                    <div className="flex items-center justify-center mt-7">
                                        <Loader heavy={true} />
                                    </div>
                                )}
                                {getContestError && (
                                    <div className="flex items-center justify-center mt-7 text-[#4C4C66]">
                                        {getContestErrorMsg}
                                    </div>
                                )}
                                {getContestRespStatus === 200 &&
                                    getContestResp.data?.value.length > 0 && (
                                        <div className="mt-2 w-full text-[#4C4C66] rounded-lg overflow-auto">
                                            <table className="w-full text-sm border-collapse border-2 rounded-lg">
                                                <thead>
                                                    <tr className="bg-[#f9f9f9]">
                                                        {/* <th className="w-[30px]">
                                                            <input
                                                                type="checkbox"
                                                                //   checked={selectedRows.includes(rowIndex.toString())}
                                                                //   onChange={() => handleCheckboxChange(rowIndex.toString())}
                                                            />
                                                        </th> */}
                                                        <th className="font-semibold text-[16px] p-2 text-Grey-400">
                                                            Contest Name
                                                        </th>
                                                        <th className="font-semibold text-[16px] p-2 text-Grey-400 w-[120px]">
                                                            Active Status
                                                        </th>
                                                        <th className="font-semibold text-[16px] p-2 text-Grey-400">
                                                            Validity
                                                        </th>
                                                        <th className="font-semibold text-[16px] p-2 text-Grey-400 w-[150px]">
                                                            Challenges
                                                        </th>
                                                        <th className="font-semibold text-[16px] p-2 text-Grey-400">
                                                            Target Audience
                                                        </th>
                                                        <th className="font-semibold text-[16px] p-2 text-Grey-400">
                                                            Options
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contestData?.map(
                                                        (
                                                            contest: any,
                                                            ind: number
                                                        ) => {
                                                            let sd = new Date(
                                                                contest.start_date
                                                            );
                                                            sd.setHours(
                                                                sd.getHours() +
                                                                    5
                                                            );
                                                            sd.setMinutes(
                                                                sd.getMinutes() +
                                                                    30
                                                            );
                                                            let ed = new Date(
                                                                contest.end_date
                                                            );
                                                            ed.setHours(
                                                                ed.getHours() +
                                                                    5
                                                            );
                                                            ed.setMinutes(
                                                                ed.getMinutes() +
                                                                    30
                                                            );
                                                            return (
                                                                <tr
                                                                    key={
                                                                        "contest-" +
                                                                        ind
                                                                    }
                                                                    className={
                                                                        ind %
                                                                            2 ===
                                                                        0
                                                                            ? "bg-[#ffffff]"
                                                                            : "bg-[#f9f9f9]"
                                                                    }
                                                                >
                                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                                        {
                                                                            contest.name
                                                                        }
                                                                    </td>
                                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                                        {contest.is_active
                                                                            ? "Active"
                                                                            : "Inactive"}
                                                                    </td>
                                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                                        {
                                                                            sd
                                                                                .toISOString()
                                                                                .split(
                                                                                    "T"
                                                                                )[0]
                                                                        }
                                                                        &nbsp;
                                                                        &#8594;
                                                                        &nbsp;
                                                                        {
                                                                            ed
                                                                                .toISOString()
                                                                                .split(
                                                                                    "T"
                                                                                )[0]
                                                                        }
                                                                    </td>
                                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                                        <div className="flex flex-row gap-1 items-center flex-wrap">
                                                                            {contest.challenges.map(
                                                                                (
                                                                                    challenge: any,
                                                                                    ind: number
                                                                                ) => (
                                                                                    <Value
                                                                                        name={
                                                                                            challenge.name
                                                                                        }
                                                                                        value={
                                                                                            ""
                                                                                        }
                                                                                        operator={
                                                                                            "|"
                                                                                        }
                                                                                    />
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-2 text-center text-[14px] font-semibold">
                                                                        <div className="flex flex-row gap-1 items-center flex-wrap">
                                                                            <Value
                                                                                name={
                                                                                    "value"
                                                                                }
                                                                                value={
                                                                                    ""
                                                                                }
                                                                                operator={
                                                                                    "|"
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="p-2 text-center text-[15px] font-semibold">
                                                                        <div className="flex flex-row gap-1 items-center flex-wrap">
                                                                            <button
                                                                                className="bg-[#FF7B5F] text-white rounded-lg px-2 py-1"
                                                                                onClick={() =>
                                                                                    handleEdit(
                                                                                        contest
                                                                                    )
                                                                                }
                                                                            >
                                                                                Edit
                                                                            </button>
                                                                            <button
                                                                                className="bg-[#FF7B5F] text-white rounded-lg px-2 py-1"
                                                                                onClick={() =>
                                                                                    handleDel(
                                                                                        ind
                                                                                    )
                                                                                }
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                            </div>
                        </>
                    )}
                    <Modal
                        style={customStyles}
                        isOpen={openDelContestModal != -1}
                        ariaHideApp={false}
                    >
                        <div className="p-4 bg-[#F9F9F9]">
                            <h2 className="text-xl font-semibold mb-4">
                                Confirm Delete
                            </h2>
                            <p>Are you sure you want to delete the contest</p>
                            <div className="mt-6 space-x-4 w-full flex justify-end">
                                <button
                                    onClick={handleCancelSave}
                                    className="px-4 py-2 rounded text-black border border-black"
                                >
                                    No
                                </button>
                                <button
                                    onClick={() => {
                                        handleDelete(
                                            contestData[openDelContestModal]
                                        );
                                        handleCancelSave();
                                    }}
                                    className="bg-[#FF7B5F] text-white px-4 py-2 rounded"
                                >
                                    yes
                                </button>
                            </div>
                        </div>
                    </Modal>
                    {active === "view-analytics" && <></>}
                </div>
            )}
        </div>
    );
};

export default ContestContainer;

import RefferalProgram from "./refferalProgram";

const SnipeShare = () => {
  return (
    <div className="bg-[#F9F9F9] px-10 h-[calc(100vh-133px)] overflow-y-auto">
      <RefferalProgram />
    </div>
  );
};

export default SnipeShare;

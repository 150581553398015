import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
// import snackbar from './containers/common/snackbarReducer';\
import gamification from "./gamification/gamificationReducer";
import events from "./token/eventReducer";
import tokens from "./token/tokenReducer";
import rootSaga from "./rootSaga";
import auth from "./auth/authReducer";
import api from "./api/apiReducer";
import developerTools from "./developerTools/developerToolsReducer";
import settings from "./settings/settingsReducer";
import user from "./user/userReducer";
import customToken from "./user/customTokenReducer";
import multipliers from "./token/multipliersReducer";
import milestone from "./token/milestoneReducer";
import streaks from "./streak/streakReducer";
import referral from "./referral/referralReducer";
import contest from "./contest/contestReducer";
import attribute from "./attribute/attributeReducer";

const reducers = combineReducers({
    gamification,
    events,
    tokens,
    auth,
    api,
    developerTools,
    settings,
    user,
    customToken,
    multipliers,
    streaks,
    referral,
    milestone,
    contest,
    attribute,
});
const sagaMiddleware = createSagaMiddleware();

// const store = configureStore({ reducer: reducers, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(applyMiddleware(sagaMiddleware)) });
const store = configureStore({
    reducer: reducers,
    middleware: [sagaMiddleware],
});
sagaMiddleware.run(rootSaga);

export default store;

import { ref as REF, getDownloadURL, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import back from "../../assets/svg/back.svg";
import editD from "../../assets/svg/editD.svg";
import searchIcon from "../../assets/svg/search.svg";
import Loader from "../../components/common/loader";
import Add from "../../components/gamification/add";
import DateContainer from "../../components/gamification/modals/nudge/date";
import Radio from "../../components/gamification/modals/nudge/radio";
import Select from "../../components/gamification/modals/nudge/select";
import Text from "../../components/gamification/modals/nudge/text";
import CustomEventUserFilterModal from "../../components/gamification/streaks/customiseEventUserFilterModal";
import EventVariationCard from "../../components/gamification/streaks/eventVariationCard";
import MilestoneTokens from "../../components/gamification/streaks/milestoneTokens";
import RewardMultipliers from "../../components/gamification/streaks/rewardMultipliers";
import RewardTokens from "../../components/gamification/streaks/rewardTokens";
import StreakCard from "../../components/gamification/streaks/streakCard";
import StreakEvents from "../../components/gamification/streaks/streakEvents";
import StreakMultipliers from "../../components/gamification/streaks/streakMultipliers";
import StreakTokens from "../../components/gamification/streaks/streakTokens";
import UsersWith from "../../components/users/management/modals/usersWith";
import Value from "../../components/users/management/value";
import { storage } from "../../firebaseConfig";
import { useUserAuth } from "../../hooks/useAuthContextProvider";
import {
    addStreak,
    deleteStreak,
    getStreaks,
    resetStreaksStates,
    updateRewardMultiplierInput,
    updateStreak,
    updateStreakMultiplierInput,
    updateStreakTokenInput,
    updateStreakTokenMilestoneInput,
    updateStreakTokenRewardInput,
} from "../../redux/streak/streakActions";
import { getEvents } from "../../redux/token/eventActions";
import { getMultipliers } from "../../redux/token/multipliersActions";
import { getTokens } from "../../redux/token/tokenActions";
import { toggleCustomTokenModal } from "../../redux/user/customTokenActions";
import {
    addCustomTokenFinalUser,
    getUserVariable,
} from "../../redux/user/userActions";
import { customStyles } from "../gamification/campaign";
interface IStreaksProps {
    ref: any;
}

const Streaks = ({ ref }: IStreaksProps) => {
    const [showModal, setShowModal] = useState(false);
    const [showConfModal, setShowConfModal] = useState(false);
    const [tImage, setTImage] = useState<any>(null);
    const [addOrUpdateStreak, setAddOrUpdateStreak] = useState<boolean>(false);
    const [priority, setPriority] = useState<number | string>("");
    const [name, setName] = useState("");
    // const [number, setNumber] = useState("");
    // const [streakchange, setStreakChange] = useState("");
    const [streakday, setStreakday] = useState("0");
    const [streakdays, setStreakdays] = useState("");
    const [increment, setIncrement] = useState("0");
    const [date, setDate] = useState<Date | null>(null);
    const [condition, setCondition] = useState<string>("3");
    const [general, setGeneral] = useState<string>("General");
    const [constant, setConstant] = useState<string>("Constant");
    const [activate, setActivate] = useState<string>("Periodcally");
    const [unlimited, setUnlimited] = useState<boolean>(false);
    const [milestone, setMilestone] = useState<boolean>(false);
    const [configureStreak, setConfigureStreak] = useState(false);
    const [showTokens, setShowTokens] = useState<boolean>(false);
    const [activeTokens, setActiveTokens] = useState<Array<string>>([]);
    const [eventDay, setEventDay] = useState<number>(1);
    const [showEvents, setShowEvents] = useState<boolean>(false);
    const [showMultipliers, setShowMultipliers] = useState<boolean>(false);
    const [showRewardMultipliers, setShowRewardMultipliers] =
        useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [showMilestoneTokens, setShowMilestoneTokens] =
        useState<boolean>(false);
    const [showRewardTokens, setShowRewardTokens] = useState<boolean>(false);
    const [activeMilestoneTokens, setActiveMilestoneTokens] = useState<
        Array<string>
    >([]);
    const [activeRewardTokens, setActiveRewardTokens] = useState<Array<string>>(
        []
    );
    const [searchEvents, setSearchEvents] = useState<string>("");
    const [showConfigureStreakModal, setshowConfigureStreakModal] =
        useState<boolean>(false);
    const [addVariationModal, setAddVariationModal] = useState<boolean>(false);
    const [eventName, setEventName] = useState<string>("");
    const [eventDescription, setEventDescription] = useState<string>("");
    const [redirectUrl, setRedirectUrl] = useState<string>("");
    const [eventDate, setEventDate] = useState<Date | null>(null);
    const [eventPeriod, setEventPeriod] = useState<string>("");
    const [eventPeriodType, setEventPeriodType] = useState<string>("days");
    const [streakEventsNumber, setStreakEventsNumber] = useState<any>("");
    const [streakDailyEventPriority, setStreakDailyEventPriority] =
        useState<any>("");
    const [streakPeriodicEventPriority, setStreakPeriodicEventPriority] =
        useState<any>("");
    const [streakUserStateEventPriority, setStreakUserStateEventPriority] =
        useState<any>("");
    const [streakCampaignEventPriority, setStreakCampaignEventPriority] =
        useState<any>("");
    const [streakEventMinimumNumber, setStreakEventMinimumNumber] =
        useState<any>("");
    const [periodicEventData, setPeriodicEventData] = useState<any>({});
    const [dailyEventData, setDailyEventData] = useState<any>({});
    const [userStateEventData, setUserStateEventData] = useState<any>({});
    const [campaignEventData, setCampaignEventData] = useState<any>({});
    const [selectedEvent, setSelectedEvent] = useState<any>();
    const [selectedEvents, setSelectedEvents] = useState<any>([]);
    const [customiseEventUserFilter, setCustomiseEventUserFilter] =
        useState<any>({});
    const [showCustomiseEventUserFilter, setShowCustomiseEventUserFilter] =
        useState<boolean>(false);
    const [eventVariation, setEventVariation] = useState<any>([]);
    const [editEventVariantData, setEditEventVariantData] = useState<
        string | null
    >(null);

    const handleAddVariantData = async (isUpdating: boolean) => {
        let imgURL: string;
        if (tImage && typeof tImage !== "string") {
            const params = tImage;
            const imageRef = REF(storage, `Streaks/${params.name}`);
            await uploadBytes(imageRef, params);
            imgURL = await getDownloadURL(imageRef);
        } else {
            imgURL = tImage || "a";
        }
        if (isUpdating) {
            const data = eventVariation.map((each: any) => {
                if (each.name === editEventVariantData) {
                    return {
                        name: eventName,
                        description: eventDescription,
                        redirect_url: redirectUrl,
                        image: imgURL,
                        date: eventDate,
                    };
                }
                return each;
            });

            setEventVariation(data);
            setEditEventVariantData(null);
            resetCustomiseEventModal();
            return;
        }
        setTImage(imgURL);
        if (eventVariation.length > 0) {
            setEventVariation([
                ...eventVariation,
                {
                    name: eventName,
                    description: eventDescription,
                    redirect_url: redirectUrl,
                    image: imgURL,
                    date: eventDate,
                    is_default: false,
                },
            ]);
        } else {
            setEventVariation([
                {
                    name: eventName,
                    description: eventDescription,
                    redirect_url: redirectUrl,
                    image: imgURL,
                    date: eventDate,
                    is_default: true,
                },
            ]);
        }
        resetCustomiseEventModal();
    };

    const handleDeleteEventVariation = (event: any) => {
        let data = eventVariation.filter((each: any, ind: number) => {
            return each.name !== event.name;
        });
        if (event.is_default && data.length > 0) {
            data[0].is_default = true;
        }
        setEventVariation(data);
        resetCustomiseEventModal();
    };
    const handleEditEventVariation = (event: any) => {
        setEventName(event.name);
        setEventDescription(event.description);
        setRedirectUrl(event.redirect_url);
        setEventDate(event.date);
        setTImage(event.image);
        setAddVariationModal(true);
        setEditEventVariantData(event.name);
    };

    const handleSetDefaultEventVariation = (event: any) => {
        const data = eventVariation.map((each: any) => {
            if (each.name === event.name) {
                return { ...each, is_default: true };
            }
            return { ...each, is_default: false };
        });
        setEventVariation(data);
    };

    const handleCustomiseModalData = (event: any) => {
        if (activate === "Daily") {
            const data = dailyEventData?.activities?.filter((each: any) => {
                return each.event === event._id;
            })[0];
            setEventVariation(data.optional_names || []);
        } else if (activate === "Periodcally") {
            const data = periodicEventData?.activities?.filter((each: any) => {
                return each.event === event._id;
            })[0];
            setEventVariation(data.optional_names || []);
            setEventPeriod(data.periodic_value);
        } else if (activate === "User") {
            const data = userStateEventData?.activities?.filter((each: any) => {
                return each.event === event._id;
            })[0];
            setEventVariation(data.optional_names || []);
            setCustomiseEventUserFilter(data.user_filter);
        } else if (activate === "challenges") {
            const data = campaignEventData?.activities?.filter((each: any) => {
                return each.event === event._id;
            })[0];
            setEventVariation(data.optional_names || []);
        }
    };

    const checkEventsDataVisiblity = (event: any) => {
        if (activate === "Daily") {
            if (
                selectedEvents.find(
                    (each: any) =>
                        each._id === event._id || each.event === event._id
                )
            ) {
                if (
                    dailyEventData?.activities?.find(
                        (each: any) => each.event === event._id
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        } else if (activate === "Periodcally") {
            if (
                selectedEvents.find(
                    (each: any) =>
                        each._id === event._id || each.event === event._id
                )
            ) {
                if (
                    periodicEventData?.activities?.find(
                        (each: any) => each.event === event._id
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        } else if (activate === "User") {
            if (
                selectedEvents.find(
                    (each: any) =>
                        each._id === event._id || each.event === event._id
                )
            ) {
                if (
                    userStateEventData?.activities?.find(
                        (each: any) => each.event === event._id
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        } else if (activate === "challenges") {
            if (
                selectedEvents.find(
                    (each: any) =>
                        each._id === event._id || each.event === event._id
                )
            ) {
                if (
                    campaignEventData?.activities?.find(
                        (each: any) => each.event === event._id
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        }
    };

    const handleItemClick = (event: any) => {
        if (activate === "Daily") {
            if (
                dailyEventData?.activities?.find((each: any) => {
                    return each.event === event._id;
                })
            ) {
                let data = dailyEventData?.activities;
                data = data.filter((each: any) => {
                    return each.event !== event._id;
                });
                setDailyEventData({
                    priority:
                        typeof streakDailyEventPriority != "string"
                            ? streakDailyEventPriority
                            : parseInt(streakDailyEventPriority),
                    activities: data,
                });
                setSelectedEvents(
                    selectedEvents?.filter((each: any) => {
                        return each.event !== event._id;
                    })
                );
            } else {
                let data = { ...dailyEventData };
                data.priority =
                    typeof streakDailyEventPriority != "string"
                        ? streakDailyEventPriority
                        : parseInt(streakDailyEventPriority);
                let activityData = {
                    name: "",
                    description: "",
                    redirect_url: "",
                    image: "",
                    event: event._id,
                    optional_names: [],
                };
                setSelectedEvents([...selectedEvents, activityData]);
                if (data.activities && data.activities.length > 0) {
                    data.activities = [...data.activities, activityData];
                } else {
                    data.activities = [activityData];
                }
                setDailyEventData(data);
            }
        } else if (activate === "Periodcally") {
            if (
                periodicEventData?.activities?.find(
                    (each: any) => each.event === event._id
                )
            ) {
                let data = periodicEventData?.activities;
                data = data.filter((each: any) => {
                    return each.event !== event._id;
                });
                setPeriodicEventData({
                    priority:
                        typeof streakPeriodicEventPriority != "string"
                            ? streakPeriodicEventPriority
                            : parseInt(streakPeriodicEventPriority),
                    activities: data,
                });
                setSelectedEvents(
                    selectedEvents?.filter((each: any) => {
                        return each.event !== event._id;
                    })
                );
            } else {
                let data = { ...periodicEventData };
                data.priority =
                    typeof streakPeriodicEventPriority != "string"
                        ? streakPeriodicEventPriority
                        : parseInt(streakPeriodicEventPriority);
                let activityData = {
                    name: "",
                    description: "",
                    redirect_url: "",
                    image: "",
                    event: event._id,
                    periodic_value: "",
                    optional_names: [],
                };
                setSelectedEvents([...selectedEvents, activityData]);
                if (data.activities && data.activities.length > 0) {
                    data.activities = [...data.activities, activityData];
                } else {
                    data.activities = [activityData];
                }
                setPeriodicEventData(data);
            }
        } else if (activate === "User") {
            if (
                userStateEventData?.activities?.find(
                    (each: any) => each.event === event._id
                )
            ) {
                let data = userStateEventData?.activities;
                data = data.filter((each: any) => {
                    return each.event !== event._id;
                });
                setUserStateEventData({
                    priority:
                        typeof streakUserStateEventPriority != "string"
                            ? streakUserStateEventPriority
                            : parseInt(streakUserStateEventPriority),
                    activities: data,
                });
                setSelectedEvents(
                    selectedEvents?.filter((each: any) => {
                        return each.event !== event._id;
                    })
                );
            } else {
                let data = { ...userStateEventData };
                data.priority =
                    typeof streakUserStateEventPriority != "string"
                        ? streakUserStateEventPriority
                        : parseInt(streakUserStateEventPriority);
                let activityData = {
                    name: "",
                    description: "",
                    redirect_url: "",
                    image: "",
                    event: event._id,

                    user_filter: customiseEventUserFilter,
                    optional_names: [],
                };
                setSelectedEvents([...selectedEvents, activityData]);
                if (data.activities && data.activities.length > 0) {
                    data.activities = [...data.activities, activityData];
                } else {
                    data.activities = [activityData];
                }
                setUserStateEventData(data);
            }
        } else if (activate === "challenges") {
            if (
                campaignEventData?.activities?.find(
                    (each: any) => each.event === event._id
                )
            ) {
                let data = campaignEventData?.activities;
                data = data.filter((each: any) => {
                    return each.event !== event._id;
                });
                setCampaignEventData({
                    priority:
                        typeof streakCampaignEventPriority != "string"
                            ? streakCampaignEventPriority
                            : parseInt(streakCampaignEventPriority),
                    activities: data,
                });
                setSelectedEvents(
                    selectedEvents?.filter((each: any) => {
                        return each.event !== event._id;
                    })
                );
            } else {
                let data = { ...campaignEventData };
                data.priority =
                    typeof streakCampaignEventPriority != "string"
                        ? streakCampaignEventPriority
                        : parseInt(streakCampaignEventPriority);
                let activityData = {
                    name: "",
                    description: "",
                    redirect_url: "",
                    image: "",
                    event: event._id,

                    optional_names: [],
                };
                setSelectedEvents([...selectedEvents, activityData]);
                if (data.activities && data.activities.length > 0) {
                    data.activities = [...data.activities, activityData];
                } else {
                    data.activities = [activityData];
                }
                setCampaignEventData(data);
            }
        }
    };
    const [selectedStreak, setSelectedStreak] = useState<string>("");
    const [arithmeticSelected, setArithmeticSelected] = useState<string>(
        "Arithmetic Increment"
    );
    const { user } = useUserAuth();
    const dispatch = useDispatch();

    const { active, type } = useSelector((state: any) => state.customToken);
    const {
        getUserVariableRespStatus,
        getUserVariableResp,
        userFinalFilter,
        isGettingUserVariable,
    } = useSelector((state: any) => state.user);
    const {
        getMultipliersResp,
        getMultipliersRespStatus,
        isGettingMultipliers,
    } = useSelector((state: any) => state.multipliers);
    const { getTokensResp, getTokensRespStatus, isGettingTokens } = useSelector(
        (state: any) => state.tokens
    );
    const {
        tokens,
        tokensMilestone,
        tokensReward,
        // events,
        multipliers,
        rewardMultipliers,
        isGettingStreaks,
        getStreaksResp,
        getStreaksRespStatus,
        isAddingStreak,
        addStreakRespStatus,
        addStreakError,
        isUpdatingStreak,
        updateStreakRespStatus,
        updateStreakError,
        deleteStreakResp,
        deleteStreakRespStatus,
    } = useSelector((state: any) => state.streaks);

    const { getEventsResp } = useSelector((state: any) => state.events);

    const getAllTokensOfClient = async () => {
        try {
            // setTokensResp(data.data.value);
        } catch (e) {
            // setTokensResp([]);
        }
    };
    useEffect(() => {
        if (getUserVariableRespStatus !== 200) {
            dispatch(getUserVariable({ token: user.accessToken }));
        }
        if (getMultipliersRespStatus !== 200) {
            dispatch(getMultipliers({ token: user.accessToken }));
        }
        if (getTokensRespStatus !== 200) {
            dispatch(getTokens({ token: user.accessToken }));
        }
        getAllTokensOfClient();
        dispatch(getEvents({ token: user.accessToken }));
        if (getStreaksRespStatus !== 200) {
            dispatch(getStreaks({ token: user.accessToken }));
        }
    }, []);

    const handleOpenModal = () => {
        setShowModal(true);
        setAddOrUpdateStreak(true);
    };
    const handleOpenConfModal = () => {
        setShowConfModal(true);
    };
    const handleCloseConfModal = () => {
        setShowConfModal(false);
    };
    const handleConti = () => {
        handleCloseConfModal();
    };

    const handleDelete = (ref_id: any) => {
        try {
            if (ref_id) {
                dispatch(deleteStreak({ token: user.accessToken, id: ref_id }));
            } else {
                throw new Error("No Streak selected for deletion");
            }
        } catch (error) {
            console.error("Delete Error:", error);
        }
    };

    const resetState = () => {
        setName("");
        // setNumber("");
        setPriority("");
        setStreakday("");
        setStreakdays("");
        setIncrement("");
        setCondition("3");
        setDate(null);
        setUnlimited(false);
        setMilestone(false);
        setConfigureStreak(false);
        setShowTokens(false);
        setActiveTokens([]);
        setEventDay(1);
        setShowEvents(false);
        setArithmeticSelected("");
        setShowMultipliers(false);
        setActiveMilestoneTokens([]);
        setActiveRewardTokens([]);
        // setTokensResp([]);
        setAddOrUpdateStreak(false);
        dispatch(resetStreaksStates({}));
        dispatch(
            addCustomTokenFinalUser({
                e: {
                    strings: [],
                    numbers: [],
                    booleans: [],
                    dates: [],
                },
            })
        );
        setSelectedStreak("");
        setGeneral("General");
        setDailyEventData({});
        setPeriodicEventData({});
        setUserStateEventData({});
        setCampaignEventData({});
        setStreakCampaignEventPriority("");
        setStreakDailyEventPriority("");
        setStreakPeriodicEventPriority("");
        setStreakUserStateEventPriority("");
        setStreakEventMinimumNumber("");
        setStreakEventsNumber("");
        resetCustomiseEventModal();
        setSelectedEvents([]);
        setCustomiseEventUserFilter({});
    };

    useEffect(() => {
        if (addStreakRespStatus === 200 || updateStreakRespStatus === 200) {
            resetState();
            dispatch(getStreaks({ token: user.accessToken }));
        } else if (addStreakError || updateStreakError) {
            alert("Something went wrong");
        }
    }, [isAddingStreak, isUpdatingStreak]);

    useEffect(() => {
        if (deleteStreakRespStatus === 200) {
            dispatch(getStreaks({ token: user.accessToken }));
        }
    }, [deleteStreakResp]);

    const handleCloseModal = () => {
        setShowModal(false);
        // setName("");
        // setNumber("");
        // setStreakChange("");
        // setStreakday("");
        // setStreakdays("");
        // setIncrement("");
        // setDate(null);
        // setCondition("3");
        // setGeneral("General");
        // setConstant("Constant");
        // setUnlimited(false);
        // setMilestone(false);
        setConfigureStreak(false);
        // setShowTokens(false);
        // setActiveTokens([]);
        // setShowEvents(false);
        // setShowMilestoneTokens(false);
        // setActiveMilestoneTokens([]);
    };

    const handleOpenExistingStreak = () => {
        const streak: any = getStreaksResp?.data?.value?.find(
            (each: any) => each._id === selectedStreak
        );
        setName(streak.name);
        setPriority(streak.priority);
        // setNumber(streak.events_per_day);
        setStreakEventMinimumNumber(streak.minimum_activities_per_day);
        setStreakEventsNumber(streak.activities_per_day);
        // setStreakEventPriority(streak)
        setStreakday(streak.milestone_reward?.day_interval || "0");
        setStreakdays(streak.allow_missed.days);
        setIncrement(streak.milestone_reward?.increment_factor || "0");
        setDate(new Date(streak.starting_date));
        setCondition(streak.number_of_days?.toString());
        setGeneral(streak.streak_type === 1 ? "General" : "Custom streak");
        setConstant(
            streak.milestone_reward?.increment_type === 1
                ? "Constant"
                : "Incremental"
        );
        setUnlimited(streak.is_unlimited);
        setMilestone(streak.milestone_reward ? true : false);
        // setConfigureStreak;
        // setShowTokens;
        setActiveTokens(
            streak.allow_missed
                ? streak.allow_missed.tokens?.map((each: any) => each.token_id)
                : []
        );
        // setEventDay;
        // setShowEvents;
        // setShowMultipliers;
        // setShowMilestoneTokens;
        setActiveMilestoneTokens(
            streak.milestone_reward
                ? streak.milestone_reward?.tokens?.map(
                      (each: any) => each.token_id
                  ) || []
                : []
        );
        setActiveRewardTokens(
            streak.rewards
                ? streak.rewards?.tokens?.map((each: any) => each.token_id) ||
                      []
                : []
        );

        setStreakDailyEventPriority(
            streak.activities?.daily_activities?.priority || ""
        );
        setStreakPeriodicEventPriority(
            streak.activities?.periodic_activities?.priority || ""
        );
        setStreakUserStateEventPriority(
            streak.activities?.user_state_activities?.priority || ""
        );
        setStreakCampaignEventPriority(
            streak.activities?.campaign_activities?.priority || ""
        );

        setDailyEventData({
            priority:
                typeof streak.activities?.daily_activities.priority !== "string"
                    ? streak.activities?.daily_activities.priority
                    : 0,
            activities: streak.activities?.daily_activities.activities.map(
                (each: any) => {
                    let { _id, uid_, ...rest } = each;
                    return {
                        ...rest,
                        optional_names: rest.optional_names.map((each: any) => {
                            let { _id, ...rest } = each;
                            return rest;
                        }),
                    };
                }
            ),
        });
        setPeriodicEventData({
            priority:
                typeof streak.activities?.periodic_activities.priority !==
                "string"
                    ? streak.activities?.periodic_activities.priority
                    : 0,
            activities: streak.activities?.periodic_activities.activities.map(
                (each: any) => {
                    let { _id, uid_, ...rest } = each;
                    return {
                        ...rest,
                        optional_names: rest.optional_names.map((each: any) => {
                            let { _id, ...rest } = each;
                            return rest;
                        }),
                    };
                }
            ),
        });
        setUserStateEventData({
            priority:
                typeof streak.activities?.user_state_activities.priority !==
                "string"
                    ? streak.activities?.user_state_activities.priority
                    : 0,
            activities: streak.activities?.user_state_activities.activities.map(
                (each: any) => {
                    let { _id, uid_, ...rest } = each;
                    return {
                        ...rest,
                        optional_names: rest.optional_names.map((each: any) => {
                            let { _id, ...rest } = each;
                            return rest;
                        }),
                    };
                }
            ),
        });
        setCampaignEventData({
            priority:
                typeof streak.activities?.campaign_activities.priority !==
                "string"
                    ? streak.activities?.campaign_activities.priority
                    : 0,
            activities: streak.activities?.campaign_activities.activities.map(
                (each: any) => {
                    let { _id, uid_, ...rest } = each;
                    return {
                        ...rest,
                        optional_names: rest.optional_names.map((each: any) => {
                            let { _id, ...rest } = each;
                            return rest;
                        }),
                    };
                }
            ),
        });

        let data: any[] = [];
        if (streak?.activities) {
            data = [
                ...streak.activities?.daily_activities?.activities.map(
                    (each: any) => {
                        let { _id, uid_, ...rest } = each;
                        return {
                            ...rest,
                            optional_names: rest.optional_names.map(
                                (each: any) => {
                                    let { _id, ...rest } = each;
                                    return rest;
                                }
                            ),
                        };
                    }
                ),
                ...streak.activities?.periodic_activities?.activities.map(
                    (each: any) => {
                        let { _id, uid_, ...rest } = each;
                        return {
                            ...rest,
                            optional_names: rest.optional_names.map(
                                (each: any) => {
                                    let { _id, ...rest } = each;
                                    return rest;
                                }
                            ),
                        };
                    }
                ),
                ...streak.activities?.user_state_activities?.activities.map(
                    (each: any) => {
                        let { _id, uid_, ...rest } = each;
                        return {
                            ...rest,
                            optional_names: rest.optional_names.map(
                                (each: any) => {
                                    let { _id, ...rest } = each;
                                    return rest;
                                }
                            ),
                        };
                    }
                ),
                ...streak.activities?.campaign_activities?.activities.map(
                    (each: any) => {
                        let { _id, uid_, ...rest } = each;
                        return {
                            ...rest,
                            optional_names: rest.optional_names.map(
                                (each: any) => {
                                    let { _id, ...rest } = each;
                                    return rest;
                                }
                            ),
                        };
                    }
                ),
            ];
        }

        setSelectedEvents(data);

        // setTokensResp;
        // setSelectedStreak;
        dispatch(
            updateStreakTokenInput({
                e:
                    streak.allow_missed?.tokens?.map(
                        ({ _id, ...each }: any) => each
                    ) || [],
            })
        );
        dispatch(
            updateStreakTokenMilestoneInput({
                e: streak.milestone_reward
                    ? streak.milestone_reward?.tokens?.map(
                          ({ _id, ...each }: any) => each
                      ) || []
                    : [],
            })
        );
        dispatch(
            updateStreakTokenRewardInput({
                e: streak.rewards
                    ? streak.rewards?.tokens?.map(
                          ({ _id, ...each }: any) => each
                      ) || []
                    : [],
            })
        );
        dispatch(
            updateStreakMultiplierInput({
                multiplierId: streak.milestone_reward
                    ? streak.milestone_reward?.multiplier || ""
                    : "",
            })
        );
        dispatch(
            updateRewardMultiplierInput({
                multiplierId: streak.rewards
                    ? streak.rewards?.multiplier || ""
                    : "",
            })
        );
        // dispatch(
        //     updateStreakEventInput({
        //         e:
        //             streak.streak_type === 1
        //                 ? [streak.general_events].map(
        //                       ({ _id, ...each }: any) => each
        //                   )
        //                 : [streak.custom_events].map(
        //                       ({ _id, ...each }: any) => each
        //                   ),
        //     })
        // );
        dispatch(addCustomTokenFinalUser({ e: streak.user_filter }));
        // setShowModal(true);
    };
    const handleEvent: any = (e: any, type: string) => {
        const keys = Object.keys(e);
        return type === "token"
            ? Object.values(e)
            : general === "General"
            ? e[keys[0]]
            : Object.values(e);
    };

    const [invalidForm1, setInvalidForm1] = useState<boolean>(false);
    const [invalidForm2, setInvalidForm2] = useState<boolean>(false);

    const handleSave = async () => {
        setInvalidForm1(true);
        setInvalidForm2(true);
        if (priority === "" || name === "" || date === null) {
            setShowModal(true);
            setInvalidForm1(true);
            return;
        }
        if (streakEventsNumber === "" || streakEventMinimumNumber === "") {
            setShowConfModal(true);
            setInvalidForm2(true);
            return;
        }
        try {
            // console.log(name);
            // console.log(general);
            // console.log(number);
            // console.log(unlimited);
            // console.log(date);
            // console.log(condition);
            // console.log(showTokens);
            // console.log(showEvents);
            // console.log(eventDay);
            // console.log(Object.keys(events));
            // console.log(streakdays); //allowed miss
            // console.log(rendertoken);
            // console.log(tokensMilestone)
            // console.log(increment) //increment factor
            // console.log(arithmeticSelected) //increment factor
            // console.log(getEventsResp) //increment factor
            // const renderevent = handleEvent(events, "event");
            const rendertoken = handleEvent(tokens, "token");
            let streakData: any = {
                name: name,
                streak_type: general === "General" ? 1 : 2,
                is_unlimited: unlimited,
                starting_date: date,
                number_of_days:
                    typeof condition !== "string"
                        ? condition
                        : parseInt(condition),
                activities_per_day:
                    typeof streakEventsNumber != "string"
                        ? streakEventsNumber
                        : parseInt(streakEventsNumber),
                minimum_activities_per_day:
                    typeof streakEventMinimumNumber != "string"
                        ? streakEventMinimumNumber
                        : parseInt(streakEventMinimumNumber),
                milestone_reward: {
                    day_interval:
                        typeof streakday !== "string"
                            ? streakday
                            : parseInt(streakday),
                    reward_type: constant === "constant" ? 1 : 2,
                    increment_type:
                        arithmeticSelected === "Arithmetic Increment" ? 1 : 2,
                    incremental_factor:
                        typeof increment !== "string"
                            ? increment
                            : parseInt(increment),
                    tokens: rendertoken,
                    multiplier: multipliers,
                },
                allow_missed: {
                    days:
                        typeof streakdays !== "string"
                            ? streakdays
                            : parseInt(streakdays),
                    tokens: rendertoken,
                },
                user_filter: userFinalFilter,
                priority:
                    typeof priority !== "string"
                        ? priority
                        : parseInt(priority),
                rewards: {
                    tokens: Object.values(tokensReward),
                    multiplier:
                        rewardMultipliers === "" ? null : rewardMultipliers,
                },

                activities: {
                    daily_activities: {
                        priority: dailyEventData.priority || 0,
                        activities: dailyEventData.activities || [],
                    },
                    periodic_activities: {
                        priority: periodicEventData.priority || 0,
                        activities: periodicEventData.activities || [],
                    },
                    user_state_activities: {
                        priority: userStateEventData.priority || 0,
                        activities: userStateEventData.activities || [],
                    },
                    campaign_activities: {
                        priority: campaignEventData.priority || 0,
                        activities: campaignEventData.activities || [],
                    },
                },
            };
            if (milestone === false) {
                const { milestone_reward, ...rest } = streakData;
                streakData = rest;
            }
            if (selectedStreak) {
                dispatch(
                    updateStreak({
                        token: user.accessToken,
                        ...streakData,
                        streak_id: selectedStreak,
                    })
                );
            } else {
                dispatch(addStreak({ token: user.accessToken, streakData }));
            }
            resetState();
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleCustomiseEvent = async () => {
        if (activate === "Daily") {
            let data = { ...dailyEventData };
            data.priority =
                typeof streakDailyEventPriority != "string"
                    ? streakDailyEventPriority
                    : parseInt(streakDailyEventPriority);
            let activityData = {
                name: eventName,
                description: eventDescription,
                redirect_url: redirectUrl,
                image: tImage,
                event: selectedEvent._id,
                optional_names: eventVariation,
            };
            if (data.activities && data.activities.length > 0) {
                data.activities = data.activities.map((each: any) => {
                    if (each.event === selectedEvent._id) return activityData;
                    return each;
                });
            } else {
                data.activities = [activityData];
            }
            setDailyEventData(data);
        } else if (activate === "Periodcally") {
            let data = { ...periodicEventData };
            data.priority =
                typeof streakPeriodicEventPriority != "string"
                    ? streakPeriodicEventPriority
                    : parseInt(streakPeriodicEventPriority);
            let activityData = {
                name: eventName,
                description: eventDescription,
                redirect_url: redirectUrl,
                image: tImage,
                event: selectedEvent._id,
                periodic_value:
                    typeof eventPeriod != "string"
                        ? eventPeriod
                        : parseInt(eventPeriod),
                optional_names: eventVariation,
            };
            if (data.activities && data.activities.length > 0) {
                data.activities = data.activities.map((each: any) => {
                    if (each.event === selectedEvent._id) return activityData;
                    return each;
                });
            } else {
                data.activities = [activityData];
            }
            setPeriodicEventData(data);
        } else if (activate === "User") {
            let data = { ...userStateEventData };
            data.priority =
                typeof streakUserStateEventPriority != "string"
                    ? streakUserStateEventPriority
                    : parseInt(streakUserStateEventPriority);
            let activityData = {
                name: eventName,
                description: eventDescription,
                redirect_url: redirectUrl,
                image: tImage,
                event: selectedEvent._id,

                user_filter: {
                    strings: customiseEventUserFilter.strings || [],
                    numbers: customiseEventUserFilter.numbers || [],
                    booleans: customiseEventUserFilter.booleans || [],
                    dates: customiseEventUserFilter.dates || [],
                },
                optional_names: eventVariation,
            };
            if (data.activities && data.activities.length > 0) {
                data.activities = data.activities.map((each: any) => {
                    if (each.event === selectedEvent._id) return activityData;
                    return each;
                });
            } else {
                data.activities = [activityData];
            }
            setUserStateEventData(data);
        } else if (activate === "challenges") {
            let data = { ...campaignEventData };
            data.priority =
                typeof streakCampaignEventPriority != "string"
                    ? streakCampaignEventPriority
                    : parseInt(streakCampaignEventPriority);
            let activityData = {
                name: eventName,
                description: eventDescription,
                redirect_url: redirectUrl,
                image: tImage,
                event: selectedEvent._id,

                optional_names: eventVariation,
            };
            if (data.activities && data.activities.length > 0) {
                data.activities = data.activities.map((each: any) => {
                    if (each.event === selectedEvent._id) return activityData;
                    return each;
                });
            } else {
                data.activities = [activityData];
            }
            setCampaignEventData(data);
        }
    };

    const handleChangeEventPriority = (e: any): void => {
        if (activate === "Daily") {
            setStreakDailyEventPriority(e.target.value);
            setDailyEventData({
                priority:
                    typeof streakDailyEventPriority != "string"
                        ? streakDailyEventPriority
                        : parseInt(streakDailyEventPriority),
                activities: dailyEventData.activities,
            });
        } else if (activate === "Periodcally") {
            setStreakPeriodicEventPriority(e.target.value);
            setPeriodicEventData({
                priority:
                    typeof streakPeriodicEventPriority != "string"
                        ? streakPeriodicEventPriority
                        : parseInt(streakPeriodicEventPriority),
                activities: periodicEventData.activities,
            });
        } else if (activate === "User") {
            setStreakUserStateEventPriority(e.target.value);
            setUserStateEventData({
                priority:
                    typeof streakUserStateEventPriority != "string"
                        ? streakUserStateEventPriority
                        : parseInt(streakUserStateEventPriority),
                activities: userStateEventData.activities,
            });
        } else if (activate === "challenges") {
            setStreakCampaignEventPriority(e.target.value);
            setCampaignEventData({
                priority:
                    typeof streakCampaignEventPriority != "string"
                        ? streakCampaignEventPriority
                        : parseInt(streakCampaignEventPriority),
                activities: campaignEventData.activities,
            });
        }
    };

    const CheckPresentIntabs = (id: string) => {
        if (activate === "Daily") {
            return dailyEventData.activities?.find(
                (each: any) => each.event === id
            );
        } else if (activate === "Periodcally") {
            return periodicEventData.activities?.find(
                (each: any) => each.event === id
            );
        } else if (activate === "User") {
            return userStateEventData.activities?.find(
                (each: any) => each.event === id
            );
        } else if (activate === "challenges") {
            return campaignEventData.activities?.find(
                (each: any) => each.event === id
            );
        }
    };

    const resetCustomiseEventModal = () => {
        setEventName("");
        setEventDescription("");
        setRedirectUrl("");
        setEventPeriod("");
        setEventPeriodType("days");
        setTImage(null);
        setEventDate(null);
    };

    useEffect(() => {
        if (selectedStreak) {
            handleOpenExistingStreak();
        }
    }, [selectedStreak]);

    const handleContinue = (e: any) => {
        e.preventDefault();
        if (configureStreak) {
            setShowModal(false);
            setConfigureStreak(false);
            document
                .querySelector("#immersive-gamification")
                ?.scroll({ top: 0, left: 0 });
            ref?.current?.scrollTo({ top: 0, left: 0 });
        } else {
            setConfigureStreak(true);
        }
    };

    // const isNumeric = (value: string) => {
    //     return /^-?\d+$/.test(value);
    // };

    // const handleNumberChange = (value: string) => {
    //     setNumber(value);
    // };
    // const handleStreakChange = (value: string) => {
    //     if (isNumeric(value)) {
    //         setStreakChange(value);
    //     } else {
    //         setStreakChange("");
    //     }
    // };

    const handleOpen = (type: string) => {
        dispatch(toggleCustomTokenModal({ id: uuidv4(), type: type }));
    };

    const handleClose = () => {
        dispatch(toggleCustomTokenModal({ id: "" }));
    };

    return (
        <div className="bg-[#F9F9F9] px-10 h-[calc(100vh-133px)] overflow-y-auto">
            <div className="flex items-center justify-between mt-5">
                <div className="text-2xl font-bold">Streaks</div>
                <div className="flex items-center gap-3">
                    <Text
                        text={search}
                        setText={setSearch}
                        place={"search streak"}
                        full={false}
                        type={"text"}
                        required={false}
                        icon={searchIcon}
                    />

                    <button
                        className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
                        onClick={() => {
                            setSelectedStreak("");
                            resetState();
                            handleOpenModal();
                        }}
                    >
                        Add Streak
                    </button>
                </div>
            </div>
            {addOrUpdateStreak && (
                <div className="bg-[#FFF2F1] rounded-lg w-full p-5 mt-5">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            <div className="text-xl font-bold">
                                {name ? name : <>Streak Name</>}
                            </div>
                            {/* <div>|</div>
              <div className="flex items-center gap-1">
                <div className="text-[12px]">Created By:</div>
                <div className="text-[12px]">User name</div>
              </div> */}
                            <div>|</div>
                            <div className="flex items-center gap-1">
                                <div className="text-[12px]">Priority: </div>
                                <div className="text-[12px]">{priority}</div>
                            </div>
                        </div>
                        <div className="px-3 py-1 bg-[#BDBDBD] text-white font-bold rounded">
                            Editing
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-3 bg-[#FF7B5F] rounded-t-lg text-white font-bold px-3 py-2 mt-5">
                        {/* <div className="col-span-1">Notification method</div> */}
                        <div className="col-span-1">Which streak</div>
                        <div className="col-span-1">For which events</div>
                        <div className="col-span-1">For which users</div>
                        <div className="col-span-1">To reward them</div>
                    </div>
                    <div className="bg-[#FFE8E6] rounded-b-lg px-3 py-2 grid grid-cols-4 gap-3">
                        <div className="col-span-1 flex items-start">
                            {name ? (
                                <div className="flex flex-row gap-1 items-center">
                                    <Value
                                        name={name}
                                        value={""}
                                        operator={"|"}
                                    />
                                    <img
                                        src={editD}
                                        alt="edit"
                                        className="w-5 h-5"
                                        onClick={() => handleOpenModal()}
                                    />
                                </div>
                            ) : (
                                <div
                                    className="flex flex-row gap-1 items-center"
                                    onClick={() => handleOpenModal()}
                                >
                                    <Add text={"Add Streak"} />
                                    <img
                                        src={editD}
                                        alt="edit"
                                        className="w-5 h-5"
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            className="col-span-1"
                            onClick={handleOpenConfModal}
                        >
                            {selectedEvents?.map(
                                (event: any, index: number) => {
                                    return (
                                        <div className="flex flex-row">
                                            <Value
                                                key={index}
                                                name={
                                                    getEventsResp.data?.value?.find(
                                                        (e: any) =>
                                                            e._id ===
                                                            event.event
                                                    )?.name
                                                }
                                                value={""}
                                            />
                                        </div>
                                    );
                                }
                            )}

                            <Add text={"SELECT EVENTS"} />
                        </div>
                        <div className="col-span-1">
                            <div
                                className="cursor-pointer"
                                onClick={() => handleOpen("to-users")}
                            >
                                <div className="flex items-center flex-wrap">
                                    {getUserVariableRespStatus === 200 &&
                                        userFinalFilter.strings
                                            ?.filter(
                                                (each: any) =>
                                                    each.values?.length > 0
                                            )
                                            ?.map((each: any) => {
                                                return (
                                                    <Value
                                                        name={
                                                            getUserVariableResp.data.value?.find(
                                                                (ele: any) =>
                                                                    ele._id ===
                                                                    each.variable_id
                                                            ).name
                                                        }
                                                        value={
                                                            [
                                                                "in",
                                                                "nin",
                                                            ].includes(
                                                                each.operation
                                                            )
                                                                ? `[${each.values?.join(
                                                                      ", "
                                                                  )}]`
                                                                : each.values
                                                        }
                                                        operator={
                                                            each.operation
                                                        }
                                                    />
                                                );
                                            })}
                                </div>
                                <div className="flex items-center flex-wrap">
                                    {getUserVariableRespStatus === 200 &&
                                        userFinalFilter.numbers
                                            ?.filter(
                                                (each: any) =>
                                                    each.values?.length > 0
                                            )
                                            ?.map((each: any) => {
                                                return (
                                                    <Value
                                                        name={
                                                            getUserVariableResp.data.value?.find(
                                                                (ele: any) =>
                                                                    ele._id ===
                                                                    each.variable_id
                                                            ).name
                                                        }
                                                        value={
                                                            ["bet"].includes(
                                                                each.operation
                                                            )
                                                                ? `[${each.values?.join(
                                                                      ","
                                                                  )}]`
                                                                : each.values
                                                        }
                                                        operator={
                                                            each.operation
                                                        }
                                                    />
                                                );
                                            })}
                                </div>
                                <div className="flex items-center flex-wrap">
                                    {getUserVariableRespStatus === 200 &&
                                        userFinalFilter.booleans?.map(
                                            (each: any) => {
                                                return (
                                                    <Value
                                                        name={
                                                            getUserVariableResp.data.value?.find(
                                                                (ele: any) =>
                                                                    ele._id ===
                                                                    each.variable_id
                                                            ).name
                                                        }
                                                        value={each.values}
                                                    />
                                                );
                                            }
                                        )}
                                </div>
                                <div className="flex items-center flex-wrap">
                                    {getUserVariableRespStatus === 200 &&
                                        userFinalFilter.dates?.map(
                                            (each: any) => {
                                                return (
                                                    <Value
                                                        name={
                                                            getUserVariableResp.data.value?.find(
                                                                (ele: any) =>
                                                                    ele._id ===
                                                                    each.variable_id
                                                            ).name
                                                        }
                                                        value={
                                                            new Date(
                                                                each.value[0]
                                                            )
                                                                ?.toISOString()
                                                                ?.split(
                                                                    "T"
                                                                )?.[0]
                                                        }
                                                        operator={
                                                            each.operation
                                                        }
                                                        multi={
                                                            each.operation ===
                                                            "bet"
                                                        }
                                                        secondVal={
                                                            new Date(
                                                                each
                                                                    .value?.[1] ||
                                                                    new Date().getTime()
                                                            )
                                                                ?.toISOString()
                                                                ?.split(
                                                                    "T"
                                                                )?.[0]
                                                        }
                                                    />
                                                );
                                            }
                                        )}
                                </div>
                                <Add text={"ADD RULES HERE"} />
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="grid grid-cols-5 gap-3">
                                <div className="col-span-2 mt-1">Tokens:</div>
                                <div className="col-span-3">
                                    <div className="flex items-center flex-wrap gap-1">
                                        {Object.values(tokensReward)
                                            .filter((each: any) => {
                                                return (
                                                    activeRewardTokens?.includes(
                                                        each.token_id
                                                    ) && each.value
                                                );
                                            })
                                            .map((each: any) => {
                                                return (
                                                    <Value
                                                        name={
                                                            getTokensResp?.data?.value?.find(
                                                                (ele: any) =>
                                                                    ele._id ===
                                                                    each.token_id
                                                            )?.name
                                                        }
                                                        value={each.value}
                                                        // operator={"|"}
                                                    />
                                                );
                                            })}
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setShowModal(true);
                                                setShowRewardTokens(true);
                                            }}
                                        >
                                            <Add text={"Add token"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-2 mt-1">
                                    Multipliers:
                                </div>
                                <div className="col-span-3">
                                    <div className="flex items-center flex-wrap gap-1">
                                        {rewardMultipliers && (
                                            <Value
                                                name={
                                                    getMultipliersResp.data?.value?.find(
                                                        (ele: any) =>
                                                            ele._id ===
                                                            rewardMultipliers
                                                    )?.name
                                                }
                                                value={""}
                                                operator={"|"}
                                            />
                                        )}
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setShowModal(true);
                                                setShowRewardMultipliers(true);
                                            }}
                                        >
                                            <Add text={"Add token"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                        <button
                            className="outline-none bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-1"
                            onClick={() => {
                                resetState();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="vibrating-button outline-none bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-1"
                            onClick={handleSave}
                            disabled={isAddingStreak || isUpdatingStreak}
                        >
                            {isAddingStreak || isUpdatingStreak ? (
                                <Loader />
                            ) : (
                                "Save"
                            )}
                        </button>
                    </div>
                </div>
            )}
            {/* {isGettingMultipliers ||
            isGettingUserVariable ||
            isGettingTokens ||
            isGettingStreaks ? (
                <div className="flex items-center justify-center mt-5">
                    <Loader heavy={true} />
                </div>
            ) : null} */}
            {getStreaksResp?.data?.value?.length < 1 &&
            addOrUpdateStreak === false &&
            getStreaksRespStatus === 200 ? (
                <div className="flex items-center justify-center mt-5 border-4 border-[#EFF0F6] p-3 bg-white rounded">
                    No existing streak. Add one!
                </div>
            ) : getStreaksResp.data?.value?.filter((each: any) => {
                  if (each.name.toLowerCase().includes(search.toLowerCase()))
                      return true;
                  else if (!search) return true;
                  else return false;
              })?.length < 1 && search ? (
                <div className="flex items-center justify-center mt-5 border-4 border-[#EFF0F6] p-3 bg-white rounded">
                    No result found :(
                </div>
            ) : null}

            {getMultipliersRespStatus === 200 &&
            getUserVariableRespStatus === 200 &&
            getTokensRespStatus === 200 &&
            getStreaksRespStatus === 200 ? (
                getStreaksResp.data?.value
                    ?.filter((each: any) => {
                        if (
                            each.name
                                .toLowerCase()
                                .includes(search.toLowerCase()) &&
                            each._id !== selectedStreak
                        )
                            return true;
                        else if (!search && each._id !== selectedStreak)
                            return true;
                        else return false;
                    })
                    .map((streak: any) => {
                        return (
                            <StreakCard
                                key={"streak-card-" + streak._id}
                                streak={streak}
                                setSelectedStreak={setSelectedStreak}
                                setAddOrUpdateStreak={setAddOrUpdateStreak}
                                handleDelete={handleDelete}
                            />
                        );
                    })
            ) : (
                <div className="flex items-center justify-center mt-5">
                    <Loader heavy={true} />
                </div>
            )}

            <Modal
                style={customStyles}
                isOpen={showModal}
                onRequestClose={handleCloseModal}
            >
                {showTokens ? (
                    <StreakTokens
                        activeTokens={activeTokens}
                        setActiveTokens={setActiveTokens}
                        tokensResp={getTokensResp?.data?.value}
                        setShowTokens={() => {
                            setShowTokens(false);
                            setShowModal(false);
                        }}
                    />
                ) : showMilestoneTokens ? (
                    <MilestoneTokens
                        milestoneTokens={activeMilestoneTokens}
                        setMilestoneTokens={setActiveMilestoneTokens}
                        tokensResp={getTokensResp?.data?.value}
                        setShowTokensMilestone={() => {
                            setShowMilestoneTokens(false);
                            setShowModal(false);
                        }}
                    />
                ) : showEvents ? (
                    <StreakEvents
                        eventDay={eventDay}
                        setShowEvents={() => {
                            setShowEvents(false);
                            setShowModal(false);
                        }}
                    />
                ) : showMultipliers ? (
                    <StreakMultipliers
                        setShowMultipliers={() => {
                            setShowMultipliers(false);
                            setShowModal(false);
                        }}
                    />
                ) : showRewardMultipliers ? (
                    <RewardMultipliers
                        setShowRewardMultipliers={() => {
                            setShowRewardMultipliers(false);
                            setShowModal(false);
                        }}
                    />
                ) : showRewardTokens ? (
                    <RewardTokens
                        rewardTokens={activeRewardTokens}
                        setRewardTokens={setActiveRewardTokens}
                        tokensResp={getTokensResp?.data?.value}
                        setShowTokensReward={() => {
                            setShowRewardTokens(false);
                            setShowModal(false);
                        }}
                    />
                ) : (
                    // ) : configureStreak ? ( //Display "Configure Streak" content
                    //   <form onSubmit={handleContinue}>
                    //     <div className="p-5 w-[750px] max-h-[600px] overflow-y-auto">
                    //       <div className="flex justify-between">
                    //         <div>
                    //           <div className="text-2xl text-[#4C4C66] font-bold">
                    //             Configure Streaks
                    //           </div>
                    //           <div className="text-[#3189B4] mt-3">Add event details</div>
                    //         </div>
                    //         <div className="cursor-pointer" onClick={handleCloseModal}>
                    //           <svg
                    //             width="18"
                    //             height="18"
                    //             viewBox="0 0 18 18"
                    //             fill="none"
                    //             xmlns="http://www.w3.org/2000/svg"
                    //           >
                    //             <path
                    //               d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                    //               fill="#4C4C66"
                    //             />
                    //           </svg>
                    //         </div>
                    //       </div>
                    //       {/* <div className="mt-5 flex gap-5">
                    //         <div className="w-full">
                    //           <div className="text-[14px] text-[#4C4C66]">
                    //             Streak definition
                    //           </div>
                    //           <div
                    //             className="mt-3 rounded border-dashed border-2 border-[#FFAFA8] cursor-pointer flex flex-col gap-3 p-3 max-w-[750px]"
                    //             onClick={() => setMilestone(!milestone)}
                    //           >
                    //             <div className="flex items-center gap-3">
                    //               <div className="text-md text-[#5C5B76]">
                    //                 {name} | {unlimited ? "Unlimited" : `${condition} days`}{" "}
                    //                 | DD/MM/YYYY |{" "}
                    //                 {general === "General" ? "Same" : "Custom"} each day
                    //               </div>
                    //             </div>
                    //           </div>
                    //         </div>
                    //       </div> */}
                    //       {/* <div className="mt-3 text-[14px] text-[#4C4C66]">
                    //         Select events for all days
                    //       </div>
                    //       <div className="">
                    //         {" "}
                    //         <div className="w-full">
                    //           <div className="rounded border-2 border-[#FFAFA8] cursor-pointer flex flex-col gap-3 p-3 max-w-[750px]">
                    //             <div className="text-md text-[#5C5B76]">For each day:</div>
                    //             <div className=" flex items-start flex-wrap gap-1 h-[100px] overflow-y-auto">
                    //               <StreakEventsContainer
                    //                 setEventDay={setEventDay} // set event day
                    //                 setShowEvents={setShowEvents} // open event selections list
                    //                 single={general === "General"} // general or custom
                    //                 total={condition}
                    //               />
                    //             </div>
                    //             <div className="flex items-center gap-3"></div>
                    //           </div>
                    //         </div>
                    //       </div> */}

                    //       <div className="mt-5 bg-[#FFE8E6]">
                    //         <div className="flex items-center gap-3  p-3">
                    //           <div className="flex gap-5">
                    //             <div className="w-full relative">
                    //               <div className="mb-3 mx-1 text-[14px] text-[#4C4C66]">
                    //                 Select events for all days
                    //               </div>
                    //               <Text
                    //                 text={streakchange}
                    //                 setText={handleStreakChange}
                    //                 place={"- eg : 5 -"}
                    //                 full={true}
                    //                 type={"number"}
                    //               />
                    //             </div>
                    //           </div>
                    //         </div>
                    //       </div>
                    //       <div className="mt-5 bg-[#FFE8E6]">
                    //         <div className="flex items-center gap-3  p-3">
                    //           <div className="flex gap-5">
                    //             <div className="w-full relative">
                    //               <div className="mb-3 mx-1 text-[14px] text-[#4C4C66]">
                    //                 Specify the minimum number of events required to
                    //                 complete a streak{" "}
                    //               </div>
                    //               <Text
                    //                 text={streakchange}
                    //                 setText={handleStreakChange}
                    //                 place={"- eg : 5 -"}
                    //                 full={true}
                    //                 type={"number"}
                    //               />
                    //             </div>
                    //           </div>
                    //         </div>
                    //       </div>
                    //     </div>
                    //     <div className="mt-10 mb-5 px-5 flex items-center justify-between">
                    //       <div
                    //         className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
                    //         onClick={() => setConfigureStreak(false)}
                    //       >
                    //         Back
                    //       </div>
                    //       <button
                    //         className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
                    //         type={"submit"}
                    //       >
                    //         Save
                    //       </button>
                    //     </div>
                    //   </form>
                    // Default content when configureStreak is false
                    <>
                        <form onSubmit={handleContinue}>
                            <div className="p-5 w-[750px] max-h-[600px] overflow-y-auto">
                                <div className="flex justify-between">
                                    <div>
                                        <div className="text-2xl text-[#4C4C66] font-bold">
                                            Add Streak
                                        </div>
                                        <div className="text-[#3189B4] mt-3">
                                            Add event details
                                        </div>
                                    </div>
                                    <div
                                        className="cursor-pointer"
                                        onClick={handleCloseModal}
                                    >
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                                                fill="#4C4C66"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="mt-5 flex items-center gap-5">
                                    <div className="">
                                        <div className="text-[14px] text-[#4C4C66]">
                                            Priority
                                        </div>
                                        <Text
                                            text={priority}
                                            setText={setPriority}
                                            place={"- eg : 1 -"}
                                            full={false}
                                            type={"number"}
                                            required={true}
                                            showMessage={invalidForm1}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <div className="text-[14px] text-[#4C4C66]">
                                            Name of streak
                                        </div>
                                        <Text
                                            text={name}
                                            setText={setName}
                                            place={"- eg : birthday streak -"}
                                            full={true}
                                            required={true}
                                            showMessage={invalidForm1}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center justify-between mt-3 gap-7">
                                    <div>
                                        <div className="text-[14px] text-[#4C4C66]">
                                            Type
                                        </div>
                                        <div className="flex items-center gap-3">
                                            {["General", "Custom streak"].map(
                                                (each: any) => {
                                                    return (
                                                        <Radio
                                                            selected={general}
                                                            setSelected={
                                                                setGeneral
                                                            }
                                                            each={each}
                                                            multi={false}
                                                            key={each}
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {general === "General" ? (
                                        <div className="text-[#4C4C66] mt-5">
                                            Note:{" "}
                                            <span className="text-[#3189B4]">
                                                GENERAL
                                            </span>{" "}
                                            means
                                            <span className="text-[#3189B4]">
                                                {" "}
                                                same event every day
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="text-[#4C4C66] mt-5">
                                            Note:{" "}
                                            <span className="text-[#3189B4]">
                                                CUSTOM
                                            </span>{" "}
                                            means
                                            <span className="text-[#3189B4]">
                                                {" "}
                                                each day can have different
                                                event
                                            </span>
                                        </div>
                                    )}
                                    {/* <div className="mt-3 flex gap-5">
                                        <div className="w-full relative">
                                            <div className="text-[14px] text-[#4C4C66]">
                                                Number of events every day
                                            </div>
                                            <Text
                                                text={number}
                                                setText={handleNumberChange}
                                                place={"- eg : 5 -"}
                                                full={true}
                                                type={"number"}
                                                required={true}
                                            />
                                        </div>
                                    </div> */}
                                    <div
                                        className="mt-3 rounded border-2 border-[#FFAFA8] cursor-pointer flex flex-col gap-3 p-3 max-w-[750px]"
                                        onClick={() => setUnlimited(!unlimited)}
                                    >
                                        <div className="flex items-center gap-3">
                                            {unlimited ? (
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                        fill="#5F5E76"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                        fill="#FFAFA8"
                                                    />
                                                </svg>
                                            )}
                                            <div className="text-lg text-[#4C4C66]">
                                                Unlimited Days
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 bg-[#FFE8E6]">
                                        <div className="flex items-center gap-3  p-3">
                                            <div>
                                                <div className="text-[#5F5E76] text-[12px]">
                                                    Select a date*
                                                </div>
                                                <DateContainer
                                                    selected={
                                                        date
                                                            ? date
                                                                  .toISOString()
                                                                  ?.split(
                                                                      "T"
                                                                  )?.[0]
                                                            : ""
                                                    }
                                                    setSelected={(val) => {
                                                        if (val) {
                                                            setDate(
                                                                new Date(val)
                                                            );
                                                        } else {
                                                            setDate(null);
                                                        }
                                                    }}
                                                    required={true}
                                                    showMessage={invalidForm1}
                                                />
                                            </div>
                                            {unlimited === false && (
                                                <div>
                                                    <div className="text-[#5F5E76] text-[12px]">
                                                        Number of days*
                                                    </div>
                                                    <Select
                                                        selected={condition}
                                                        setSelected={
                                                            setCondition
                                                        }
                                                        required={true}
                                                        values={[
                                                            "3",
                                                            "4",
                                                            "5",
                                                            "6",
                                                            "7",
                                                            "8",
                                                            "9",
                                                            "10",
                                                            "11",
                                                            "12",
                                                            "13",
                                                            "14",
                                                        ]}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {
                                        <div className="">
                                            <div className="mt-5 bg-[#FFE8E6]">
                                                <div className="text-[#4C4C66] pt-5 mx-2">
                                                    Max number of days a user
                                                    can miss the streak and
                                                    amount to continue
                                                </div>
                                                <div className="flex items-center gap-3  p-3">
                                                    <div>
                                                        <div className="text-[#5F5E76] text-[12px]">
                                                            Number of days
                                                        </div>
                                                        <Text
                                                            text={streakdays}
                                                            setText={
                                                                setStreakdays
                                                            }
                                                            place={"- eg : 5 -"}
                                                            full={true}
                                                            type={"number"}
                                                            // required={true}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="text-[#5F5E76] text-[12px]">
                                                            Specify token and
                                                            amount by which they
                                                            can resume the
                                                            streak*
                                                        </div>
                                                        <div className="w-full border-2 border-[#FFAFA8] bg-white rounded px-3 py-2 flex items-start flex-wrap gap-1 h-[100px] overflow-y-auto">
                                                            {Object.values(
                                                                tokens
                                                            )
                                                                .filter(
                                                                    (
                                                                        each: any
                                                                    ) => {
                                                                        return (
                                                                            activeTokens?.includes(
                                                                                each.token_id
                                                                            ) &&
                                                                            each.value
                                                                        );
                                                                    }
                                                                )
                                                                .map(
                                                                    (
                                                                        each: any
                                                                    ) => {
                                                                        return (
                                                                            <Value
                                                                                name={
                                                                                    getTokensResp?.data?.value?.find(
                                                                                        (
                                                                                            ele: any
                                                                                        ) =>
                                                                                            ele._id ===
                                                                                            each.token_id
                                                                                    )
                                                                                        ?.name
                                                                                }
                                                                                value={
                                                                                    each.value
                                                                                }
                                                                                // operator={"|"}
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() =>
                                                                    setShowTokens(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <Add
                                                                    text={
                                                                        "Add Token"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div>
                            <div className="text-[#5F5E76] text-[12px]">
                              Specify token and amount by which they can resume
                              the streak*
                            </div>
                            <div className="w-full border-2 border-[#FFAFA8] bg-white rounded px-3 py-2 flex items-start flex-wrap gap-1 h-[100px] overflow-y-auto">
                              {Object.values(tokensMilestone)
                                .filter(
                                  (each: any) =>
                                    activeMilestoneTokens?.includes(
                                      each.token_id
                                    ) && each.value
                                )
                                .map((each: any) => {
                                  return (
                                    <Value
                                      name={
                                        tokensResp.find(
                                          (ele: any) =>
                                            ele._id === each.token_id
                                        )?.name
                                      }
                                      value={each.value}
                                      // operator={"|"}
                                    />
                                  );
                                })}
                              <div
                                className="cursor-pointer"
                                onClick={() => setShowMilestoneTokens(true)}
                              >
                                <Add text={"Add Token"} />
                              </div>
                            </div>
                          </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="mt-5 bg-[#FFE8E6]">
                                        <div className="flex flex-col gap-3 p-3">
                                            <div
                                                className="flex items-center gap-3"
                                                onClick={() =>
                                                    setMilestone(!milestone)
                                                }
                                            >
                                                {milestone ? (
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                            fill="#5F5E76"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                            fill="#FFAFA8"
                                                        />
                                                    </svg>
                                                )}

                                                <div className="text-lg text-[#4C4C66]">
                                                    Set rewards for the
                                                    milestone
                                                </div>
                                            </div>

                                            {milestone && (
                                                <>
                                                    <div className="flex items-center justify-between gap-7">
                                                        <div>
                                                            <div className="flex items-center gap-3">
                                                                {[
                                                                    "Constant",
                                                                    "Incremental",
                                                                ].map(
                                                                    (
                                                                        each: string
                                                                    ) => {
                                                                        return (
                                                                            <Radio
                                                                                selected={
                                                                                    constant
                                                                                }
                                                                                setSelected={
                                                                                    setConstant
                                                                                }
                                                                                each={
                                                                                    each
                                                                                }
                                                                                multi={
                                                                                    false
                                                                                }
                                                                                white={
                                                                                    true
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {constant ===
                                                        "Incremental" && (
                                                        <div className="flex items-center justify-between gap-7">
                                                            <div>
                                                                <div className="flex items-center gap-3">
                                                                    {[
                                                                        "Arithmetic Increment",
                                                                        "Geometric Increment",
                                                                    ].map(
                                                                        (
                                                                            each: string
                                                                        ) => {
                                                                            return (
                                                                                <Radio
                                                                                    selected={
                                                                                        arithmeticSelected
                                                                                    }
                                                                                    setSelected={
                                                                                        setArithmeticSelected
                                                                                    }
                                                                                    each={
                                                                                        each
                                                                                    }
                                                                                    multi={
                                                                                        false
                                                                                    }
                                                                                    white={
                                                                                        true
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="">
                                                        <div className="flex gap-3">
                                                            <div>
                                                                <div className="text-[#5F5E76] text-[12px]">
                                                                    Number of
                                                                    days*
                                                                </div>
                                                                <Text
                                                                    text={
                                                                        streakday
                                                                    }
                                                                    setText={
                                                                        setStreakday
                                                                    }
                                                                    place={
                                                                        "- eg : 5 -"
                                                                    }
                                                                    full={true}
                                                                    type={
                                                                        "number"
                                                                    }
                                                                />
                                                            </div>
                                                            <div>
                                                                <div className="text-[#5F5E76] text-[12px]">
                                                                    Specify
                                                                    token and
                                                                    amount*
                                                                </div>
                                                                <div className="w-[500px] border-2 border-[#FFAFA8] bg-white rounded px-3 py-2 flex items-start flex-wrap gap-1 h-[100px] overflow-y-auto">
                                                                    {Object.values(
                                                                        tokensMilestone
                                                                    )
                                                                        .filter(
                                                                            (
                                                                                each: any
                                                                            ) =>
                                                                                activeMilestoneTokens?.includes(
                                                                                    each.token_id
                                                                                ) &&
                                                                                each.value
                                                                        )
                                                                        .map(
                                                                            (
                                                                                each: any
                                                                            ) => {
                                                                                return (
                                                                                    <Value
                                                                                        name={
                                                                                            getTokensResp?.data?.value?.find(
                                                                                                (
                                                                                                    ele: any
                                                                                                ) =>
                                                                                                    ele._id ===
                                                                                                    each.token_id
                                                                                            )
                                                                                                ?.name
                                                                                        }
                                                                                        value={
                                                                                            each.value
                                                                                        }
                                                                                        // operator={"|"}
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    <div
                                                                        className="cursor-pointer"
                                                                        onClick={() =>
                                                                            setShowMilestoneTokens(
                                                                                true
                                                                            )
                                                                        }
                                                                    >
                                                                        <Add
                                                                            text={
                                                                                "Add Token"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="flex gap-3">
                                                            {constant ===
                                                                "Incremental" && (
                                                                <div>
                                                                    <div className="text-[#5F5E76] text-[12px]">
                                                                        Increment
                                                                        factor
                                                                    </div>
                                                                    <Text
                                                                        text={
                                                                            increment
                                                                        }
                                                                        setText={
                                                                            setIncrement
                                                                        }
                                                                        place={
                                                                            "- eg : 5 -"
                                                                        }
                                                                        full={
                                                                            true
                                                                        }
                                                                        type={
                                                                            "number"
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                            <div className="w-full">
                                                                <div
                                                                    className={
                                                                        "w-[500px] border-2 border-[#FFAFA8] bg-white rounded px-3 py-2 flex items-start flex-wrap gap-1 h-[60px] overflow-y-auto" +
                                                                        (constant ===
                                                                        "Constant"
                                                                            ? " w-full"
                                                                            : "")
                                                                    }
                                                                >
                                                                    {multipliers && (
                                                                        <Value
                                                                            name={
                                                                                getMultipliersResp.data?.value?.find(
                                                                                    (
                                                                                        ele: any
                                                                                    ) =>
                                                                                        ele._id ===
                                                                                        multipliers
                                                                                )
                                                                                    ?.name
                                                                            }
                                                                            value={
                                                                                ""
                                                                            }
                                                                            operator={
                                                                                "|"
                                                                            }
                                                                        />
                                                                    )}
                                                                    <div
                                                                        className="cursor-pointer"
                                                                        onClick={() =>
                                                                            setShowMultipliers(
                                                                                true
                                                                            )
                                                                        }
                                                                    >
                                                                        <Add
                                                                            text={
                                                                                "Multiplier"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {/* <div className="text-[#4C4C66] mt-5">
                    Note: <span className="text-[#3189B4]">GENERAL</span> means
                    <span className="text-[#3189B4]"> same</span> event every
                    day
                  </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 mb-5 px-5 flex items-center justify-end">
                                <button
                                    className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2 z-[1]"
                                    type={"submit"}
                                >
                                    Continue
                                </button>
                            </div>
                        </form>
                    </>
                )}
            </Modal>
            <Modal
                style={customStyles}
                isOpen={showConfModal}
                onRequestClose={handleCloseConfModal}
            >
                <form
                    onSubmit={(e) => {
                        handleContinue(e);
                        handleConti();
                    }}
                >
                    <div className="p-5 w-[750px] max-h-[600px] overflow-y-auto">
                        <div className="flex justify-between">
                            <div>
                                <div className="text-2xl text-[#4C4C66] font-bold">
                                    Configure Streaks
                                </div>
                                <div className="text-[#3189B4] mt-3">
                                    Add event details
                                </div>
                            </div>
                            <div
                                className="cursor-pointer"
                                onClick={handleCloseConfModal}
                            >
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                                        fill="#4C4C66"
                                    />
                                </svg>
                            </div>
                        </div>
                        {/* <div className="mt-5 flex gap-5">
                <div className="w-full">
                  <div className="text-[14px] text-[#4C4C66]">
                    Streak definition
                  </div>
                  <div
                    className="mt-3 rounded border-dashed border-2 border-[#FFAFA8] cursor-pointer flex flex-col gap-3 p-3 max-w-[750px]"
                    onClick={() => setMilestone(!milestone)}
                  >
                    <div className="flex items-center gap-3">
                      <div className="text-md text-[#5C5B76]">
                        {name} | {unlimited ? "Unlimited" : `${condition} days`}{" "}
                        | DD/MM/YYYY |{" "}
                        {general === "General" ? "Same" : "Custom"} each day
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                        {/* <div className="mt-3 text-[14px] text-[#4C4C66]">
                Select events for all days
              </div>
              <div className="">
                {" "}
                <div className="w-full">
                  <div className="rounded border-2 border-[#FFAFA8] cursor-pointer flex flex-col gap-3 p-3 max-w-[750px]">
                    <div className="text-md text-[#5C5B76]">For each day:</div>
                    <div className=" flex items-start flex-wrap gap-1 h-[100px] overflow-y-auto">
                      <StreakEventsContainer
                        setEventDay={setEventDay} // set event day
                        setShowEvents={setShowEvents} // open event selections list
                        single={general === "General"} // general or custom
                        total={condition}
                      />
                    </div>
                    <div className="flex items-center gap-3"></div>
                  </div>
                </div>
              </div> */}

                        <div className="mt-5 bg-[#FFE8E6]">
                            <div className="flex items-center gap-3  p-3">
                                <div className="flex-1 gap-5">
                                    <div className="w-full relative">
                                        <div className="mb-3 mx-1 text-[14px] text-[#4C4C66]">
                                            Number of activities per day *
                                        </div>
                                        <Text
                                            text={streakEventsNumber}
                                            setText={setStreakEventsNumber}
                                            place={"- eg : 5 -"}
                                            full={true}
                                            type={"number"}
                                            required={true}
                                            showMessage={invalidForm2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 bg-[#FFE8E6]">
                            <div className="flex items-center gap-3  p-3">
                                <div className="flex-1 gap-5">
                                    <div className="w-full relative">
                                        <div className="mb-3 mx-1 text-[14px] text-[#4C4C66]">
                                            Specify the minimum number of events
                                            required to complete a streak *
                                        </div>
                                        <Text
                                            text={streakEventMinimumNumber}
                                            setText={
                                                setStreakEventMinimumNumber
                                            }
                                            place={"- eg : 5 -"}
                                            full={true}
                                            type={"number"}
                                            required={true}
                                            showMessage={invalidForm2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center gap-6 mt-10">
                            <div
                                className={
                                    "text-lg cursor-pointer text-[#5F5E76]" +
                                    (activate === "Daily"
                                        ? " border-b-2 border-[#5F5E76] font-bold"
                                        : "")
                                }
                                onClick={() => setActivate("Daily")}
                            >
                                Daily
                            </div>
                            <div
                                className={
                                    "text-lg cursor-pointer text-[#5F5E76]" +
                                    (activate === "Periodcally"
                                        ? " border-b-2 border-[#5F5E76] font-bold"
                                        : "")
                                }
                                onClick={() => setActivate("Periodcally")}
                            >
                                Periodcally
                            </div>
                            <div
                                className={
                                    "text-lg cursor-pointer text-[#5F5E76]" +
                                    (activate === "User"
                                        ? " border-b-2 border-[#5F5E76] font-bold"
                                        : "")
                                }
                                onClick={() => setActivate("User")}
                            >
                                User States
                            </div>
                            <div
                                className={
                                    "text-lg cursor-pointer text-[#5F5E76]" +
                                    (activate === "challenges"
                                        ? " border-b-2 border-[#5F5E76] font-bold"
                                        : "")
                                }
                                onClick={() => setActivate("challenges")}
                            >
                                Challenges and Campaigns
                            </div>
                        </div>
                        <div className="mt-5 bg-[#FFE8E6]">
                            <div className="flex items-center gap-3  p-3">
                                <div className="flex-1 gap-5">
                                    <div className="w-[100%] relative">
                                        <div className="mb-3 mx-1 text-[14px] text-[#4C4C66]">
                                            Priority
                                        </div>
                                        <div
                                            className={
                                                "flex items-center gap-1 relative w-full"
                                            }
                                        >
                                            <input
                                                type={type}
                                                placeholder={"- eg : 1 -"}
                                                className={
                                                    "border-2 border-[#FFAFA8] px-3 py-2 outline-none rounded w-full"
                                                }
                                                value={
                                                    activate === "Daily"
                                                        ? streakDailyEventPriority
                                                        : activate ===
                                                          "Periodcally"
                                                        ? streakPeriodicEventPriority
                                                        : activate === "User"
                                                        ? streakUserStateEventPriority
                                                        : activate ===
                                                          "challenges"
                                                        ? streakCampaignEventPriority
                                                        : ""
                                                }
                                                onChange={
                                                    handleChangeEventPriority
                                                }
                                                min={0}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" bg-[#FFE8E6] p-2 mt-5">
                            <form>
                                <div className=" text-[14px] mx-1">
                                    <div className="text-[#5F5E76] text-md mt-1">
                                        Search Events
                                    </div>
                                    <Text
                                        text={searchEvents}
                                        setText={setSearchEvents}
                                        full={true}
                                        place={"Search event"}
                                        required={false}
                                        type={"text"}
                                        icon={searchIcon}
                                    />
                                </div>

                                <div className="text-[#5F5E76] text-[14px] mt-3 mx-1">
                                    Or, Select the events
                                </div>
                                {getEventsResp &&
                                getEventsResp?.data?.value.length > 0 &&
                                getEventsResp?.data?.value?.filter(
                                    (each: any) => {
                                        return (
                                            each?.name
                                                ?.toLowerCase()
                                                ?.includes(
                                                    searchEvents?.toLowerCase()
                                                ) &&
                                            checkEventsDataVisiblity(each)
                                        );
                                    }
                                ).length >= 1 ? (
                                    getEventsResp.data.value
                                        ?.filter((each: any) => {
                                            return (
                                                each?.name
                                                    ?.toLowerCase()
                                                    ?.includes(
                                                        searchEvents?.toLowerCase()
                                                    ) &&
                                                checkEventsDataVisiblity(each)
                                            );
                                        })
                                        .map((event: any, index: number) => {
                                            return (
                                                <div
                                                    key={event._id}
                                                    className="rounded border-2 border-[#FFAFA8] bg-[#FFE8E6] cursor-pointer flex flex-row gap-3 p-3 max-w-[750px] mt-2 mb-2 justify-between items-center"
                                                    onClick={() => {
                                                        handleItemClick(event);
                                                    }}
                                                >
                                                    <div className="flex items-center gap-3">
                                                        {CheckPresentIntabs(
                                                            event._id
                                                        ) ? (
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM11.003 16L18.073 8.929L16.66 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                                                                    fill="#5F5E76"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM4.5 19.5V4.5H19.5V19.5H4.5Z"
                                                                    fill="#FFAFA8"
                                                                />
                                                            </svg>
                                                        )}

                                                        <div className="text-lg text-[#4C4C66]">
                                                            {event.name}
                                                        </div>

                                                        <div className="text-lg text-[#CEB0FA]">
                                                            |
                                                        </div>
                                                        <div className="text-sm text-[#4C4C66]">
                                                            type:{" "}
                                                            {event.transaction_type
                                                                ? "Non-Transactional"
                                                                : "Transactional"}
                                                        </div>
                                                    </div>
                                                    {CheckPresentIntabs(
                                                        event._id
                                                    ) ? (
                                                        <button
                                                            className="flex w-[100px] h-[35px] text-[12px] text-[#3189B4] z-[10000] justify-center items-center rounded cursor-pointer bg-[#FFE8E6]"
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedEvent(
                                                                    event
                                                                );
                                                                setshowConfigureStreakModal(
                                                                    true
                                                                );
                                                                handleCustomiseModalData(
                                                                    event
                                                                );
                                                            }}
                                                        >
                                                            Customize*
                                                        </button>
                                                    ) : null}
                                                </div>
                                            );
                                        })
                                ) : (
                                    <div className="flex mx-1 items-center justify-center mt-3 border-1 border-[#EFF0F6] p-2 bg-white rounded">
                                        No result found :(
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>

                    <div className="mt-10 mb-5 px-5 flex items-center justify-between">
                        <div
                            className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
                            onClick={() => handleCloseConfModal()}
                        >
                            Back
                        </div>
                        <button
                            className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-2"
                            type={"submit"}
                        >
                            Save
                        </button>
                    </div>
                </form>
            </Modal>

            <Modal
                style={customStyles}
                isOpen={showConfigureStreakModal}
                shouldCloseOnEsc={true}
                onRequestClose={() => setshowConfigureStreakModal(false)}
            >
                <div className="p-5 w-[750px] max-h-[600px] overflow-y-auto">
                    <div className="flex justify-between">
                        <div>
                            <div className="text-2xl text-[#4C4C66] font-bold">
                                Customize Event
                            </div>
                            {/* <div className="text-m text-[#3189B4] font-bold">
                                Lorem ipsum dolor sit amet consectetur. Eget
                                egestas quis cursus elementum laoreet leo.
                                Blandit cras pulvinar odio mauris sagittis ut
                                dolor viverra accumsan.
                            </div> */}
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={() => setshowConfigureStreakModal(false)}
                        >
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                                    fill="#4C4C66"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="mt-5 flex flex-col w-full">
                        <div className="text-[14px] text-[#4C4C66] mt-2 mb-2">
                            All variations of this event
                        </div>
                        <div className="flex flex-col gap-3 max-h-[250px] overflow-y-scroll">
                            {eventVariation.map((event: any) => {
                                return (
                                    <EventVariationCard
                                        event={event}
                                        handleSetDefault={() => {
                                            handleSetDefaultEventVariation(
                                                event
                                            );
                                        }}
                                        handleDelete={() =>
                                            handleDeleteEventVariation(event)
                                        }
                                        handleEdit={() => {
                                            handleEditEventVariation(event);
                                        }}
                                    />
                                );
                            })}
                        </div>
                        <div className="text-[14px] text-[#4C4C66] mt-2">
                            Or define another variation of this event
                        </div>
                        <button
                            type="button"
                            className="mt-2 flex flex-row bg-[#FFE8E6] items-center justify-between border-2 border-[#FFAFA8] px-3 py-2 outline-none rounded w-full"
                            onClick={() => {
                                setAddVariationModal(true);
                            }}
                        >
                            <span>Add a new variation</span>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.1 5.4H9.5V6.6H7.1V9H5.9V6.6H3.5V5.4H5.9V3H7.1V5.4ZM6.5 12C4.9087 12 3.38258 11.3679 2.25736 10.2426C1.13214 9.11742 0.5 7.5913 0.5 6C0.5 4.4087 1.13214 2.88258 2.25736 1.75736C3.38258 0.632141 4.9087 0 6.5 0C8.0913 0 9.61742 0.632141 10.7426 1.75736C11.8679 2.88258 12.5 4.4087 12.5 6C12.5 7.5913 11.8679 9.11742 10.7426 10.2426C9.61742 11.3679 8.0913 12 6.5 12ZM6.5 10.8C7.77304 10.8 8.99394 10.2943 9.89411 9.39411C10.7943 8.49394 11.3 7.27304 11.3 6C11.3 4.72696 10.7943 3.50606 9.89411 2.60589C8.99394 1.70571 7.77304 1.2 6.5 1.2C5.22696 1.2 4.00606 1.70571 3.10589 2.60589C2.20571 3.50606 1.7 4.72696 1.7 6C1.7 7.27304 2.20571 8.49394 3.10589 9.39411C4.00606 10.2943 5.22696 10.8 6.5 10.8Z"
                                    fill="#8880A1"
                                />
                            </svg>
                        </button>
                        {activate === "Periodcally" ? (
                            <>
                                <div className="text-[14px] text-[#4C4C66] mt-2">
                                    Specify the period of event
                                </div>
                                <div className="flex w-[100%] gap-2 mt-2">
                                    <Text
                                        text={eventPeriod}
                                        setText={setEventPeriod}
                                        place={"- eg: 10 -"}
                                        full={false}
                                        type={"number"}
                                    />{" "}
                                    <select
                                        id="options"
                                        className="border-2 border-[#FFAFA8] px-3 py-2 outline-none rounded w-[30%]"
                                        onChange={(e) =>
                                            setEventPeriodType(e.target.value)
                                        }
                                    >
                                        <option key={"option1"} value={"days"}>
                                            Days
                                        </option>
                                        <option key={"option2"} value={"weeks"}>
                                            Weeks
                                        </option>
                                        <option
                                            key={"option3"}
                                            value={"months"}
                                        >
                                            Month
                                        </option>
                                        <option key={"option4"} value={"years"}>
                                            Year
                                        </option>
                                    </select>
                                </div>
                            </>
                        ) : null}
                        {activate === "User" ? (
                            <>
                                <div className="text-[14px] text-[#4C4C66] mt-2">
                                    Select users
                                </div>
                                <div className="w-full bg-[#FFE8E6] rounded px-3 py-2 flex items-start flex-wrap gap-1 h-[100px] overflow-y-auto">
                                    <div
                                        className="cursor-pointer"
                                        onClick={() =>
                                            setShowCustomiseEventUserFilter(
                                                true
                                            )
                                        }
                                    >
                                        <div className="flex items-center flex-wrap">
                                            {customiseEventUserFilter &&
                                                customiseEventUserFilter.strings
                                                    ?.filter(
                                                        (each: any) =>
                                                            each.values
                                                                ?.length > 0
                                                    )
                                                    ?.map((each: any) => {
                                                        return (
                                                            <Value
                                                                name={
                                                                    getUserVariableResp.data.value?.find(
                                                                        (
                                                                            ele: any
                                                                        ) =>
                                                                            ele._id ===
                                                                            each.variable_id
                                                                    ).name
                                                                }
                                                                value={
                                                                    [
                                                                        "in",
                                                                        "nin",
                                                                    ].includes(
                                                                        each.operation
                                                                    )
                                                                        ? `[${each.values?.join(
                                                                              ", "
                                                                          )}]`
                                                                        : each.values
                                                                }
                                                                operator={
                                                                    each.operation
                                                                }
                                                            />
                                                        );
                                                    })}
                                        </div>
                                        <div className="flex items-center flex-wrap">
                                            {customiseEventUserFilter &&
                                                customiseEventUserFilter.numbers
                                                    ?.filter(
                                                        (each: any) =>
                                                            each.values
                                                                ?.length > 0
                                                    )
                                                    ?.map((each: any) => {
                                                        return (
                                                            <Value
                                                                name={
                                                                    getUserVariableResp.data.value?.find(
                                                                        (
                                                                            ele: any
                                                                        ) =>
                                                                            ele._id ===
                                                                            each.variable_id
                                                                    ).name
                                                                }
                                                                value={
                                                                    [
                                                                        "bet",
                                                                    ].includes(
                                                                        each.operation
                                                                    )
                                                                        ? `[${each.values?.join(
                                                                              ","
                                                                          )}]`
                                                                        : each.values
                                                                }
                                                                operator={
                                                                    each.operation
                                                                }
                                                            />
                                                        );
                                                    })}
                                        </div>
                                        <div className="flex items-center flex-wrap">
                                            {customiseEventUserFilter &&
                                                customiseEventUserFilter.booleans?.map(
                                                    (each: any) => {
                                                        return (
                                                            <Value
                                                                name={
                                                                    getUserVariableResp.data.value?.find(
                                                                        (
                                                                            ele: any
                                                                        ) =>
                                                                            ele._id ===
                                                                            each.variable_id
                                                                    ).name
                                                                }
                                                                value={
                                                                    each.values
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                        </div>
                                        <div className="flex items-center flex-wrap">
                                            {customiseEventUserFilter &&
                                                customiseEventUserFilter.dates?.map(
                                                    (each: any) => {
                                                        return (
                                                            <Value
                                                                name={
                                                                    getUserVariableResp.data.value?.find(
                                                                        (
                                                                            ele: any
                                                                        ) =>
                                                                            ele._id ===
                                                                            each.variable_id
                                                                    ).name
                                                                }
                                                                value={
                                                                    new Date(
                                                                        each.value[0]
                                                                    )
                                                                        ?.toISOString()
                                                                        ?.split(
                                                                            "T"
                                                                        )?.[0]
                                                                }
                                                                operator={
                                                                    each.operation
                                                                }
                                                                multi={
                                                                    each.operation ===
                                                                    "bet"
                                                                }
                                                                secondVal={
                                                                    new Date(
                                                                        each
                                                                            .value?.[1] ||
                                                                            new Date().getTime()
                                                                    )
                                                                        ?.toISOString()
                                                                        ?.split(
                                                                            "T"
                                                                        )?.[0]
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                        </div>
                                        <Add
                                            text={"- SELECT USER ATTRIBUTE -"}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className="w-full mt-5 flex items-center justify-between">
                        <div className="flex flex-row">
                            <div className="text-[#5F5E76] text-lg rounded cursor-pointer py-3 px-1">
                                {" "}
                                <img
                                    src={back}
                                    alt="back"
                                    className="w-[20px] h-[20px]"
                                />
                            </div>

                            <div
                                className="text-[#5F5E76] text-lg rounded cursor-pointer py-2"
                                onClick={() => {
                                    setshowConfigureStreakModal(false);
                                }}
                            >
                                Cancel
                            </div>
                        </div>
                        <button
                            className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-5 py-2"
                            onClick={() => {
                                handleCustomiseEvent();
                                setshowConfigureStreakModal(false);
                            }}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                style={customStyles}
                isOpen={addVariationModal}
                shouldCloseOnEsc={true}
                onRequestClose={() => setAddVariationModal(false)}
            >
                <form
                    onSubmit={() => {
                        handleAddVariantData(
                            editEventVariantData ? true : false
                        );
                        setAddVariationModal(false);
                    }}
                >
                    <div className="p-5 w-[750px] max-h-[600px] overflow-y-auto">
                        <div className="flex justify-between">
                            <div>
                                <div className="text-2xl text-[#4C4C66] font-bold">
                                    Add a new variation
                                </div>
                                {/* <div className="text-m text-[#3189B4] font-bold">
                                Lorem ipsum dolor sit amet consectetur. Eget
                                egestas quis cursus elementum laoreet leo.
                                Blandit cras pulvinar odio mauris sagittis ut
                                dolor viverra accumsan.
                            </div> */}
                            </div>
                            <div
                                className="cursor-pointer"
                                onClick={() => setAddVariationModal(false)}
                            >
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
                                        fill="#4C4C66"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="mt-5 flex gap-5">
                            <div>
                                <div className="text-[14px] text-[#4C4C66]">
                                    Add image for token
                                </div>
                                <label htmlFor={"in"}>
                                    <div className="w-[150px] h-[150px] object-cover border-2 border-dashed border-[#FFAFa8] rounded flex items-center justify-center text-center mt-1 text-[14px] cursor-pointer p-5">
                                        {tImage ? (
                                            <img
                                                src={
                                                    typeof tImage === "string"
                                                        ? tImage
                                                        : URL.createObjectURL(
                                                              tImage
                                                          )
                                                }
                                                alt="t"
                                            />
                                        ) : (
                                            "Drag & Drop or upload"
                                        )}
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    id="in"
                                    className="hidden"
                                    onChange={(e: any) => {
                                        setTImage(e.target.files[0]);
                                    }}
                                />
                            </div>
                            <div className="w-[3px] bg-[#CEB0Fa]"></div>
                            <div className="w-full">
                                <div className="text-[14px] text-[#4C4C66]">
                                    Name
                                </div>
                                <Text
                                    text={eventName}
                                    setText={setEventName}
                                    place={"- eg: coin of fortune -"}
                                    full={true}
                                    required={true}
                                />
                                <div className="text-[14px] text-[#4C4C66] mt-2">
                                    Description
                                </div>
                                <Text
                                    text={eventDescription}
                                    setText={setEventDescription}
                                    place={"- eg: coin of fortune -"}
                                    full={true}
                                />
                                <div className="text-[14px] text-[#4C4C66] mt-2">
                                    Redirect URL
                                </div>
                                <Text
                                    text={redirectUrl}
                                    setText={setRedirectUrl}
                                    place={"- eg : PATH/TO/screen -"}
                                    full={true}
                                />
                                <div className="text-[14px] text-[#4C4C66] mt-2">
                                    Specify Date
                                </div>
                                <DateContainer
                                    selected={
                                        eventDate
                                            ? eventDate
                                                  .toISOString()
                                                  ?.split("T")?.[0]
                                            : ""
                                    }
                                    setSelected={(val) => {
                                        if (val) {
                                            setEventDate(new Date(val));
                                        } else {
                                            setEventDate(null);
                                        }
                                    }}
                                    required={false}
                                    classname="w-full"
                                />
                            </div>
                        </div>
                        <div className="w-full mt-5 flex items-center justify-between">
                            <div className="flex flex-row">
                                <div className="text-[#5F5E76] text-lg rounded cursor-pointer py-3 px-1">
                                    {" "}
                                    <img
                                        src={back}
                                        alt="back"
                                        className="w-[20px] h-[20px]"
                                    />
                                </div>

                                <div
                                    className="text-[#5F5E76] text-lg rounded cursor-pointer py-2"
                                    onClick={() => {
                                        resetCustomiseEventModal();
                                        setAddVariationModal(false);
                                    }}
                                >
                                    Cancel
                                </div>
                            </div>
                            <button
                                className="bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-5 py-2"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>
            <Modal
                style={customStyles}
                isOpen={active}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => handleClose()}
                shouldCloseOnEsc={true}
            >
                {/* {type === "notification-method" && <Notification />} */}
                {type === "to-users" && (
                    <UsersWith
                        state={
                            showConfigureStreakModal && activate === "User"
                                ? customiseEventUserFilter
                                : null
                        }
                    />
                )}
            </Modal>
            <Modal
                style={customStyles}
                isOpen={showCustomiseEventUserFilter}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setShowCustomiseEventUserFilter(false)}
                shouldCloseOnEsc={true}
            >
                <CustomEventUserFilterModal
                    setShow={() => setShowCustomiseEventUserFilter(false)}
                    data={customiseEventUserFilter}
                    setData={setCustomiseEventUserFilter}
                />
            </Modal>
        </div>
    );
};

export default Streaks;

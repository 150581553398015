import { useLocation, useNavigate } from "react-router-dom";
import home from "../../assets/svg/home.svg";
import gamification from "../../assets/svg/gamification.svg";
import devTools from "../../assets/svg/devTools.svg";
import devToolsN from "../../assets/svg/devToolsN.svg";
import settings from "../../assets/svg/settings.svg";
import settingsN from "../../assets/svg/settingsN.svg";
import people from "../../assets/svg/people.svg";
import peopleN from "../../assets/svg/peopleN.svg";
import tokens from "../../assets/svg/tokens.svg";
import tokensN from "../../assets/svg/tokensN.svg";
import social from "../../assets/svg/snipeSocial.svg";
import socialN from "../../assets/svg/snipeSocialN.svg";
import contests from "../../assets/svg/contest.svg";
import event from "../../assets/svg/event.svg";

import { useUserAuth } from "../../hooks/useAuthContextProvider";

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userDetails } = useUserAuth();
    return (
        <div className="bg-[#101010] ss text-[16px]  h-screen flex flex-col justify-between pb-[50px] sticky top-0">
            <div className="]">
                <div className="px-[40px] py-1 text-[24px] flex items-center gap-3 mb-[56px] font-bold pt-5">
                    <img src={home} alt="slc" className="w-[25px]" />
                    <div className="text-[#65EEC8] pr-[65px]">
                        snipe<span className="text-[#82C8F1]">.api</span>
                    </div>
                </div>
                <ul>
                    {/* <li
            className={
              "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
              (location.pathname === "/"
                ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                : " text-[#92ADBD]")
            }
            onClick={() => navigate("/")}
          >
            <img src={location.pathname === "/" ? home : homeN} alt="home" />
            Home
          </li> */}
                    {/* <li
            className={
              "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
              (location.pathname === "/analytics"
                ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                : " text-[#92ADBD]")
            }
            onClick={() => navigate("/analytics")}
          >
            <img
              src={location.pathname === "/analytics" ? analytics : analyticsN}
              alt="analytics"
            />
            Analytics
          </li> */}
                    {userDetails?.client_id?.features_enabled?.includes(
                        "tms"
                    ) ? (
                        <li
                            className={
                                "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
                                (location.pathname === "/tokens"
                                    ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                                    : " text-[#92ADBD]")
                            }
                            onClick={() => navigate("/tokens")}
                        >
                            <img
                                src={
                                    location.pathname === "/tokens"
                                        ? tokens
                                        : tokensN
                                }
                                alt="tokens"
                            />
                            Tokens
                        </li>
                    ) : null}
                    <li
                        className={
                            "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
                            (location.pathname === "/users"
                                ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                                : " text-[#92ADBD]")
                        }
                        onClick={() => navigate("/users")}
                    >
                        <img
                            src={
                                location.pathname === "/users"
                                    ? people
                                    : peopleN
                            }
                            className="w-[20px] h-[20px]"
                            alt="users"
                        />
                        Users
                    </li>
                    {userDetails?.client_id?.features_enabled?.find(
                        (each: string) => each?.includes("streaks")
                    ) ? (
                        <li
                            className={
                                "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
                                (location.pathname === "/social"
                                    ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                                    : " text-[#92ADBD]")
                            }
                            onClick={() => navigate("/social")}
                        >
                            <img
                                src={
                                    location.pathname === "/social"
                                        ? social
                                        : socialN
                                }
                                alt="snipeSocial"
                            />
                            Social
                        </li>
                    ) : null}
                    {userDetails?.client_id?.features_enabled?.find(
                        (each: string) => each?.includes("streaks")
                    ) ? (
                        <li
                            className={
                                "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
                                (location.pathname === "/gamification"
                                    ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                                    : " text-[#92ADBD]")
                            }
                            onClick={() => navigate("/gamification")}
                        >
                            <img
                                src={
                                    location.pathname === "/gamification"
                                        ? gamification
                                        : gamification
                                }
                                alt="gamification"
                            />
                            Gamification
                        </li>
                    ) : null}

                    {userDetails?.client_id?.features_enabled?.includes(
                        "contests"
                    ) ? (
                        <li
                            className={
                                "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
                                (location.pathname === "/contests"
                                    ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                                    : " text-[#92ADBD]")
                            }
                            onClick={() => navigate("/contests")}
                        >
                            <img src={contests} alt="contests" />
                            <div>Contests</div>
                        </li>
                    ) : null}

                    <li
                        className={
                            "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
                            (location.pathname === "/events"
                                ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                                : " text-[#92ADBD]")
                        }
                        onClick={() => navigate("/events")}
                    >
                        <img src={event} alt="contests" />
                        <div>Events</div>
                    </li>

                    <li
                        className={
                            "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
                            (location.pathname === "/developer-tools"
                                ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                                : " text-[#92ADBD]")
                        }
                        onClick={() => navigate("/developer-tools")}
                    >
                        <img
                            src={
                                location.pathname === "/developer-tools"
                                    ? devTools
                                    : devToolsN
                            }
                            alt="devTools"
                        />
                        Developer Tools
                    </li>
                    {/* <li
            className={
              "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
              (location.pathname === "/business"
                ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                : " text-[#92ADBD]")
            }
            onClick={() => navigate("/business")}
          >
            <img
              src={location.pathname === "/business" ? business : businessN}
              alt="business"
            />
            Business
          </li> */}
                </ul>
            </div>
            <ul>
                <li
                    className={
                        "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
                        (location.pathname === "/settings"
                            ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
                            : " text-[#92ADBD]")
                    }
                    onClick={() => navigate("/settings")}
                >
                    <img
                        src={
                            location.pathname === "/settings"
                                ? settings
                                : settingsN
                        }
                        alt="settings"
                    />
                    Settings
                </li>
                {/* <li
          className={
            "py-3  cursor-pointer px-[40px] flex items-center gap-3" +
            (location.pathname === "/support"
              ? " border-l-4 border-[#C9E0EF] text-[#C9E0EF] pl-[36px] font-bold"
              : " text-[#92ADBD]")
          }
          onClick={() => navigate("/support")}
        >
          <img
            src={location.pathname === "/support" ? support : supportN}
            alt="support"
          />
          Support
        </li> */}
            </ul>
        </div>
    );
};

export default Sidebar;

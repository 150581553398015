import {
  GET_TOKENS_INIT,
  GET_TOKENS_SUCCESS,
  GET_TOKENS_FAILURE,
  UPDATE_TOKEN_INIT,
  UPDATE_TOKEN_SUCCESS,
  UPDATE_TOKEN_FAILURE,
  ADD_TOKEN_INIT,
  ADD_TOKEN_SUCCESS,
  ADD_TOKEN_FAILURE,
  GET_ANALYTICS_INIT,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAILURE,
  GET_TOKEN_HISTORY_INIT,
  GET_TOKEN_HISTORY_SUCCESS,
  GET_TOKEN_HISTORY_FAILURE,
  RESET_TOKEN,
} from "../actionTypes";
import { ITokenState } from "../../models/tokens/token";

const initialState = {
  isGettingTokens: false,
  getTokensError: false,
  getTokensErrorMsg: "",
  getTokensResp: [],
  getTokensRespStatus: 0,
  isUpdatingToken: false,
  updateTokenError: false,
  updateTokenErrorMsg: "",
  updateTokenResp: [],
  updateTokenRespStatus: 0,
  isAddingToken: false,
  addTokenError: false,
  addTokenErrorMsg: "",
  addTokenResp: [],
  addTokenRespStatus: 0,
  isGettingAnalytics: false,
  getAnalyticsError: false,
  getAnalyticsErrorMsg: "",
  getAnalyticsResp: [],
  getAnalyticsRespStatus: 0,
  isGettignTokenHistory: false,
  getTokenHistoryError: false,
  getTokenHistoryErrorMsg: "",
  getTokenHistoryResp: [],
  getTokenHistoryRespStatus: 0,
};

const tokens = (state: ITokenState = initialState, action: any) => {
  switch (action.type) {
    case GET_TOKENS_INIT:
      return {
        ...state,
        isGettingTokens: true,
        getTokensError: false,
        getTokensRespStatus: 0,
        // getAnalyticsRespStatus:0
      };

    case GET_TOKENS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingTokens: false,
        getTokensResp: data,
        getTokensRespStatus: 200,
      };
    }

    case GET_TOKENS_FAILURE:
      const { error } = action;
      return {
        ...state,
        isGettingTokens: false,
        getTokensError: true,
        getTokensErrorMsg: error,
        getTokensRespStatus: 400,
      };

    case UPDATE_TOKEN_INIT:
      return {
        ...state,
        isUpdatingToken: true,
        updateTokenError: false,
        updateTokenRespStatus: 0,
      };

    case UPDATE_TOKEN_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isUpdatingToken: false,
        updateTokenResp: data,
        updateTokenRespStatus: 200,
      };
    }

    case UPDATE_TOKEN_FAILURE:
      return {
        ...state,
        isUpdatingToken: false,
        updateTokenError: true,
        updateTokenErrorMsg: action.error,
        updateTokenRespStatus: 400,
      };

    case ADD_TOKEN_INIT:
      return {
        ...state,
        isAddingToken: true,
        addTokenError: false,
        addTokenRespStatus: 0,
      };

    case ADD_TOKEN_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isAddingToken: false,
        addTokenResp: data,
        addTokenRespStatus: 200,
      };
    }

    case ADD_TOKEN_FAILURE:
      return {
        ...state,
        isAddingToken: false,
        addTokenError: true,
        addTokenErrorMsg: action.error,
        addTokenRespStatus: 400,
      };

    case GET_ANALYTICS_INIT:
      return {
        ...state,
        isGettingAnalytics: true,
        getAnalyticsError: false,
        getAnalyticsRespStatus: 0,
      };

    case GET_ANALYTICS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingAnalytics: false,
        getAnalyticsResp: data,
        getAnalyticsRespStatus: 200,
      };
    }

    case GET_ANALYTICS_FAILURE:
      return {
        ...state,
        isGettingAnalytics: false,
        getAnalyticsError: true,
        getAnalyticsErrorMsg: action.error,
        getAnalyticsRespStatus: 400,
      };

    case GET_TOKEN_HISTORY_INIT:
      return {
        ...state,
        isGettingTokenHistory: true,
        getTokenHistoryError: false,
        getTokenHistoryRespStatus: 0,
      };

    case GET_TOKEN_HISTORY_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        isGettingTokenHistory: false,
        getTokenHistoryResp: data,
        getTokenHistoryRespStatus: 200,
      };
    }

    case GET_TOKEN_HISTORY_FAILURE:
      return {
        ...state,
        isGettingTokenHistory: false,
        getTokenHistoryError: true,
        getTokenHistoryErrorMsg: action.error,
        getTokenHistoryRespStatus: 400,
      };

    case RESET_TOKEN:
      return {
        ...state,
        getAnalyticsRespStatus: 0,
        getTokensRespStatus: 0,
      };
    default:
      return state;
  }
};

export default tokens;

import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Add from "../../../components/gamification/add";
import dayjs from "dayjs";
import Value from "../../../components/users/management/value";
import AddReferral from "../../../components/referral/addReferral";
import AddEvent from "../../../components/referral/addEvent";
import AddRewards from "../../../components/referral/addRewards";
import { customStyles } from "../../gamification/campaign";
import Loader from "../../../components/common/loader";
import { toggleConfirmationPopup } from "../../../redux/api/apiActions";

import {
  addReferral,
  getReferrals,
  deleteReferral,
  updateReferral,
} from "../../../redux/referral/referralActions";
import {
  getUserVariable,
  addCustomTokenFinalUser,
} from "../../../redux/user/userActions";

import { getTokens } from "../../../redux/token/tokenActions";
import { getEvents } from "../../../redux/token/eventActions";
import { useDispatch, useSelector } from "react-redux";
import { useUserAuth } from "../../../hooks/useAuthContextProvider";
import { toggleCustomTokenModal } from "../../../redux/user/customTokenActions";
import UsersWith from "../../../components/users/management/modals/usersWith";

import { v4 as uuidv4 } from "uuid";
import ReferralCard from "../../../components/referral/referralCard";
interface ITokens {
  value: any;
  token_id: string;
  event_id: string;
}
interface IEvents {
  event_id: string;
  tokens: [];
  reward_type: number;
}

type selectedPercentageTypeState = {
  [theme: string]: {
    [event_id: string]: {
      [token_id: string]: {
        min_spend: boolean;
        cap_value: boolean;
      };
    };
  };
};

const initialSelectedPercentageTypeState: selectedPercentageTypeState = {
  referee: {},
  referrer: {},
};

const Refferalp = () => {
  const [showModal, setShowModal] = useState(false);
  const [addOrUpdateRefferal, setAddOrUpdateRefferal] =
    useState<boolean>(false);
  const [selectedReferal, setSelectedReferral] = useState<string>("");
  const [showModale, setShowModale] = useState(false);
  const [showModalr, setShowModalr] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const [selectedItem, setSelectedItem] = useState<IEvents[]>([]);
  const [selectedReferrerRewards, setSelectedReferrerRewards] = useState<
    ITokens[]
  >([]);
  const [selectedRefereeRewards, setSelectedRefereeRewards] = useState<
    ITokens[]
  >([]);

  const [form, setForm] = useState<any>({
    referalType: "",
    events: [],
    tokens: [],
  });

  const [selectedPercentageType, setSelectedPercentageState] = useState(
    initialSelectedPercentageTypeState
  );
  const [day, setDay] = useState("");
  const [date, setDate] = useState<Date | null>(null);
  const [datee, setDatee] = useState<Date | null>(null);
  const [general, setGeneral] = useState<string>("Code Based");
  const [priority, setPriority] = useState("");
  const [allows, setAllows] = useState<boolean>(false);
  const [allowed, setAllowed] = useState<boolean>(false);
  const [active, setActive] = useState<string>("Referrer-Rewards");
  const [selectedReferalData, setSelectedReferalData] = useState<any>({});

  const { getEventsResp } = useSelector((state: any) => state.events);

  const { getUserVariableRespStatus, getUserVariableResp, userFinalFilter } =
    useSelector((state: any) => state.user);
  const { getTokensResp, getTokensRespStatus } = useSelector(
    (state: any) => state.tokens
  );

  const { active: act, type: typ } = useSelector(
    (state: any) => state.customToken
  );

  const {
    isGettingReferrals,
    getReferralsResp,
    getReferralsRespStatus,
    isUpdatingReferral,
    updateReferralResp,
    updateReferralRespStatus,
    updateReferralError,
    isAddingReferral,
    addReferralResp,
    addReferralRespStatus,
    addReferralError,
    isDeletingReferral,
    deleteReferralResp,
    deleteReferralRespStatus,
    deleteReferralError,
  } = useSelector((state: any) => state.referral);

  const { user } = useUserAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getTokensRespStatus !== 200) {
      dispatch(getTokens({ token: user.accessToken }));
    }
    dispatch(getEvents({ token: user.accessToken }));
    dispatch(getReferrals({ token: user.accessToken }));
    dispatch(getUserVariable({ token: user.accessToken }));
  }, []);
  useEffect(() => {
    if (addReferralRespStatus === 200 || updateReferralResp === 200) {
      dispatch(getReferrals({ token: user.accessToken }));
    } else if (addReferralError || updateReferralError) {
      alert("Something went wrong");
    }
  }, [isUpdatingReferral, isAddingReferral]);

  useEffect(() => {
    if (deleteReferralRespStatus === 200) {
      dispatch(getReferrals({ token: user.accessToken }));
    }
  }, [deleteReferralResp]);

  useEffect(() => {
    if (selectedReferal && getReferralsResp) {
      setSelectedReferalData(
        getReferralsResp?.data?.value?.find(
          (each: any) => each._id === selectedReferal
        )
      );
    }
  }, [selectedReferal, getReferralsResp]);

  useEffect(() => {
    if (selectedReferalData) {
      handleOpenExistingReferal();
    }
  }, [selectedReferalData]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModalRef = (rewardType: string) => {
    setShowModalr(true);
    setActive(rewardType);
  };

  const handleCloseModalRef = () => {
    setShowModalr(false);
  };
  const handleOpenModalEvent = () => {
    setShowModale(true);
  };

  const handleOpen = (type: string) => {
    dispatch(toggleCustomTokenModal({ id: uuidv4(), type: type }));
  };
  const handleClose = () => {
    dispatch(toggleCustomTokenModal({ id: "" }));
  };
  const handleCloseModalEvent = () => {
    setShowModale(false);
  };
  const handleName = (name: string) => {
    setName(name);
  };
  const handlePriority = (priority: any) => {
    setPriority(priority);
  };
  const handleGeneral = (general: string) => {
    setGeneral(general);
  };
  const handleDate = (date: Date | null) => {
    setDate(date);
  };
  const handleDatee = (datee: Date | null) => {
    setDatee(datee);
  };
  const handleselectedItem = (selectedItem: any) => {
    setSelectedItem(selectedItem);
  };
  const handleSelectedReferrerRewards = (selectedReferrerRewards: any) => {
    setSelectedReferrerRewards(selectedReferrerRewards);
  };
  const handleSelectedRefereeRewards = (selectedRefereeRewards: any) => {
    setSelectedRefereeRewards(selectedRefereeRewards);
  };
  const handleSetAllows = (allows: boolean) => {
    setAllows(allows);
  };
  const handleSetDay = (day: any) => {
    setDay(day);
  };
  const handleSetAllowed = (allowed: boolean) => {
    setAllowed(allowed);
  };

  const handleSetActive = (active: any) => {
    setActive(active);
  };

  const handleSelectedPercentageState = (
    theme: "referee" | "referrer",
    event_id: string,
    token_id: string,
    valueType: "min_spend" | "cap_value"
  ) => {
    setSelectedPercentageState((prevState) => ({
      ...prevState,
      [theme]: {
        ...prevState[theme],
        [event_id]: {
          ...prevState[theme]?.[event_id],
          [token_id]: {
            ...prevState[theme]?.[event_id]?.[token_id],
            [valueType]: !prevState[theme]?.[event_id]?.[token_id]?.[valueType],
          },
        },
      },
    }));
  };

  // const handleDelete = (ref_id: any) => {
  //   try {
  //     if (ref_id) {
  //       dispatch(
  //         deleteReferral({ token: user.accessToken, referral_id: ref_id })
  //       );
  //     } else {
  //       throw new Error("No referral selected for deletion");
  //     }
  //   } catch (error) {
  //     console.error("Delete Error:", error);
  //   }
  // };
  const handleDelete = (ref_id: any) => {
    try {
      if (ref_id) {
        dispatch(
          toggleConfirmationPopup({
            isConfirmation: true,
            confirmationFunction: () => {
              try {
                dispatch(
                  deleteReferral({
                    token: user.accessToken,
                    referral_id: ref_id,
                  })
                );
                dispatch(toggleConfirmationPopup({ isConfirmation: false }));
              } catch (error) {
                console.error("Delete Error:", error);
              }
            },
            confirmationText: "Do you really want to delete the referral?",
          })
        );
      } else {
        throw new Error("No referral selected for deletion");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleCont = () => {
    setIsEditing(true);
    setForm((prevState: any) => ({
      ...prevState,
      referalType: general,
    }));
    handleCloseModal();
  };
  const handleConti = () => {
    setIsEditing(true);
    setForm((prevState: any) => ({
      ...prevState,
      events: selectedItem,
    }));
    handleCloseModalEvent();
  };
  const handleContr = () => {
    setIsEditing(true);
    setForm((prevState: any) => ({
      ...prevState,
      token: {
        referrer: selectedReferrerRewards,
        referee: selectedRefereeRewards,
      },
    }));
    handleCloseModalRef();
  };
  const handleEdit = () => {
    setIsEditing(() => true);
    handleOpenModal();
  };

  const resetAllStates = () => {
    setName("");
    setAddOrUpdateRefferal(false);
    setForm({ referalType: "", events: [], tokens: [] });
    setSelectedPercentageState(initialSelectedPercentageTypeState);
    setDay("");
    setDate(null);
    setDatee(null);
    setGeneral("Code Based");
    setPriority("");
    setAllows(false);
    setAllowed(false);
    setActive("Referrer-Rewards");
    setIsEditing(false);
    dispatch(
      addCustomTokenFinalUser({
        e: {
          strings: [],
          numbers: [],
          booleans: [],
          dates: [],
        },
      })
    );
  };

  function transformRewardsToNewFormat(rewards: any): any[] {
    return rewards.reduce((acc: any, item: any) => {
      return [
        ...acc,
        ...item.tokens.map((token: any) => {
          const newItem = {
            token_id: token.token_id,
            event_id: item.event_id,
            value: {} as {
              noTokens?: number;
              minSpend?: number;
              percentage?: number;
              capValue?: number;
            },
          };

          if (item.reward_type !== 2) {
            newItem.value.percentage = token.value;
          } else {
            newItem.value.minSpend = token.min_spend;

            if (token.cap_value) {
              newItem.value.percentage = token.value;
              newItem.value.capValue = token.cap_value;
            } else {
              newItem.value.noTokens = token.value;
            }
          }

          return newItem;
        }),
      ];
    }, []);
  }

  const handleOpenExistingReferal = () => {
    setName(selectedReferalData?.name);
    setSelectedPercentageState(initialSelectedPercentageTypeState);
    setDate(selectedReferalData?.start_date);
    setDatee(selectedReferalData?.end_date);
    setGeneral(selectedReferalData?.referral_type === 1 ? "Code Based" : "");
    setPriority(selectedReferalData?.priority);
    setActive("Referrer-Rewards");
    const refereeRewards = selectedReferalData.referee_rewards || [];
    const referrerRewards = selectedReferalData.referrer_rewards || [];

    const existingRefereeRewards = transformRewardsToNewFormat(refereeRewards);
    const existingReferrerRewards =
      transformRewardsToNewFormat(referrerRewards);

    setSelectedRefereeRewards(existingRefereeRewards);
    setSelectedReferrerRewards(existingReferrerRewards);
    const union = [...refereeRewards, ...referrerRewards];
    const uniqueObjects = Object.values(
      union.reduce((acc, obj) => {
        if (obj && obj.event_id) {
          acc[obj.event_id] = obj;
        }
        return acc;
      }, {})
    );

    const selectedEvents = getEventsResp?.data?.value?.flatMap((event: any) => {
      if (uniqueObjects.some((u: any) => u.event_id === event._id)) {
        return [event];
      }
      return [];
    });

    setSelectedItem(selectedEvents);
    dispatch(addCustomTokenFinalUser({ e: selectedReferalData.user_filter }));
  };

  function processEventTokens(events: any[], selectedTokens: any[]): any[] {
    return events.map((event: any) => {
      const event_id: string = event._id;
      const eventTokens: any[] = selectedTokens
        .filter((token: any) => token.event_id === event_id)
        .map(({ value, token_id }: any) => {
          const v: number = value?.noTokens || value?.percentage;

          const tokenData: any = {
            value: v,
            token_id,
          };

          if (value?.minSpend !== undefined || null) {
            tokenData.min_spend = parseInt(value?.minSpend);
          }

          if (value?.capValue !== undefined || null) {
            tokenData.cap_value = parseInt(value?.capValue);
          }

          return tokenData;
        });

      return {
        event_id,
        reward_type:
          event?.transaction_type === 0 || event?.is_transaction_based === false
            ? 1
            : 2,
        tokens: eventTokens,
      };
    });
  }

  const handleSave = async () => {
    try {
      setIsEditing(false);

      const referrer_rewards =
        selectedReferrerRewards.length > 0
          ? processEventTokens(selectedItem, selectedReferrerRewards)
          : [];

      const referee_rewards =
        selectedRefereeRewards.length > 0
          ? processEventTokens(selectedItem, selectedRefereeRewards)
          : [];

      let referralData: any = {
        token: user.accessToken,
        name: name,
        start_date: date,
        end_date: datee,
        referral_type: form.referalType === "Code Based" ? 1 : 2,
        priority: parseInt(priority),
        referrer_rewards,
        referee_rewards,
        user_filter: userFinalFilter,
      };

      if (selectedReferal) {
        dispatch(
          updateReferral({ ...referralData, referral_id: selectedReferal })
        );
      } else {
        dispatch(addReferral(referralData));
      }

      resetAllStates();
      setTimeout(() => {
        dispatch(getReferrals({ token: user.accessToken }));
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="bg-[#F9F9F9] h-[calc(100vh-133px)] overflow-y-auto">
      <div className="flex items-center justify-between mt-5">
        <div className="text-2xl">
          <span className="font-bold">Snipe</span>{" "}
          <span style={{ fontFamily: "cardo", fontStyle: "italic" }}>
            Share
          </span>{" "}
          <span className="font-bold">Campaigns</span>
        </div>
        <div className="flex items-center gap-3">
          <button
            className="outline-none flex items-center gap-2 bg-[#FF7B5F] text-white rounded px-5 py-2 cursor-pointer"
            onClick={() => {
              resetAllStates();
              setSelectedReferral("");
              setSelectedReferalData({});
              handleOpenModal();
              setAddOrUpdateRefferal(true);
            }}
          >
            Add Referral
          </button>
        </div>
      </div>
      {addOrUpdateRefferal && (
        <div className="bg-[#FFF2F1] rounded-lg w-full p-5 mt-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="text-xl font-bold">
                {name ? name : <>Referral Name</>}
              </div>
              <div>|</div>

              <div className="flex items-center gap-1">
                <div className="text-[12px]">Priority: </div>
                <div className="text-[12px]">{priority}</div>
              </div>
            </div>
            {isEditing ? (
              <div className="px-3 py-1 bg-[#BDBDBD] text-white font-bold rounded">
                Editing
              </div>
            ) : (
              <div className="px-3 py-1 bg-[#BDBDBD] text-white font-bold rounded">
                Saved
              </div>
            )}
          </div>
          <div className="flex flex-row justify-between mt-2 mb-4">
            <div className="mt-2">
              Starting Date: {date ? dayjs(date).format("DD/MM/YYYY") : "N/A"}
            </div>
            <div className="mt-2">
              Ending Date: {datee ? dayjs(datee).format("DD/MM/YYYY") : "N/A"}
            </div>
          </div>
          <div className="grid grid-cols-4 gap-3 bg-[#FF7B5F] rounded-t-lg text-white font-bold px-3 py-2 mt-2">
            <div className="col-span-1">Referral Type</div>
            <div className="col-span-1">For users with</div>
            <div className="col-span-1">For these events</div>
            <div className="col-span-1">Rewards</div>
          </div>
          <div className="bg-[#FFE8E6] rounded-b-lg px-3 py-2 grid grid-cols-4 gap-3">
            <div className="col-span-1 flex items-start">
              {general ? <Value name={general} value={""} /> : ""}
            </div>
            <div className="col-span-1">
              <div
                className="cursor-pointer"
                onClick={() => handleOpen("to-users")}
              >
                <div className="flex items-center flex-wrap">
                  {getUserVariableRespStatus === 200 &&
                    getUserVariableResp &&
                    userFinalFilter.strings
                      ?.filter((each: any) => each?.values?.length > 0)
                      ?.map((each: any) => {
                        return (
                          <Value
                            name={
                              getUserVariableResp?.data?.value?.find(
                                (ele: any) => ele._id === each.variable_id
                              ).name
                            }
                            value={
                              ["in", "nin"].includes(each.operation)
                                ? `[${each.values?.join(", ")}]`
                                : each.values
                            }
                            operator={each.operation}
                          />
                        );
                      })}
                </div>
                <div className="flex items-center flex-wrap">
                  {getUserVariableRespStatus === 200 &&
                    userFinalFilter.numbers
                      ?.filter((each: any) => each.values?.length > 0)
                      ?.map((each: any) => {
                        return (
                          <Value
                            name={
                              getUserVariableResp?.data?.value?.find(
                                (ele: any) => ele._id === each.variable_id
                              ).name
                            }
                            value={
                              ["bet"].includes(each.operation)
                                ? `[${each.values?.join(",")}]`
                                : each.values
                            }
                            operator={each.operation}
                          />
                        );
                      })}
                </div>
                <div className="flex items-center flex-wrap">
                  {getUserVariableRespStatus === 200 &&
                    userFinalFilter.booleans?.map((each: any) => {
                      return (
                        <Value
                          name={
                            getUserVariableResp?.data?.value?.find(
                              (ele: any) => ele._id === each.variable_id
                            ).name
                          }
                          value={each.values}
                        />
                      );
                    })}
                </div>
                <div className="flex items-center flex-wrap">
                  {getUserVariableRespStatus === 200 &&
                    userFinalFilter.dates?.map((each: any) => {
                      return (
                        <Value
                          name={
                            getUserVariableResp?.data?.value?.find(
                              (ele: any) => ele._id === each.variable_id
                            ).name
                          }
                          value={
                            new Date(each.value[0])
                              ?.toISOString()
                              ?.split("T")?.[0]
                          }
                          operator={each.operation}
                          multi={each.operation === "bet"}
                          secondVal={
                            new Date(each.value?.[1] || new Date().getTime())
                              ?.toISOString()
                              ?.split("T")?.[0]
                          }
                        />
                      );
                    })}
                </div>
                <Add text={"ADD RULES HERE"} />
              </div>
            </div>
            <div className="col-span-1" onClick={handleOpenModalEvent}>
              {selectedItem &&
              selectedItem.length > 0 &&
              getEventsResp &&
              getEventsResp?.data?.value.length > 0
                ? getEventsResp?.data?.value
                    .filter((event: any) =>
                      selectedItem.some((e: any) => e?._id === event._id)
                    )
                    .map((event: any) => (
                      <div className="flex flex-row">
                        <Value key={event._id} name={event.name} value={""} />
                      </div>
                    ))
                : ""}

              <Add text={"SELECT EVENTS"} />
            </div>
            <div className="col-span-1">
              <div className="grid grid-cols-5 gap-3">
                <div className="col-span-2 mt-1">Referrer Rewards</div>
                <div className="col-span-3">
                  <div className="flex items-center flex-wrap gap-1">
                    <div
                      className="cursor-pointer"
                      onClick={() => handleOpenModalRef("Referrer-Rewards")}
                    >
                      {selectedReferrerRewards &&
                      selectedReferrerRewards.length > 0 &&
                      getTokensResp &&
                      getTokensResp?.data?.value.length > 0
                        ? selectedReferrerRewards.map((referrerReward: any) => {
                            const matchingTokens =
                              getTokensResp?.data?.value.filter(
                                (token: any) =>
                                  referrerReward?.token_id === token._id
                              );
                            return matchingTokens.map((token: any) => (
                              <div className="flex flex-row" key={token._id}>
                                <Value
                                  key={token._id}
                                  name={token.name}
                                  value={
                                    selectedReferrerRewards?.find(
                                      (t) => t.token_id === token._id
                                    )?.value?.noTokens ||
                                    selectedReferrerRewards?.find(
                                      (t) => t.token_id === token._id
                                    )?.value?.percentage
                                  }
                                />
                              </div>
                            ));
                          })
                        : ""}

                      <Add text={"Add Rewards"} />
                    </div>
                  </div>
                </div>
                <div className="col-span-2 mt-1">Referee Rewards</div>
                <div className="col-span-3">
                  <div className="flex items-center flex-wrap gap-1">
                    <div
                      className="cursor-pointer"
                      onClick={() => handleOpenModalRef("Referee-Rewards")}
                    >
                      {selectedRefereeRewards &&
                      selectedRefereeRewards.length > 0 &&
                      getTokensResp &&
                      getTokensResp?.data?.value.length > 0
                        ? selectedRefereeRewards.map((refereeReward: any) => {
                            const matchingTokens =
                              getTokensResp?.data?.value.filter(
                                (token: any) =>
                                  refereeReward?.token_id === token._id
                              );

                            return matchingTokens.map((token: any) => (
                              <div className="flex flex-row" key={token._id}>
                                <Value
                                  key={token._id}
                                  name={token.name}
                                  value={
                                    refereeReward?.value?.noTokens ||
                                    refereeReward?.value?.percentage
                                  }
                                />
                              </div>
                            ));
                          })
                        : ""}

                      <Add text={"Add Rewards"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3 mt-5">
            <button
              className="outline-none bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-1"
              onClick={handleEdit}
            >
              Edit
            </button>
            {isEditing ? (
              <button
                className="vibrating-button outline-none bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-1"
                onClick={handleSave}
                disabled={isAddingReferral || isUpdatingReferral}
              >
                {isAddingReferral || isUpdatingReferral ? <Loader /> : "Save"}{" "}
              </button>
            ) : (
              <button
                className="vibrating-button outline-none bg-[#FF7B5F] text-white text-lg font-bold rounded cursor-pointer px-3 py-1"
                onClick={handleDelete}
              >
                {isDeletingReferral ? <Loader /> : "Delete"}{" "}
              </button>
            )}
          </div>
        </div>
      )}
      {getReferralsResp?.data?.value?.length < 1 &&
      addOrUpdateRefferal === false &&
      getReferralsRespStatus === 200 ? (
        <div className="flex items-center justify-center mt-5 border-4 border-[#EFF0F6] p-3 bg-white rounded">
          No existing referral. Add one!
        </div>
      ) : isDeletingReferral || isAddingReferral || isGettingReferrals ? (
        <div className="flex item-center justify-center w-full h-full">
          <Loader heavy />
        </div>
      ) : null}
      {getReferralsRespStatus === 200 &&
        getReferralsResp?.data?.value?.map((referral: any) => {
          return (
            <ReferralCard
              referral={referral}
              setSelectedReferral={setSelectedReferral}
              setAddOrUpdateReferral={setAddOrUpdateRefferal}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          );
        })}
      {/* Modal */}
      <Modal
        style={customStyles}
        isOpen={act}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => handleClose()}
        shouldCloseOnEsc={true}
      >
        {typ === "to-users" && <UsersWith />}
      </Modal>
      {showModal && (
        <AddReferral
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleCont={handleCont}
          name={name}
          setName={(name: string) => handleName(name)}
          priority={priority}
          setPriority={(priority: any) => handlePriority(priority)}
          general={general}
          setGeneral={(general: string) => handleGeneral(general)}
          date={date}
          setDate={(date: Date | null) => handleDate(date)}
          datee={datee}
          setDatee={(datee: Date | null) => handleDatee(datee)}
        />
      )}
      {showModale && (
        <AddEvent
          showModale={showModale}
          handleConti={handleConti}
          handleCloseModalEvent={handleCloseModalEvent}
          selectedItem={selectedItem}
          setSelectedItem={(selectedItem: any) =>
            handleselectedItem(selectedItem)
          }
          events={getEventsResp?.data?.value}
        />
      )}
      {showModalr && (
        <AddRewards
          showModalr={showModalr}
          handleContr={handleContr}
          handleCloseModalRef={handleCloseModalRef}
          selectedReward={selectedItem}
          selectedReferrerRewards={selectedReferrerRewards}
          selectedRefereeRewards={selectedRefereeRewards}
          setSelectedReferrerRewards={(selectedItems: any) =>
            handleSelectedReferrerRewards(selectedItems)
          }
          setSelectedRefereeRewards={(selectedItems: any) =>
            handleSelectedRefereeRewards(selectedItems)
          }
          allows={allows}
          setAllows={(allows: boolean) => handleSetAllows(allows)}
          day={day}
          setDay={(day: any) => handleSetDay(day)}
          allowed={allowed}
          setAllowed={(allowed: boolean) => handleSetAllowed(allowed)}
          active={active}
          setActive={(active: string) => handleSetActive(active)}
          type={selectedPercentageType}
          setType={(
            theme: "referee" | "referrer",
            event_id: string,
            token_id: string,
            valueType: "min_spend" | "cap_value"
          ) =>
            handleSelectedPercentageState(theme, event_id, token_id, valueType)
          }
          tokens={getTokensResp?.data?.value}
          events={getEventsResp?.data?.value}
        />
      )}
    </div>
  );
};

export default Refferalp;

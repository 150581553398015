import { useEffect, useRef } from "react";

type TextProps = {
    text: string | number;
    setText: any;
    place: string;
    full: boolean;
    required?: boolean;
    type?: string;
    icon?: any;
    showMessage?: boolean;
    maxLength?: number;
};

const Text = ({
    text,
    setText,
    place,
    full,
    required,
    type = "text",
    icon,
    showMessage = false,
    maxLength,
}: TextProps) => {
    const ref = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (showMessage) {
            ref.current?.reportValidity();
        }
    }, [showMessage]);
    return (
        <div
            className={
                "flex items-center gap-1 relative" + (full ? " w-full" : "")
            }
        >
            <input
                ref={ref}
                type={type}
                placeholder={place}
                className={
                    "border-2 border-[#FFAFA8] px-3 py-2 outline-none rounded" +
                    (full ? " w-full" : "")
                }
                value={text}
                onChange={(e: any) => {
                    type === "number"
                        ? setText(
                              e.target.value.length > 10
                                  ? e.target.value.slice(0, 10)
                                  : e.target.value
                          )
                        : setText(e.target.value);
                }}
                required={required ? true : false}
                min={0}
                maxLength={maxLength ? maxLength : 1000}
            />
            {icon && (
                <img src={icon} alt="input" className="absolute right-3" />
            )}
        </div>
    );
};
export default Text;

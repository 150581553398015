import { put, call, takeLatest } from "redux-saga/effects";
import { baseURL, endPoints } from "../../utils/apiEndPoints";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../utils/apiRequests";
import {
  ADD_USER_VARIABLE_INIT,
  UPDATE_USER_VARIABLE_INIT,
  GET_USER_VARIABLE_INIT,
  DELETE_USER_VARIABLE_INIT,
  SEARCH_USER_WITH_ID_INIT,
  FILTER_CREDIT_INIT,
  FILTER_CREDIT_INDIVIDUAL_INIT,
  GET_USER_TRANSACTION_HISTORY_INIT,
  GET_USER_TOKEN_TRANSACTION_HISTORY_INIT,
} from "../actionTypes";
import {
  addUserVariableSuccess,
  addUserVariableFailure,
  updateUserVariableSuccess,
  updateUserVariableFailure,
  getUserVariableSuccess,
  getUserVariableFailure,
  deleteUserVariableSuccess,
  deleteUserVariableFailure,
  searchUserWithIdSuccess,
  searchUserWithIdFailure,
  getUserTransactionHistorySuccess,
  getUserTransactionHistoryFailure,
  filterCreditSuccess,
  filterCreditFailure,
  filterCreditIndividualSuccess,
  filterCreditIndividualFailure,
  getUserTokenTransactionHistorySuccess,
  getUserTokenTransactionHistoryFailure,
} from "./userActions";

function* addUserVariable(action: any): any {
  try {
    const { token, ...reqData } = action.payload.params;
    const data = yield call(
      postRequest,
      `${baseURL}/${endPoints.addVariable}`,
      { ...reqData },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(addUserVariableSuccess(data));
    } else {
      yield put(addUserVariableFailure(data));
    }
  } catch (err: any) {
    yield put(addUserVariableFailure(err.message));
  }
}

export function* addUserVariableSaga() {
  yield takeLatest(ADD_USER_VARIABLE_INIT, addUserVariable);
}

function* updateUserVariable(action: any): any {
  try {
    const { token, variableId, ...reqData } = action.payload.params;
    const data = yield call(
      patchRequest,
      `${baseURL}/${endPoints.updateVariable}/${variableId}`,
      { ...reqData },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(updateUserVariableSuccess(data));
    } else {
      yield put(updateUserVariableFailure(data));
    }
  } catch (err: any) {
    yield put(updateUserVariableFailure(err.message));
  }
}

export function* updateUserVariableSaga() {
  yield takeLatest(UPDATE_USER_VARIABLE_INIT, updateUserVariable);
}

function* getUserVariable(action: any): any {
  try {
    // const { token, ...reqData } = action.payload.params;
    const data = yield call(
      getRequest,
      `${baseURL}/${endPoints.getVariables}`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(getUserVariableSuccess(data));
    } else {
      yield put(getUserVariableFailure(data));
    }
  } catch (err: any) {
    yield put(getUserVariableFailure(err.message));
  }
}

export function* getUserVariableSaga() {
  yield takeLatest(GET_USER_VARIABLE_INIT, getUserVariable);
}

function* deleteUserVariable(action: any): any {
  try {
    const { variableId } = action.payload.params;
    const data = yield call(
      deleteRequest,
      `${baseURL}/${endPoints.deleteVariable}/${variableId}`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(deleteUserVariableSuccess(data));
    } else {
      yield put(deleteUserVariableFailure(data));
    }
  } catch (err: any) {
    yield put(deleteUserVariableFailure(err.message));
  }
}

export function* deleteUserVariableSaga() {
  yield takeLatest(DELETE_USER_VARIABLE_INIT, deleteUserVariable);
}

function* filterCredit(action: any): any {
  try {
    const { token, ...reqData } = action.payload.params;
    const formatedReqData = reqData.tokens.map((each: any) => {
      return {
        token_id: each.token_id,
        value: each.value,
      };
    });
    const data = yield call(
      postRequest,
      `${baseURL}/${endPoints.filterCredit}`,
      { ...reqData, tokens: formatedReqData },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(filterCreditSuccess(data));
    } else {
      yield put(filterCreditFailure(data));
    }
  } catch (err: any) {
    console.log(err);
    yield put(filterCreditFailure(err.message));
  }
}

export function* filterCreditSaga() {
  yield takeLatest(FILTER_CREDIT_INIT, filterCredit);
}

function* filterCreditIndividual(action: any): any {
  try {
    const { token, ...reqData } = action.payload.params;
    const formatedReqData =
      reqData.tokens?.map((each: any) => {
        return {
          token_id: each.token_id,
          value: each.value,
        };
      }) || [];
    const data = yield call(
      postRequest,
      `${baseURL}/${endPoints.filterCreditIndividual}`,
      { ...reqData, tokens: formatedReqData },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(filterCreditIndividualSuccess(data));
    } else {
      yield put(filterCreditIndividualFailure(data));
    }
  } catch (err: any) {
    console.log(err);
    yield put(filterCreditIndividualFailure(err.message));
  }
}

export function* filterCreditIndividualSaga() {
  yield takeLatest(FILTER_CREDIT_INDIVIDUAL_INIT, filterCreditIndividual);
}

function* searchUserWithId(action: any): any {
  try {
    const { userId } = action.payload.params;
    const data = yield call(
      getRequest,
      `${baseURL}/${endPoints.userHistory}/${userId}`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(searchUserWithIdSuccess(data));
    } else {
      yield put(searchUserWithIdFailure(data));
    }
  } catch (err: any) {
    yield put(searchUserWithIdFailure(err.message));
  }
}

export function* searchUserWithIdSaga() {
  yield takeLatest(SEARCH_USER_WITH_ID_INIT, searchUserWithId);
}

function* getUserTransactionHistory(action: any): any {
  try {
    const { userId } = action.payload.params;
    const data = yield call(
      getRequest,
      `${baseURL}/${endPoints.userHistory}/${userId}`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(getUserTransactionHistorySuccess(data));
    } else {
      yield put(getUserTransactionHistoryFailure(data));
    }
  } catch (err: any) {
    yield put(getUserTransactionHistoryFailure(err.message));
  }
}

export function* getUserTransactionHistorySaga() {
  yield takeLatest(
    GET_USER_TRANSACTION_HISTORY_INIT,
    getUserTransactionHistory
  );
}
function* getUserTokenTransactionHistory(action: any): any {
  try {
    const { userId, transaction_type } = action.payload.params;

    const data = yield call(
      getRequest,
      `${baseURL}/${endPoints.userTransactionTokenHistory}/?user_id=${userId}${
        transaction_type !== "" ? `&transaction_type=${transaction_type}` : ""
      }`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(getUserTokenTransactionHistorySuccess(data));
    } else {
      yield put(getUserTokenTransactionHistoryFailure(data));
    }
  } catch (err: any) {
    yield put(getUserTokenTransactionHistoryFailure(err.message));
  }
}
export function* getUserTokenTransactionHistorySaga() {
  yield takeLatest(
    GET_USER_TOKEN_TRANSACTION_HISTORY_INIT,
    getUserTokenTransactionHistory
  );
}

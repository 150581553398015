import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_MULTIPLIERS_INIT,
  UPDATE_MULTIPLIER_INIT,
  ADD_MULTIPLIER_INIT,
  DELETE_MULTIPLIER_INIT,
} from "../actionTypes";
import {
  getMultipliersSuccess,
  getMultipliersFailure,
  updateMultiplierSuccess,
  updateMultiplierFailure,
  addMultiplierSuccess,
  addMultiplierFailure,
  deleteMultiplierSuccess,
  deleteMultiplierFailure,
} from "./multipliersActions";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../utils/apiRequests";
import { endPoints } from "../../utils/apiEndPoints";
import store from "../store";

function* getMultipliers(action: any): any {
  try {
    const data = yield call(
      getRequest,
      `${(store.getState().developerTools as any).url}/${
        endPoints.readMultiplier
      }`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(getMultipliersSuccess(data));
    } else {
      yield put(getMultipliersFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(getMultipliersFailure(err.message));
  }
}

export function* getMultipliersSaga() {
  yield takeLatest(GET_MULTIPLIERS_INIT, getMultipliers);
}

function* updateMultiplier(action: any): any {
  try {
    const { token, multiplier_id, ...reqData } = action.payload.params;
    const data = yield call(
      patchRequest,
      `${(store.getState().developerTools as any).url}/${
        endPoints.updateMultiplier
      }/${multiplier_id}`,
      { ...reqData },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(updateMultiplierSuccess(data));
    } else {
      yield put(updateMultiplierFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(updateMultiplierFailure(err.message));
  }
}

export function* updateMultiplierSaga() {
  yield takeLatest(UPDATE_MULTIPLIER_INIT, updateMultiplier);
}

function* addMultiplier(action: any): any {
  try {
    const { token, ...reqData } = action.payload.params;
    const data = yield call(
      postRequest,
      `${(store.getState().developerTools as any).url}/${
        endPoints.addMultiplier
      }`,
      { ...reqData },
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(addMultiplierSuccess(data));
    } else {
      yield put(addMultiplierFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(addMultiplierFailure(err.message));
  }
}

export function* addMultiplierSaga() {
  yield takeLatest(ADD_MULTIPLIER_INIT, addMultiplier);
}

function* deleteMultiplier(action: any): any {
  try {
    const { multiplier_id } = action.payload.params;
    const data = yield call(
      deleteRequest,
      `${(store.getState().developerTools as any).url}/${
        endPoints.deleteMultiplier
      }/${multiplier_id}`,
      { Authorization: `Bearer ${action.payload.params.token}` }
    );
    if (!data.error) {
      yield put(deleteMultiplierSuccess(data));
    } else {
      yield put(deleteMultiplierFailure(data.message));
    }
  } catch (err: any) {
    console.log(err);
    yield put(deleteMultiplierFailure(err.message));
  }
}

export function* deleteMultiplierSaga() {
  yield takeLatest(DELETE_MULTIPLIER_INIT, deleteMultiplier);
}

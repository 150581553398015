import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleModeEnvironment } from "../../redux/developerTools/developerToolsActions";
import { useNavigate } from "react-router-dom";

const ToggleSwitch: React.FC = () => {
  const dispatch = useDispatch();
  const { isProduction } = useSelector((state: any) => state.developerTools);
  const navigate = useNavigate();

  const handleToggle = () => {
    dispatch(toggleModeEnvironment({ isProduction: !isProduction }));
    navigate(0);
  };

  return (
    <div className="flex items-center space-x-2">
      <label className="flex items-center cursor-pointer gap-3 bg-[#eff0F6] border-2 border=[#E0E4ff] rounded-[100px] px-3 py-2">
        <div
          className={
            "mr-3 " +
            (isProduction === false
              ? " font-bold text-[#4C4C66]"
              : " font-medium text-gray-400")
          }
        >
          Developement
        </div>
        <div
          className={`relative ${
            isProduction ? "bg-[#4C4C66]" : "bg-gray-300"
          } w-10 h-6 rounded-full transition`}
        >
          <input
            type="checkbox"
            className="hidden"
            checked={isProduction}
            onChange={handleToggle}
          />
          <div
            className={`dot absolute top-1 ${
              isProduction ? "bg-white right-1" : "left-1 bg-gray-400"
            } w-4 h-4 rounded-full transition`}
          ></div>
        </div>
        <div
          className={
            "ml-3 " +
            (isProduction
              ? " font-bold text-[#4C4C66]"
              : " font-medium text-gray-400")
          }
        >
          Production
        </div>
      </label>
    </div>
  );
};

export default ToggleSwitch;

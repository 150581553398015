import {
    ADD_ATTRIBUTE_FAILURE,
    ADD_ATTRIBUTE_INIT,
    ADD_ATTRIBUTE_SUCCESS,
    DELETE_ATTRIBUTE_FAILURE,
    DELETE_ATTRIBUTE_INIT,
    DELETE_ATTRIBUTE_SUCCESS,
    GET_ATTRIBUTE_FAILURE,
    GET_ATTRIBUTE_INIT,
    GET_ATTRIBUTE_SUCCESS,
    UPDATE_ATTRIBUTE_FAILURE,
    UPDATE_ATTRIBUTE_INIT,
    UPDATE_ATTRIBUTE_SUCCESS,
} from "../actionTypes";

export const getAttributeSuccess = (data: any) => {
    return {
        type: GET_ATTRIBUTE_SUCCESS,
        data,
    };
};

export const getAttributeFailure = (error: any) => {
    return {
        type: GET_ATTRIBUTE_FAILURE,
        error,
    };
};

export const getAttribute = (params: any) => {
    const payload = { params };
    return {
        type: GET_ATTRIBUTE_INIT,
        payload,
    };
};

export const updateAttributeSuccess = (data: any) => {
    return {
        type: UPDATE_ATTRIBUTE_SUCCESS,
        data,
    };
};

export const updateAttributeFailure = (error: any) => {
    return {
        type: UPDATE_ATTRIBUTE_FAILURE,
        error,
    };
};

export const updateAttribute = (params: any) => {
    const payload = { params };
    return {
        type: UPDATE_ATTRIBUTE_INIT,
        payload,
    };
};

export const addAttributeSuccess = (data: any) => {
    return {
        type: ADD_ATTRIBUTE_SUCCESS,
        data,
    };
};

export const addAttributeFailure = (error: any) => {
    return {
        type: ADD_ATTRIBUTE_FAILURE,
        error,
    };
};

export const addAttribute = (params: any) => {
    const payload = { params };
    return {
        type: ADD_ATTRIBUTE_INIT,
        payload,
    };
};

export const deleteAttributeSuccess = (data: any) => {
    return {
        type: DELETE_ATTRIBUTE_SUCCESS,
        data,
    };
};

export const deleteAttributeFailure = (error: any) => {
    return {
        type: DELETE_ATTRIBUTE_FAILURE,
        error,
    };
};

export const deleteAttribute = (params: any) => {
    const payload = { params };
    return {
        type: DELETE_ATTRIBUTE_INIT,
        payload,
    };
};
